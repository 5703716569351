import { DateTermV2 } from '../../../../domain/value-object/DateTermV2'
import { DateTimeV2 } from '../../../../domain/value-object/DateTimeV2'
import { DateV2 } from '../../../../domain/value-object/DateV2'
import { ReferencedEntity } from '../../../../domain/value-object/ReferencedEntity'

export type SingleSheetPropertyId = string
export enum SingleSheetPropertyType {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  MULTI_LINE_TEXT = 'MULTI_LINE_TEXT',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  ENTITY_SEARCH = 'ENTITY_SEARCH',
}
export type SingleSheetProperty = {
  id: SingleSheetPropertyId
  type: SingleSheetPropertyType
  label: string
  required: boolean
}
export type SingleSheetPropertyValueType =
  | undefined
  | boolean
  | number
  | string
  | string[]
  | DateV2
  | DateTermV2
  | DateTimeV2
  | ReferencedEntity
  | ReferencedEntity[]

import {
  ClickAwayListener,
  IconButton,
  ListItemButton,
  Popper,
  styled,
} from '@mui/material'
import { CSSProperties, useCallback, useMemo, useRef, useState } from 'react'
import { colorPalette } from '../../style/colorPallete'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { SelectOption } from './model'
import { CloseRounded } from '@mui/icons-material'
import ClearIcon from '../icons/ClearIcon'

type SelectProps<Value extends any, Option extends SelectOption<Value>> = {
  value: Value | undefined
  options: Option[]
  // onChange is optional in case it is disabled.
  onChange?: (v: Value | undefined) => void
  disabled?: boolean
  clearable?: boolean
  renderOption?: (v: Option) => JSX.Element
  renderValue?: (v: Option | undefined) => JSX.Element
} & StyleProps
type StyleProps = {}
export const Select = <Value extends any, Option extends SelectOption<Value>>({
  value,
  options,
  onChange,
  disabled = false,
  clearable = false,
  renderOption: _renderOption,
  renderValue: _renderValue,
}: SelectProps<Value, Option>) => {
  const [open, setOpen] = useState<boolean>(false)
  const toggle = useCallback(() => {
    if (disabled) return
    setOpen(prev => !prev)
  }, [disabled])
  const close = useCallback(() => setOpen(false), [])
  const ref = useRef<HTMLDivElement>(null)
  const onClick = useCallback(
    (option: Option) => {
      onChange && onChange(option.value)
      close()
    },
    [close, onChange]
  )
  const renderOption = useCallback(
    (v: Option) => {
      if (_renderOption) return _renderOption(v)
      return <MenuItemText>{v.name}</MenuItemText>
    },
    [_renderOption]
  )
  const currentValueComponent = useMemo(() => {
    const option = options.find(opt => opt.value === value)
    if (_renderValue) return _renderValue(option)
    return <SelectedValue>{option?.name}</SelectedValue>
  }, [_renderValue, options, value])
  const clear = useCallback(() => {
    if (!clearable) return
    onChange && onChange(undefined)
  }, [clearable, onChange])
  return (
    <>
      <Root>
        <SelectableArea ref={ref} onClick={toggle}>
          <SelectedValueArea>{currentValueComponent}</SelectedValueArea>
          <ExpandMoreIcon open={open} />
        </SelectableArea>
        {value && clearable && (
          <ClearIconArea>
            <ClearIconButton onClick={clear}>
              <ClearIcon />
            </ClearIconButton>
          </ClearIconArea>
        )}
      </Root>
      <StyledPopper
        open={open}
        anchorEl={ref.current}
        placement="bottom-start"
        disablePortal={true}
        sx={{
          zIndex: 9999,
        }}
      >
        <ClickAwayListener onClickAway={close}>
          <PopperRoot width="150px">
            <Menu>
              {options.map((v, i) => (
                <MenuItem
                  key={i}
                  onClick={() => {
                    onClick(v)
                  }}
                >
                  {renderOption(v)}
                </MenuItem>
              ))}
            </Menu>
          </PopperRoot>
        </ClickAwayListener>
      </StyledPopper>
    </>
  )
}

const Root = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
})
const SelectableArea = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
})
const SelectedValueArea = styled('div')({
  flex: '1 1 0%',
})
const SelectedValue = styled('span')({
  color: colorPalette.monotone[10],
  fontSize: '12px',
})
const ClearIconArea = styled('div')({
  position: 'absolute',
  right: '20px',
})
const ClearIconButton = styled(IconButton)({
  padding: '2px',
})
const ExpandMoreIcon = styled(ExpandMoreRoundedIcon)(
  ({ open }: { open: boolean }) => {
    const transform = useMemo(() => {
      if (open) return 'rotate(180deg)'
      return 'none'
    }, [open])
    return {
      color: colorPalette.monotone[4],
      transform,
      transition: 'transform .2s',
    }
  }
)
const StyledPopper = styled(Popper)({
  zIndex: 10,
})
type PopperRootProps = {
  width?: CSSProperties['width']
  height?: CSSProperties['height']
}
const PopperRoot = styled('div')(({ width, height }: PopperRootProps) => ({
  backgroundColor: colorPalette.monotone[0],
  borderRadius: '4px',
  boxShadow: `0px 4px 16px 0px ${colorPalette.monotone[4]}80`,
  padding: '8px 0',
  width,
  height,
}))
const Menu = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
})
const MenuItem = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  color: colorPalette.monotone[4],
})
const MenuItemText = styled('span')({
  fontSize: '14px',
  lineHeight: 1,
  color: 'inherit',
  '&:hover': {
    // color: colorPalette.monotone[4],
    // backgroundColor: colorPalette.skyBlue[0],
  },
})

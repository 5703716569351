import { useCallback, useMemo, useRef } from 'react'
import { useProjectPlanSelectGridOptions } from './gridOptions'
import { BulkSheetView } from '../../../../containers/BulkSheetView'
import { useProjectPlanSelectData } from './hooks/data'
import { CopyRoot } from '../../model'

type ProjectPlanSelectProps = {
  projectUuid: string | undefined
  selected: CopyRoot
  updateSelected: (root: CopyRoot) => void
  rootName: string
}
export const ProjectPlanSelect = ({
  projectUuid,
  selected,
  updateSelected,
  rootName,
}: ProjectPlanSelectProps) => {
  const selectedUuids = useMemo(() => {
    if (!projectUuid) return []
    if (selected.type === 'PROJECT') {
      return [projectUuid]
    }
    return [selected.uuid]
  }, [projectUuid, selected])
  const ref = useRef<HTMLDivElement>(null)
  const { rows } = useProjectPlanSelectData(
    projectUuid,
    selectedUuids,
    rootName
  )
  const gridOptions = useProjectPlanSelectGridOptions(
    projectUuid,
    updateSelected
  )
  return <BulkSheetView ref={ref} gridOptions={gridOptions} rowData={rows} />
}

import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { useCallback } from 'react'
import { Checkbox } from '../../../../components/inputs/Checkbox'

type CheckboxCellProps = {
  value: boolean | undefined
  onChange: (v: boolean | undefined) => void
} & CellPropsBase

export const CheckboxCell = ({
  label,
  value,
  onChange,
  cellWidth,
  required,
  validationErrors,
}: CheckboxCellProps) => {
  const onClick = useCallback(
    event => {
      onChange(!value)
    },
    [value, onChange]
  )
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea validationErros={validationErrors}>
        <Checkbox size={'m'} checked={!!value} onClick={onClick} />
      </CellValueArea>
    </FormCell>
  )
}

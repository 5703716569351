import { ClickAwayListener, Typography } from '@mui/material'
import { ImportExport as ImportExportIcon } from '@mui/icons-material'
import { intl } from '../../../../../i18n'
import { Color, FontSize, IconColor } from '../../../../../styles/commonStyles'
import { useCallback, useState } from 'react'
import { ColDef } from 'ag-grid-community'
import SortColumnListPopper from './SortColumnListPopper'
import { colorPalette } from '../../../../style/colorPallete'
import { SortedColumnState } from '../../../../model/bulkSheetColumnSortState'
import { BootstrapTooltip } from '../../../../components/tooltips/Tooltip/BootStrapToopTip'
import { isProduction } from '../../../../../utils/urls'

type Props = {
  onDeleteSortedColumn?: (column: string | ColDef<any>) => void
  onDeleteSortedAllColumns: (() => void) | undefined
  onChangeSortColumnState?:
    | ((column: string | ColDef<any>, sort: 'asc' | 'desc' | null) => void)
    | undefined
  sortColumnsState: SortedColumnState[]
  getDisplayColumnName?: (colDef: ColDef) => string | undefined
}

const SortButton = ({
  onDeleteSortedColumn,
  onDeleteSortedAllColumns,
  onChangeSortColumnState,
  sortColumnsState,
  getDisplayColumnName,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element>()
  const [mouseOver, setMouseOver] = useState(false)

  const handleClose = useCallback(() => setAnchorEl(undefined), [])
  const handleClick = useCallback(event => setAnchorEl(event.currentTarget), [])

  const handleMouseEnter = useCallback(() => setMouseOver(true), [])
  const handleMouseLeave = useCallback(() => setMouseOver(false), [])

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div style={{ minWidth: 'fit-content' }}>
          {isProduction && (
            <button
              style={{
                background: 'transparent',
                border: 0,
                fontSize: FontSize.MEDIUM,
                fontWeight: 500,
                display: 'flex',
                flexDirection: 'row',
                padding: '5px 10px',
                cursor: sortColumnsState.length >= 1 ? 'pointer' : '',
              }}
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              <Typography sx={{ color: colorPalette.monotone[5] }}>
                {intl.formatMessage({ id: 'wbs.header.sort.title' })}
              </Typography>
              <Typography
                sx={{
                  color:
                    sortColumnsState.length >= 1
                      ? colorPalette.skyBlue[7]
                      : colorPalette.monotone[3],
                  cursor: sortColumnsState.length >= 1 ? 'pointer' : '',
                }}
              >
                {sortColumnsState.length >= 1
                  ? sortColumnsState.length.toString()
                  : intl.formatMessage({ id: 'wbs.header.none.label' })}
              </Typography>
            </button>
          )}

          {!isProduction && (
            <BootstrapTooltip
              arrow={true}
              title={intl.formatMessage({
                id: 'wbs.header.sorted.table.column.count',
              })}
              open={mouseOver && !anchorEl}
              onClose={() => setMouseOver(false)}
              disableInteractive={true}
            >
              <button
                style={{
                  background: 'transparent',
                  border: 0,
                  fontSize: FontSize.MEDIUM,
                  fontWeight: 500,
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '5px 10px',
                  cursor: sortColumnsState.length >= 1 ? 'pointer' : '',
                }}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Typography sx={{ color: colorPalette.monotone[5] }}>
                  <ImportExportIcon
                    style={{
                      width: '16px',
                      height: '16px',
                      marginRight: '1px',
                      color: sortColumnsState && IconColor.GREY,
                      verticalAlign: 'middle',
                    }}
                  />
                </Typography>
                <Typography
                  sx={{
                    color:
                      sortColumnsState.length >= 1
                        ? colorPalette.skyBlue[7]
                        : colorPalette.monotone[3],
                    cursor: sortColumnsState.length >= 1 ? 'pointer' : '',
                  }}
                >
                  {sortColumnsState.length >= 1 ? (
                    <>
                      <span style={{ color: colorPalette.monotone[3] }}>
                        {intl.formatMessage({
                          id: 'wbs.header.filter.sort.label.separator',
                        })}
                      </span>
                      {sortColumnsState.length.toString()}
                    </>
                  ) : (
                    intl.formatMessage({
                      id: 'wbs.header.filter.sort.label.separator',
                    }) + intl.formatMessage({ id: 'wbs.header.none.label' })
                  )}
                </Typography>
              </button>
            </BootstrapTooltip>
          )}
        </div>
      </ClickAwayListener>
      {sortColumnsState.length >= 1 && (
        <SortColumnListPopper
          anchorEl={anchorEl}
          placement={'bottom-start'}
          onDeleteSortedColumn={onDeleteSortedColumn}
          onDeleteSortedAllColumns={onDeleteSortedAllColumns}
          onChangeSortColumnState={onChangeSortColumnState}
          sortColumnsState={sortColumnsState}
          getDisplayColumnName={getDisplayColumnName}
        />
      )}
    </>
  )
}

export default SortButton

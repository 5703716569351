import * as d3 from 'd3'

export type DateV2 = Date
export type DateV2ForApiRequest = string
type DateV2FormatPattern = 'YYYY/MM/DD' | 'YYYY-MM-DD'

const newDateFromString = (src: string): DateV2 => {
  return new Date(src)
}

const today = (): DateV2 => {
  const now = new Date()
  now.setHours(0)
  now.setMinutes(0)
  now.setSeconds(0)
  now.setMilliseconds(0)
  return now
}

const fromApiResponseToVo = (src: string | undefined): DateV2 | undefined => {
  if (!src) return undefined
  return d3.timeDay.floor(new Date(src))
}
const fromVoToApiRequest = (src: DateV2): string => {
  return formatDate(src, 'YYYY-MM-DD')
}

const format = (src: DateV2): string => {
  return formatDate(src, 'YYYY/MM/DD')
}

const isEqual = (a: DateV2 | undefined, b: DateV2 | undefined): boolean => {
  if (!a && !b) return true
  if (!a || !b) return false
  return a.valueOf() === b.valueOf()
}

export const dateV2Service = {
  newDateFromString,
  today,
  format,
  fromApiResponseToVo,
  fromVoToApiRequest,
  isEqual,
}

// Private
const formatDate = (
  src: DateV2,
  formatPattern?: DateV2FormatPattern
): string => {
  const year = src.getFullYear()
  const month = src.getMonth() + 1
  const fullMonth = month < 10 ? `0${month}` : month.toString()
  const date = src.getDate()
  const fullDate = date < 10 ? `0${date}` : date.toString()
  if ('YYYY-MM-DD' === formatPattern) {
    return `${year}-${fullMonth}-${fullDate}`
  }
  return `${year}/${fullMonth}/${fullDate}`
}

import { InputBase, Dialog, styled } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce'
import { colorPalette } from '../../../../style/colorPallete'
import { normalize } from '../../../../../utils/multilineText'
import { listItemHandler } from '../../../../../utils/markdown'
import CancelButton from '../../../../components/buttons/CancelButton'
import SaveButton from '../../../../components/buttons/SaveButton'
import MultilineTextEditor from '../../../../components/editors/multiline-text/MultilineTextEditor'

type MultilineTextCellProps = {
  dataUuid: string
  externalId: string
  value: string | undefined
  onChange?: (v: string | undefined) => void
} & CellPropsBase
export const MultilineTextCell = ({
  label,
  cellWidth,
  dataUuid,
  externalId,
  value,
  onChange,
  editable = true,
  required = false,
  validationErrors,
}: MultilineTextCellProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const onCellClick = useCallback(() => {
    if (!editable) return
    setOpen(true)
  }, [editable])
  const onClose = useCallback(() => {
    setOpen(false)
  }, [])
  const onSubmit = useCallback(() => {
    setOpen(false)
  }, [])
  const onCancel = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea onClick={onCellClick} validationErros={validationErrors}>
        <MarkdownBox className="markdown-body ag-grid-markdown-body">
          <ReactMarkdown
            children={value || ''}
            className="ag-grid-cell-markdown"
            linkTarget={'_blank'}
            remarkPlugins={[remarkGfm]}
            remarkRehypeOptions={{
              handlers: {
                listItem(h, node, parent) {
                  return listItemHandler(h, node, parent)
                },
              },
            }}
          />
        </MarkdownBox>
      </CellValueArea>
      <MultilineTextEditorDialog
        value={value || ''}
        onChange={
          onChange ||
          (() => {
            return
          })
        }
        open={open}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onClose={onClose}
        dataUuid={dataUuid}
        externalId={externalId}
      />
    </FormCell>
  )
}

const MultilineTextEditorDialog = ({
  value,
  onChange,
  open,
  onSubmit,
  onCancel,
  onClose,
  dataUuid,
  externalId,
}: {
  value: string
  onChange: (value: string | undefined) => void
  open: boolean
  onSubmit: () => void
  onCancel: () => void
  onClose: () => void
  dataUuid: string
  externalId: string
}) => {
  return (
    <Dialog
      PaperProps={{ sx: { maxWidth: '100% !important', height: '100%' } }}
      open={open}
      onClose={onClose}
    >
      <MultiLineTextEditorArea>
        <MultilineTextEditor
          dataUuid={dataUuid}
          externalId={externalId}
          value={value || ''}
          setValue={onChange}
          disableSbsMode={true}
        />
      </MultiLineTextEditorArea>
      <ButtonArea>
        <SaveButton onClick={onSubmit} />
        <CancelButton onClick={onCancel} />
      </ButtonArea>
    </Dialog>
  )
}

const MarkdownBox = styled('div')({
  height: '100%',
})
const MultiLineTextEditorArea = styled('div')({
  height: '100%',
  width: 640,
  margin: 10,
  border: '2px solid #dddddd',
  borderRadius: 4,
})
const ButtonArea = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 10,
})

import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { ProjectPlanRepository } from '../../applications/ports/projectPlanRepository'
import { CopyProjectPlanRequest } from '../../domain/entity/ProjectPlanEntity'
import { WbsItemType } from '../../domain/entity/WbsItemEntity'

export const useProjectPlanRepository = (): ProjectPlanRepository => {
  const getWithChildren = useCallback(
    async (
      projectUuid: string,
      wbsItemTypes: WbsItemType[] | undefined,
      rootProjectPlanUuid: string | undefined
    ) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/plans/descendant',
        {
          projectUuid,
          wbsItemTypes,
          rootProjectPlanUuid,
        }
      )
      return response.json
    },
    []
  )
  const copy = useCallback(async (request: CopyProjectPlanRequest) => {
    const response = await api.functional.request(
      'POST',
      '/api/v1/projects/plans/copy',
      request
    )
    return response.json
  }, [])
  return { getWithChildren, copy }
}

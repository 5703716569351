import {
  Dialog,
  DialogTitle,
  DialogContent,
  PaperProps,
  Paper,
  styled,
} from '@mui/material'
import { useCallback, useState } from 'react'
import Draggable from 'react-draggable'
import { ResizableBox } from 'react-resizable'
import { MarkdownPreview } from '../../../../../components/editors/markdown/Markdown/MarkdownPreview'
import { MarkdownType } from '../../../../../components/editors/multiline-text/MultilineTextEditor'

export const useMultiLineTextPreviewDialog = () => {
  const [open, setOpen] = useState(false)
  const openDialog = useCallback(() => {
    setOpen(true)
  }, [])
  const closeDialog = useCallback(() => {
    setOpen(false)
  }, [])
  return {
    open,
    openDialog,
    closeDialog,
  }
}
export type MultiLineTextProps = {
  text: string
  open: boolean
  openDialog: () => void
  closeDialog: () => void
}
export const MultiLineTextPreviewDialog = ({
  text,
  open,
  closeDialog,
  title,
}: MultiLineTextProps & {
  title?: string
}) => {
  return (
    <StyledDialog
      onClose={closeDialog}
      open={open}
      PaperComponent={DraggablePaper}
      maxWidth={false}
      aria-labelledby="change-log-multi-line-text-dialog"
    >
      <MarkdownPreviewBox
        height={Math.min(600, globalThis.innerHeight)}
        width={INITIAL_WIDTH}
        className="react-resizable"
      >
        {title && (
          <StyledDialogTitle id="change-log-multi-line-text-dialog-title">
            {title}
          </StyledDialogTitle>
        )}
        <StyledDialogContent id="change-log-multi-line-text-preview">
          <MarkdownPreview
            text={text || ''}
            markdownType={MarkdownType.Preview}
          />
        </StyledDialogContent>
      </MarkdownPreviewBox>
    </StyledDialog>
  )
}

const INITIAL_WIDTH = Math.min(500, globalThis.innerWidth)
const StyledDialog = styled(Dialog)({})
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  flex: '0 1 auto',
  cursor: 'move',
  padding: theme.spacing(1),
}))
const StyledDialogContent = styled(DialogContent)({
  flex: '1 1 auto',
  borderTop: 'solid 1px #C4C4C4',
  padding: 5,
})
const MarkdownPreviewBox = styled(ResizableBox)({})
const DraggablePaper = (props: PaperProps) => {
  return (
    <Draggable
      handle="#change-log-multi-line-text-dialog-title"
      cancel="#change-log-multi-line-text-preview"
    >
      <DraggablePaperInner {...props} />
    </Draggable>
  )
}
const DraggablePaperInner = styled(Paper)({
  '& .MuiPaper-root': {
    position: 'absolute',
    top: 60,
    left: (globalThis.innerWidth - INITIAL_WIDTH) / 2,
    overflow: 'hidden',
  },
})

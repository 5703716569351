import { useState, useCallback, ChangeEvent, useMemo } from 'react'
import { styled } from '@mui/material'
import { TextInput } from '../../../../../components/inputs/TextInput'
import SubmitButton from '../../button/SubmitButton'
import CloseButton from '../../button/CloseButton'
import { colorPalette } from '../../../../../style/colorPallete'

type AddAttachmentLinkPopperContentProps = {
  submit: (url: string, name: string) => void
  close: () => void
}
export const AddAttachmentLinkPopperContent = ({
  submit,
  close,
}: AddAttachmentLinkPopperContentProps) => {
  const [url, setUrl] = useState<string>('')
  const [name, setName] = useState<string>('')

  const onChangeUrl = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value)
  }, [])
  const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }, [])

  const submitIsDisabled = useMemo(() => !url && !name, [url, name])

  const onSubmit = useCallback(() => {
    submit(url, name)
  }, [submit, url, name])

  return (
    <PopperContentRoot>
      <Inputs>
        <InputBox>
          <StyledTextInput
            value={url}
            onChange={onChangeUrl}
            placeholder="File URL"
          />
        </InputBox>
        <InputBox>
          <StyledTextInput
            value={name}
            onChange={onChangeName}
            placeholder="Link name"
          />
        </InputBox>
      </Inputs>
      <Buttons>
        <SubmitButton onClick={onSubmit} disabled={submitIsDisabled} />
        <CloseButton onClick={close} />
      </Buttons>
    </PopperContentRoot>
  )
}
const PopperContentRoot = styled('div')({
  padding: '0 4px',
})
const Inputs = styled('div')({
  width: '100%',
})
const InputBox = styled('div')({
  border: `1px solid ${colorPalette.monotone[2]}`,
  height: '100%',
  width: '100%',
  margin: '4px 0',
})
const StyledTextInput = styled(TextInput)({
  width: '100%',
})
const Buttons = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px',
  marginTop: '20px',
})

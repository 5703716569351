const isMonitored = () => {
  return typeof newrelic === 'object'
}

export const beginInteraction = (name: string) => {
  if (!isMonitored()) {
    return
  }
  // End browser interaction if there is one.
  newrelic.interaction().end()
  // Start new browser interaction.
  newrelic.interaction().setName(name).save()
}

export const traceFunc = <T extends Array<any>, U>(
  name: string,
  fn: (...args: T) => void
): ((...args: T) => void) => {
  if (!isMonitored()) {
    return fn
  }
  return (...args: T) => {
    newrelic.interaction().createTracer(name, () => fn(...args))()
  }
}

export const traceAndRun = (name: string, fn: () => void) => {
  if (!isMonitored()) {
    fn()
    return
  }
  newrelic.interaction().createTracer(name, fn)()
}

type PerformanceMonitoredActionType = 'usecase' | 'other'

type PageActionPerformance = {
  name: string
  start: number
  end: number
  duration: number
  actionType: PerformanceMonitoredActionType
}

const monitorAction = (name: string, action: PageActionPerformance) => {
  if (!isMonitored()) return
  newrelic.addPageAction(name, action)
  newrelic.addToTrace(action)
}

/**
 *
 * If you want to measure the performance of async function, use runUseCaseAsyncWithPerfMonitoring instead.
 *
 * @param name
 * @param fn
 * @returns
 */
export const runUseCaseWithPerfMonitoring = <T>(
  name: string,
  fn: () => T
): T => {
  const start = Date.now()
  const output = fn()
  const end = Date.now()
  monitorAction(name, {
    name,
    start,
    end,
    duration: end - start,
    actionType: 'usecase',
  })
  return output
}

/**
 *
 * @param name
 * @param fn
 * @returns
 */
export const runUseCaseAsyncWithPerfMonitoring = async <T>(
  name: string,
  fn: () => Promise<T>
): Promise<T> => {
  const start = Date.now()
  const output = await fn()
  const end = Date.now()
  monitorAction(name, {
    name,
    start,
    end,
    duration: end - start,
    actionType: 'usecase',
  })
  return output
}

export const runWithPerfMonitoring = <T>(name: string, fn: () => T): T => {
  const start = Date.now()
  const output = fn()
  const end = Date.now()
  monitorAction(name, {
    name,
    start,
    end,
    duration: end - start,
    actionType: 'other',
  })
  return output
}

export const runAsyncWithPerfMonitoring = async <T>(
  name: string,
  fn: () => Promise<T>
): Promise<T> => {
  const start = Date.now()
  const output = await fn()
  const end = Date.now()
  monitorAction(name, {
    name,
    start,
    end,
    duration: end - start,
    actionType: 'other',
  })
  return output
}

export const startReportUserInfo = (user: { uuid: string; code: string }) => {
  if (!isMonitored()) {
    return
  }
  // @ts-ignore "@types/new-relic-browser" does not support the latest NewRelic browser agent.
  newrelic.setCustomAttribute('userUuid', user.uuid, true)
  // @ts-ignore "@types/new-relic-browser" does not support the latest NewRelic browser agent.
  newrelic.setCustomAttribute('userCode', user.code, true)
  // @ts-ignore "@types/new-relic-browser" does not support the latest NewRelic browser agent.
  newrelic.setUserId(user.uuid)
}
export const endReportUserInfo = () => {
  if (!isMonitored()) {
    return
  }
  // @ts-ignore "@types/new-relic-browser" does not support the latest NewRelic browser agent.
  newrelic.setCustomAttribute('userUuid', null, true)
  // @ts-ignore "@types/new-relic-browser" does not support the latest NewRelic browser agent.
  newrelic.setCustomAttribute('userCode', null, true)
  // @ts-ignore "@types/new-relic-browser" does not support the latest NewRelic browser agent.
  newrelic.setUserId(null)
}
export const startReportTenantInfo = (tenant: {
  uuid: string
  alias: string
}) => {
  if (!isMonitored()) {
    return
  }
  // @ts-ignore "@types/new-relic-browser" does not support the latest NewRelic browser agent.
  newrelic.setCustomAttribute('tenantUuid', tenant.uuid, true)
  // @ts-ignore "@types/new-relic-browser" does not support the latest NewRelic browser agent.
  newrelic.setCustomAttribute('tenantAlias', tenant.alias, true)
}
export const endReportTenantInfo = () => {
  if (!isMonitored()) {
    return
  }
  // @ts-ignore "@types/new-relic-browser" does not support the latest NewRelic browser agent.
  newrelic.setCustomAttribute('tenantUuid', null, true)
  // @ts-ignore "@types/new-relic-browser" does not support the latest NewRelic browser agent.
  newrelic.setCustomAttribute('tenantAlias', null, true)
}

export const addActionLog = (
  name: string,
  params: Record<string, newrelic.SimpleType>
) => {
  if (!isMonitored()) return
  newrelic.addPageAction(name, params)
}

export const reportError = (message: string) => {
  if (!isMonitored()) {
    console.error(message)
  } else {
    newrelic.noticeError(message)
  }
}

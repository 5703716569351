import { Switch as MuiSwitch } from '@mui/material'
import styled from 'styled-components'
import { colorPalette } from '../../../style/colorPallete'

export const Switch = styled(MuiSwitch)(({ checked }) => ({
  width: 68,
  height: 32,
  padding: 5,
  '& .MuiSwitch-switchBase': {
    margin: 5,
    padding: 0,
    transform: 'translateX(1px)',
    '&.Mui-checked': {
      color: colorPalette.monotone[0],
      transform: 'translateX(35px)',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colorPalette.skyBlue[7],
    },
  },
  '& .MuiSwitch-thumb': {
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 18,
  },
}))

/**
 * @deprecated
 */
export const LegacySwitch = styled(MuiSwitch)(({ checked }) => ({
  padding: 8,
  width: '80px',
  height: '40px',
  borderRadius: '50%',
  marginLeft: '15px',
  marginRight: '0px',
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      content: checked ? '"ON"' : '""',
      left: 16,
      color: colorPalette.monotone[0],
      display: 'flex',
      alignItems: 'center',
    },
    '&:after': {
      content: checked ? '""' : '"OFF"',
      right: 27,
      color: colorPalette.monotone[4],
      display: 'flex',
      alignItems: 'center',
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 18,
    height: 18,
    margin: '2px 2px 2px 2px',
    position: 'relative',
    left: checked ? '19px' : '1px',
  },
  '& .Mui-checked': {
    color: `${colorPalette.monotone[0]} !important`,
  },
  '& .Mui-checked+.MuiSwitch-track': {
    backgroundColor: colorPalette.skyBlue[7],
    opacity: 1,
  },
}))

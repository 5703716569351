import API from '../../lib/commons/api'
import { TaskActualWorkEntity } from '../../domain/entity/TaskActualWorkEntity'
import { TaskActualWorkRepository } from './../../applications/ports/taskActualWorkRepository'
import { useCallback } from 'react'
import { UUIDV2 } from '../../domain/value-object/UUIDV2'
import { DateV2, dateV2Service } from '../../domain/value-object/DateV2'
import { WorkHour } from '../../domain/value-object/WorkHour'

export const useTaskActualWorkRepository = (): TaskActualWorkRepository => {
  const fetchMineByTaskUuidAndActualDate = useCallback(
    async (
      taskUuid: UUIDV2,
      actualDate: DateV2
    ): Promise<TaskActualWorkEntity> => {
      const response = await API.functional.request(
        'GET',
        '/api/v1/projects/task_actual_works/hour',
        { taskUuid, actualDate: dateV2Service.fromVoToApiRequest(actualDate) },
        true
      )
      const taskActualWorkObj = response.json
      return {
        taskUuid: taskActualWorkObj.taskUuid,
        actualDate: dateV2Service.fromApiResponseToVo(
          taskActualWorkObj.actualDate
        )!,
        hour: taskActualWorkObj.hour,
      }
    },
    []
  )
  const saveMine = useCallback(
    async (entity: TaskActualWorkEntity): Promise<void> => {
      const actualDateStr = dateV2Service.fromVoToApiRequest(entity.actualDate)
      await API.functional.request(
        'POST',
        '/api/v1/projects/task_actual_works/save',
        {
          taskUuid: entity.taskUuid,
          hour: entity.hour,
          actualDate: actualDateStr,
          actualDateTime: {
            startDateTime: Date.parse(`${actualDateStr} 00:00:00`),
            endDateTime: Date.parse(`${actualDateStr} 00:00:00`),
          },
        },
        true
      )
    },
    []
  )
  return {
    fetchMineByTaskUuidAndActualDate,
    saveMine,
  }
}

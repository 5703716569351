import { Map, List } from 'immutable'
import objects from '../utils/objects'
import { getFunctionByPath } from '../view/pages'

enum ActionType {
  PUSH_LAYER = 'PUSH_LAYER',
  POP_LAYER = 'POP_LAYER',
  REPLACE_OR_PUSH_LAYER = 'REPLACE_OR_PUSH_LAYER',
  PUT_HEADER_COMPONENT = 'PUT_HEADER_COMPONENT',
  ADD_CUSTOM_COMPONENTS = 'ADD_CUSTOM_COMPONENTS',
  CLEAR_HEADER_COMPONENTS = 'CLEAR_HEADER_COMPONENTS',
  REPLACE_HEADER_COMPONENT = 'REPLACE_HEADER_COMPONENT',
}

export type CheckResult = {
  ok: boolean
  message?: string
}

// Workaround: Consider putting this interface in somewhere more common, like lib.
export interface FunctionLayer {
  externalId: string
  code?: string
  params?: { [key: string]: any }
  titleComponent?: JSX.Element
  customHeaderComponents?: List<CustomComponent>
  onPreOpenInNew?: () => void
  onClose?: () => void
  auxiliaries?: any
  canOpenInNew?: () => CheckResult | Promise<CheckResult>
  canCopyUrlLink?: () => CheckResult | Promise<CheckResult>
  getHeaderComponentData?: (singleSheetData: any[], layer: FunctionLayer) => any
  hideHeader?: boolean // TODO Remove this property after rendering header on each page.
}

export const fromUrlToLayer = (): FunctionLayer | undefined => {
  // Consider dependency on functions/index
  const pathname = window.location.pathname
  const fn = getFunctionByPath(pathname)
  if (!fn) {
    return undefined
  }
  const externalId = fn.externalId
  const splittedPath = pathname.split('/')
  const functionPath = fn.defaultPath.substring(1)
  const code = splittedPath[splittedPath.indexOf(functionPath) + 1]
  const search = window.location.search
  const params = {}
  search
    .substring(1)
    .split('&')
    .forEach(s => {
      const keyValue = s.split('=')
      objects.setValue(params, keyValue[0], keyValue[1])
    })
  return {
    externalId,
    code,
    params,
  }
}

export const getUrlParamsFromLayer = (layer: FunctionLayer): string => {
  if (!layer.params) {
    return ''
  }
  const params = layer.params
  const paramsStrArray = Object.keys(params).map(key => `${key}=${params[key]}`)
  return paramsStrArray.join('&')
}

export interface CustomComponent {
  key: string
  layout: {
    position: 'left' | 'right' | 'end'
    index: number
  }
  component: JSX.Element
}

type State = {
  currentDepth: number
  layers: Map<number, FunctionLayer>
}

export const pushFunctionLayer = (newLayer: FunctionLayer) => ({
  type: ActionType.PUSH_LAYER,
  layer: newLayer,
})

export const popFunctionLayer = () => ({
  type: ActionType.POP_LAYER,
})

export const replaceOrPushFunctionLayer = (
  layer: FunctionLayer,
  depth: number = 0
) => ({
  type: ActionType.REPLACE_OR_PUSH_LAYER,
  depth,
  layer,
})

export const putHeaderComponent = (
  titleComponent?: JSX.Element,
  customComponents?: CustomComponent[]
) => ({
  type: ActionType.PUT_HEADER_COMPONENT,
  titleComponent,
  customComponents,
})

export const addCustomComponents = (customComponents: CustomComponent[]) => ({
  type: ActionType.ADD_CUSTOM_COMPONENTS,
  customComponents,
})

export const clearHeaderComponents = () => ({
  type: ActionType.CLEAR_HEADER_COMPONENTS,
})

export const replaceHeaderComponent = (customComponent: CustomComponent) => ({
  type: ActionType.REPLACE_HEADER_COMPONENT,
  customComponent,
})

export const reducer = (
  state: State = {
    currentDepth: 0,
    layers: Map(),
  },
  action: any
): State => {
  switch (action.type) {
    case ActionType.PUSH_LAYER: {
      const newDepth = state.currentDepth + 1
      return {
        ...state,
        currentDepth: newDepth,
        layers: state.layers.set(newDepth, action.layer),
      }
    }
    case ActionType.POP_LAYER: {
      return {
        ...state,
        currentDepth: Math.max(state.currentDepth - 1, 0),
        layers: state.layers.remove(state.currentDepth),
      }
    }
    case ActionType.REPLACE_OR_PUSH_LAYER: {
      return {
        ...state,
        layers: state.layers.set(action.depth, action.layer),
      }
    }
    case ActionType.PUT_HEADER_COMPONENT: {
      const currentLayer = state.layers.get(state.currentDepth)
      if (!currentLayer) {
        return state
      }
      let newCustomComponents = currentLayer.customHeaderComponents || List()
      if (action.customComponents) {
        action.customComponents.forEach(v => {
          const index = newCustomComponents.findIndex(c => c.key === v.key)
          if (index < 0) {
            return
          }
          newCustomComponents = newCustomComponents.remove(index)
        })
        newCustomComponents = newCustomComponents.push(
          ...(action.customComponents || [])
        )
      }
      const newLayers = state.layers.set(state.currentDepth, {
        ...currentLayer,
        titleComponent: action.titleComponent,
        customHeaderComponents: newCustomComponents,
      })
      return {
        ...state,
        layers: newLayers,
      }
    }
    case ActionType.ADD_CUSTOM_COMPONENTS: {
      const currentLayer = state.layers.get(state.currentDepth)
      if (!currentLayer) {
        return state
      }
      const newLayer = {
        ...currentLayer,
        titleComponent: undefined,
        customHeaderComponents: (
          currentLayer.customHeaderComponents || List()
        ).push(...(action.customComponents || [])),
      }
      return {
        ...state,
        layers: state.layers.set(state.currentDepth, newLayer),
      }
    }
    case ActionType.CLEAR_HEADER_COMPONENTS: {
      const currentLayer = state.layers.get(state.currentDepth)
      if (!currentLayer) {
        return state
      }
      const newLayer = {
        ...currentLayer,
        titleComponent: undefined,
        customHeaderComponents: undefined,
      }
      return {
        ...state,
        layers: state.layers.set(state.currentDepth, newLayer),
      }
    }
    case ActionType.REPLACE_HEADER_COMPONENT: {
      const currentLayer = state.layers.get(state.currentDepth)
      if (!currentLayer || !currentLayer.customHeaderComponents) {
        return state
      }
      const index = currentLayer.customHeaderComponents.findIndex(
        c => c.key === action.customComponent?.key
      )
      if (index < 0) {
        return state
      }
      const newLayer = {
        ...currentLayer,
        customHeaderComponents: currentLayer.customHeaderComponents.splice(
          index,
          1,
          action.customComponent
        ),
      }
      return {
        ...state,
        layers: state.layers.set(state.currentDepth, newLayer),
      }
    }
    default:
      return state
  }
}

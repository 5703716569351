import {
  CreateTicketInput,
  TicketEntity,
  ticketService,
  UpdateTicketDeltaInput,
} from '.'
import { EntityExtensionValue } from '../../../view/containers/meta/entityExtension'
import { IItemDelta } from '../../value-object/ItemDeltaInputVO'
import { UpdateTagInput } from '../WbsItemEntity'

export type RiskEntity = {
  uuid: string
  ticket: TicketEntity

  riskType?: string
  probability?: string
  impact?: string
  strategy?: string
  score?: number
}

export type RiskType = 'OMISSION'
export type Probability =
  | 'EXTREMELY_HIGH'
  | 'HIGH'
  | 'MIDDLE'
  | 'LOW'
  | 'EXTREMELY_LOW'

export type Impact =
  | 'EXTREMELY_HIGH'
  | 'HIGH'
  | 'MIDDLE'
  | 'LOW'
  | 'EXTREMELY_LOW'

export type Strategy =
  | 'AVOIDANCE'
  | 'TRANSFER'
  | 'REDUCTION'
  | 'RETENTION'
  | 'SHARING'

export type CreateRiskInput = {
  riskType: RiskType
  probability: Probability
  impact: Impact
  strategy: Strategy

  ticket: CreateTicketInput
  extensions?: EntityExtensionValue[]
}
export type CreateRiskRequest = {
  input: CreateRiskInput
  watchers: string[]
  tags: UpdateTagInput
}
export type UpdateRiskDeltaRequest = {
  input: UpdateRiskDeltaInput
  watchers: string[]
  tags: UpdateTagInput
}
export type UpdateRiskDeltaInput = {
  uuid: string
  ticket: UpdateTicketDeltaInput

  riskType?: IItemDelta<string>
  probability?: IItemDelta<string>
  impact?: IItemDelta<string>
  strategy?: IItemDelta<string>
}

export type UpdateRiskDeltaResult = {}

const fromApiResponseToEntity = (src: any): RiskEntity => {
  return {
    ...src,
    uuid: src.uuid,
    ticket: ticketService.fromApiResponseToEntity(src.ticket),
  }
}

export const riskService = {
  fromApiResponseToEntity,
}

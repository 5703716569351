import { styled } from '@mui/material'
import { WbsItemTitle } from './WbsItemTitle'
import { colorPalette } from '../../../../style/colorPallete'
import { useFunctionLayerDialogHeaderComponents } from '../../hooks/functionLayerDialogHeaderComponents'
import ProjectPlanBreadcrumbs from '../../../../components/headers/HeaderBar/Breadcrumbs/ProjectPlanBreadcrumbs'
import FavoriteIcon from '../../../../components/icons/FavoriteIcon'
import { UpdateFormModelValue, WbsItemFormModel } from '../../model'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../store'
import { useCallback, useMemo } from 'react'
import { UserDetail } from '../../../../../lib/functions/user'
import { WbsItemIcon } from '../../../../components/icons/WbsItemIcon'
import { WbsItemTypeVO } from '../../../../../domain/value-object/WbsItemTypeVO'

type WbsItemHeaderProps = {
  uuid: string
  isCreatingWbsItem: boolean
  title: string
  onChangeTitle: (v: string) => void
  type: WbsItemTypeVO
  watchers: WbsItemFormModel['watchers']
  onChangeWatchers: UpdateFormModelValue<'watchers'>
  functionLayerDepth: number
}
export const WbsItemHeader = ({
  uuid,
  isCreatingWbsItem,
  title,
  onChangeTitle,
  type,
  watchers,
  onChangeWatchers,
  functionLayerDepth,
}: WbsItemHeaderProps) => {
  const { leftHeaderComponents, rightHeaderComponents } =
    useFunctionLayerDialogHeaderComponents(functionLayerDepth)
  const me = useSelector<AllState, UserDetail | undefined>(
    state => state.user.user
  )
  const watching = useMemo(
    () => watchers && watchers.some(v => v.uuid === me?.uuid),
    [watchers, me]
  )
  const onClick = useCallback(
    (checked: boolean) => {
      if (!me) return
      if (watching && watchers) {
        onChangeWatchers(watchers.filter(v => v.uuid !== me.uuid))
      } else if (!watching) {
        const meAsWatcher = {
          uuid: me.uuid,
          name: me.name,
          iconUrl: me.iconUrl,
        }
        onChangeWatchers(watchers ? [...watchers, meAsWatcher] : [meAsWatcher])
      }
    },
    [watching, watchers, me, onChangeWatchers]
  )

  return (
    <HeaderRoot>
      {leftHeaderComponents}
      <WbsItemTypeIcon type={type} />
      <WbsItemTitle value={title} onChange={onChangeTitle} />
      <WatcherIcon
        checked={watching}
        onClick={onClick}
        style={{ margin: 'auto 0' }}
      />
      {!isCreatingWbsItem && (
        <ProjectPlanBreadcrumbs
          rootUuid={uuid}
          rootUuidType="wbsItem"
          itemName={title}
        />
      )}
      {rightHeaderComponents}
    </HeaderRoot>
  )
}
const HeaderRoot = styled('div')({
  display: 'flex',
  width: '100%',
  padding: '4px 12px',
  backgroundColor: colorPalette.monotone[0],
})
const WbsItemTypeIcon = styled(WbsItemIcon)({
  width: '19px',
})
const WatcherIcon = styled(FavoriteIcon)({
  margin: 'auto 4px',
})

import { useCallback, useEffect, useState } from 'react'
import api from '../../lib/commons/api'
import {
  WbsItemAdditionalPropertiesSaveRequest,
  WbsItemAdditionalPropertyRepository,
} from '../../applications/ports/wbsItemAdditionalPropertyRepository'
import {
  WbsItemTypeObject,
  WbsItemTypeVO,
} from '../../domain/value-object/WbsItemTypeVO'
import { UserBasic } from '../../lib/functions/user'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import {
  EntitySearchReferenceEntity,
  WbsItemAdditionalPropertyType,
} from '../../domain/entity/WbsItemAdditionalPropertyEntity'
import { I18nLabelObject } from '../../domain/value-object/I18nLabelVO'

export const useWbsItemAdditionalPropertyRepository =
  (): WbsItemAdditionalPropertyRepository => {
    const getByProjectUuid = useCallback(
      async (
        projectUuid: string
      ): Promise<WbsItemAdditionalPropertyLayoutEntity> => {
        return getWbsItemAdditionalProperties(projectUuid)
      },
      []
    )
    const save = useCallback(
      async (
        request: WbsItemAdditionalPropertiesSaveRequest
      ): Promise<void> => {
        await api.functional.request(
          'POST',
          '/api/v1/projects/wbs_item_additional_properties/batch',
          request
        )
        return
      },
      []
    )
    return {
      getByProjectUuid,
      save,
    }
  }

// WARNING Only use in only Redux(src/store/wbsItemAdditionalProperty.ts).
export const getWbsItemAdditionalProperties = async (
  projectUuid: string,
  useCache?: boolean
) => {
  const response = await api.functional.request(
    'GET',
    '/api/v1/projects/wbs_item_additional_properties',
    { projectUuid, useCache }
  )
  const layout: WbsItemAdditionalPropertiesFetchResponse = response.json

  return {
    projectUuid: layout.projectUuid,
    groupHeaderLayouts: layout.groupHeaderLayouts.map(
      ({ groupHeader, propertyLayouts, displayOrder }) => {
        return {
          groupHeader: {
            uuid: groupHeader.uuid,
            lockVersion: groupHeader.lockVersion,
            revision: groupHeader.revision,
            headerNameI18n: groupHeader.headerNameI18n,
            entityExtensionUuid: groupHeader.entityExtensionUuid,
            entityExtensionExternalId: groupHeader.entityExtensionExternalId,
            createdAt: groupHeader.createdAt,
            createdBy: groupHeader.createdBy,
            updatedAt: groupHeader.updatedAt,
            updatedBy: groupHeader.updatedBy,
          },
          displayOrder,
          propertyLayouts: propertyLayouts.map(
            ({ wbsItemAdditionalProperty, displayOrder }) => {
              return {
                wbsItemAdditionalProperty: {
                  uuid: wbsItemAdditionalProperty.uuid,
                  lockVersion: wbsItemAdditionalProperty.lockVersion,
                  revision: wbsItemAdditionalProperty.revision,
                  projectUuid: wbsItemAdditionalProperty.projectUuid,
                  propertyNameI18n: wbsItemAdditionalProperty.propertyNameI18n,
                  propertyType:
                    wbsItemAdditionalProperty.propertyType as WbsItemAdditionalPropertyType,
                  required: wbsItemAdditionalProperty.required,
                  selectOptions: wbsItemAdditionalProperty.selectOptions,
                  entitySearchReferenceEntity:
                    wbsItemAdditionalProperty.entitySearchReferenceEntity as
                      | EntitySearchReferenceEntity
                      | undefined,
                  wbsItemTypes: wbsItemAdditionalProperty.wbsItemTypes
                    ? wbsItemAdditionalProperty.wbsItemTypes.map(
                        v => new WbsItemTypeVO(v)
                      )
                    : undefined,
                  using: wbsItemAdditionalProperty.using,
                  entityExtensionUuid:
                    wbsItemAdditionalProperty.entityExtensionUuid,
                  entityExtensionExternalId:
                    wbsItemAdditionalProperty.entityExtensionExternalId,
                  createdAt: wbsItemAdditionalProperty.createdAt,
                  createdBy: wbsItemAdditionalProperty.createdBy,
                  updatedAt: wbsItemAdditionalProperty.updatedAt,
                  updatedBy: wbsItemAdditionalProperty.updatedBy,
                },
                displayOrder,
              }
            }
          ),
        }
      }
    ),
  }
}
interface WbsItemAdditionalPropertyObject {
  uuid: string
  lockVersion: number
  revision: string
  projectUuid: string
  propertyNameI18n: I18nLabelObject
  propertyType: string
  required: boolean
  selectOptions?: {
    uuid: string
    selectOption: string
  }[]
  wbsItemTypes: WbsItemTypeObject[]
  entitySearchReferenceEntity?: string
  using: boolean
  entityExtensionUuid?: string
  entityExtensionExternalId?: string
  createdAt: number
  createdBy?: UserBasic
  updatedAt: number
  updatedBy?: UserBasic
}
interface GroupHeaderObject {
  uuid: string
  lockVersion: number
  revision: string
  headerNameI18n: I18nLabelObject
  entityExtensionUuid?: string
  entityExtensionExternalId?: string
  createdAt: number
  createdBy?: UserBasic
  updatedAt: number
  updatedBy?: UserBasic
}
interface WbsItemAdditionalPropertyWithLayoutObject {
  projectUuid: string
  groupHeaderLayouts: {
    groupHeader: GroupHeaderObject
    displayOrder: number
    propertyLayouts: {
      wbsItemAdditionalProperty: WbsItemAdditionalPropertyObject
      displayOrder: number
    }[]
  }[]
}

type WbsItemAdditionalPropertiesFetchResponse =
  WbsItemAdditionalPropertyWithLayoutObject

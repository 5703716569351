import React, { useCallback, useMemo, useState } from 'react'
import './styles.scss'

interface Props {
  isLoading: boolean
  elem?: Element | null
}

const Loading: React.FC<Props> = (props: Props) => {
  const pos = props.elem ? props.elem.getBoundingClientRect() : undefined

  return props.isLoading ? (
    <div
      style={{
        position: pos ? 'absolute' : undefined,
        zIndex: 9999,
        overflow: 'hidden',
        top: pos ? pos.top : undefined,
        left: pos ? pos.left : undefined,
        width: pos ? pos.width : undefined,
        height: pos ? pos.height : undefined,
      }}
    >
      <div className={'loader'} />
    </div>
  ) : (
    <></>
  )
}

export default Loading

export const useLoading = () => {
  const [isLoading, setLoading] = useState(false)

  const showLoading = useCallback(() => {
    setLoading(true)
  }, [])
  const hideLoading = useCallback(() => {
    setLoading(false)
  }, [])

  const state = useMemo(
    () => ({
      isLoading,
      showLoading,
      hideLoading,
    }),
    [isLoading, showLoading, hideLoading]
  )
  return state
}

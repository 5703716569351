import { useEffect } from 'react'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import { Task } from './components/pages/Task'
import { Deliverable } from './components/pages/Deliverable'
import { useDispatch } from 'react-redux'
import { DeliverableList } from './components/pages/DeliverableList'
import { Process } from './components/pages/Process'
import { Refinement } from './components/pages/Refinement'
import { Ticket } from './components/pages/Ticket'
import { Risk } from './components/pages/Risk'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { loginToProject } from '../../../store/project'
import { useEditingTicketBasic, useWbsItemBasic } from './hooks/wbsItemBasic'
import { useApplicationFunctionUuid } from './hooks/applicationFunctionUuid'
import { TicketInitialValue } from './model/ticket'

type FunctionLayerProps = {
  // Re-consider how to implement.
  functionLayerDepth: number
  auxiliaries?: {
    editingTicket: TicketInitialValue
  }
}
type WbsItemV2Props = {
  code: string
} & FunctionLayerProps
export type WbsItemProps = {
  projectUuid: string
  wbsItemType: WbsItemTypeVO
  uuid: string
  functionUuid: string
} & FunctionLayerProps
export type TicketProps = {
  ticketType: string
  ticketInitialValue?: TicketInitialValue
} & WbsItemProps

export const WbsItemV2 = ({
  code,
  functionLayerDepth,
  auxiliaries,
}: WbsItemV2Props) => {
  const dispatch = useDispatch()
  const editingTicket = auxiliaries?.editingTicket
  const editingTicketBasic = useEditingTicketBasic(auxiliaries?.editingTicket)
  const basic = useWbsItemBasic(code, editingTicketBasic)
  const functionUuid = useApplicationFunctionUuid(basic)

  useEffect(() => {
    if (!basic || functionLayerDepth > 0) return
    dispatch(loginToProject(basic.projectUuid))
  }, [basic, functionLayerDepth, dispatch])

  if (!basic) return <></>

  const { projectUuid, uuid, type, ticketType, typeVo } = basic
  switch (type) {
    case WbsItemType.TASK:
      switch (ticketType) {
        case 'REFINEMENT_NEW':
          return (
            <Refinement
              projectUuid={projectUuid}
              wbsItemType={typeVo}
              uuid={uuid}
              functionUuid={functionUuid}
              ticketType={ticketType}
              functionLayerDepth={functionLayerDepth}
              ticketInitialValue={editingTicket}
            />
          )
        case 'RISK':
          return (
            <Risk
              projectUuid={projectUuid}
              wbsItemType={typeVo}
              uuid={uuid}
              functionUuid={functionUuid}
              ticketType={ticketType}
              functionLayerDepth={functionLayerDepth}
              ticketInitialValue={editingTicket}
            />
          )
      }
      if (!!ticketType) {
        return (
          <Ticket
            projectUuid={projectUuid}
            wbsItemType={typeVo}
            uuid={uuid}
            functionUuid={functionUuid}
            ticketType={ticketType}
            functionLayerDepth={functionLayerDepth}
            ticketInitialValue={editingTicket}
          />
        )
      }
      return (
        <Task
          projectUuid={projectUuid}
          wbsItemType={typeVo}
          uuid={uuid}
          functionUuid={functionUuid}
          functionLayerDepth={functionLayerDepth}
        />
      )
    case WbsItemType.DELIVERABLE:
      return (
        <Deliverable
          projectUuid={projectUuid}
          wbsItemType={typeVo}
          uuid={uuid}
          functionUuid={functionUuid}
          functionLayerDepth={functionLayerDepth}
        />
      )
    case WbsItemType.DELIVERABLE_LIST:
      return (
        <DeliverableList
          projectUuid={projectUuid}
          wbsItemType={typeVo}
          uuid={uuid}
          functionUuid={functionUuid}
          functionLayerDepth={functionLayerDepth}
        />
      )
    case WbsItemType.PROCESS:
      return (
        <Process
          projectUuid={projectUuid}
          wbsItemType={typeVo}
          uuid={uuid}
          functionUuid={functionUuid}
          functionLayerDepth={functionLayerDepth}
        />
      )
  }
  return <></>
}

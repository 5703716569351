import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { SelectOption } from '../../../../model/selectOption'
import { MultiSelect } from '../../../../components/inputs/MultiSelect'

type MultiSelectCellProps = {
  value: string[] | undefined
  onChange: (v: string[] | undefined) => void
  options: SelectOption[]
  displayIcon?: boolean
} & CellPropsBase

export const MultiSelectCell = ({
  label,
  options,
  value,
  onChange,
  cellWidth,
  required,
  displayIcon = false,
  validationErrors,
}: MultiSelectCellProps) => {
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea validationErros={validationErrors}>
        <MultiSelect value={value} onChange={onChange} options={options} />
      </CellValueArea>
    </FormCell>
  )
}

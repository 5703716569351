import * as React from 'react'

const BigFireIcon = React.forwardRef(
  (
    props: { style?: React.CSSProperties },
    ref: React.LegacyRef<SVGSVGElement>
  ) => (
    <svg
      {...props}
      width={props.style?.fontSize || 25}
      height={props.style?.fontSize || 25}
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
    >
      <g clip-path="url(#clip0_4678_162036)">
        <path
          d="M216.76 77.8799C212.22 73.2099 204.39 75.6799 203.11 82.0599C203.05 82.3799 202.97 82.6999 202.88 83.0299C198.8 98.5599 176.86 99.2699 171.17 84.2499C166.73 72.5099 163.52 57.2199 163.03 44.7199C162.6 33.6399 150.23 27.2699 140.95 33.3499C121.75 45.9299 107.88 73.5399 101.59 88.0999C99.23 93.5699 92.33 95.2199 87.71 91.4699C85.46 89.6499 83.32 87.5899 81.82 85.5199C76.41 78.0899 69.58 79.1999 65.21 87.2899C53.23 109.48 44 145 44 163.87C44 202.5 64.64 236.23 95.46 254.62C95.46 254.62 146.83 285.53 204.6 254.09C229.6 238.88 248.12 213.61 253.68 183.98C260.62 146.8 242.14 103.98 216.76 77.8899V77.8799Z"
          fill={props.style?.color || '#FF2727'}
        />
        <path
          d="M185.73 250.2C202.12 240.23 214.26 223.67 217.9 204.24C223.19 175.88 210.88 149 189.98 134C187.12 131.83 183.04 132.27 180.7 135.13C170.47 147.88 149.83 140.68 149.83 124.29V111.02C149.83 104.08 142.11 99.9198 136.39 103.74C116.19 117.27 80.46 147.44 80.46 191.06C80.46 216.38 93.99 238.49 114.19 250.55C114.19 250.55 146.55 269.15 185.73 250.2Z"
          fill={props.style?.color || '#FF7B22'}
        />
        <path
          d="M180.97 219.71C180.97 236.91 167.03 250.85 149.83 250.85C132.63 250.85 118.69 236.91 118.69 219.71C118.69 202.51 149.83 167.82 149.83 167.82C149.83 167.82 180.97 202.52 180.97 219.71Z"
          fill={props.style?.color || '#EAD303'}
        />
      </g>
      <defs>
        <clipPath id="clip0_4678_162036">
          <rect
            width="211.15"
            height="237.2"
            fill="white"
            transform="translate(44 31)"
          />
        </clipPath>
      </defs>
    </svg>
  )
)

export default BigFireIcon

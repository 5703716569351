import { styled } from '@mui/material'
import { Select } from '../../../../../components/select'
import { UpdateFormModelValue, WbsItemFormModel } from '../../../model'
import { ValuePanelInputRoot } from './commons'
import { SelectOptionWithIcon } from '../../../../../components/select/model'
import {
  renderOptionWithIcon,
  renderValueOnlyIcon,
} from '../../../../../components/select/variations'
import Tooltip from '../../../../../components/tooltips/Tooltip'

type PriorityInputProps = {
  label: string
  value: WbsItemFormModel['priority']
  onChange: UpdateFormModelValue<'priority'>
  options: SelectOptionWithIcon<string>[]
}
export const PriorityInput = ({
  label,
  value,
  onChange,
  options,
}: PriorityInputProps) => {
  return (
    <Tooltip message={label}>
      <Root>
        <Select
          value={value}
          onChange={onChange}
          options={options}
          renderOption={renderOptionWithIcon}
          renderValue={renderValueOnlyIcon}
        />
      </Root>
    </Tooltip>
  )
}

const Root = styled(ValuePanelInputRoot)({
  width: '40px',
})

import { useCallback, useMemo } from 'react'
import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import {
  toLabel,
  WbsItemStatus,
} from '../../../../../domain/entity/WbsItemEntity'
import { Select } from '../../../../components/select'
import { UpdateFormModelValue } from '../../../../pages/WbsItemV2/model'
import { WbsStatusLabel } from '../../../../components/data-display/WbsStatusLabel'
import { SelectOption } from '../../../../components/select/model'

type WbsStatusCellProps = {
  value: WbsItemStatus | undefined
  onChange?: UpdateFormModelValue<'status'>
} & CellPropsBase
export const WbsStatusCell = ({
  label,
  cellWidth,
  value,
  onChange,
  editable = true,
  required = false,
  validationErrors,
}: WbsStatusCellProps) => {
  const options = useMemo(
    () =>
      Object.values(WbsItemStatus).map(v => ({
        value: v,
        name: toLabel(v),
      })),
    []
  )
  const renderOption = useCallback(
    (option: SelectOption<WbsItemStatus>) => (
      <WbsStatusLabel status={option.value} />
    ),
    []
  )
  const renderValue = useCallback(
    (option: SelectOption<WbsItemStatus> | undefined) => {
      if (!option) return <></>
      return <WbsStatusLabel status={option.value} />
    },
    []
  )
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea validationErros={validationErrors}>
        <Select
          value={value}
          options={options}
          onChange={onChange}
          disabled={!editable}
          renderOption={renderOption}
          renderValue={renderValue}
        />
      </CellValueArea>
    </FormCell>
  )
}

import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  WbsItemBasic,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { useWbsItemRepository } from '../../../../services/adaptors/wbsItemRepositoryAdaptor'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import { wbsItemTypeRepository } from '../../../../domain/repository'
import { TicketEntity } from '../../../../domain/entity/ticket'
import { TicketInitialValue } from '../model/ticket'

export type WbsItemBasicInitialValue = Omit<WbsItemBasic, 'typeVo'>

export const useWbsItemBasic = (
  code: string,
  wbsItemBasicInitialValue?: WbsItemBasicInitialValue
): WbsItemBasic | undefined => {
  const [basic, setBasic] = useState<WbsItemBasic | undefined>()
  const { getBasicByCode } = useWbsItemRepository()
  useEffect(() => {
    const fn = async () => {
      if (wbsItemBasicInitialValue) {
        const wbsItemBasic = await createWbsItemBasic(wbsItemBasicInitialValue)
        setBasic(wbsItemBasic)
      } else {
        const wbsItemBasic = await getBasicByCode(code)
        setBasic(wbsItemBasic)
      }
    }
    fn()
  }, [code, getBasicByCode, wbsItemBasicInitialValue])

  return basic
}
export const useEditingTicketBasic = (
  editingTicket?: TicketInitialValue
): WbsItemBasicInitialValue | undefined => {
  const basic = useMemo<WbsItemBasicInitialValue | undefined>(() => {
    if (!editingTicket || !editingTicket.wbsItem) return
    return {
      projectUuid: editingTicket.wbsItem.projectUuid,
      uuid: editingTicket.wbsItem.uuid,
      type: editingTicket.wbsItem.type,
      ticketType: editingTicket.ticketType,
    } as WbsItemBasicInitialValue
  }, [editingTicket])
  return basic
}

const createWbsItemBasic = async ({
  projectUuid,
  uuid,
  type,
  ticketType,
}: WbsItemBasicInitialValue): Promise<WbsItemBasic> => {
  let typeVo: WbsItemTypeVO | undefined = undefined
  if (ticketType) {
    const ticketTypes = await wbsItemTypeRepository.getTicketTypes(projectUuid)
    typeVo = ticketTypes.find(v => v.code === ticketType)
  } else {
    const wbsItemTypes = await wbsItemTypeRepository.getWbsItemTypes(
      projectUuid
    )
    typeVo = wbsItemTypes.find(v => v.code === type)
  }
  if (!typeVo) {
    throw new Error(
      `WbsItemType is not found. wbsItemType=${type}, ticketType=${ticketType}`
    )
  }
  return {
    projectUuid,
    uuid,
    type,
    ticketType,
    typeVo,
  }
}

import { ColDef, IFilterParams } from 'ag-grid-community'
import { IconCellRenderer } from '../../../components/cellRenderer'
import {
  EntitySearchReferenceEntity,
  WbsItemAdditionalPropertyEntity,
} from '../../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import {
  ReferenceEntityValueType,
  wbsItemAdditionalPropertyValuesVoService,
} from '../../../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import { Option } from '..'
import { EntitySearchCellEditor } from '../../../components/cellEditor'
import { WbsItemType } from '../../../../../../domain/entity/WbsItemEntity'
import { filter } from '../../../../../pages/ProjectPlanNew/projectPlanNew'
import {
  ClientSideSelectFilter,
  EntitySearchFilter,
  EntitySearchFilterType,
  SelectFilter,
  ServerSideEntitySearchFilter,
  ServerSideSelectFilter,
} from '../../../components/filter'
import {
  EntitySearchReferenceEntityRepository,
  GetEntitySearchReferenceEntityRepository,
} from '../../../../../hooks/useWbsItemAdditionalProperties'

export const entitySearchColumnDef = <RowData>(
  column: ColDef,
  projectUuid: string,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>,
  getEntitySearchRepositories: GetEntitySearchReferenceEntityRepository
): void => {
  const repository = getEntitySearchRepositories(
    wbsItemAdditionalProperty.entitySearchReferenceEntity!
  )
  column.cellRenderer = IconCellRenderer
  column.cellRendererParams = {
    ...column.cellRendererParams,
    getLabelWithIconUrl: (
      rowData: RowData
    ):
      | {
          label: string
          iconUrl: string
        }
      | undefined => {
      const wbsItemAdditionalPropertyValues =
        option.getAdditionalPropertyValues(rowData)
      if (!wbsItemAdditionalPropertyValues) return undefined
      const wbsItemAdditionalPropertyValue =
        wbsItemAdditionalPropertyValuesVoService.getValue(
          wbsItemAdditionalPropertyValues,
          wbsItemAdditionalProperty.uuid
        )
      if (
        !wbsItemAdditionalPropertyValue ||
        !wbsItemAdditionalPropertyValue.value
      ) {
        return undefined
      }
      const referenceEntityValue =
        wbsItemAdditionalPropertyValue.value as ReferenceEntityValueType
      return {
        label: referenceEntityValue.name,
        iconUrl: referenceEntityValue.iconUrl,
      }
    },
  }
  column.cellEditor = EntitySearchCellEditor
  column.cellEditorParams = {
    search: (text: string, data) => repository.search(text),
  }
  setFilterParams(column, wbsItemAdditionalProperty, option, repository)
}

const setFilterParams = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>,
  referenceEntityRepository: EntitySearchReferenceEntityRepository
) => {
  const projectUuid = wbsItemAdditionalProperty.projectUuid
  switch (option.columnFilterType) {
    case 'CLIENT_SIDE':
      column.filter = ClientSideSelectFilter
      column.floatingFilter = !option.disableFloatingFilter
      column.filterParams = {
        valueGetter: params => {
          if (!params.node.data) return undefined

          const wbsItemAdditionalPropertyValues =
            option.getAdditionalPropertyValues(params.node.data)
          if (!wbsItemAdditionalPropertyValues) return undefined
          const wbsItemAdditionalPropertyValue =
            wbsItemAdditionalPropertyValuesVoService.getValue(
              wbsItemAdditionalPropertyValues,
              wbsItemAdditionalProperty.uuid
            )
          if (
            !wbsItemAdditionalPropertyValue ||
            !wbsItemAdditionalPropertyValue.value
          ) {
            return undefined
          }
          const referenceEntityValue =
            wbsItemAdditionalPropertyValue.value as ReferenceEntityValueType
          return referenceEntityValue
        },
        getValue: (option: ReferenceEntityValueType) => {
          return option.uuid
        },
        getLabel: (option: ReferenceEntityValueType) => {
          return option.name
        },
      }
      break
    case 'SERVER_SIDE':
      column.filter = ServerSideEntitySearchFilter
      column.floatingFilter = !option.disableFloatingFilter
      column.filterParams = {
        searchOptions: async (params: IFilterParams, text: string) =>
          referenceEntityRepository.search(text),
        fetch: (v: EntitySearchFilter) => {
          return filter({
            projectUuid: wbsItemAdditionalProperty.projectUuid,
            additionalPropertyValues: [
              {
                wbsItemAdditionalPropertyUuid: wbsItemAdditionalProperty.uuid,
                propertyType: wbsItemAdditionalProperty.propertyType,
                values: v.selectedValues.map(v => v.uuid),
                includeBlank: v.filterType === EntitySearchFilterType.BLANK,
              },
            ],
          })
        },
      }
      break
    default:
      break
  }
}

import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popper,
  PopperPlacementType,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ColDef } from 'ag-grid-community'
import { intl } from '../../../../../i18n'
import { Button } from '../../../../components/buttons'
import { FontSize } from '../../../../../styles/commonStyles'
import { colorPalette } from '../../../../style/colorPallete'
import { useCallback } from 'react'
import { isProduction } from '../../../../../utils/urls'

type Props = {
  anchorEl: any
  filteredColumns?: ColDef[]
  placement?: PopperPlacementType
  onDeleteFilteredColumn?: (column: ColDef) => void
  resetFilters: () => void
  getDisplayColumnName?: (colDef: ColDef) => string | undefined
}

const FilterPopper = ({
  anchorEl,
  filteredColumns,
  placement,
  onDeleteFilteredColumn,
  resetFilters,
  getDisplayColumnName,
}: Props) => {
  const getDisplayValue = useCallback(
    (colDef: ColDef): string => {
      const value = getDisplayColumnName
        ? getDisplayColumnName(colDef)
        : undefined
      return value ?? colDef.headerName ?? ''
    },
    [getDisplayColumnName]
  )
  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      placement={placement || 'right-start'}
      disablePortal={true}
      style={{
        zIndex: 1300,
        background: colorPalette.monotone[0],
        borderRadius: '4px',
        boxShadow: '0px 1px 2px #0000004D',
      }}
    >
      <List
        component="nav"
        style={{
          position: 'relative',
          overflow: 'auto',
          width: 'fit-content',
          padding: '10px',
          maxHeight: '430px',
        }}
      >
        {!isProduction && (
          <Typography
            sx={{
              color: colorPalette.monotone,
              fontSize: FontSize.MEDIUM,
              fontWeight: 400,
              textAlign: 'center',
            }}
          >
            {intl.formatMessage({ id: 'wbs.header.filter.criteria' })}
          </Typography>
        )}
        <Button
          colorPattern="monotone"
          onClick={resetFilters}
          sx={{
            border: 'none',
            background: 'transparent',
            padding: '15px 15px 15px 15px',
            cursor: 'pointer',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: colorPalette.pink[6],
              fontSize: FontSize.MEDIUM,
              fontWeight: 400,
            }}
          >
            {intl.formatMessage({ id: 'wbs.header.filter.popper.reset' })}
          </Typography>
        </Button>
        {filteredColumns?.map((column: ColDef, index) => {
          return (
            <ListItem
              key={`filteredColumn${index}`}
              onClick={e => {
                e.stopPropagation()
              }}
              sx={{
                padding: '0px',
                background: colorPalette.monotone[1],
                paddingRight: '8px',
                paddingLeft: '8px',
                border: 'none',
                borderRadius: '32px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '5px 0 10px 0',
              }}
            >
              <ListItemText
                primary={getDisplayValue(column)}
                primaryTypographyProps={{
                  width: 'fit-content',
                  minWidth: '150px',
                  height: '30px',
                  border: 'none',
                  color: colorPalette.monotone[4],
                  fontSize: FontSize.MEDIUM,
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 12px 0 16px',
                  textAlign: 'center',
                }}
                sx={{ margin: 0 }}
              />
              {onDeleteFilteredColumn && (
                <IconButton
                  style={{
                    padding: '3px',
                    background: colorPalette.monotone[0],
                  }}
                  onClick={() => onDeleteFilteredColumn(column)}
                >
                  <CloseIcon
                    fontSize="small"
                    style={{ height: '10px', width: '10px' }}
                  />
                </IconButton>
              )}
            </ListItem>
          )
        })}
      </List>
    </Popper>
  )
}

export default FilterPopper

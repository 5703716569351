import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { colorPalette } from '../../../../style/colorPallete'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { CSSProperties, useCallback, useMemo, useRef, useState } from 'react'
import { SelectOption } from '../../../../model/selectOption'
import { Select } from '../../../../components/select'

type SelectCellProps = {
  value: string | undefined
  onChange: (v: string | undefined) => void
  options: SelectOption[]
  displayIcon?: boolean
} & CellPropsBase

export const SelectCell = ({
  label,
  options,
  value,
  onChange,
  cellWidth,
  required,
  validationErrors,
  displayIcon = false,
}: SelectCellProps) => {
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea validationErros={validationErrors}>
        <Select
          value={value}
          onChange={onChange}
          options={options}
          clearable={true}
        />
      </CellValueArea>
    </FormCell>
  )
}

import { useEffect, useMemo } from 'react'
import { useWbsItemLabel } from '../../label'
import { intl } from '../../../../../../i18n'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'

export const useRefinementLabel = (
  wbsItemAdditionalProperties: WbsItemAdditionalPropertyLayoutEntity | undefined
) => {
  // TODO Remove after all entity extensions are transfered.
  const emptyList = useMemo(() => [], [])
  const labelMap = useWbsItemLabel(emptyList, wbsItemAdditionalProperties)
  useEffect(() => {
    labelMap.set('event', intl.formatMessage({ id: 'refinement.event' }))
    labelMap.set(
      'knownRefinement',
      intl.formatMessage({ id: 'refinement.knownRefinement' })
    )
    labelMap.set(
      'originalRefinement',
      intl.formatMessage({ id: 'refinement.originalRefinement' })
    )
    labelMap.set(
      'originalRefinementUuid',
      intl.formatMessage({ id: 'refinement.originalRefinement' })
    ) // TODO Remove "originalRefinement" or "originalRefinementUuid". "originalRefinementUuid" is required by change logs.
    labelMap.set(
      'detectionPhase',
      intl.formatMessage({ id: 'refinement.detectionPhase' })
    )
    labelMap.set(
      'environment',
      intl.formatMessage({ id: 'refinement.environment' })
    )
    labelMap.set('domain', intl.formatMessage({ id: 'refinement.domain' }))
    labelMap.set(
      'featureType',
      intl.formatMessage({ id: 'refinement.featureType' })
    )
    labelMap.set('feature', intl.formatMessage({ id: 'refinement.feature' }))
    labelMap.set(
      'defectEvent',
      intl.formatMessage({ id: 'refinement.defectEvent' })
    )
    labelMap.set(
      'occurrenceProcedure',
      intl.formatMessage({ id: 'refinement.occurrenceProcedure' })
    )
    labelMap.set(
      'testSpecification',
      intl.formatMessage({ id: 'refinement.testSpecification' })
    )
    labelMap.set('testCase', intl.formatMessage({ id: 'refinement.testCase' }))
    labelMap.set(
      'importance',
      intl.formatMessage({ id: 'refinement.importance' })
    )
    labelMap.set('impact', intl.formatMessage({ id: 'refinement.impact' }))
    labelMap.set(
      'desiredDeliveryDate',
      intl.formatMessage({ id: 'refinement.desiredDeliveryDate' })
    )
    labelMap.set(
      'bugAnalysis',
      intl.formatMessage({ id: 'refinement.bugAnalysis' })
    )
    labelMap.set('cause', intl.formatMessage({ id: 'refinement.cause' }))
    labelMap.set(
      'directCause',
      intl.formatMessage({ id: 'refinement.directCause' })
    )
    labelMap.set(
      'rootCause',
      intl.formatMessage({ id: 'refinement.rootCause' })
    )
    labelMap.set(
      'properDetectionPhase',
      intl.formatMessage({ id: 'refinement.properDetectionPhase' })
    )
    labelMap.set(
      'interimAction',
      intl.formatMessage({ id: 'refinement.interimAction' })
    )
    labelMap.set(
      'permanentAction',
      intl.formatMessage({ id: 'refinement.permanentAction' })
    )
    labelMap.set(
      'documentsToBeCorrected',
      intl.formatMessage({ id: 'refinement.documentsToBeCorrected' })
    )
    labelMap.set(
      'horizontalDeploymentTarget',
      intl.formatMessage({ id: 'refinement.horizontalDeploymentTarget' })
    )
    labelMap.set(
      'horizontalDeploymentContent',
      intl.formatMessage({ id: 'refinement.horizontalDeploymentContent' })
    )
  }, [labelMap])
  return labelMap
}

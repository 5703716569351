import { ColDef, IFilterParams } from 'ag-grid-community'
import { WbsItemAdditionalPropertyEntity } from '../../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { Option } from '..'
import { MultiSelectCellEditor } from '../../../components/cellEditor/MultiSelectCellEditor'
import {
  ClientSideSelectFilter,
  SelectFilter,
  ServerSideSelectFilter,
} from '../../../components/filter'
import { filter } from '../../../../../pages/ProjectPlanNew/projectPlanNew'

export const multiSelectColumnDef = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>
): void => {
  column.cellEditor = MultiSelectCellEditor
  column.cellEditorParams = {
    options: wbsItemAdditionalProperty.selectOptions
      ? wbsItemAdditionalProperty.selectOptions.map(selectOption => ({
          label: selectOption.selectOption,
          value: selectOption.selectOption,
        }))
      : [],
  }
  setFilterParams(column, wbsItemAdditionalProperty, option)
  return
}
const setFilterParams = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>
) => {
  switch (option.columnFilterType) {
    case 'CLIENT_SIDE':
      column.filter = ClientSideSelectFilter
      column.floatingFilter = !option.disableFloatingFilter
      column.filterParams = {
        getValue: option => option.value,
        getLabel: option => option.label,
      }
      break
    case 'SERVER_SIDE':
      column.filter = ServerSideSelectFilter
      column.floatingFilter = !option.disableFloatingFilter
      column.filterParams = {
        fetchOptions: async (params: IFilterParams) => {
          return wbsItemAdditionalProperty.selectOptions
            ? wbsItemAdditionalProperty.selectOptions.map(selectOption => ({
                label: selectOption.selectOption,
                value: selectOption.selectOption,
              }))
            : []
        },
        getValue: option => option.value,
        getLabel: option => option.label,
        fetch: (v: SelectFilter) => {
          return filter({
            projectUuid: wbsItemAdditionalProperty.projectUuid,
            additionalPropertyValues: [
              {
                wbsItemAdditionalPropertyUuid: wbsItemAdditionalProperty.uuid,
                propertyType: wbsItemAdditionalProperty.propertyType,
                values: v.values,
                includeBlank: v.includeBlank,
              },
            ],
          })
        },
      }
      break
    default:
      break
  }
}

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  Avatar,
  Box,
  Checkbox,
  MenuItem,
  Paper,
  Popper,
  styled,
} from '@mui/material'
import InputBase from '@mui/material/InputBase'
import { ICellEditorParams } from 'ag-grid-community'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import _ from 'lodash'
import SaveButton from '../../../../components/buttons/SaveButton'
import CancelButton from '../../../../components/buttons/CancelButton'

interface SelectOption {
  value: string
  label: string
  iconUrl?: string
}

interface Props extends ICellEditorParams {
  options: SelectOption[]
  value: string[]
}

export const MultiSelectCellEditor = forwardRef((props: Props, ref) => {
  const inputRef = React.createRef<HTMLInputElement>()
  const [value, setValue] = useState<SelectOption[]>([])

  useEffect(() => {
    setValue(props.options.filter(o => props.value.includes(o.value)))
  }, [props.options, props.value])

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const valueChanged = useCallback(
    (
      event: React.ChangeEvent<{}>,
      option: SelectOption[] | null,
      reason: AutocompleteChangeReason
    ) => {
      setValue(option || [])
    },
    []
  )
  const onSubmit = useCallback(() => {
    props.api.stopEditing()
  }, [props.api])

  const onCancel = useCallback(() => {
    props.api.stopEditing(true)
  }, [props.api])

  const onClose = useCallback(
    (e, reason) => {
      if (['blur', 'toggleInput'].includes(reason)) {
        return
      }
      let cancel = false
      if (reason === 'escape') {
        cancel = true
      }
      props.api.stopEditing(cancel)
    },
    [props.api]
  )

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value.map(v => v.value)
      },
    }
  })

  return (
    <Autocomplete
      open={true}
      multiple={true}
      options={props.options}
      getOptionLabel={option => option.label}
      renderOption={(props, option: SelectOption, { selected }) => {
        return (
          <MultiSelectMenuItem {...props}>
            <MultiSelectMenuItemCheckbox selected={selected} />
            {option.iconUrl && (
              <MultiSelectMenuItemIcon iconUrl={option.iconUrl} />
            )}
            <MultiSelectMenuItemLabel label={option.label} />
          </MultiSelectMenuItem>
        )
      }}
      isOptionEqualToValue={(value, option) => value.value === option.value}
      // filterOptions={options => options}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <InputBase
          inputRef={inputRef}
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          autoFocus={true}
        />
      )}
      PopperComponent={props => <Popper {...props} placement="bottom-start" />}
      PaperComponent={props => (
        <Paper {...props}>
          {props.children}
          <ButtonBox>
            <SaveButton onClick={onSubmit} />
            <CancelButton onClick={onCancel} />
          </ButtonBox>
        </Paper>
      )}
      autoSelect={false}
      blurOnSelect={false}
      noOptionsText={''}
      value={value}
      onChange={valueChanged}
      onClose={onClose}
      disableCloseOnSelect={true}
    />
  )
})

const MultiSelectMenuItem = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  '& .MuiCheckbox-root': { marginRight: '0px !important' },
  '& .MuiAvatar-root': { margin: '0px 6px 0px 0px !important' },
})
const MultiSelectMenuItemCheckbox = ({ selected }: { selected: boolean }) => {
  return (
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      checkedIcon={<CheckBoxIcon fontSize="small" />}
      style={{ marginRight: 8 }}
      checked={selected}
      color={'primary'}
    />
  )
}
const MultiSelectMenuItemIcon = ({ iconUrl }: { iconUrl: string }) => {
  return (
    <Avatar
      variant="circular"
      sx={{
        marginRight: '5px',
        height: '23px',
        width: '23px',
        borderRadius: '50%',
      }}
      src={iconUrl}
    />
  )
}
const MultiSelectMenuItemLabel = ({ label }: { label: string }) => {
  return <Box sx={{ whiteSpace: 'nowrap' }}>{label}</Box>
}
const ButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '36px',
})

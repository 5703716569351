import { useCallback, useEffect, useMemo, useState } from 'react'
import { useChangeLogRepository } from '../../../../../../services/adaptors/changeLogRepositoryAdaptor'
import {
  ChangeLog,
  Delta,
} from '../../../../../../domain/value-object/ChangeLog'
import InfiniteScroll from 'react-infinite-scroller'
import {
  ChangeLogDisplayModel,
  ChangeLogValueParser,
  DeltaDisplayModel,
} from '../../../model/changeLog'
import {
  Link,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { colorPalette } from '../../../../../style/colorPallete'
import { dateTimeV2Service } from '../../../../../../domain/value-object/DateTimeV2'
import { multiLineTextV2Service } from '../../../../../../domain/value-object/MultiLineTextV2'
import {
  MultiLineTextPreviewDialog,
  useMultiLineTextPreviewDialog,
} from './MultiLineTextPreviewDialog'

type ChangeLogTabPanelProps = {
  uuids: string[]
  getParser: (path: string) => ChangeLogValueParser | undefined
  labelMap: Map<string, string>
}
export const ChangeLogTabPanel = ({
  uuids,
  getParser,
  labelMap,
}: ChangeLogTabPanelProps) => {
  const mapDeltaToDisplayModel = useCallback(
    async (delta: Delta): Promise<DeltaDisplayModel | undefined> => {
      const label = labelMap.get(delta.path)
      const parser = getParser(delta.path)
      if (!label || !parser) return undefined
      const before = delta.oldValue && (await parser(delta.oldValue))
      const after = delta.newValue && (await parser(delta.newValue))
      return {
        label,
        before,
        after,
      }
    },
    [getParser, labelMap]
  )
  const { fetch } = useChangeLogRepository()
  const [changeLogs, setChangeLogs] = useState<ChangeLog[]>([])
  const [changeLogModels, setChangeLogModels] = useState<
    ChangeLogDisplayModel[]
  >([])
  const [loadedCount, setLoadedCount] = useState<number>(0)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const load = useCallback(async () => {
    const response = await fetch(uuids, loadedCount, 10)
    setChangeLogs(prev => [...prev, ...response])
    const models = await Promise.all(
      response.map(async log => {
        const delta = await Promise.all(
          log.delta.map(d => mapDeltaToDisplayModel(d))
        ).then(delta => delta.filter(d => !!d) as DeltaDisplayModel[])
        return {
          updatedAt: new Date(log.updatedAt),
          updatedBy: log.updatedBy?.name || '',
          delta,
        }
      })
    ).then(model => model.filter(m => m.delta.length > 0))
    setChangeLogModels(prev => [...prev, ...models])
    const loaded = response.length
    setLoadedCount(prev => prev + loaded)
    if (loaded < 10) setHasMore(false)
  }, [fetch, loadedCount, mapDeltaToDisplayModel, uuids])

  return (
    <InfiniteScroll
      loadMore={load}
      hasMore={hasMore}
      useWindow={false}
      threshold={10}
    >
      {changeLogModels.map((changeLog, i) => (
        <ChangeLogView key={i} changeLog={changeLog} />
      ))}
    </InfiniteScroll>
  )
}

type ChangeLogViewProps = {
  changeLog: ChangeLogDisplayModel
}
const ChangeLogView = ({ changeLog }: ChangeLogViewProps) => {
  const changedAt = useMemo(() => {
    return dateTimeV2Service.format(changeLog.updatedAt)
  }, [changeLog.updatedAt])
  return (
    <ChangeLogRoot>
      <HeaderArea>
        <ChangedAt>{changedAt}</ChangedAt>
        <ChangedBy>{changeLog.updatedBy}</ChangedBy>
      </HeaderArea>
      <BodyArea>
        <ChangeLogTable>
          <ChangeLogTableHeader>
            <ChangeLogTableRow>
              <ChangeLogTablePropertyNameCell>
                {'項目名'}
              </ChangeLogTablePropertyNameCell>
              <ChangeLogTableValueCell>{'変更前'}</ChangeLogTableValueCell>
              <ChangeLogTableValueCell>{'変更後'}</ChangeLogTableValueCell>
            </ChangeLogTableRow>
          </ChangeLogTableHeader>
          <ChangeLogTableBody>
            {changeLog.delta.map((d, i) => (
              <ChangeLogDeltaRow
                key={i}
                delta={d}
                updatedAt={changedAt}
                updatedBy={changeLog.updatedBy}
              />
            ))}
          </ChangeLogTableBody>
        </ChangeLogTable>
      </BodyArea>
    </ChangeLogRoot>
  )
}

type ChangeLogDeltaRowProps = {
  delta: DeltaDisplayModel
  updatedAt: string
  updatedBy: string
}
const ChangeLogDeltaRow = ({
  delta,
  updatedAt,
  updatedBy,
}: ChangeLogDeltaRowProps) => {
  const [before, after] = useMemo(
    () => [delta.before || '（未設定）', delta.after || '（未設定）'],
    [delta.after, delta.before]
  )
  return (
    <ChangeLogTableRow>
      <ChangeLogTablePropertyNameCell>
        {delta.label}
      </ChangeLogTablePropertyNameCell>
      <ChangeLogTableValueCell>
        <ChangeLogTableDeltaValueLabel
          value={before}
          beforeOrAfter="before"
          updatedAt={updatedAt}
          updatedBy={updatedBy}
        />
      </ChangeLogTableValueCell>
      <ChangeLogTableValueCell>
        <ChangeLogTableDeltaValueLabel
          value={after}
          beforeOrAfter="after"
          updatedAt={updatedAt}
          updatedBy={updatedBy}
        />
      </ChangeLogTableValueCell>
    </ChangeLogTableRow>
  )
}

const ChangeLogRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  gap: '8px',
})
const HeaderArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})
const ChangedAt = styled('span')({
  color: colorPalette.monotone[4],
  fontSize: '10px',
})
const ChangedBy = styled('span')({
  color: colorPalette.monotone[10],
  fontSize: '11px',
})
const BodyArea = styled('div')({})
const ChangeLogTable = styled(Table)({})
const ChangeLogTableHeader = styled(TableHead)({
  backgroundColor: colorPalette.monotone[1],
})
const ChangeLogTableRow = styled(TableRow)({})
const ChangeLogTableBody = styled(TableBody)({})
const ChangeLogTableCell = styled(TableCell)({
  padding: '4px 8px',
})
const ChangeLogTablePropertyNameCell = styled(ChangeLogTableCell)({
  width: '24%',
})
const ChangeLogTableValueCell = styled(ChangeLogTableCell)({
  width: '38%',
})
const ChangeLogTableDeltaValueLabel = ({
  value,
  beforeOrAfter,
  updatedAt,
  updatedBy,
}: {
  value: string
  beforeOrAfter: 'before' | 'after'
  updatedAt: string
  updatedBy: string
}) => {
  const isMultiLineText = useMemo(
    () =>
      typeof value === 'string' &&
      multiLineTextV2Service.isMultiLineText(value),
    [value]
  )

  if (!isMultiLineText) return <>{value}</>

  return (
    <MultiLineTextValueLabel
      text={value}
      beforeOrAfter={beforeOrAfter}
      updatedAt={updatedAt}
      updatedBy={updatedBy}
    />
  )
}

const MultiLineTextValueLabel = ({
  text,
  beforeOrAfter,
  updatedAt,
  updatedBy,
}: {
  text: string
  beforeOrAfter: 'before' | 'after'
  updatedAt: string
  updatedBy: string
}) => {
  const { open, openDialog, closeDialog } = useMultiLineTextPreviewDialog()
  const dialogTitle = useMemo(
    () =>
      `[${
        beforeOrAfter === 'before' ? '変更前' : '変更後'
      }]更新者:${updatedBy} / 更新日時:${updatedAt}`,
    [beforeOrAfter, updatedAt, updatedBy]
  )
  return (
    <>
      <MultiLineTexPreviewLink onClick={openDialog}>
        {'確認する'}
      </MultiLineTexPreviewLink>
      <MultiLineTextPreviewDialog
        text={text}
        title={dialogTitle}
        open={open}
        openDialog={openDialog}
        closeDialog={closeDialog}
      />
    </>
  )
}
const MultiLineTexPreviewLink = styled(Link)({
  cursor: 'pointer',
})

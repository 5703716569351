import { useCallback, useMemo } from 'react'

export const useSubmitButton = (
  submit: () => Promise<void>,
  edited: boolean,
  hasValidationErrors: boolean
): {
  onClickSubmitButton: () => Promise<void>
  submitButtonIsActive: boolean
} => {
  const submitButtonIsActive = useMemo(
    () => edited && !hasValidationErrors,
    [edited, hasValidationErrors]
  )
  const onClickSubmitButton = useCallback(async () => {
    if (!submitButtonIsActive) {
      return
    }
    await submit()
  }, [submit, submitButtonIsActive])

  return {
    onClickSubmitButton,
    submitButtonIsActive,
  }
}

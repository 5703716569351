import { styled, Dialog, DialogTitle } from '@mui/material'
import { Button } from '../../../../components/buttons'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import { colorPalette } from '../../../../style/colorPallete'
import { useCallback, useMemo, useState } from 'react'
import { intl } from '../../../../../i18n'
import { CopyRoot, Project } from '../../model'
import { SubmitAlertDialog } from '../dialog/SubmitAlertDialog'

type CopyWbsHeaderProps = {
  copiable: boolean
  copy: () => Promise<void>
  srcProject: Project | undefined
  srcRootProjectPlan: CopyRoot
  destProject: Project | undefined
  destRootProjectPlan: CopyRoot
}
export const CopyWbsHeader = ({
  copiable,
  copy,
  ...others
}: CopyWbsHeaderProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const onOpen = useCallback(() => setOpen(true), [])
  const onClose = useCallback(() => setOpen(false), [])
  return (
    <>
      <Root>
        <Button colorPattern="green" disabled={!copiable} onClick={onOpen}>
          <CopyIcon />
          コピーする
        </Button>
      </Root>
      <SubmitAlertDialog
        open={open}
        onClose={onClose}
        submit={copy}
        {...others}
      />
    </>
  )
}

const Root = styled('div')({
  display: 'flex',
  width: '100%',
  height: '42px',
})

const CopyIcon = styled(ContentCopyOutlinedIcon)({
  fontSize: '14px',
})

import moment from 'moment'
import { WorkloadUnit } from '../../../../../../lib/functions/workload'
import { WbsItemStatus } from '../../components/cell/custom/wbsItemStatus'
import { WbsItemRow } from '../../../../../../lib/functions/wbsItem'
import DateVO from '../../../../../../vo/DateVO'

const isDeliverable = (wbsItem: WbsItemRow) =>
  !!wbsItem.wbsItemType?.isDeliverable()
const isTask = (wbsItem: WbsItemRow) => !!wbsItem.wbsItemType?.isTask()
const isDone = (wbsItem: WbsItemRow) => wbsItem.status === WbsItemStatus.DONE
const isNotDiscard = (wbsItem: WbsItemRow) =>
  wbsItem.status !== WbsItemStatus.DISCARD

const isScheduledToBeDone = (wbsItem: WbsItemRow): boolean => {
  const scheduledDate = wbsItem.scheduledDate
  if (!scheduledDate || !scheduledDate.endDate) {
    return false
  }
  return (
    moment(scheduledDate.endDate).isSameOrBefore(moment(), 'days') &&
    isNotDiscard(wbsItem)
  )
}
const isPreceding = (wbsItem: WbsItemRow) => {
  const scheduledDate = wbsItem.scheduledDate
  if (!scheduledDate || !scheduledDate.endDate) {
    return false
  }
  return (
    [WbsItemStatus.DONE].includes(wbsItem.status!) &&
    moment(scheduledDate.endDate).isAfter(moment(), 'days')
  )
}
const isTodayBehindScheduleStart = (wbsItem: WbsItemRow): boolean => {
  const { scheduledDate, actualDate } = wbsItem
  if (!!scheduledDate?.startDate && !actualDate?.startDate) {
    return DateVO.now().isAfter(new DateVO(scheduledDate.startDate))
  }
  return false
}
const isTodayBehindScheduleEnd = (wbsItem: WbsItemRow): boolean => {
  const { scheduledDate, actualDate } = wbsItem
  if (!!scheduledDate?.endDate && !actualDate?.endDate) {
    return DateVO.now().isAfter(new DateVO(scheduledDate.endDate))
  }
  return isDelayed(wbsItem)
}
const isActualStartBehindSchedule = (wbsItem: WbsItemRow): boolean => {
  const { scheduledDate, actualDate } = wbsItem
  if (!!scheduledDate?.startDate && !!actualDate?.startDate) {
    return new DateVO(actualDate.startDate).isAfter(
      new DateVO(scheduledDate.startDate)
    )
  }
  return false
}
const isActualEndBehindSchedule = (wbsItem: WbsItemRow): boolean => {
  const { scheduledDate, actualDate } = wbsItem
  if (!!scheduledDate?.endDate && !!actualDate?.endDate) {
    return new DateVO(actualDate.endDate).isAfter(
      new DateVO(scheduledDate.endDate)
    )
  }
  return false
}
const isStartDelayed = (wbsItem: WbsItemRow): boolean => {
  return (
    isTodayBehindScheduleStart(wbsItem) || isActualStartBehindSchedule(wbsItem)
  )
}
const isEndDelayed = (wbsItem: WbsItemRow): boolean => {
  return isTodayBehindScheduleEnd(wbsItem) || isActualEndBehindSchedule(wbsItem)
}
const isDelayed = (wbsItem: WbsItemRow) => {
  const scheduledDate = wbsItem.scheduledDate
  if (!scheduledDate || !scheduledDate.endDate) {
    return false
  }
  return (
    ![WbsItemStatus.DONE, WbsItemStatus.DISCARD].includes(wbsItem.status!) &&
    moment(scheduledDate.endDate).isBefore(moment(), 'days')
  )
}
const isPrecedingOnSchedule = (wbsItem: WbsItemRow): boolean => {
  const scheduledDate = wbsItem.scheduledDate
  if (!scheduledDate || !scheduledDate.endDate) {
    return false
  }
  return (
    [WbsItemStatus.DONE].includes(wbsItem.status!) &&
    moment(scheduledDate.endDate).isSameOrAfter(moment(), 'days')
  )
}

const isRemaining = (wbsItem: WbsItemRow): boolean => {
  return !isDone(wbsItem) && isNotDiscard(wbsItem)
}

const isUnplanned = (wbsItem: WbsItemRow): boolean => {
  if (isDone(wbsItem) || !isNotDiscard(wbsItem)) return false
  const scheduledDate = wbsItem.scheduledDate
  return !scheduledDate || !scheduledDate.startDate || !scheduledDate.endDate
}

const getEstimatedWorkload = (
  wbsItem: WbsItemRow,
  workloadUnit: WorkloadUnit = WorkloadUnit.HOUR
): number => {
  if (!wbsItem.estimatedWorkload) {
    return 0
  }
  switch (workloadUnit) {
    case WorkloadUnit.HOUR:
      return wbsItem.estimatedWorkload.hour
    case WorkloadUnit.DAY:
      return wbsItem.estimatedWorkload.day
    case WorkloadUnit.MONTH:
      return wbsItem.estimatedWorkload.month
  }
}

export default {
  isDeliverable,
  isTask,
  isDone,
  isNotDiscard,
  isScheduledToBeDone,
  isPreceding,
  isTodayBehindScheduleStart,
  isTodayBehindScheduleEnd,
  isActualStartBehindSchedule,
  isActualEndBehindSchedule,
  isStartDelayed,
  isEndDelayed,
  isDelayed,
  isPrecedingOnSchedule,
  isRemaining,
  isUnplanned,
  getEstimatedWorkload,
}

import {
  WbsItemEntity,
  fromApiResponseToEntity as fromApiResponseToWbsItemEntity,
  UpdateTagInput,
  UpdateWbsItemDeltaInput,
  WbsItemBasic,
  ReferencedParentWbsItem,
} from '../WbsItemEntity'
import { IItemDelta } from '../../value-object/ItemDeltaInputVO'
import { ReferencedTicketListEntity } from '../TicketListEntity'
import {
  WbsItemAdditionalPropertyValuesVO,
  wbsItemAdditionalPropertyValuesVoService,
} from '../../value-object/WbsItemAdditionalPropertyValuesVO'
import { CreateProjectPlanInput } from '../ProjectPlanEntity'
import { EntityExtensionValue } from '../../../view/containers/meta/entityExtension'

export type TicketEntity = {
  uuid: string
  ticketType: PredeterminedTicketType | string
  ticketList: ReferencedTicketListEntity
  parentWbsItem: ReferencedParentWbsItem
  wbsItem: WbsItemEntity
  entityExtensionValues?: WbsItemAdditionalPropertyValuesVO // TODO Remove after all entity extensions are transfered.
}

export type PredeterminedTicketType =
  | 'REFINEMENT'
  | 'ISSUE'
  | 'RISK'
  | 'CHANGE_REQUEST'
  | 'MEETING'

export type CreateTicketInput = {
  uuid: string
  projectUuid: string
  ticketListUuid: string
  ticketType: string
  parentUuid?: string
  parentWbsItemUuid?: string
  prevSiblingUuid?: string
  projectPlan: CreateProjectPlanInput
  extensions?: EntityExtensionValue[]
}
export type CreateTicketRequest = {
  input: CreateTicketInput
  watchers?: string[]
  tags?: UpdateTagInput
}
export type UpdateTicketDeltaInput = {
  uuid: string
  ticketListUuid?: IItemDelta<string>
  parentUuid?: IItemDelta<string>
  prevSiblingUuid?: IItemDelta<string>
  wbsItem: UpdateWbsItemDeltaInput
}

export type UpdateTicketDeltaResult = {}

export type UpdateTicketDeltaRequest = {
  input: UpdateTicketDeltaInput
  watchers: string[]
  tags: UpdateTagInput
}

const fromApiResponseToEntity = (src: any): TicketEntity => {
  return {
    uuid: src.uuid,
    ticketType: src.ticketType,
    ticketList: {
      uuid: src.ticketList.uuid,
      name: src.ticketList.displayName,
      ticketType: src.ticketList.ticketType,
    },
    parentWbsItem: {
      uuid: src.parentWbsItem.uuid,
      name: src.parentWbsItem.displayName,
    },
    entityExtensionValues: src.extensions
      ? wbsItemAdditionalPropertyValuesVoService.of(
          src.uuid,
          src.extensions.map(v => ({
            wbsItemAdditionalPropertyUuid: v.uuid,
            value: v.value,
          }))
        )
      : undefined,
    wbsItem: fromApiResponseToWbsItemEntity(src.wbsItem),
  }
}

export const ticketService = {
  fromApiResponseToEntity,
}

import * as React from 'react'

const SmallFireIcon = React.forwardRef(
  (
    props: { style?: React.CSSProperties },
    ref: React.LegacyRef<SVGSVGElement>
  ) => (
    <svg
      {...props}
      width={props.style?.fontSize || 25}
      height={props.style?.fontSize || 25}
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
    >
      <g clip-path="url(#clip0_4678_162043)">
        <path
          d="M193.48 235.52C213.48 223.35 228.3 203.14 232.74 179.43C239.2 144.82 224.17 112.01 198.66 93.7102C195.17 91.0602 190.19 91.5902 187.34 95.0902C174.85 110.65 149.66 101.86 149.66 81.8602V65.6602C149.66 57.1902 140.24 52.1102 133.26 56.7702C108.6 73.2802 65 110.11 65 163.34C65 194.24 81.51 221.23 106.17 235.94C106.17 235.94 145.67 258.64 193.48 235.52Z"
          fill={props.style?.color || '#FF7B22'}
        />
        <path
          d="M187.67 198.31C187.67 219.3 170.66 236.31 149.67 236.31C128.68 236.31 111.67 219.3 111.67 198.31C111.67 177.32 149.67 134.98 149.67 134.98C149.67 134.98 187.67 177.33 187.67 198.31Z"
          fill={props.style?.color || '#EAD303'}
        />
      </g>
      <defs>
        <clipPath id="clip0_4678_162043">
          <rect
            width="169.24"
            height="190.91"
            fill="white"
            transform="translate(65 55)"
          />
        </clipPath>
      </defs>
    </svg>
  )
)

export default SmallFireIcon

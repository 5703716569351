import { InputBase, styled } from '@mui/material'
import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce'
import { useWorkloadUnit } from '../../../../hooks/useWorkloadUnit'
import { WorkloadUnit } from '../../../../../lib/functions/workload'

type WorkloadCellProps = {
  value: number | undefined
  onChange: (v: number | undefined) => void
} & CellPropsBase
export const WorkloadCell = ({
  label,
  cellWidth,
  value,
  onChange,
  editable = true,
  required = false,
  validationErrors,
}: WorkloadCellProps) => {
  const {
    convertWorkloadFromHourToSelectedUnit: toDay,
    hoursPerSelectedUnit: hoursPerDay,
  } = useWorkloadUnit(WorkloadUnit.DAY)
  const {
    convertWorkloadFromHourToSelectedUnit: toMonth,
    hoursPerSelectedUnit: hoursPerMonth,
  } = useWorkloadUnit(WorkloadUnit.MONTH)
  const [hourInput, setHourInput] = useState('')
  const [dayInput, setDayInput] = useState('')
  const [monthInput, setMonthInput] = useState('')
  const changedByInput = useRef(false)

  const round = useCallback((u: number) => Math.round(u * 100) / 100, [])
  const valueToInputValue = useCallback(
    (value: number | undefined) => {
      return {
        hour: value,
        day: value ? round(toDay(value)) : undefined,
        month: value ? round(toMonth(value)) : undefined,
      }
    },
    [toDay, toMonth, round]
  )

  useEffect(() => {
    const { hour, day, month } = valueToInputValue(value)
    changedByInput.current = false
    setHourInput(hour ? hour + '' : '')
    setDayInput(day ? day + '' : '')
    setMonthInput(month ? month + '' : '')
  }, [value, valueToInputValue])

  const parse = useCallback((v: string): number => {
    const parsed = parseFloat(v)
    if (isNaN(parsed) || parsed < 0) return 0
    return parsed
  }, [])

  // Hour input value
  const onChangeHour = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setHourInput(e.target.value)
    changedByInput.current = true
  }, [])
  const debouncedHourInput = useDebounce(hourInput, 500)
  useEffect(() => {
    if (!changedByInput.current) return
    const normalized = parse(debouncedHourInput)
    const hour = round(normalized)
    onChange(hour)
  }, [debouncedHourInput, parse, round, onChange])

  // Day input value
  const onChangeDay = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDayInput(e.target.value)
    changedByInput.current = true
  }, [])
  const debouncedDayInput = useDebounce(dayInput, 500)
  useEffect(() => {
    if (!changedByInput.current) return
    const normalized = parse(debouncedDayInput)
    const hour = round(normalized * hoursPerDay)
    onChange(hour)
  }, [debouncedDayInput, hoursPerDay, parse, round, onChange])

  // Month input value
  const onChangeMonth = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMonthInput(e.target.value)
    changedByInput.current = true
  }, [])
  const debouncedMonthInput = useDebounce(monthInput, 500)
  useEffect(() => {
    if (!changedByInput.current) return
    const normalized = parse(debouncedMonthInput)
    const hour = round(normalized * hoursPerMonth)
    onChange(hour)
  }, [debouncedMonthInput, hoursPerMonth, parse, round, onChange])

  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea validationErros={validationErrors}>
        <TextInput
          value={hourInput}
          onChange={onChangeHour}
          disabled={!editable}
        />
        /
        <TextInput
          value={dayInput}
          onChange={onChangeDay}
          disabled={!editable}
        />
        /
        <TextInput
          value={monthInput}
          onChange={onChangeMonth}
          disabled={!editable}
        />
      </CellValueArea>
    </FormCell>
  )
}

const TextInput = styled(InputBase)({})

import { ValueFormatterParams } from 'ag-grid-community'
import {
  CellClassParams,
  GridOptions,
  ValueGetterParams,
} from 'ag-grid-enterprise'
import { useMemo } from 'react'
import { intl } from '../../../../../i18n'
import {
  columnTypes,
  frameworkComponents,
  defaultColDef,
  ColumnType,
} from '../../../../containers/commons/AgGrid'
import { ProjectPlanNameCellRenderer } from './renderers/ProjectPlanNameCellRenderer'
import { CheckboxCellRenderer } from './renderers/CheckboxCellRenderer'
import { CopyRoot } from '../../model'

// Ag grid options
export const useProjectPlanSelectGridOptions = (
  projectUuid: string | undefined,
  updateSelected: (uuid: CopyRoot, selected: boolean) => void
): GridOptions => {
  const gridOptions = useMemo(
    () => ({
      // Styling
      headerHeight: 32,
      rowHeight: 32,
      // Row grouping
      treeData: true,
      // TODO: delete this option if the expanded condition is saved.
      groupDefaultExpanded: -1,
      autoGroupColumnDef: {
        field: 'displayName',
        headerName: intl.formatMessage({
          id: 'projectOverview.displayName',
        }),
        width: 300,
        hide: false,
        pinned: true,
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: ProjectPlanNameCellRenderer,
        },
      },
      // Column definition
      columnTypes: columnTypes(),
      components: frameworkComponents,
      defaultColDef: { ...defaultColDef(), hide: true },
      tooltipShowDelay: 100,
      suppressContextMenu: true,
      columnDefs: [
        {
          field: 'selected',
          headerName: '#',
          pinned: true,
          lockPosition: 'left' as 'left',
          suppressMovable: true,
          width: 50,
          hide: false,
          cellStyle: { justifyContent: 'center' },
          cellRenderer: CheckboxCellRenderer,
          cellRendererParams: {
            projectUuid,
            updateSelected,
          },
        },
      ],
    }),
    [projectUuid, updateSelected]
  )
  return gridOptions
}

import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { ProjectRepository } from '../../applications/ports/projectRepository'

export const useProjectRepository = (): ProjectRepository => {
  const getAssigned = useCallback(async () => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/projects/assigned',
      {
        loggedIn: false,
      },
      true
    )
    return response.json
  }, [])
  return {
    getAssigned,
  }
}

import { ProjectPlanSelect } from '../ProjectPlanSelect'
import { useCallback, useMemo } from 'react'
import { ProjectEntity } from '../../../../../domain/entity/ProjectEntity'
import { ContentRoot, Description, Label, ProjectSelect, Root, Title } from '.'
import { styled } from '@mui/material'
import { CopyRoot, Project } from '../../model'

type DestSelectPaneProps = {
  title: string
  projectUuid: string | undefined
  onChangeProject: (v: Project | undefined) => void
  rootProjectPlan: CopyRoot
  onChangeRootProjectPlan: (v: CopyRoot) => void
  isCurrentProject: boolean
  projects: ProjectEntity[]
}
export const DestSelectPane = ({
  title,
  projectUuid,
  onChangeProject,
  rootProjectPlan,
  onChangeRootProjectPlan,
  projects,
  isCurrentProject,
}: DestSelectPaneProps) => {
  return (
    <Root>
      <Title>{title}</Title>
      <ContentRoot>
        <ProjectSelect
          projectUuid={projectUuid}
          onChangeProject={onChangeProject}
          projects={projects}
          disabled={isCurrentProject}
        />
        <ItemRoot>
          <Label>コピー対象の工程</Label>
          <Description>選択した工程の直下にコピーします。</Description>
          <ProjectPlanSelect
            projectUuid={projectUuid}
            selected={rootProjectPlan}
            updateSelected={onChangeRootProjectPlan}
            rootName={'WBSのルート'}
          />
        </ItemRoot>
      </ContentRoot>
    </Root>
  )
}

const ItemRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  height: '100%',
})

import { useCallback } from 'react'
import { useTaskActualWorkRepository } from '../../../services/adaptors/taskActualWorkRepositoryAdaptor'
import { UUIDV2 } from '../../../domain/value-object/UUIDV2'
import { DateV2 } from '../../../domain/value-object/DateV2'
import { TaskActualWorkEntity } from '../../../domain/entity/TaskActualWorkEntity'

type TaskActualWork = {
  taskUuid: UUIDV2
  actualDate: DateV2
  hour: number
}
type SaveTaskActualWork = {
  saveMyTaskActualWork: (entity: TaskActualWork) => Promise<void>
}

export const useSaveTaskActualWork = (): SaveTaskActualWork => {
  const { saveMine } = useTaskActualWorkRepository()
  const saveMyTaskActualWork = useCallback(
    async (entity: TaskActualWork) => {
      await saveMine(entity)
    },
    [saveMine]
  )
  return { saveMyTaskActualWork }
}

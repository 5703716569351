export type DateTimeV2 = Date

const format = (src: DateTimeV2): string => {
  const year = src.getFullYear()
  const month = toFullValue(src.getMonth() + 1)
  const date = toFullValue(src.getDate())
  const hour = toFullValue(src.getHours())
  const minutes = toFullValue(src.getMinutes())
  const seconds = toFullValue(src.getSeconds())
  return `${year}/${month}/${date} ${hour}:${minutes}:${seconds}`
}

const toFullValue = (v: number): string => {
  return v < 10 ? `0${v}` : v.toString()
}

const newFromString = (src: string): DateTimeV2 => {
  return new Date(src)
}

export const dateTimeV2Service = {
  format,
  newFromString,
}

import {
  CreateRiskInput,
  Impact,
  Probability,
  RiskEntity,
  RiskType,
  Strategy,
  UpdateRiskDeltaInput,
} from '../../../../domain/entity/ticket/RiskEntity'
import { generateUuid } from '../../../../utils/uuids'
import { isSelectValueDiffered, toSelectDeltaValue } from './properties'

export type RiskFormModel = Omit<RiskEntity, 'ticket'>
export type RiskField = keyof Omit<RiskFormModel, 'uuid'>

export const createRiskInitialValue = (): RiskFormModel => {
  const uuid = generateUuid()
  return {
    uuid,
  }
}

export const toRiskCreateInput = (
  riskFormModel: RiskFormModel
): Omit<CreateRiskInput, 'ticket' | 'extensions'> => {
  return {
    riskType: riskFormModel.riskType as RiskType,
    probability: riskFormModel.probability as Probability,
    impact: riskFormModel.impact as Impact,
    strategy: riskFormModel.strategy as Strategy,
  }
}
export const toRiskDeltaInput = (
  entity: RiskEntity,
  model: RiskFormModel
): Omit<UpdateRiskDeltaInput, 'ticket'> => {
  const input: Omit<UpdateRiskDeltaInput, 'ticket'> = {
    uuid: entity.uuid,
  }
  // TODO: Consider to simplify, maybe by defining field properties.
  const updatableFields: RiskField[] = [
    'riskType',
    'probability',
    'impact',
    'strategy',
  ]
  for (let field of updatableFields) {
    if (isValueDiffered(entity, model, field)) {
      input[field] = toDeltaValue(entity, model, field)
    }
  }
  return input
}

const isValueDiffered = (
  entity: RiskEntity,
  model: RiskFormModel,
  path: RiskField
) => {
  switch (path) {
    case 'riskType':
    case 'probability':
    case 'impact':
    case 'strategy':
      return isSelectValueDiffered(entity[path], model[path])
  }
  return false
}

const toDeltaValue = (
  entity: RiskEntity,
  model: RiskFormModel,
  path: RiskField
) => {
  switch (path) {
    case 'riskType':
    case 'probability':
    case 'impact':
    case 'strategy':
      return toSelectDeltaValue(entity[path], model[path])
  }
  return undefined
}

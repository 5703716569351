import {
  ClickAwayListener,
  IconButton,
  ListItemButton,
  Popper,
  styled,
} from '@mui/material'
import { CSSProperties, useCallback, useMemo, useRef, useState } from 'react'
import { colorPalette } from '../../../style/colorPallete'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { CloseRounded } from '@mui/icons-material'
import { SelectOption } from '../../../model/selectOption'
import ClearIcon from '../../icons/ClearIcon'

type SelectValue = string
type MultiSelectProps = {
  value: SelectValue[] | undefined
  options: SelectOption[]
  // onChange is optional in case it is disabled.
  onChange?: (v: SelectValue[] | undefined) => void
  disabled?: boolean
  clearable?: boolean
} & StyleProps
type StyleProps = {}
export const MultiSelect = ({
  value,
  options,
  onChange,
  disabled = false,
  clearable = false,
}: MultiSelectProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const toggle = useCallback(() => setOpen(prev => !prev), [])
  const close = useCallback(() => setOpen(false), [])
  const ref = useRef<HTMLDivElement>(null)
  const onClick = useCallback(
    (option: SelectOption) => {
      if (onChange) {
        const newValue = value ? [...value] : []
        const index = newValue.indexOf(option.value)
        if (index >= 0) {
          newValue.splice(index, 1)
        } else {
          newValue.push(option.value)
        }
        onChange(
          options.filter(o => newValue.includes(o.value)).map(o => o.value)
        )
      }
    },
    [onChange, value]
  )
  const selectedValuesLabel = useMemo(() => {
    if (!value || value.length === 0) {
      return ''
    }
    return options
      .filter(o => value.includes(o.value))
      .map(o => o.name)
      .join(',')
  }, [options, value])
  const clear = useCallback(() => {
    if (!clearable) return
    onChange && onChange(undefined)
  }, [clearable, onChange])
  return (
    <>
      <Root>
        <SelectableArea ref={ref} onClick={toggle}>
          <SelectedValueArea>
            <SelectedValue>{selectedValuesLabel}</SelectedValue>
          </SelectedValueArea>
          <ExpandMoreIcon open={open} />
        </SelectableArea>
        {value && clearable && (
          <ClearIconArea>
            <ClearIconButton onClick={clear}>
              <ClearIcon />
            </ClearIconButton>
          </ClearIconArea>
        )}
      </Root>
      <StyledPopper open={open} anchorEl={ref.current} placement="bottom-start">
        <ClickAwayListener onClickAway={close}>
          <PopperRoot width="150px">
            <Menu>
              {options.map((v, i) => (
                <MenuItem
                  key={i}
                  selected={Boolean(value?.includes(v.value))}
                  onClick={() => {
                    onClick(v)
                  }}
                >
                  <MenuItemText>{v.name}</MenuItemText>
                </MenuItem>
              ))}
            </Menu>
          </PopperRoot>
        </ClickAwayListener>
      </StyledPopper>
    </>
  )
}

const Root = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
})
const SelectableArea = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
})
const SelectedValueArea = styled('div')({
  flex: '1 1 0%',
})
const SelectedValue = styled('span')({
  color: colorPalette.monotone[10],
  fontSize: '12px',
})
const ClearIconArea = styled('div')({
  position: 'absolute',
  right: '20px',
})
const ClearIconButton = styled(IconButton)({
  padding: '2px',
})
const ExpandMoreIcon = styled(ExpandMoreRoundedIcon)(
  ({ open }: { open: boolean }) => {
    const transform = useMemo(() => {
      if (open) return 'rotate(180deg)'
      return 'none'
    }, [open])
    return {
      color: colorPalette.monotone[4],
      transform,
      transition: 'transform .2s',
    }
  }
)
const StyledPopper = styled(Popper)({
  zIndex: 10,
})
type PopperRootProps = {
  width?: CSSProperties['width']
  height?: CSSProperties['height']
}
const PopperRoot = styled('div')(({ width, height }: PopperRootProps) => ({
  backgroundColor: colorPalette.monotone[0],
  borderRadius: '4px',
  boxShadow: `0px 4px 16px 0px ${colorPalette.monotone[4]}80`,
  padding: '8px 0',
  width,
  height,
}))
const Menu = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
})
const MenuItem = styled(ListItemButton)(
  ({ selected }: { selected: boolean }) => {
    return {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      cursor: 'pointer',
      color: colorPalette.monotone[4],
      backgroundColor: selected ? colorPalette.skyBlue[0] : undefined,
    }
  }
)
const MenuItemText = styled('span')({
  fontSize: '14px',
  lineHeight: 1,
  color: 'inherit',
  '&:hover': {},
})

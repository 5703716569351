import { useCallback } from 'react'
import {
  useBooleanParser,
  useDateParser,
  useReferencedEntityParser,
  useSelectParser,
  useTextParser,
} from '../../changeLogParser'
import { RefinementSelectOptions } from './selectOptions'
import { WbsItemSelectOptions } from '../../wbsItemSelectOptions'
import { useWbsItemChangeLogParser } from '../../wbsItemChangeLogParser'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { useTicketRepository } from '../../../../../../services/adaptors/ticketRepositoryAdaptor'
import { useRefinementRepository } from '../../../../../../services/adaptors/refinementRepositoryAdaptor'

export const useRefinementChangeLogParser = (
  wbsItemSelectOptions: WbsItemSelectOptions,
  wbsItemAdditionalProperties:
    | WbsItemAdditionalPropertyLayoutEntity
    | undefined,
  {
    detectionPhases,
    environments,
    domains,
    featureTypes,
    impacts,
    importances,
    refinementCauses,
  }: RefinementSelectOptions
) => {
  const getWbsItemValueParser = useWbsItemChangeLogParser(
    wbsItemSelectOptions,
    [],
    wbsItemAdditionalProperties
  )
  const textParser = useTextParser()
  const booleanParser = useBooleanParser()
  const detectionPhasesParser = useSelectParser(detectionPhases)
  const environmentsParser = useSelectParser(environments)
  const domainsParser = useSelectParser(domains)
  const featureTypesParser = useSelectParser(featureTypes)
  const impactsParser = useSelectParser(impacts)
  const importancesParser = useSelectParser(importances)
  const refinementCausesParser = useSelectParser(refinementCauses)
  const dateParser = useDateParser()
  const { fetchAsReferencedEntity: fetchRefinement } = useRefinementRepository()
  const refinementParser = useReferencedEntityParser(fetchRefinement)
  const getChangeLogParser = useCallback(
    (path: string) => {
      switch (path) {
        case 'knownRefinement':
        case 'horizontalDeploymentTarget':
          return booleanParser
        case 'originalRefinementUuid':
          return refinementParser
        case 'detectionPhase':
        case 'properDetectionPhase':
          return detectionPhasesParser
        case 'environment':
          return environmentsParser
        case 'domain':
          return domainsParser
        case 'featureType':
          return featureTypesParser
        case 'importance':
          return impactsParser
        case 'impact':
          return importancesParser
        case 'cause':
          return refinementCausesParser
        case 'feature':
        case 'defectEvent':
        case 'occurrenceProcedure':
        case 'testSpecification':
        case 'testCase':
        case 'directCause':
        case 'rootCause':
        case 'interimAction':
        case 'permanentAction':
        case 'documentsToBeCorrected':
        case 'horizontalDeploymentContent':
          return textParser
        case 'desiredDeliveryDate':
          return dateParser
      }
      return getWbsItemValueParser(path)
    },
    [
      booleanParser,
      dateParser,
      detectionPhasesParser,
      domainsParser,
      environmentsParser,
      featureTypesParser,
      getWbsItemValueParser,
      impactsParser,
      importancesParser,
      refinementCausesParser,
      textParser,
      refinementParser,
    ]
  )
  return getChangeLogParser
}

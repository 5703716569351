import { useCallback } from 'react'
import { SelectOption } from '../../../model/selectOption'
import { ReferencedEntity } from '../../../../domain/value-object/ReferencedEntity'
import { useTeamRepository } from '../../../../services/adaptors/teamRepositoryAdaptor'
import { useProjectMemberRepository } from '../../../../services/adaptors/projectMemberRepositoryAdaptor'
import { dateV2Service } from '../../../../domain/value-object/DateV2'
import { dateTimeV2Service } from '../../../../domain/value-object/DateTimeV2'
import { ChangeLogValueParser } from '../model/changeLog'

const toAlternateText = () => {
  //
}

export const useTextParser = (): ChangeLogValueParser => {
  const parser = useCallback((v: string | undefined) => v, [])
  return parser
}

export const useBooleanParser = (): ChangeLogValueParser => {
  const parser = useCallback((v: string | undefined) => v, [])
  return parser
}

export const useSelectParser = (
  options: SelectOption[]
): ChangeLogValueParser => {
  const parser = useCallback(
    (v: string | undefined) => {
      if (!v) return undefined
      const option = options.find(op => op.value === v)
      return option?.name
    },
    [options]
  )
  return parser
}

export const useReferencedEntityParser = (
  fetch: (uuid: string) => Promise<ReferencedEntity | undefined>
): ChangeLogValueParser => {
  const parser = useCallback(
    async (v: string | undefined) => {
      if (!v) return undefined
      const response = await fetch(v)
      return response?.name
    },
    [fetch]
  )
  return parser
}

export const useDateParser = (): ChangeLogValueParser => {
  const parser = useCallback((v: string | undefined) => {
    if (!v) return undefined
    const date = dateV2Service.newDateFromString(v)
    return dateV2Service.format(date)
  }, [])
  return parser
}

export const useDateTimeParser = (): ChangeLogValueParser => {
  const parser = useCallback((v: string | undefined) => {
    if (!v) return undefined
    const dateTime = dateTimeV2Service.newFromString(v)
    return dateTimeV2Service.format(dateTime)
  }, [])
  return parser
}

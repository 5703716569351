import { styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { Select } from '../../../../components/select'
import { ProjectEntity } from '../../../../../domain/entity/ProjectEntity'
import { useCallback, useMemo } from 'react'
import { Project } from '../../model'

type ProjectSelectProps = {
  projectUuid: string | undefined
  onChangeProject: (v: Project | undefined) => void
  projects: ProjectEntity[]
  disabled?: boolean
}
export const ProjectSelect = ({
  projectUuid,
  onChangeProject,
  projects,
  disabled,
}: ProjectSelectProps) => {
  const { projectOptions } = useMemo(
    () => ({
      projectOptions: projects.map(p => ({
        value: p.uuid,
        name: p.displayName,
      })),
    }),
    [projects]
  )
  const onChange = useCallback(
    (v: string | undefined) => {
      const project = projectOptions.find(p => p.value === v)
      onChangeProject(
        project && {
          uuid: project.value,
          name: project.name,
        }
      )
    },
    [onChangeProject, projectOptions]
  )
  return (
    <ItemRoot>
      <Label>プロジェクト</Label>
      <ProjectSelectRoot>
        <Select
          value={projectUuid}
          options={projectOptions}
          onChange={onChange}
          disabled={disabled}
        />
      </ProjectSelectRoot>
    </ItemRoot>
  )
}

export const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'calc(100% - 100px)',
  gap: '8px',
})

export const Title = styled('div')({
  fontSize: '18px',
  fontWeight: 600,
  color: colorPalette.monotone[10],
})

export const ContentRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '12px',
  height: '100%',
  borderRadius: '4px',
  border: `1px solid ${colorPalette.monotone[2]}`,
})

const ItemRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
})

export const Label = styled('div')({
  fontSize: '14px',
  fontWeight: 600,
  color: colorPalette.monotone[9],
})
export const Description = styled('div')({
  fontSize: '12px',
  color: colorPalette.monotone[9],
})

export const ProjectSelectRoot = styled('div')({
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  width: '172px',
  height: '26px',
  padding: '0 4px',
})

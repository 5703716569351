import { useCallback } from 'react'
import { useTaskActualWorkRepository } from '../../../services/adaptors/taskActualWorkRepositoryAdaptor'
import { UUIDV2 } from '../../../domain/value-object/UUIDV2'
import { DateV2 } from '../../../domain/value-object/DateV2'

type TaskActualWork = {
  taskUuid: UUIDV2
  actualDate: DateV2
  hour: number
}
type GetTaskActualWork = {
  getMyActualWorkByTaskUuidAndDate: (
    taskUuid: UUIDV2,
    actualDate: DateV2
  ) => Promise<TaskActualWork>
}

export const useGetTaskActualWork = (): GetTaskActualWork => {
  const { fetchMineByTaskUuidAndActualDate } = useTaskActualWorkRepository()
  const getMyActualWorkByTaskUuidAndDate = useCallback(
    async (taskUuid: UUIDV2, actualDate: DateV2) => {
      const entity = await fetchMineByTaskUuidAndActualDate(
        taskUuid,
        actualDate
      )

      return entity
    },
    [fetchMineByTaskUuidAndActualDate]
  )
  return { getMyActualWorkByTaskUuidAndDate }
}

import { InputBase, styled } from '@mui/material'
import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce'

type NumberCellProps = {
  value: number | undefined
  onChange: (v: number | undefined) => void
  placeholder?: string
} & CellPropsBase
export const NumberCell = ({
  label,
  cellWidth,
  value,
  onChange,
  placeholder,
  editable = true,
  required = false,
  validationErrors,
}: NumberCellProps) => {
  const [inputValue, setInputValue] = useState('')
  const changedByInput = useRef(false)

  useEffect(() => {
    changedByInput.current = false
    setInputValue(value ? value + '' : '')
  }, [value])

  const round = useCallback((v: number | undefined): number | undefined => {
    return v ? Math.round(v * 100) / 100 : undefined
  }, [])
  const parse = useCallback((v: string): number => {
    const parsed = parseFloat(v)
    if (isNaN(parsed) || parsed < 0) return 0
    return parsed
  }, [])

  const onChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    changedByInput.current = true
    setInputValue(e.target.value)
  }, [])
  const debouncedInputValue = useDebounce(inputValue, 300)
  useEffect(() => {
    if (!changedByInput.current) return
    const normalized = parse(debouncedInputValue)
    const newValue = round(normalized)
    onChange(newValue)
  }, [debouncedInputValue, parse, round, onChange])

  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea validationErros={validationErrors}>
        <TextInput
          value={inputValue}
          onChange={onChangeInput}
          disabled={!editable}
          placeholder={placeholder}
        />
      </CellValueArea>
    </FormCell>
  )
}

const TextInput = styled(InputBase)({
  width: '100%',
})

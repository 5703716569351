import { useEffect, useMemo } from 'react'
import { intl } from '../../../../i18n'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { toChangeLogPath as toWbsItemAdditionalPropertyChangeLogPath } from '../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { i18nLabelVoService } from '../../../../domain/value-object/I18nLabelVO'
import { WbsItemEntityExtensionProperty } from '../model/entityExtension'

export const useWbsItemLabel = (
  entityExtensionProperties: WbsItemEntityExtensionProperty[],
  wbsItemAdditionalProperties: WbsItemAdditionalPropertyLayoutEntity | undefined
) => {
  const labelMap = useMemo(() => new Map<string, string>(), [])
  useEffect(() => {
    labelMap.set(
      'basicInformation',
      intl.formatMessage({ id: 'wbsItem.basicInformation' })
    )
    labelMap.set('code', intl.formatMessage({ id: 'wbsItem.code' }))
    labelMap.set(
      'displayName',
      intl.formatMessage({ id: 'wbsItem.displayName' })
    )
    labelMap.set('status', intl.formatMessage({ id: 'wbsItem.status' }))
    labelMap.set('substatus', intl.formatMessage({ id: 'wbsItem.substatus' }))
    labelMap.set('ticketType', intl.formatMessage({ id: 'wbsItem.ticketType' }))
    labelMap.set('type', intl.formatMessage({ id: 'wbsItem.type' }))
    labelMap.set('tags', intl.formatMessage({ id: 'wbsItem.tags' }))
    labelMap.set('assignment', intl.formatMessage({ id: 'wbsItem.assignment' }))
    labelMap.set('team', intl.formatMessage({ id: 'wbsItem.team' }))
    labelMap.set(
      'accountable',
      intl.formatMessage({ id: 'wbsItem.accountable' })
    )
    labelMap.set(
      'responsible',
      intl.formatMessage({ id: 'wbsItem.responsible' })
    )
    labelMap.set('assignee', intl.formatMessage({ id: 'wbsItem.assignee' }))
    labelMap.set('watchers', intl.formatMessage({ id: 'wbsItem.watchers' }))
    labelMap.set('planning', intl.formatMessage({ id: 'wbsItem.planning' }))
    labelMap.set('difficulty', intl.formatMessage({ id: 'wbsItem.difficulty' }))
    labelMap.set('priority', intl.formatMessage({ id: 'wbsItem.priority' }))
    labelMap.set(
      'estimatedStoryPoint',
      intl.formatMessage({ id: 'wbsItem.estimatedStoryPoint' })
    )
    labelMap.set(
      'estimatedHour',
      intl.formatMessage({ id: 'wbsItem.estimatedHour' })
    )
    labelMap.set('actualHour', intl.formatMessage({ id: 'wbsItem.actualHour' }))
    labelMap.set(
      'scheduledDate',
      intl.formatMessage({ id: 'wbsItem.scheduledDate' })
    )
    labelMap.set('actualDate', intl.formatMessage({ id: 'wbsItem.actualDate' }))
    labelMap.set('sprint', intl.formatMessage({ id: 'wbsItem.sprint' }))
    labelMap.set('updateInfo', intl.formatMessage({ id: 'wbsItem.updateInfo' }))
    labelMap.set('createdBy', intl.formatMessage({ id: 'wbsItem.createdBy' }))
    labelMap.set('createdAt', intl.formatMessage({ id: 'wbsItem.createdAt' }))
    labelMap.set('updatedBy', intl.formatMessage({ id: 'wbsItem.updatedBy' }))
    labelMap.set('updatedAt', intl.formatMessage({ id: 'wbsItem.updatedAt' }))
    labelMap.set('revision', intl.formatMessage({ id: 'wbsItem.revision' }))
    labelMap.set(
      'basicInformationTab',
      intl.formatMessage({ id: 'wbsItem.basicInformationTab' })
    )
    labelMap.set(
      'description',
      intl.formatMessage({ id: 'wbsItem.description' })
    )
    labelMap.set('startIf', intl.formatMessage({ id: 'wbsItem.startIf' }))
    labelMap.set('completeIf', intl.formatMessage({ id: 'wbsItem.completeIf' }))
    labelMap.set(
      'projectPlans',
      intl.formatMessage({ id: 'wbsItem.projectPlans' })
    )
    labelMap.set(
      'deliverableAttachments',
      intl.formatMessage({ id: 'wbsItem.deliverableAttachments' })
    )
    labelMap.set('discussion', intl.formatMessage({ id: 'wbsItem.discussion' }))
    labelMap.set(
      'actualResult',
      intl.formatMessage({ id: 'wbsItem.actualResult' })
    )
    labelMap.set('revisions', intl.formatMessage({ id: 'wbsItem.revisions' }))
    labelMap.set(
      'development',
      intl.formatMessage({ id: 'wbsItem.development' })
    )
  }, [labelMap])

  // Set entity extension property labels.
  useEffect(() => {
    if (!entityExtensionProperties) return
    entityExtensionProperties.forEach(entityExtensionProperty => {
      labelMap.set(
        `extension.${entityExtensionProperty.externalId}`,
        entityExtensionProperty.name
      )
    })
  }, [labelMap, entityExtensionProperties])
  // Set additional property labels.
  useEffect(() => {
    if (!wbsItemAdditionalProperties) return
    wbsItemAdditionalProperties.groupHeaderLayouts.forEach(
      ({ propertyLayouts }) => {
        propertyLayouts.forEach(({ wbsItemAdditionalProperty }) => {
          labelMap.set(
            toWbsItemAdditionalPropertyChangeLogPath(wbsItemAdditionalProperty),
            i18nLabelVoService.getLabel(
              wbsItemAdditionalProperty.propertyNameI18n
            )
          )
        })
      }
    )
  }, [labelMap, wbsItemAdditionalProperties])

  return labelMap
}

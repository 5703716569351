import _ from 'lodash'
import React, { ComponentProps } from 'react'
import { styled } from '@mui/system'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import components from '../../pages'
import { connect } from 'react-redux'
import store, { AllState } from '../../../store'
import { doNotRequireSave, requireSave } from '../../../store/requiredSaveData'
import { Function as ApplicationFunction } from '../../../lib/commons/appFunction'
import AlertDialog, { DialogProps } from '../../components/dialogs/AlertDialog'
import HeaderBar from '../../components/headers/HeaderBar'
import {
  addCustomComponents,
  CustomComponent,
  FunctionLayer,
  popFunctionLayer,
  replaceHeaderComponent,
} from '../../../store/functionLayer'
import { Map } from 'immutable'
import { CloseIcon } from '../../components/icons/CloseIcon'
import { LinkIcon } from '../../components/icons/LinkIcon'
import { OpenInNewIcon } from '../../components/icons/OpenInNewIcon'

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    height: 'calc(100% - 64px)',
    width: 'calc(100% - 64px)',
    maxWidth: '100%',
    overflow: 'hidden',
  },
})
const StyledDialogTitle = styled(DialogTitle)({
  padding: 0,
})
const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'hidden',
  padding: 0,
})
const Content = styled('div')({
  flex: 1,
  overflowY: 'hidden',
  '& > div': {
    height: '100%',
  },
})

interface ComponentOwnProps {
  depth: number
}

type Props = ComponentOwnProps & StateProps & WrappedComponentProps

type StateProps = {
  layers: Map<number, FunctionLayer>
  functions: ApplicationFunction[]
  data: any[]
}

interface State {
  open: boolean
  alertDialog: DialogProps
}

class LayerDialog extends React.PureComponent<Props, State> {
  requireSave: boolean = false

  constructor(prop) {
    super(prop)
    this.state = {
      open: false,
      alertDialog: { isOpen: false },
    }
    this.requireSave = store.getState().hasRequiredSaveData.hasRequiredSaveData
    store.dispatch(doNotRequireSave())
  }

  openInNewIcon = (layer: FunctionLayer): CustomComponent => {
    return {
      key: 'openInNew',
      layout: { position: 'left', index: 2 },
      component: <OpenInNewIcon layer={layer} />,
    }
  }

  componentDidUpdate(prevProps: Props) {
    const prevLayer = prevProps.layers.get(prevProps.depth)
    const layer = this.props.layers.get(this.props.depth)
    if (!prevLayer && layer) {
      store.dispatch(
        addCustomComponents([
          {
            key: 'close',
            layout: { position: 'left', index: 1 },
            component: <CloseIcon onClose={this.confirmClose} size="xs" />,
          },
          this.openInNewIcon(layer),
          {
            key: 'link',
            layout: { position: 'right', index: 99 },
            component: <LinkIcon layer={layer} />,
          },
        ])
      )
      this.setState({ open: true })
    } else if (layer) {
      const comparePrevLayer = _.omit(prevLayer, ['customHeaderComponents'])
      const compareLayer = _.omit(layer, ['customHeaderComponents'])
      if (!_.isEqual(comparePrevLayer, compareLayer)) {
        store.dispatch(replaceHeaderComponent(this.openInNewIcon(layer)))
      }
    }
    if (prevLayer && !layer) this.setState({ open: false })
  }

  close = () => {
    if (this.requireSave) {
      store.dispatch(requireSave())
    } else {
      store.dispatch(doNotRequireSave())
    }
    store.dispatch(popFunctionLayer())
    const layer = this.props.layers.get(this.props.depth)
    layer?.onClose && layer.onClose()
  }

  confirmClose = () => {
    const changeData = store.getState().hasRequiredSaveData.hasRequiredSaveData
    if (changeData) {
      this.openConfirmationDialog()
    } else {
      this.close()
    }
  }

  openConfirmationDialog = () => {
    this.setState({
      alertDialog: {
        isOpen: true,
        title: this.props.intl.formatMessage({
          id: 'layer.dialog.confirm.title',
        }),
        message: this.props.intl.formatMessage({
          id: 'layer.dialog.confirm.message',
        }),
        submitButtonTitle: this.props.intl.formatMessage({
          id: 'yes',
        }),
        submitHandler: () => {
          this.close()
          this.setState({
            alertDialog: {
              isOpen: false,
            },
          })
        },
        closeHandler: () => {
          this.setState({
            alertDialog: {
              isOpen: false,
            },
          })
        },
        closeButtonTitle: this.props.intl.formatMessage({
          id: 'no',
        }),
      },
    })
  }

  renderContent = (layer: FunctionLayer) => {
    const externalId = layer.externalId
    const component = components[externalId]
    const appFunction = this.props.functions.find(
      f => f.externalId === externalId
    )
    return React.createElement(component.class, {
      ...component,
      externalId: externalId,
      uuid: appFunction?.uuid,
      code: layer.code,
      auxiliaries: layer.auxiliaries,
      hideHeader: true,
      functionLayerDepth: this.props.depth,
    })
  }

  private getComponentData = (layer: FunctionLayer) => {
    if (layer.getHeaderComponentData) {
      const data = layer.getHeaderComponentData(this.props.data, layer)
      if (data) return data
    }
    const data = this.props.data.find(
      v => (v.ticket?.wbsItem || v.wbsItem || v)?.code === layer.code
    )
    return data
  }

  getTitleComponent = layer => {
    const component = components[layer.externalId]
    const data = this.getComponentData(layer)
    if (!component.options?.['TitleComponent'] || !data) return undefined
    return React.createElement(component.options['TitleComponent'], { data })
  }

  getHeaderComponent = layer => {
    const component = components[layer.externalId]
    const data = this.getComponentData(layer)
    if (!component.options?.['HeaderComponent'] || !data) return undefined
    return React.createElement(component.options['HeaderComponent'], { data })
  }

  render() {
    const { layers, depth } = this.props
    const layer = layers.get(depth)
    if (!layer) {
      return <></>
    }
    const element = this.renderContent(layer)

    return (
      <StyledDialog
        open={this.state.open}
        onClose={this.confirmClose}
        fullWidth={true}
      >
        <StyledDialogTitle>
          {!layer.hideHeader && (
            <HeaderBar
              depth={depth}
              TitleComponent={this.getTitleComponent(layer)}
              HeaderComponent={this.getHeaderComponent(layer)}
            />
          )}
        </StyledDialogTitle>
        <StyledDialogContent
          onKeyDown={e => {
            if (e.nativeEvent.isComposing && e.key === 'Escape') {
              e.stopPropagation()
            }
          }}
        >
          <Content>{element}</Content>
        </StyledDialogContent>
        <AlertDialog {...this.state.alertDialog} />
        <LayerDialog {...this.props} depth={depth + 1} />
      </StyledDialog>
    )
  }
}

const mapStateToProps = (state: AllState, props: ComponentOwnProps) => {
  return {
    functions: state.appFunction.functions,
    layers: state.functionLayer.layers,
    data: state.singleSheet.data,
  }
}

export default connect(mapStateToProps)(injectIntl(LayerDialog))

import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import {
  AgGridValueGetter,
  AgGridValueSetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
  OpenDetailSpec,
  RestoreSearchConditionOptions,
} from '../../containers/BulkSheet'
import {
  CellClickedEvent,
  CellStyle,
  CellStyleFunc,
  ColDef,
  GetContextMenuItemsParams,
  ICellEditorParams,
  NewValueParams,
  RowNode,
  StatusPanelDef,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import ContextMenu, {
  ContextMenuGroup,
  ContextMenuItemId,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import WbsItemApi, {
  createDeltaRequestByRow,
  createRequestByRow,
  createRowByResponse,
  getWbsItemFunctionUuid,
  taskActualResultExists,
  WbsItemDeltaInput,
  WbsItemDetail,
  WbsItemRow,
} from '../../../lib/functions/wbsItem'
import { ColumnType, columnTypes } from '../../containers/commons/AgGrid'
import { SprintStatus } from '../../../lib/functions/sprint'
import { getUrlQueryStringParams } from '../../../utils/urls'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '..'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import TicketApi, {
  CreateTicketInput,
  TicketDetail,
  UpdateTicketDeltaBatchRequest,
  UpdateTicketDeltaInput,
} from '../../../lib/functions/ticket'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import Workload, {
  getWorkloadUnitValue,
  round,
  WorkloadUnit,
} from '../../../lib/functions/workload'
import { generateUuid } from '../../../utils/uuids'
import {
  Comment,
  commentListToSummary,
  CommentSummary,
} from '../../../store/comments'
import CommentHeaderWbsItem, {
  mapRowDataForCommentHeader,
} from '../../containers/Comment/CommentHeaderWbsItem'
import TicketListApi, {
  TicketListDetail,
} from '../../../lib/functions/ticketList'
import ViewMeta from '../../containers/meta/ViewMeta'
import { formatDateTime } from '../../../utils/date'
import SelectVO from '../../../vo/SelectVO'
import { APIResponse, successDummyResponse } from '../../../lib/commons/api'
import { openComment } from '../../../store/information'
import store from '../../../store'
import objects from '../../../utils/objects'
import { AttachmentSummary } from '../../../utils/attachment'
import { createWatchers } from '../ProjectPlan/projectPlanOptions'
import projectPlanFacadeAggregator, {
  EvmIndicator,
} from '../../containers/commons/AgGrid/lib/aggregator/projectPlanAggregator'
import DateVO from '../../../vo/DateVO'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import uiStates, {
  RequestOfGetStates,
  UiStateKey,
  UiStateScope,
} from '../../../lib/commons/uiStates'
import SprintBacklogApi, {
  createSprintBacklogUpdateBatchRequest,
} from '../../../lib/functions/sprintBacklog'
import { List } from 'immutable'
import { getTicketType } from '../../containers/commons/AgGrid/components/cell/custom/ticketType'
import { KeyBindListener } from '../../model/keyBind'
import {
  TagForWbsItem,
  TagsInput,
  createTagInputs,
  isTagColumnEdited,
} from '../../../lib/functions/tag'
import { dateVoService } from '../../../domain/value-object/DateVO'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export class TicketRow extends RowData {
  wbsItem: WbsItemRow = {}
  ticketType?: SelectVO
  cumulation?: ProjectPlanCumulation
  ticketList?: WbsItemDetail
  parentWbsItem?: WbsItemDetail
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
}

export class TicketRowDataSpec extends RowDataSpec<TicketDetail, TicketRow> {
  columnTypes(): { [key: string]: ColDef } {
    return {
      priorityColumn: {
        valueFormatter: () => {
          return ''
        },
      },
      ticketTypeColumn: {
        ...columnTypes().ticketTypeColumn,
        filterValueGetter: (params: ValueGetterParams) => {
          return getTicketType(params.data?.wbsItem?.baseWbsItemType)?.name
        },
      },
      path: {
        cellStyle: { direction: 'rtl' },
      },
    }
  }

  createNewRow(ctx: TicketBulkSheetContext): TicketRow {
    const wbsItemType = store
      .getState()
      .project.ticketTypes.find(v => v.code === ctx.state.ticketType)
    const createRandomCode = Math.random().toString(36).slice(-8).toUpperCase()
    const { dailyWorkHours, monthlyWorkDays } =
      store.getState().tenant.organization!
    return {
      uuid: generateUuid(),
      ticketType: ctx.state.ticketType,
      wbsItem: {
        uuid: '',
        code: createRandomCode,
        status: WbsItemStatus.TODO,
        type: WbsItemType.TASK,
        ticketType: ctx.state.ticketType,
        displayName: '',
        estimatedWorkload: Workload.from({
          standard: { dailyWorkHours, monthlyWorkDays },
        }),
        wbsItemType: wbsItemType,
        baseWbsItemType: wbsItemType,
      },
      ticketList: ctx.state.ticketList?.wbsItem,
      parentWbsItem: ctx.state.ticketList?.wbsItem,
      isAdded: false,
      isEdited: false,
    } as TicketRow
  }

  overwriteRowItemsWithParents(params: {
    child: TicketRow
    parent: TicketRow
  }): TicketRow {
    return params.child
  }

  importNewRow(v: TicketDetail, ctx: TicketBulkSheetContext): boolean {
    if (!v.wbsItem.code) {
      const createRandomCode = Math.random()
        .toString(36)
        .slice(-8)
        .toUpperCase()
      v.wbsItem.code = createRandomCode
    }
    const ticketType = store
      .getState()
      .project.ticketTypes.find(t => t.code === ctx.state.ticketType)
    ticketType && (v.wbsItem.typeDto = ticketType.toWbsItemTypeObject())
    v.ticketType = ctx.state.ticketType
    ctx.state.ticketList &&
      (v.ticketList = {
        ...ctx.state.ticketList.wbsItem,
        uuid: ctx.state.ticketList.uuid,
      })
    v.wbsItem.type = WbsItemType.TASK
    const context = ctx.state.gridOptions.context
    v.wbsItem.estimatedHour = round(
      objects.getValue(v.wbsItem, 'estimatedWorkload') /
        (context?.workloadUnitState?.hoursPerSelectedUnit || 1)
    )
    return true
  }

  createRowByResponse(response: TicketDetail, viewMeta: ViewMeta): TicketRow {
    const wbsItem = createRowByResponse(response.wbsItem, response.cumulation)
    return {
      ...response,
      // TODO Fix ui meta or response
      ticketType: wbsItem.ticketType || response['ticketType'],
      wbsItem: wbsItem,
      createdAt: formatDateTime(response.createdAt),
      updatedAt: formatDateTime(response.updatedAt),
      extensions: viewMeta.deserializeEntityExtensions(
        response.extensions || []
      ),
    } as unknown as TicketRow
  }
}

export interface TicketProps extends BulkSheetSpecificProps {
  updateAggregationRowNodes: (nodes: RowNode[]) => void
}

export interface TicketState extends BulkSheetState {
  ticketType?: string
  ticketList?: TicketListDetail
  lastTicketListUuidMap?: { [key: string]: string | undefined }
  workloadUnit: WorkloadUnit
  tags?: TagForWbsItem[]
}

export interface TicketBulkSheetContext
  extends BulkSheetContext<TicketProps, TicketDetail, TicketRow, TicketState> {}

interface TicketSearchCondition {
  ticketType?: string
  lastTicketListUuidMap?: { [key: string]: string | undefined }
  projectUuid: string
}

interface TicketListInfo {
  ticketType?: string
  ticketList?: TicketListDetail
  lastTicketListUuidMap?: { [key: string]: string | undefined }
}

export default class TicketOptions extends BulkSheetOptions<
  TicketProps,
  TicketDetail,
  TicketRow,
  TicketState
> {
  rowDataSpec = new TicketRowDataSpec()
  uniqueColumn = 'ticket.list.wbsItem.code'
  addable = (bulkSheetState: TicketState) =>
    Boolean(bulkSheetState.ticketType && bulkSheetState.ticketList)
  draggable = true
  enableExcelExport = true
  enableExcelImport = true
  displayNameField = 'wbsItem.displayName'
  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: TicketBulkSheetContext
  ): ContextMenu | undefined => {
    return new ContextMenu(
      [
        ctx.generateURLCopyContextMenu(params, [
          ContextMenuItemId.COPY_URL,
          ContextMenuItemId.COPY_URL_AND_NAME,
        ]),
        ctx.generateAddContextMenuGroup(params),
        ctx.generateEditContextMenu(params, [ContextMenuItemId.REMOVE_ROW]),
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }

  private getTicketListInfo = async (
    projectUuid: string,
    savedSearchCondition: TicketSearchCondition | undefined,
    state: TicketState,
    isRestoredByUser?: boolean
  ): Promise<TicketListInfo> => {
    const urlParams = getUrlQueryStringParams()
    const urlTicketListUuid: string | undefined = urlParams?.ticketList
    const existsRestoredCondition: boolean =
      savedSearchCondition?.projectUuid === projectUuid &&
      !!savedSearchCondition?.lastTicketListUuidMap &&
      !!savedSearchCondition?.ticketType
    const restoreTicketListUuid: string | undefined = existsRestoredCondition
      ? savedSearchCondition?.lastTicketListUuidMap![
          savedSearchCondition?.ticketType!
        ]
      : undefined

    const ticketListUuid: string | undefined = isRestoredByUser
      ? restoreTicketListUuid || urlTicketListUuid
      : urlTicketListUuid || restoreTicketListUuid

    if (!ticketListUuid) {
      return {}
    }
    const ticketList =
      !state.ticketList || state.ticketList.uuid !== ticketListUuid
        ? (await TicketListApi.findByUuid(projectUuid, ticketListUuid)).json
        : state.ticketList

    const newTicketListUuidMap = {
      ...state.lastTicketListUuidMap,
      ...savedSearchCondition?.lastTicketListUuidMap,
    }
    newTicketListUuidMap[ticketList.ticketType] = ticketListUuid
    return {
      ticketType: ticketList?.ticketType,
      ticketList,
      lastTicketListUuidMap: newTicketListUuidMap,
    }
  }

  updateDefaultState = async (
    s: TicketState,
    applicationFunctionUuid: string
  ) => {
    const statusPanels =
      s.gridOptions.statusBar?.statusPanels || ([] as StatusPanelDef[])
    statusPanels.push({
      statusPanel: 'countWbsStatusComponent',
      align: 'left',
    })
    let pageState
    const pageStateProp = await this.getPageState(applicationFunctionUuid)
    if (pageStateProp && pageStateProp.value) {
      pageState = JSON.parse(pageStateProp.value)
    }
    const ticketListInfo = await this.getTicketListInfo(
      s.uuid,
      undefined,
      s,
      false
    )
    const newState = {
      ...s,
      editable: false,
      aggregateTargetType: AggregateField.WBS_ITEM_WORKLOAD,
      workloadUnit: pageState ? pageState.workloadUnit : WorkloadUnit.HOUR,
      ...ticketListInfo,
    }
    objects.setValue(
      newState,
      'gridOptions.statusBar.statusPanels',
      statusPanels
    )
    return newState
  }
  getDefaultContext = (): any => ({
    aggregateTargetType: AggregateField.WBS_ITEM_WORKLOAD,
    workloadUnit: WorkloadUnit.DAY,
  })
  columnAndFilterStateKey = (ctx: TicketBulkSheetContext) =>
    `${UiStateKey.TicketColumnAndFilterState}-${ctx.state.uuid}-${
      ctx.state.ticketType
    }${ctx.state.ticketList ? `-${ctx.state.ticketList.uuid}` : ''}`
  searchConditionStateKeySuffix = (ctx: TicketBulkSheetContext) =>
    `${ctx.state.uuid}`
  getRowsToRemove = (
    nodes: RowNode[],
    ctx: TicketBulkSheetContext
  ): {
    rows: RowNode[]
    unremovableReasonMessageId?: string
  } => {
    let result: RowNode[] = []
    const canRemoveRow = (node: RowNode) => {
      const row: TicketRow = node.data
      return !row.wbsItem.actualHour
    }
    nodes.forEach(node => {
      if (canRemoveRow(node)) {
        result.push(node)
      }
    })
    return { rows: result }
  }

  pinnedColumns = [
    'ticket.list.action',
    'ticket.list.ticketList',
    'ticket.list.parentPath',
    'ticket.list.parentWbsItem',
    'ticket.list.wbsItem.code',
    'ticket.list.wbsItem.status',
    'ticket.list.wbsItem.substatus',
    'ticket.list.ticketType',
    'ticket.list.wbsItem.displayName',
    'ticket.list.attachment',
  ]
  lockedColumns = [
    'ticket.list.action',
    'ticket.list.attachment',
    'ticket.list.wbsItem.code',
    'ticket.list.wbsItem.status',
    'ticket.list.wbsItem.substatus',
    'ticket.list.ticketType',
    'ticket.list.ticketList',
    'ticket.list.parentPath',
    'ticket.list.parentWbsItem',
  ]
  customColumnTypes = {
    'ticket.list.wbsItem.status': [ColumnType.wbsItemStatus],
    'ticket.list.wbsItem.scheduledDate.startDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'ticket.list.wbsItem.scheduledDate.endDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'ticket.list.wbsItem.actualDate.startDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'ticket.list.wbsItem.actualDate.endDate': [ColumnType.wbsItemActualDate],
    'ticket.list.wbsItem.priority': ['priorityColumn'],
    'ticket.list.commentSummary.latestComment': [ColumnType.comment],
    'ticket.list.parentPath': ['path'],
  }
  customRenderers = {
    'ticket.list.wbsItem.displayName': 'wbsItemTreeCellRenderer',
  }
  fieldRefreshedAfterMove = ['wbsItem.status']

  async getAll(state: TicketState) {
    if (!state.ticketList?.uuid) {
      return successDummyResponse
    }
    return TicketApi.getByTicketListUuid({
      ticketListUuid: state.ticketList.uuid,
    })
  }

  getUpdatedRowAncestors = async (
    uuid: string,
    treeRootUuid?: string,
    state?: TicketState
  ): Promise<TicketDetail> => {
    const ticketlist = state?.ticketList
    const ticket = (await TicketApi.getDetail({ uuid })).json as TicketDetail
    if (ticketlist?.uuid !== ticket.ticketList.uuid) {
      return {} as TicketDetail
    }
    return ticket
  }

  onSubmit = async (
    ctx: TicketBulkSheetContext,
    data: {
      added: TicketRow[]
      edited: {
        before: TicketRow
        after: TicketRow
      }[]
      deleted: TicketRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const request: UpdateTicketDeltaBatchRequest = {
      added: data.added.map(row =>
        this.buildCreateTicketRequestByRow(row, viewMeta, ctx)
      ),
      edited: data.edited.map(row =>
        this.buildUpdateTicketDeltaRequestByRow(row, viewMeta)
      ),
      deleted: data.deleted.map(row => this.buildDeleteRequestByRow(row)),
      watchers: createWatchers(data.added, data.edited),
      tags: createTagInputs(data.added, data.edited),
    }
    const response = await TicketApi.updateBatchDelta(request)

    // Update sprint backlogs
    const addedUuidMap = Object.fromEntries(
      response.json?.added.map(v => [v.uuid, v.wbsItemUuid])
    )
    const sprintBacklogRequest = createSprintBacklogUpdateBatchRequest(
      [
        ...data.added.map(v => ({ ...v.wbsItem, uuid: addedUuidMap[v.uuid] })),
        ...data.edited.map(v => v.after.wbsItem),
      ],
      Object.fromEntries(
        [...response.json.added, ...response.json.edited].map(v => [
          v.wbsItemUuid,
          v.wbsItemLockVersion,
        ])
      ),
      data.edited.map(v => v.before.wbsItem)
    )
    await SprintBacklogApi.updateBatchSprintBacklog(sprintBacklogRequest)

    return response
  }

  protected buildCreateTicketRequestByRow(
    row: TicketRow,
    viewMeta: ViewMeta,
    ctx: TicketBulkSheetContext
  ): CreateTicketInput {
    const u = row.wbsItem
    const wbsItemInput = createRequestByRow(u, viewMeta, `ticket.list.wbsItem`)
    return {
      ...row,
      uuid: row.uuid,
      projectUuid: ctx.state.uuid,
      ticketType: ctx.state.ticketType,
      ticketListUuid: ctx.state.ticketList!.uuid,
      parentWbsItemUuid: row.parentWbsItem?.uuid,
      projectPlan: {
        projectUuid: ctx.state.uuid,
        uuid: generateUuid(),
        type: WbsItemType.TASK,
        wbsItem: wbsItemInput,
        productBacklogItem: false,
      },
      extensions: viewMeta.serializeEntityExtensionsForApi(row.extensions),
      prevSiblingUuid: row.prevSiblingUuid,
      parentUuid: row.parentUuid,
    } as CreateTicketInput
  }

  createWbsItemDeltaRequestByRow = (
    editedRow: {
      before: TicketRow
      after: TicketRow
    },
    viewMeta: ViewMeta
  ): WbsItemDeltaInput => {
    const { before: editedRowBefore, after: editedRowAfter } = editedRow
    return createDeltaRequestByRow(
      {
        before: editedRowBefore.wbsItem,
        after: editedRowAfter.wbsItem,
      },
      viewMeta,
      'projectPlan.wbsItem',
      {
        before: editedRowBefore.extensions,
        after: editedRowAfter.extensions,
      }
    )
  }

  protected buildUpdateTicketDeltaRequestByRow(
    editedRow: {
      before: TicketRow
      after: TicketRow
    },
    viewMeta: ViewMeta
  ): UpdateTicketDeltaInput {
    const wbsItemInput = createDeltaRequestByRow(
      {
        before: editedRow.before.wbsItem,
        after: editedRow.after.wbsItem,
      },
      viewMeta,
      `ticket.list.wbsItem`,
      {}
    )
    return {
      uuid: editedRow.after.uuid,
      ticketListUuid: {
        oldValue: editedRow.before.ticketList?.uuid,
        newValue: editedRow.after.ticketList?.uuid,
      },
      parentUuid: {
        oldValue: editedRow.before.parentUuid,
        newValue: editedRow.after.parentUuid,
      },
      prevSiblingUuid: {
        oldValue: editedRow.before.prevSiblingUuid,
        newValue: editedRow.after.prevSiblingUuid,
      },
      wbsItem: wbsItemInput,
      extensions: viewMeta.serializeEntityExtensionsDeltaForApi({
        oldExtensions: editedRow.before.extensions,
        newExtensions: editedRow.after.extensions,
      }),
    } as UpdateTicketDeltaInput
  }

  protected buildDeleteRequestByRow = (row: TicketRow) => {
    return {
      wbsItemUuid: row.wbsItem.uuid!,
    }
  }

  private getPageState = async uuid => {
    const request: RequestOfGetStates = {
      applicationFunctionUuid: uuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    }
    const response = await uiStates.get(request)
    return response.json
  }

  getApplicationContext = (ctx: TicketBulkSheetContext) => {
    const groupKeys: string[] = []
    if (!ctx.props.projectUuid) {
      return undefined
    }
    groupKeys.push(ctx.props.projectUuid)
    const state = ctx.state
    if (!state) {
      return { groupKeys }
    }
    if (state.ticketType) {
      groupKeys.push(state.ticketType)
    }
    if (state.ticketList) {
      groupKeys.push(state.ticketList.uuid)
    }
    return { groupKeys }
  }

  onTicketListChanged(
    ticketList: TicketListDetail | undefined,
    ctx: TicketBulkSheetContext
  ) {
    ctx.setState({ ticketList, editable: !!ticketList }, () => {
      ctx.refreshGrid()
    })
  }

  getOpenDetailSpec = async (row: TicketRow): Promise<OpenDetailSpec> => {
    const externalId =
      row.ticketType?.getValue() === 'RISK'
        ? APPLICATION_FUNCTION_EXTERNAL_ID.RISK
        : APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM
    return {
      openInDialog: true,
      layer: {
        externalId,
        code: row.wbsItem.code!,
      },
    }
  }

  getSearchCondition = (state: TicketState) => {
    return {
      ticketType: state.ticketType,
      lastTicketListUuidMap: state.lastTicketListUuidMap,
      projectUuid: state.uuid,
    }
  }

  restoreSearchCondition = async (
    searchCondition: {
      ticketType: string
      lastTicketListUuidMap: { [key: string]: string | undefined }
      projectUuid: string
    },
    ctx: TicketBulkSheetContext,
    options?: RestoreSearchConditionOptions
  ) => {
    const ticketListInfo = await this.getTicketListInfo(
      ctx.state.uuid,
      searchCondition,
      ctx.state,
      options?.isRestoredSavedSearchConditionByUser
    )
    if (ticketListInfo.ticketList?.ticketType) {
      ctx.setState({
        ticketType: ticketListInfo.ticketList?.ticketType,
        lastTicketListUuidMap: ticketListInfo.lastTicketListUuidMap,
      })
      this.onTicketListChanged(ticketListInfo.ticketList, ctx)
    } else {
      this.onTicketListChanged(undefined, ctx)
    }
  }

  pinnedTopRowData: TicketRow[] = [
    {
      rowNumber: 'Total',
      uuid: generateUuid(),
      wbsItem: {},
      isTotal: true,
      isViewOnly: true,
    } as TicketRow,
  ]

  getTaskActualResultKey = (params: CellClickedEvent): string | undefined => {
    const row: TicketRow = params.data
    if (row && taskActualResultExists(row.wbsItem)) {
      return row.wbsItem.uuid
    }
    return undefined
  }

  getCellEditorParams = (field: string): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.estimatedStoryPoint') {
      return {
        wbsItemTypeFieldName: 'wbsItem.type',
        wbsItemStatusFieldName: 'wbsItem.status',
        storyPointWorkloadFieldName: 'wbsItem.estimatedStoryPoint',
      }
    }
    if (field === 'wbsItem.scheduledDate.endDate') {
      return {
        getInitialValueOnCalendar: (params: ICellEditorParams) => {
          const startDate = params.data?.wbsItem?.scheduledDate?.startDate
          return startDate ? dateVoService.construct(startDate) : undefined
        },
      }
    }
    if (field === 'wbsItem.actualDate.endDate') {
      return {
        getInitialValueOnCalendar: (params: ICellEditorParams) => {
          const startDate = params.data?.wbsItem?.actualDate?.startDate
          return startDate ? dateVoService.construct(startDate) : undefined
        },
      }
    }
    if (field === 'wbsItem.currentSprint') {
      return {
        validatePaste: value =>
          [SprintStatus.INPROGRESS, SprintStatus.STANDBY].includes(
            value.status!
          ),
      }
    }
  }

  public getCellRendererParams = (
    field: string
  ): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.status') {
      return {
        isAggregate: true,
      }
    }
    if (field === 'wbsItem.actualHour') {
      return {
        link: (row: TicketRow) => row && taskActualResultExists(row.wbsItem),
      }
    }
    if (field === 'wbsItem.watchers') {
      return {
        showIcon: true,
      }
    }
    return undefined
  }

  getAttachmentCellRendererParams = () => {
    return {
      getAttachmentList: async (row: TicketRow) => {
        if (row.wbsItem.uuid) {
          const response = await WbsItemApi.getDetail({
            uuid: row.wbsItem.uuid,
          })
          const wbsItemDetail = response.json
          return wbsItemDetail.deliverableAttachments || []
        }
        return []
      },
      getAttachmentSummary: (row: TicketRow) => {
        return row.deliverableAttachmentSummary
      },
    }
  }

  getDetailColumnCellRendererParams = (ctx: TicketBulkSheetContext) => {
    return {
      path: 'wbsItem.description',
      openComment: (row: TicketRow, ctx: TicketBulkSheetContext) => {
        const applicationFunctionUuid = getWbsItemFunctionUuid()
        const wbsItemUuid = row.wbsItem.uuid
        if (!applicationFunctionUuid || !wbsItemUuid) {
          return
        }
        store.dispatch(
          openComment({
            applicationFunctionUuid,
            dataUuid: wbsItemUuid,
            projectUuid: row.wbsItem.projectUuid!,
            headerComponents: [
              <CommentHeaderWbsItem
                key={1}
                wbsItem={mapRowDataForCommentHeader(row.wbsItem)}
                onAfterUpdate={() => {
                  ctx.refreshAfterUpdateSingleRow(row.uuid)
                }}
              />,
            ],
          })
        )
      },
      addRowActions: (row: TicketRow) => {
        ctx.addRow(row.uuid)
      },
      getCommentSummary: (row: TicketRow) => row.commentSummary,
    }
  }
  isSetKeyBind = true
  customColumnWidth = (field: string): number | undefined => {
    if (
      [
        'wbsItem.priority',
        'wbsItem.accountable',
        'wbsItem.responsible',
        'wbsItem.assignee',
        'wbsItem.difficulty',
      ].includes(field)
    ) {
      return 65
    }
    if (
      [
        'wbsItem.estimatedStoryPoint',
        'storyPoint.earnedValue',
        'wbsItem.estimatedWorkload',
        'wbsItem.actualHour',
        'wbsItem.earnedValue',
        'remaining',
        'preceding',
        'delayed',
        'plannedValue',
      ].includes(field)
    ) {
      return 80
    }
    if (['wbsItem.code'].includes(field)) {
      return 100
    }
    if (field === 'wbsItem.status') {
      return 150
    }
    if (field === 'action') {
      return 135
    }
    if (['attachment'].includes(field)) {
      return 55
    }
    if (['wbsItem.tags'].includes(field)) {
      return 100
    }
    return undefined
  }

  getValueGetter = (field: string): AgGridValueGetter | undefined => {
    if (['wbsItem.earnedValue'].includes(field)) {
      return (params: ValueGetterParams) => {
        if (!params.data.isTotal) {
          return projectPlanFacadeAggregator(
            params.node!,
            EvmIndicator.EARNED_VALUE,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_WORKLOAD,
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        let sum = 0
        if (!params.api) {
          return
        }
        params.api.forEachNode(node => {
          const nodeData = node.data
          if (nodeData.isTotal) {
            return
          }
          if (nodeData.wbsItem.status === WbsItemStatus.DONE) {
            sum = sum + nodeData.wbsItem.estimatedWorkload.hour
          }
        })
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        return Number((Number(sum) || 0) / denominator)
      }
    }
    if (['plannedValue'].includes(field)) {
      return (params: ValueGetterParams) => {
        if (!params.data.isTotal) {
          return projectPlanFacadeAggregator(
            params.node!,
            EvmIndicator.PLANNED_VALUE,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_WORKLOAD,
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        let sum = 0
        if (!params.api) {
          return
        }
        params.api.forEachNode(node => {
          const nodeData = node.data
          if (nodeData.isTotal) {
            return
          }

          if (
            nodeData.wbsItem.scheduledDate &&
            new DateVO(nodeData.wbsItem.scheduledDate.endDate).isSameOrBefore(
              DateVO.now()
            )
          ) {
            sum = sum + nodeData.wbsItem.estimatedWorkload.hour
          }
        })
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        return Number((Number(sum) || 0) / denominator)
      }
    }
    if (['preceding'].includes(field)) {
      return (params: ValueGetterParams) => {
        if (!params.data.isTotal) {
          return projectPlanFacadeAggregator(
            params.node!,
            EvmIndicator.PRECEDING,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_WORKLOAD,
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        let sum = 0
        if (!params.api) {
          return
        }
        params.api.forEachNode(node => {
          const nodeData = node.data
          if (nodeData.isTotal) {
            return
          }

          if (
            nodeData.wbsItem.scheduledDate &&
            new DateVO(nodeData.wbsItem.scheduledDate.endDate).isAfter(
              DateVO.now()
            ) &&
            nodeData.wbsItem.status === WbsItemStatus.DONE
          ) {
            sum = sum + nodeData.wbsItem.estimatedWorkload.hour
          }
        })
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        return Number((Number(sum) || 0) / denominator)
      }
    }
    if (['delayed'].includes(field)) {
      return (params: ValueGetterParams) => {
        if (!params.data.isTotal) {
          return projectPlanFacadeAggregator(
            params.node!,
            EvmIndicator.DELAYED,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_WORKLOAD,
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        let sum = 0
        if (!params.api) {
          return
        }
        params.api.forEachNode(node => {
          const nodeData = node.data
          if (nodeData.isTotal) {
            return
          }

          if (
            nodeData.wbsItem.scheduledDate &&
            new DateVO(nodeData.wbsItem.scheduledDate.endDate).isSameOrBefore(
              DateVO.now()
            ) &&
            nodeData.wbsItem.status !== WbsItemStatus.DONE
          ) {
            sum = sum + nodeData.wbsItem.estimatedWorkload.hour
          }
        })
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        return Number((Number(sum) || 0) / denominator)
      }
    }
    if (['remaining'].includes(field)) {
      return (params: ValueGetterParams) => {
        if (!params.data.isTotal) {
          return projectPlanFacadeAggregator(
            params.node!,
            EvmIndicator.REMAINING,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_WORKLOAD,
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          )
        }
        let sum = 0
        if (!params.api) {
          return
        }
        params.api.forEachNode(node => {
          const nodeData = node.data
          if (nodeData.isTotal) {
            return
          }

          if (nodeData.wbsItem.status !== WbsItemStatus.DONE) {
            sum = sum + nodeData.wbsItem.estimatedWorkload.hour
          }
        })
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        return Number((Number(sum) || 0) / denominator)
      }
    }
    if (field === 'wbsItem.estimatedWorkload') {
      return (params: ValueGetterParams) => {
        const data: TicketRow = params.data
        if (data.isTotal) {
          let sum = 0
          if (!params.api) {
            return
          }
          params.api.forEachNode(node => {
            const nodeData = node.data
            if (nodeData.isTotal) {
              return
            }
            if (nodeData.wbsItem.status !== WbsItemStatus.DISCARD) {
              sum =
                sum +
                getWorkloadUnitValue(
                  nodeData.wbsItem.estimatedWorkload,
                  params.context.workloadUnit
                )
            }
          })
          return sum
        }
        return getWorkloadUnitValue(
          data.wbsItem.estimatedWorkload,
          params.context.workloadUnit
        )
      }
    }
    if (['wbsItem.actualHour'].includes(field)) {
      return (params: ValueGetterParams) => {
        const data: TicketRow = params.data
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        const format = (value: number | undefined) =>
          Number((Number(value) || 0) / denominator)
        if (data.isTotal) {
          let sum = 0
          if (!params.api) {
            return
          }
          params.api.forEachNode(node => {
            const nodeData = node.data
            if (nodeData.isTotal) {
              return
            }
            if (nodeData.wbsItem.status !== WbsItemStatus.DISCARD) {
              sum = sum + format(nodeData.cumulation?.actualHour)
            }
          })
          return sum
        }
        return format(data.cumulation?.actualHour)
      }
    }
    if (field === 'ticketType') {
      return (params: ValueGetterParams) => {
        return params.data?.wbsItem?.baseWbsItemType
      }
    }
    if (field === 'wbsItem.tags') {
      return (params: ValueGetterParams) => {
        return params.data?.wbsItem?.tags
      }
    }
  }

  getValueSetter = (field: string): AgGridValueSetter | undefined => {
    if (field === 'wbsItem.estimatedWorkload') {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) {
          return false
        }
        params.data.isEdited = true
        const unit = params.context.workloadUnit
        const { dailyWorkHours, monthlyWorkDays } =
          store.getState().tenant.organization!
        let workload: Workload
        switch (unit) {
          case WorkloadUnit.MONTH:
            workload = Workload.from({
              month: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          case WorkloadUnit.DAY:
            workload = Workload.from({
              day: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          case WorkloadUnit.HOUR:
            workload = Workload.from({
              hour: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          default:
            workload = new Workload(0, 0, 0, {
              dailyWorkHours,
              monthlyWorkDays,
            })
        }
        params.data.wbsItem.estimatedWorkload = workload
        return true
      }
    }
    return undefined
  }

  getCellStyle = (field: string): CellStyle | CellStyleFunc | undefined => {
    if (field === 'wbsItem.priority') {
      return {
        justifyContent: 'center',
      }
    }
    return undefined
  }

  getOnCellValueChanged = (field: string, ctx: TicketBulkSheetContext) => {
    if (
      [
        'wbsItem.status',
        'wbsItem.estimatedWorkload',
        'wbsItem.scheduledDate.startDate',
        'wbsItem.scheduledDate.endDate',
        'wbsItem.actualHour',
      ].includes(field)
    ) {
      return (params: NewValueParams) => {
        if (ctx.props.specificProps?.updateAggregationRowNodes) {
          ctx.props.specificProps?.updateAggregationRowNodes(
            ctx.getAllRowNodes()
          )
        }
      }
    }
    return undefined
  }

  onRowDataChanged = (ctx: TicketBulkSheetContext) => {
    if (ctx.props.specificProps?.updateAggregationRowNodes) {
      ctx.props.specificProps?.updateAggregationRowNodes(ctx.getAllRowNodes())
    }
  }

  mergeRowCommentSummary = (
    targetRow: TicketRow,
    comments: List<Comment> | undefined
  ): TicketRow => {
    return {
      ...targetRow,
      commentSummary: commentListToSummary(comments),
    }
  }

  getKeyBindListeners = (ctx: TicketBulkSheetContext): KeyBindListener[] => {
    return [
      {
        key: 'alt+shift+l',
        fn: () => {
          if (
            !!ctx.gridApi?.getSelectedNodes() &&
            ctx.gridApi?.getSelectedNodes().length > 0
          ) {
            ctx.addRow(
              ctx.gridApi?.getSelectedNodes().slice(-1)[0].data.uuid,
              ctx.gridApi?.getSelectedNodes().slice(-1)[0].parent &&
                !ctx.gridApi?.getSelectedNodes().slice(-1)[0].parent?.groupData
                ? ctx.gridApi?.getSelectedNodes().slice(-1)[0].parent?.id
                : undefined
            )
          }
        },
      },
      {
        key: 'mod+shift+l',
        fn: () => {
          if (
            !!ctx.gridApi?.getSelectedNodes() &&
            ctx.gridApi?.getSelectedNodes().length > 0
          ) {
            ctx.addRow(
              ctx.gridApi?.getSelectedNodes().slice(-1)[0].data.uuid,
              ctx.gridApi?.getSelectedNodes().slice(-1)[0].data.uuid
            )
          }
        },
      },
      {
        key: 'alt+shift+d',
        fn: ctx.removeRowKeyEventListener,
      },
    ]
  }
}

import { ComponentProps, useCallback, useMemo } from 'react'
import _ from 'lodash'
import {
  EntitySearchReferenceEntityRepository,
  GetEntitySearchReferenceEntityRepository,
  useEntitySearchReferenceEntityRepositories,
  useWbsItemAdditionalProperties,
} from '../../../../hooks/useWbsItemAdditionalProperties'
import { TableHeader } from '../../../../containers/SingleSheetV2/table/Header'
import { TableRow } from '../../../../containers/SingleSheetV2/table/cells'
import { NumberCell as SingleSheetNumberCell } from '../../../../containers/SingleSheetV2/table/cells/NumberCell'
import { TextCell as SingleSheetTextCell } from '../../../../containers/SingleSheetV2/table/cells/TextCell'
import { DateCell as SingleSheetDateCell } from '../../../../containers/SingleSheetV2/table/cells/DateCell'
import { DateTimeCell as SingleSheetDateTimeCell } from '../../../../containers/SingleSheetV2/table/cells/DateTimeCell'
import { CheckboxCell as SingleSheetCheckboxCell } from '../../../../containers/SingleSheetV2/table/cells/CheckboxCell'
import { SelectCell as SingleSheetSelectCell } from '../../../../containers/SingleSheetV2/table/cells/SelectCell'
import { MultiSelectCell as SingleSheetMultiSelectCell } from '../../../../containers/SingleSheetV2/table/cells/MultiSelectCell'
import { AutocompleteCell as SingleSheetAutocompleteCell } from '../../../../containers/SingleSheetV2/table/cells/AutocompleteCell'
import { i18nLabelVoService } from '../../../../../domain/value-object/I18nLabelVO'
import {
  EntitySearchReferenceEntity,
  WbsItemAdditionalPropertyEntity,
  WbsItemAdditionalPropertySelectOption,
  WbsItemAdditionalPropertyType,
} from '../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { WbsItemFormModel } from '../../model'
import {
  WbsItemAdditionalPropertyEntitySearchValueType,
  WbsItemAdditionalPropertyMultiLineTextValueType,
  WbsItemAdditionalPropertyCheckboxValueType,
  WbsItemAdditionalPropertyNumberValueType,
  WbsItemAdditionalPropertySelectValueType,
  WbsItemAdditionalPropertyMultiSelectValueType,
  WbsItemAdditionalPropertyTextValueType,
  wbsItemAdditionalPropertyValuesVoService,
  WbsItemAdditionalPropertyValueType,
  WbsItemAdditionalPropertyValuesVO,
  WbsItemAdditionalPropertyDateValueType,
  WbsItemAdditionalPropertyDateTimeValueType,
} from '../../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import {
  PropertyLayout,
  WbsItemAdditionalPropertyLayoutEntity,
} from '../../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { MultilineTextCell } from '../../../../containers/SingleSheetV2/table/cells/MultilineTextCell'
import {
  DateV2,
  dateV2Service,
} from '../../../../../domain/value-object/DateV2'
import {
  DateTimeV2,
  dateTimeV2Service,
} from '../../../../../domain/value-object/DateTimeV2'
import {
  SingleSheetPropertiesValidationErrors,
  SingleSheetPropertyValidationErrors,
  ValidateSingleSheetWbsItemAdditionalPropertyValue,
} from '../../../../containers/SingleSheetV2/hooks/formValidation'

export const WbsItemAdditionalPropertyCells = ({
  projectUuid,
  wbsItemUuid,
  wbsItemAdditionalProperties,
  wbsItemAdditionalPropertyValues,
  onChange: _onChange,
  validate,
  validationErrors,
}: {
  projectUuid: string
  wbsItemUuid: string
  wbsItemAdditionalProperties?: WbsItemAdditionalPropertyLayoutEntity
  wbsItemAdditionalPropertyValues?: WbsItemAdditionalPropertyValuesVO
  onChange: (value: WbsItemAdditionalPropertyValuesVO) => void
  validate: ValidateSingleSheetWbsItemAdditionalPropertyValue
  validationErrors: SingleSheetPropertiesValidationErrors
}) => {
  const { getEntitySearchReferenceEntityRepository } =
    useEntitySearchReferenceEntityRepositories(projectUuid)
  const onChange = useCallback(
    (
      wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
      value: WbsItemAdditionalPropertyValueType
    ) => {
      let vo =
        wbsItemAdditionalPropertyValues ||
        wbsItemAdditionalPropertyValuesVoService.construct()
      wbsItemAdditionalPropertyValuesVoService.setValue(
        vo,
        wbsItemAdditionalProperty.uuid,
        value
      )
      _onChange(vo)
      validate(wbsItemAdditionalProperty, value)
    },
    [_onChange, wbsItemAdditionalPropertyValues, validate]
  )

  if (!wbsItemAdditionalProperties) return <></>
  return (
    <>
      {wbsItemAdditionalProperties.groupHeaderLayouts.map(
        ({ groupHeader, propertyLayouts }) => {
          return (
            <>
              <TableHeader
                title={i18nLabelVoService.getLabel(groupHeader.headerNameI18n)}
              />
              <AdditionalPropertyCells
                wbsItemUuid={wbsItemUuid}
                propertyLayouts={propertyLayouts}
                additionalPropertyValues={wbsItemAdditionalPropertyValues}
                getEntitySearchReferenceEntityRepository={
                  getEntitySearchReferenceEntityRepository
                }
                onChange={onChange}
                validationErrors={validationErrors}
              />
            </>
          )
        }
      )}
    </>
  )
}

const AdditionalPropertyCells = ({
  wbsItemUuid,
  propertyLayouts,
  additionalPropertyValues,
  getEntitySearchReferenceEntityRepository,
  onChange,
  validationErrors,
}: {
  wbsItemUuid: string
  propertyLayouts: PropertyLayout[]
  additionalPropertyValues?: WbsItemAdditionalPropertyValuesVO
  getEntitySearchReferenceEntityRepository: (
    entitySearchReferenceEntity: EntitySearchReferenceEntity
  ) => EntitySearchReferenceEntityRepository
  onChange: (
    wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
    value: WbsItemAdditionalPropertyValueType
  ) => void
  validationErrors: SingleSheetPropertiesValidationErrors
}) => {
  const propertyLayoutChunks: PropertyLayout[][] = _.chunk(propertyLayouts, 2)
  return (
    <>
      {propertyLayoutChunks.map(propertyLayouts => {
        return (
          <TableRow
            key={propertyLayouts
              .map(v => v.wbsItemAdditionalProperty.uuid)
              .join(',')}
          >
            {propertyLayouts.map(({ wbsItemAdditionalProperty }) => {
              return (
                <AdditionalPropertyCell
                  key={wbsItemAdditionalProperty.uuid}
                  wbsItemAdditionalProperty={wbsItemAdditionalProperty}
                  wbsItemUuid={wbsItemUuid}
                  value={
                    additionalPropertyValues
                      ? wbsItemAdditionalPropertyValuesVoService.getValue(
                          additionalPropertyValues,
                          wbsItemAdditionalProperty.uuid
                        )?.value
                      : undefined
                  }
                  getEntitySearchReferenceEntityRepository={
                    getEntitySearchReferenceEntityRepository
                  }
                  onChange={onChange}
                  validationErrors={
                    validationErrors[wbsItemAdditionalProperty.uuid]
                  }
                />
              )
            })}
          </TableRow>
        )
      })}
    </>
  )
}
const AdditionalPropertyCell = ({
  wbsItemAdditionalProperty,
  wbsItemUuid,
  value,
  getEntitySearchReferenceEntityRepository,
  onChange,
  validationErrors,
}: {
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity
  wbsItemUuid: string
  value: WbsItemAdditionalPropertyValueType
  getEntitySearchReferenceEntityRepository: GetEntitySearchReferenceEntityRepository
  onChange: (
    wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
    value: WbsItemAdditionalPropertyValueType
  ) => void
  validationErrors?: SingleSheetPropertyValidationErrors
}) => {
  const {
    uuid,
    projectUuid,
    propertyNameI18n,
    propertyType,
    required,
    selectOptions,
    entitySearchReferenceEntity,
  } = wbsItemAdditionalProperty
  const label = useMemo(
    () => i18nLabelVoService.getLabel(propertyNameI18n),
    [propertyNameI18n]
  )
  const onChangeValue = useCallback(
    (newValue: WbsItemAdditionalPropertyValueType) => {
      onChange(wbsItemAdditionalProperty, newValue)
    },
    [wbsItemAdditionalProperty, onChange]
  )

  switch (propertyType) {
    case WbsItemAdditionalPropertyType.NUMBER:
      return (
        <NumberCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyNumberValueType}
          editable={true}
          required={required}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.TEXT:
      return (
        <TextCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyTextValueType}
          editable={true}
          required={required}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.MULTI_LINE_TEXT:
      return (
        <MultilineTextCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyMultiLineTextValueType}
          dataUuid={wbsItemUuid}
          externalId={uuid}
          editable={true}
          required={required}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.DATE:
      return (
        <DateCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyDateValueType}
          editable={true}
          required={required}
        />
      )
    case WbsItemAdditionalPropertyType.DATE_TIME:
      return (
        <DateTimeCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyDateTimeValueType}
          editable={true}
          required={required}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.CHECKBOX:
      return (
        <CheckboxCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyCheckboxValueType}
          editable={true}
          required={required}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.SELECT:
      return (
        <SelectCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertySelectValueType}
          editable={true}
          required={required}
          selectOptions={selectOptions}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.MULTI_SELECT:
      return (
        <MultiSelectCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyMultiSelectValueType}
          editable={true}
          required={required}
          selectOptions={selectOptions}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.ENTITY_SEARCH:
      return (
        <AutocompleteCell
          label={label}
          cellWidth="half"
          // @ts-ignore
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyEntitySearchValueType}
          editable={true}
          required={required}
          entitySearchReferenceEntity={entitySearchReferenceEntity!}
          getEntitySearchReferenceEntity={
            getEntitySearchReferenceEntityRepository
          }
          validationErrors={validationErrors}
        />
      )
  }
}

// Custom single sheet cells.
const NumberCell = SingleSheetNumberCell
const TextCell = SingleSheetTextCell
const DateCell = ({
  value,
  onChange,
  ...otherProps
}: Omit<SingleSheetDateCellProps, 'value' | 'onChange'> & {
  value: WbsItemAdditionalPropertyDateValueType
  onChange: (value: WbsItemAdditionalPropertyValueType) => void
}) => {
  const dateValue = useMemo(
    () => (value ? new Date(value) : undefined),
    [value]
  )
  const onChangeValue = useCallback(
    (newValue: DateV2 | undefined) => {
      onChange(
        newValue
          ? wbsItemAdditionalPropertyValuesVoService.toValueFromDate(newValue)
          : undefined
      )
    },
    [onChange]
  )
  return (
    <SingleSheetDateCell
      {...otherProps}
      value={dateValue}
      onChange={onChangeValue}
    />
  )
}
const DateTimeCell = ({
  value,
  onChange,
  ...otherProps
}: Omit<SingleSheetDateTimeCellProps, 'value' | 'onChange'> & {
  value: WbsItemAdditionalPropertyDateTimeValueType
  onChange: (value: WbsItemAdditionalPropertyValueType) => void
}) => {
  const dateTimeValue = useMemo(
    () => (value ? new Date(value) : undefined),
    [value]
  )
  const onChangeValue = useCallback(
    (newValue: DateTimeV2 | undefined) => {
      onChange(newValue ? newValue.getTime() : undefined)
    },
    [onChange]
  )
  return (
    <SingleSheetDateTimeCell
      {...otherProps}
      value={dateTimeValue}
      onChange={onChangeValue}
    />
  )
}
const CheckboxCell = SingleSheetCheckboxCell
const SelectCell = ({
  selectOptions,
  ...otherProps
}: Omit<SingleSheetSelectCellProps, 'options'> & {
  selectOptions: WbsItemAdditionalPropertySelectOption[] | undefined
}) => {
  const options = useMemo(
    () =>
      selectOptions
        ? selectOptions.map(o => ({
            name: o.selectOption,
            value: o.selectOption,
          }))
        : [],
    [selectOptions]
  )
  return <SingleSheetSelectCell {...otherProps} options={options} />
}
const MultiSelectCell = ({
  selectOptions,
  ...otherProps
}: Omit<SingleSheetMultiSelectCellProps, 'options'> & {
  selectOptions: WbsItemAdditionalPropertySelectOption[] | undefined
}) => {
  const options = useMemo(
    () =>
      selectOptions
        ? selectOptions.map(o => ({
            name: o.selectOption,
            value: o.selectOption,
          }))
        : [],
    [selectOptions]
  )
  return <SingleSheetMultiSelectCell {...otherProps} options={options} />
}
const AutocompleteCell = ({
  entitySearchReferenceEntity,
  getEntitySearchReferenceEntity,
  ...otherProps
}: Omit<SingleSheetAutocompleteCellProps, 'search'> & {
  entitySearchReferenceEntity: EntitySearchReferenceEntity
  getEntitySearchReferenceEntity: GetEntitySearchReferenceEntityRepository
}) => {
  const search = useMemo(
    () => getEntitySearchReferenceEntity(entitySearchReferenceEntity).search,
    [entitySearchReferenceEntity, getEntitySearchReferenceEntity]
  )
  return <SingleSheetAutocompleteCell {...otherProps} search={search} />
}

// Single sheet cell props types.
type SingleSheetDateCellProps = ComponentProps<typeof SingleSheetDateCell>
type SingleSheetDateTimeCellProps = ComponentProps<
  typeof SingleSheetDateTimeCell
>
type SingleSheetSelectCellProps = ComponentProps<typeof SingleSheetSelectCell>
type SingleSheetMultiSelectCellProps = ComponentProps<
  typeof SingleSheetMultiSelectCell
>
type SingleSheetAutocompleteCellProps = ComponentProps<
  typeof SingleSheetAutocompleteCell
>

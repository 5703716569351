import { useEffect } from 'react'
import usePrevious from '../../../hooks/usePrevious'

export const useBrowserTabNameSetter = (
  wbsItemName: string,
  functionLayerDepth: number,
  initialized: boolean
) => {
  const beforeInitialized = usePrevious(initialized)
  useEffect(() => {
    const initializedNow = initialized && !beforeInitialized
    if (initializedNow && functionLayerDepth === 0) {
      document.title = wbsItemName
    }
  }, [wbsItemName, functionLayerDepth, initialized, beforeInitialized])
}

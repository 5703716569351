import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  RefObject,
  useMemo,
} from 'react'
import { useDebouncedCallback } from '../../../hooks/useDebounce'
import { useWindowResizeListener } from '../../../hooks/useWindowResizeListener'

export const useResizableSingleSheet = <T extends HTMLElement>() => {
  const singleSheetRootRef = useRef<T>(null)
  return singleSheetRootRef
}
export const useResizableArea = <T extends HTMLElement>(
  resizableAreaRef: RefObject<T>
) => {
  const [maxWidth, setMaxWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const defaultWidth = useMemo(() => maxWidth * 0.5, [maxWidth])

  useEffect(() => {
    if (resizableAreaRef.current) {
      setMaxWidth(resizableAreaRef.current.offsetWidth)
      setHeight(resizableAreaRef.current.offsetHeight)
    }
  }, [])
  const windowResizeHandler = useCallback(() => {
    if (resizableAreaRef.current) {
      setMaxWidth(resizableAreaRef.current.offsetWidth)
      setHeight(resizableAreaRef.current.offsetHeight)
    }
  }, [])

  const debouncedWindowResizeHandler = useDebouncedCallback(
    windowResizeHandler,
    500
  )
  useWindowResizeListener(debouncedWindowResizeHandler)

  return {
    defaultWidth,
    maxWidth,
    height,
  }
}

import { useCallback, useMemo } from 'react'
import {
  StyledDialog,
  StyledDialogTitle,
  Title,
  StyledDialogContent,
  StyledDialogActions,
  HeaderText,
} from '.'
import { intl } from '../../../../../i18n'
import { Project, CopyRoot } from '../../model'
import { Button } from '../../../../components/buttons'

type SubmitAlertDialogProps = {
  open: boolean
  onClose: () => void
  submit: () => void
  srcProject: Project | undefined
  srcRootProjectPlan: CopyRoot
  destProject: Project | undefined
  destRootProjectPlan: CopyRoot
}
export const SubmitAlertDialog = ({
  open,
  onClose,
  submit,
  srcProject,
  srcRootProjectPlan,
  destProject,
  destRootProjectPlan,
}: SubmitAlertDialogProps) => {
  const onSubmit = useCallback(() => {
    submit()
    onClose()
  }, [onClose, submit])
  const message = useMemo(() => {
    const srcProjectName = srcProject?.name || ''
    const srcRootName =
      srcRootProjectPlan.type === 'PROJECT'
        ? 'すべてのWBS'
        : `${srcRootProjectPlan.name}とその下のWBS`
    const destProjectName = destProject?.name || ''
    const destRootName =
      destRootProjectPlan.type === 'PROJECT'
        ? 'WBSのルート'
        : `${destRootProjectPlan.name}の直下`
    return `${srcProjectName}の${srcRootName}を${destProjectName}の${destRootName}にコピーします。\nよろしいですか？`
  }, [destProject, destRootProjectPlan, srcProject, srcRootProjectPlan])
  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <Title>WBSコピー</Title>
      </StyledDialogTitle>
      <StyledDialogContent>
        <HeaderText>{message}</HeaderText>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onSubmit} colorPattern="skyBlue">
          コピーする
        </Button>
        <Button onClick={onClose} colorPattern="monotone" variant="outlined">
          {intl.formatMessage({ id: 'dialog.cancel' })}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  )
}

import { useMemo } from 'react'
import { intl } from '../../../../../i18n'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import { SelectOption } from '../../../../model/selectOption'
import { CheckboxSelectConditionItemLabel } from '../CheckboxSelectConditionItemLabel'
import { TicketTypeCheckboxSelectConditionProps } from '../../search-components/wbs-item-search-components/TicketTypeCheckboxSelectCondition'
import { FocusableSearchItemLabelProps } from '..'

type TicketTypeCheckboxSelectConditionItemLabelProps = Omit<
  TicketTypeCheckboxSelectConditionProps,
  'colSpan' | 'onEnter'
> & {
  isLoading?: boolean
  search: () => void
  onClose?: () => void
}

export const TicketTypeCheckboxSelectConditionItemLabel = ({
  value,
  isLoading,
  onChange,
  onClose,
  ...other
}: TicketTypeCheckboxSelectConditionItemLabelProps) => {
  const { ticketTypes } = useProjectPrivateContext()
  const options = useMemo(() => {
    const selectOptions: SelectOption[] = ticketTypes.map(t => ({
      name: t.name,
      value: t.code,
      iconUrl: t.iconUrl,
    }))
    selectOptions.push({
      name: intl.formatMessage({ id: 'none' }),
      value: 'NONE',
    })
    return selectOptions
  }, [ticketTypes])
  return (
    <CheckboxSelectConditionItemLabel
      {...other}
      header={intl.formatMessage({
        id: 'wbsItemSearchFilterPanel.ticketTypes',
      })}
      value={value}
      onChange={onChange}
      onClose={onClose}
      options={options}
      isLoading={isLoading}
    />
  )
}

import { useEffect, useState } from 'react'
import { intl } from '../../../../../i18n'
import {
  ExtendSelectOption,
  TagMultiAutocompleteConditionProps,
} from '../../search-components/wbs-item-search-components/TagMultiAutocompleteCondition'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTagsByProject } from '../../../../../store/tag'
import { AllState } from '../../../../../store'
import { CheckboxSelectConditionItemLabel } from '../CheckboxSelectConditionItemLabel'
import { TagForWbsItem } from '../../../../../lib/functions/tag'

type TagMultiAutocompleteConditionLabelItemProps = Omit<
  TagMultiAutocompleteConditionProps,
  'colSpan' | 'onEnter'
> & {
  isLoading?: boolean
  search: () => void
  onClose?: () => void
}

export const TagMultiAutocompleteConditionItemLabel = ({
  value,
  isLoading,
  onChange,
  onClose,
  search,
}: TagMultiAutocompleteConditionLabelItemProps) => {
  const dispatch = useDispatch()
  const { projectUuid } = useProjectPrivateContext()
  const tags = useSelector<AllState, TagForWbsItem[] | undefined>(state =>
    projectUuid ? state.tag[projectUuid] : undefined
  )
  const [options, setOptions] = useState<ExtendSelectOption[]>([])
  useEffect(() => {
    if (projectUuid && options?.length === 0) {
      dispatch(fetchTagsByProject(projectUuid))
    }
  }, [projectUuid])
  useEffect(() => {
    if (!tags || tags.length === 0) return
    setOptions(
      tags.map(v => ({
        value: v.uuid,
        name: v.name,
        backgroundColor: v.backgroundColor,
      }))
    )
  }, [tags])

  return (
    <CheckboxSelectConditionItemLabel
      header={intl.formatMessage({
        id: 'wbsItemSearchFilterPanel.tags',
      })}
      value={value}
      onChange={onChange}
      onClose={onClose}
      search={search}
      options={options}
      isLoading={isLoading}
    />
  )
}

import { Color, FontSize } from '../../../../../../../../styles/commonStyles'
import { intl } from '../../../../../../../../i18n'
import { WbsItemRow } from '../../../../../../../../lib/functions/wbsItem'

import { WbsItemStatus } from '.'
import WbsItemRowApi from '../../../../lib/wbsItem/index'
import Tooltip from '../../../../../../../components/tooltips/Tooltip'
import SmallFireIcon from '../../../../../../../components/icons/SmallFireIcon'
import BigFireIcon from '../../../../../../../components/icons/BigFireIcon'
import CoinIcon from '../../../../../../../components/icons/CoinIcon'

export const ProgressIcon = (props: {
  wbsItem: Partial<WbsItemRow>
  fontSize?: number
}) => {
  const { wbsItem, fontSize } = props
  if (!wbsItem || !wbsItem.status) return <></>
  const todo = wbsItem.status === WbsItemStatus.TODO
  const completed = [WbsItemStatus.DONE, WbsItemStatus.DISCARD].includes(
    wbsItem.status
  )
  if (WbsItemRowApi.isEndDelayed(wbsItem)) {
    return (
      <Tooltip message={intl.formatMessage({ id: 'wbs.end.delayed' })}>
        <BigFireIcon
          style={{
            color: completed ? Color.DISABLED : undefined,
            fontSize: fontSize || FontSize.X_LARGE,
          }}
        />
      </Tooltip>
    )
  } else if (WbsItemRowApi.isStartDelayed(wbsItem)) {
    return (
      <Tooltip message={intl.formatMessage({ id: 'wbs.start.delayed' })}>
        <SmallFireIcon
          style={{
            color: todo ? undefined : Color.DISABLED,
            fontSize: fontSize || FontSize.X_LARGE,
          }}
        />
      </Tooltip>
    )
  } else if (WbsItemRowApi.isPrecedingOnSchedule(wbsItem)) {
    return (
      <Tooltip
        message={intl.formatMessage({ id: 'wbs.preceding.on.schedule' })}
      >
        <CoinIcon
          style={{
            color: todo ? Color.ALERT : Color.DISABLED,
            fontSize: fontSize || FontSize.X_LARGE,
          }}
        />
      </Tooltip>
    )
  }
  return <></>
}

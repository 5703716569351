import { useCallback, useEffect, useMemo, useState } from 'react'
import { ResizableArea } from '../../../../components/draggers/ResizableArea'
import {
  MainArea,
  PanelRoot,
  ResizableMainArea,
  SideBarArea,
  SingleSheetRoot,
} from '../../../../containers/SingleSheetV2'
import {
  Tab,
  Tabs,
  TabMainArea,
  TabPanelArea,
} from '../../../../containers/SingleSheetV2/Tab'
import { TextCell } from '../../../../containers/SingleSheetV2/table/cells/TextCell'
import { PageArea } from '../../..'
import { WbsItemType } from '../../../../../domain/entity/WbsItemEntity'
import { TableRow } from '../../../../containers/SingleSheetV2/table/cells'
import { TableHeader } from '../../../../containers/SingleSheetV2/table/Header'
import { SelectCell } from '../../../../containers/SingleSheetV2/table/cells/SelectCell'
import { AutocompleteCell } from '../../../../containers/SingleSheetV2/table/cells/AutocompleteCell'
import { WbsItemProps } from '../..'
import { MultiAutocompleteCell } from '../../../../containers/SingleSheetV2/table/cells/MultiAutocompleteCell'
import { WorkloadCell } from '../../../../containers/SingleSheetV2/table/cells/WorkloadCell'
import { MultilineTextEditorTab } from '../tab-panel/multiline-text'
import { ProjectPlanTab } from '../tab-panel/project-plan'
import { AttachmentTab } from '../tab-panel/attachment'
import { DiscussionTab } from '../tab-panel/discussion'
import { WbsItemHeader } from '../header'
import { WbsItemSubHeader } from '../subheader'
import { DateTermCell } from '../../../../containers/SingleSheetV2/table/cells/DateTermCell'
import { DateTimeCell } from '../../../../containers/SingleSheetV2/table/cells/DateTimeCell'
import { DevelopmentTabPanel } from '../tab-panel/DevelopmentTabPanel'
import { ChangeLogTabPanel } from '../tab-panel/change-log'
import { WbsStatusCell } from '../../../../containers/SingleSheetV2/table/cells/WbsStatusCell'
import { useWbsItemSelectOptions } from '../../hooks/wbsItemSelectOptions'
import { useWbsItemSingleSheetData } from '../../hooks/wbsItemData'
import Loading from '../../../../components/process-state-notifications/Loading'
import { styled } from '@mui/material'
import { WbsTagsCell } from '../../../../containers/SingleSheetV2/table/cells/WbsTagsCell'
import { useAttachmentHandlers } from '../../hooks/attachments'
import { useWbsItemPropertySearch } from '../../hooks/wbsItemPropertySearch'
import { useWbsItemChangeLogParser } from '../../hooks/wbsItemChangeLogParser'
import { useWbsItemModelModifier } from '../../hooks/wbsItemModelModifier'
import { useWbsItemLabel } from '../../hooks/label'
import { SingleSheetWrapper } from '.'
import { WbsItemAdditionalPropertyCells } from '../cell/WbsItemAdditionalProperties'
import { useWbsItemAdditionalPropertiesBySingleType } from '../../../../hooks/useWbsItemAdditionalProperties'
import { useWbsItemEntityExtension } from '../../hooks/wbsItemEntityExtension'
import {
  WbsItemEntityExtensionCells,
  WbsItemEntityExtensionCellsUnderDefaultHeader,
} from '../cell/WbsItemEntityExtensionCells'
import { useSingleSheetKeyBind } from '../../../../containers/SingleSheetV2/hooks/keyBind'
import CancelConfirmDialog, {
  useCancelConfirmDialog,
} from '../../../../components/dialogs/CancelConfirmDialog'
import { useResizableSingleSheet } from '../../../../containers/SingleSheetV2/hooks/resizableArea'
import {
  ProcessLeftTabs,
  useWbsItemLeftTabs,
  WbsItemLeftTab,
  WbsItemLeftTabs,
} from '../tab/LeftTabs'
import {
  useWbsItemRightTabs,
  WbsItemRightTab,
  WbsItemRightTabs,
} from '../tab/RightTabs'
import { useBrowserTabNameSetter } from '../../hooks/browserTabNameSetter'
import { useSingleSheetFormValidation } from '../../../../containers/SingleSheetV2/hooks/formValidation'
import {
  SingleSheetProperty,
  SingleSheetPropertyType,
} from '../../../../containers/SingleSheetV2/model/singleSheetProperty'
import { WbsItemEditableField } from '../../model'
import { useSubmitButton } from '../../../../containers/SingleSheetV2/hooks/submitButton'

export const Process = ({
  projectUuid,
  wbsItemType,
  uuid,
  functionUuid,
  functionLayerDepth,
}: WbsItemProps) => {
  const singleSheetRef = useResizableSingleSheet<HTMLDivElement>()
  const {
    initialized,
    edited,
    isLoading,
    formModel,
    onChange,
    reload,
    submit,
  } = useWbsItemSingleSheetData(uuid)
  const wbsItemProperties = useMemo<SingleSheetProperty[]>(() => {
    return [
      {
        id: 'displayName',
        type: SingleSheetPropertyType.TEXT,
        label: '名称',
        required: true,
      },
    ]
  }, [])
  const {
    validate,
    validateWbsItemAdditionalPropertyValue,
    validateEntityExtensionPropertyValue,
    validationErrors,
    hasValidationErrors,
  } = useSingleSheetFormValidation(wbsItemProperties)
  useBrowserTabNameSetter(
    formModel.displayName || '',
    functionLayerDepth,
    initialized
  )
  /**
   * Show the description tab only during creating wbs item on SingleSheet.
   * Now this case is only SingleSheet is opened on ProjectPlan when users create a ticket.
   */
  const isCreatingWbsItem = false
  const { leftTab, onChangeLeftTab } = useWbsItemLeftTabs(isCreatingWbsItem)
  const { rightTab, onChangeRightTab } = useWbsItemRightTabs(isCreatingWbsItem)
  const {
    subStatuses,
    difficulties,
    priorities,
    getSubStatusByStatus,
    getStatusBySubStatus,
  } = useWbsItemSelectOptions(projectUuid, WbsItemType.PROCESS)
  const { searchTeam, searchMember } = useWbsItemPropertySearch(
    projectUuid,
    formModel
  )
  const { wbsItemAdditionalProperties } =
    useWbsItemAdditionalPropertiesBySingleType(projectUuid, wbsItemType)
  const {
    groupHeaders: wbsItemEntityExtensionPropertyGroupHeaders,
    entityExtensionProperties,
    childrenUnderBasicInfoHeader:
      childEntityExtensionPropertiesUnderBasicInfoHeader,
    childrenUnderPlanHeader: childEntityExtensionPropertiesUnderPlanHeader,
  } = useWbsItemEntityExtension(functionUuid, projectUuid)

  const getChangeLogParser = useWbsItemChangeLogParser(
    {
      subStatuses,
      difficulties,
      priorities,
    },
    entityExtensionProperties,
    wbsItemAdditionalProperties
  )

  const {
    onChangeDisplayName,
    onChangeStatus,
    onChangeSubStatus,
    onChangeTags,
    onChangeTeam,
    onChangeAccountable,
    onChangeResponsible,
    onChangeAssignee,
    onChangeWatchers,
    onChangeDifficulty,
    onChangePriority,
    onChangeSprint,
    onChangeEstimatedHour,
    onChangeScheduledDate,
    onChangeActualDate,
    onChangeDescription,
    onChangeStartIf,
    onChangeCompleteIf,
    onChangeAdditionalPropertyValues,
    onChangeEntityExtensionValues,
  } = useWbsItemModelModifier(
    onChange,
    {
      getStatusBySubStatus,
      getSubStatusByStatus,
    },
    validate
  )

  const labelMap = useWbsItemLabel(
    entityExtensionProperties,
    wbsItemAdditionalProperties
  )
  const {
    statusLabel,
    subStatusLabel,
    priorityLabel,
    teamLabel,
    accountableLabel,
    responsibleLabel,
    assigneeLabel,
    scheduledDateLabel,
    actualDateLabel,
    estimatedHourLabel,
    actualHourLabel,
  } = useMemo(() => {
    return {
      statusLabel: labelMap.get('status') || '',
      subStatusLabel: labelMap.get('substatus') || '',
      priorityLabel: labelMap.get('priority') || '',
      teamLabel: labelMap.get('team') || '',
      accountableLabel: labelMap.get('accountable') || '',
      responsibleLabel: labelMap.get('responsible') || '',
      assigneeLabel: labelMap.get('assignee') || '',
      scheduledDateLabel: labelMap.get('scheduledDate') || '',
      actualDateLabel: labelMap.get('actualDate') || '',
      estimatedHourLabel: labelMap.get('estimatedHour') || '',
      actualHourLabel: labelMap.get('actualHour') || '',
    }
  }, [labelMap, labelMap.size])

  const { onClickSubmitButton, submitButtonIsActive } = useSubmitButton(
    submit,
    edited,
    hasValidationErrors
  )
  useSingleSheetKeyBind(submit)
  const { openDialog: openCancelConfirmDialog, ...cancelConfirmDialogProps } =
    useCancelConfirmDialog(reload)

  return (
    <PageArea>
      <Loading isLoading={isLoading} />
      {initialized && (
        <>
          <WbsItemHeader
            uuid={uuid}
            isCreatingWbsItem={false}
            title={formModel.displayName || ''}
            onChangeTitle={onChangeDisplayName}
            type={wbsItemType}
            watchers={formModel.watchers}
            onChangeWatchers={onChangeWatchers}
            functionLayerDepth={functionLayerDepth}
          />
          <WbsItemSubHeader
            projectUuid={projectUuid}
            submit={onClickSubmitButton}
            reload={edited ? openCancelConfirmDialog : reload}
            submitIsActive={submitButtonIsActive}
            model={formModel}
            statusLabel={statusLabel}
            subStatusLabel={subStatusLabel}
            priorityLabel={priorityLabel}
            teamLabel={teamLabel}
            accountableLabel={accountableLabel}
            responsibleLabel={responsibleLabel}
            assigneeLabel={assigneeLabel}
            scheduledDateLabel={scheduledDateLabel}
            actualDateLabel={actualDateLabel}
            estimatedHourLabel={estimatedHourLabel}
            actualHourLabel={actualHourLabel}
            onChangeStatus={onChangeStatus}
            onChangeSubStatus={onChangeSubStatus}
            onChangePriority={onChangePriority}
            onChangeTeam={onChangeTeam}
            onChangeAccountable={onChangeAccountable}
            onChangeResponsible={onChangeResponsible}
            onChangeAssignee={onChangeAssignee}
            onChangeScheduledDate={onChangeScheduledDate}
            onChangeActualDate={onChangeActualDate}
            subStatuses={subStatuses}
            priorities={priorities}
          />
          <SingleSheetWrapper>
            <SingleSheetRoot ref={singleSheetRef}>
              <ResizableMainArea singleSheetRootRef={singleSheetRef}>
                <TabPanelArea>
                  <ProcessLeftTabs
                    isCreatingWbsItem={isCreatingWbsItem}
                    leftTab={leftTab}
                    onChangeLeftTab={onChangeLeftTab}
                  />
                  <TabMainArea>
                    <PanelRoot>
                      {leftTab === WbsItemLeftTab.BASIC_INFORMATION && (
                        <>
                          <TableHeader title="基本情報" />
                          <TableRow>
                            <TextCell
                              label="コード"
                              cellWidth="half"
                              value={formModel.code}
                              required={true}
                              editable={false}
                              validationErrors={validationErrors['code']}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="名称"
                              cellWidth="full"
                              value={formModel.displayName}
                              onChange={onChangeDisplayName}
                              required={true}
                              validationErrors={validationErrors['displayName']}
                            />
                          </TableRow>
                          <TableRow>
                            <WbsStatusCell
                              label="ステータス"
                              cellWidth="half"
                              value={formModel.status}
                              onChange={onChangeStatus}
                              required={true}
                              validationErrors={validationErrors['status']}
                            />
                          </TableRow>
                          <TableRow>
                            <SelectCell
                              label="サブステータス"
                              cellWidth="half"
                              value={formModel.substatus}
                              options={subStatuses}
                              onChange={onChangeSubStatus}
                              validationErrors={validationErrors['substatus']}
                            />
                          </TableRow>
                          <TableRow>
                            <WbsTagsCell
                              projectUuid={projectUuid}
                              label="タグ"
                              cellWidth="half"
                              value={formModel.tags || []}
                              onChange={onChangeTags}
                              validationErrors={validationErrors['tags']}
                            />
                          </TableRow>
                          <WbsItemEntityExtensionCellsUnderDefaultHeader
                            defaultHeaderId="basic-info"
                            projectUuid={projectUuid}
                            wbsItemUuid={uuid}
                            entityExtensionProperties={
                              childEntityExtensionPropertiesUnderBasicInfoHeader
                            }
                            wbsItemEntityExtensionValues={
                              formModel.entityExtensionValues
                            }
                            onChange={onChangeEntityExtensionValues}
                            validate={validateEntityExtensionPropertyValue}
                            validationErrors={validationErrors}
                          />
                          <TableHeader title="担当" />
                          <TableRow>
                            <AutocompleteCell
                              label="チーム"
                              cellWidth="half"
                              value={formModel.team}
                              onChange={onChangeTeam}
                              search={searchTeam}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="責任者"
                              cellWidth="half"
                              value={formModel.accountable}
                              onChange={onChangeAccountable}
                              search={searchMember}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="担当者"
                              cellWidth="half"
                              value={formModel.responsible}
                              onChange={onChangeResponsible}
                              search={searchMember}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="アサイン先"
                              cellWidth="half"
                              value={formModel.assignee}
                              onChange={onChangeAssignee}
                              search={searchMember}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <MultiAutocompleteCell
                              label="ウォッチャー"
                              cellWidth="half"
                              value={formModel.watchers || []}
                              onChange={onChangeWatchers}
                              search={searchMember}
                            />
                          </TableRow>
                          <TableHeader title="期間・見積もり" />
                          <TableRow>
                            <DateTermCell
                              label="予定期間"
                              cellWidth="half"
                              value={formModel.scheduledDate}
                              onChange={onChangeScheduledDate}
                            />
                          </TableRow>
                          <TableRow>
                            <DateTermCell
                              label="実績期間"
                              cellWidth="half"
                              value={formModel.actualDate}
                              onChange={onChangeActualDate}
                            />
                          </TableRow>
                          <WbsItemEntityExtensionCellsUnderDefaultHeader
                            defaultHeaderId="plan"
                            projectUuid={projectUuid}
                            wbsItemUuid={uuid}
                            entityExtensionProperties={
                              childEntityExtensionPropertiesUnderPlanHeader
                            }
                            wbsItemEntityExtensionValues={
                              formModel.entityExtensionValues
                            }
                            onChange={onChangeEntityExtensionValues}
                            validate={validateEntityExtensionPropertyValue}
                            validationErrors={validationErrors}
                          />
                          <WbsItemEntityExtensionCells
                            projectUuid={projectUuid}
                            wbsItemUuid={uuid}
                            wbsItemEntityExtensionPropertyGroupHeaders={
                              wbsItemEntityExtensionPropertyGroupHeaders
                            }
                            wbsItemEntityExtensionValues={
                              formModel.entityExtensionValues
                            }
                            onChange={onChangeEntityExtensionValues}
                            validate={validateEntityExtensionPropertyValue}
                            validationErrors={validationErrors}
                          />
                          <WbsItemAdditionalPropertyCells
                            projectUuid={projectUuid}
                            wbsItemUuid={uuid}
                            wbsItemAdditionalProperties={
                              wbsItemAdditionalProperties
                            }
                            wbsItemAdditionalPropertyValues={
                              formModel.additionalPropertyValues
                            }
                            onChange={onChangeAdditionalPropertyValues}
                            validate={validateWbsItemAdditionalPropertyValue}
                            validationErrors={validationErrors}
                          />
                          <TableHeader title="更新履歴" />
                          <TableRow>
                            <AutocompleteCell
                              label="登録者"
                              cellWidth="half"
                              value={formModel.createdBy}
                              editable={false}
                              search={async () => []}
                              displayIcon={true}
                            />
                            <DateTimeCell
                              label="登録日時"
                              cellWidth="half"
                              value={formModel.createdAt}
                              editable={false}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="更新者"
                              cellWidth="half"
                              value={formModel.updatedBy}
                              editable={false}
                              search={async () => []}
                              displayIcon={true}
                            />
                            <DateTimeCell
                              label="更新日時"
                              cellWidth="half"
                              value={formModel.updatedAt}
                              editable={false}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="更新回数"
                              cellWidth="half"
                              value={formModel.revision}
                              editable={false}
                            />
                          </TableRow>
                        </>
                      )}
                      {leftTab === WbsItemLeftTab.DESCRIPTION && (
                        <MultilineTextEditorTab
                          uuid={uuid}
                          externalId="process.description"
                          value={formModel.description}
                          onChange={onChangeDescription}
                        />
                      )}
                      {leftTab === WbsItemLeftTab.START_IF && (
                        <MultilineTextEditorTab
                          uuid={uuid}
                          externalId="process.startIf"
                          value={formModel.startIf}
                          onChange={onChangeStartIf}
                        />
                      )}
                      {leftTab === WbsItemLeftTab.COMPLETE_IF && (
                        <MultilineTextEditorTab
                          uuid={uuid}
                          externalId="process.completeIf"
                          value={formModel.completeIf}
                          onChange={onChangeCompleteIf}
                        />
                      )}
                      {leftTab === WbsItemLeftTab.SUB_TASK && (
                        <ProjectPlanTab uuid={uuid} />
                      )}
                    </PanelRoot>
                  </TabMainArea>
                </TabPanelArea>
              </ResizableMainArea>
              <SideBarArea>
                <TabPanelArea>
                  <WbsItemRightTabs
                    isCreatingWbsItem={isCreatingWbsItem}
                    rightTab={rightTab}
                    onChangeRightTab={onChangeRightTab}
                  />
                  <TabMainArea>
                    <PanelRoot>
                      {rightTab === WbsItemRightTab.DISCUSSION && (
                        <DiscussionTab
                          functionUuid={functionUuid}
                          projectUuid={projectUuid}
                          uuid={uuid}
                        />
                      )}
                      {rightTab === WbsItemRightTab.CHANGE_LOG && (
                        <ChangeLogTabPanel
                          uuids={[uuid]}
                          getParser={getChangeLogParser}
                          labelMap={labelMap}
                        />
                      )}
                      {rightTab === WbsItemRightTab.DEVELOPMENT && (
                        <DevelopmentTabPanel uuid={uuid} />
                      )}
                    </PanelRoot>
                  </TabMainArea>
                </TabPanelArea>
              </SideBarArea>
            </SingleSheetRoot>
          </SingleSheetWrapper>
          <CancelConfirmDialog {...cancelConfirmDialogProps} />
        </>
      )}
    </PageArea>
  )
}

import { ReferencedTicketListEntity } from './../../../../../domain/entity/TicketListEntity/index'
import { useCallback, useMemo, useState } from 'react'
import { useTicketRepository } from '../../../../../services/adaptors/ticketRepositoryAdaptor'

export const useTicketSearch = (projectUuid: string, ticketTypes: string[]) => {
  const { searchAsReferencedEntity } = useTicketRepository()
  const searchTicket = useCallback(
    async (searchText: string) => {
      const candidates = await searchAsReferencedEntity(
        projectUuid,
        ticketTypes,
        searchText
      )
      return candidates
    },
    [searchAsReferencedEntity, projectUuid, ticketTypes]
  )
  const hookResult = useMemo(() => ({ searchTicket }), [searchTicket])

  return hookResult
}

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '../..'
import { AllState } from '../../../../store'
import { WbsItemBasic } from '../../../../domain/entity/WbsItemEntity'

export const useApplicationFunctionUuid = (
  wbsItemBasic: WbsItemBasic | undefined
): string => {
  const functions = useSelector(
    (state: AllState) => state.appFunction.functions
  )
  const functionUuid = useMemo(() => {
    let functionExternalId = APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM
    if (wbsItemBasic?.ticketType) {
      functionExternalId = APPLICATION_FUNCTION_EXTERNAL_ID.TICKET
    }
    return functions.find(f => f.externalId === functionExternalId)?.uuid || ''
  }, [functions, wbsItemBasic])

  return functionUuid
}

import {
  AutocompleteRenderGetTagProps,
  Chip,
  ChipProps,
  styled,
} from '@mui/material'
import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import {
  ReferencedEntity,
  ReferencedEntityWithBackgroundColor,
} from '../../../../../domain/value-object/ReferencedEntity'
import { MultiAutocomplete } from '../../../../components/inputs/MultiAutocomplete'
import { useCallback, useMemo, useState } from 'react'
import { getTextColorOnBackgroundColor } from '../../../../../utils/color'
import { useWbsTagRepository } from '../../../../../services/adaptors/wbsTagRepositoryAdaptor'

type WbsTagsCellProps = {
  projectUuid: string
  value: ReferencedEntityWithBackgroundColor[]
  onChange: (v: ReferencedEntityWithBackgroundColor[]) => void
} & CellPropsBase

export const WbsTagsCell = ({
  projectUuid,
  cellWidth,
  label,
  value,
  onChange,
  editable = true,
  required = false,
  validationErrors,
}: WbsTagsCellProps) => {
  const renderTags = useCallback(
    (
      value: ReferencedEntityWithBackgroundColor[],
      getTagProps: AutocompleteRenderGetTagProps
    ) =>
      value.map((v, index) => (
        <StyledChip
          label={v.name}
          backgroundColor={v.backgroundColor}
          {...getTagProps({ index })}
          key={getTagProps({ index }).key}
        />
      )),
    []
  )
  const [fetched, setFetched] = useState<boolean>(false)
  const [tags, setTags] = useState<ReferencedEntityWithBackgroundColor[]>([])
  const { fetchListAsReferencedEntity } = useWbsTagRepository()
  const search = useCallback(
    async (searchText: string) => {
      if (!fetched) {
        const response = await fetchListAsReferencedEntity(projectUuid)
        setTags(response)
        setFetched(true)
        return response.filter(tag => tag.name.includes(searchText))
      }
      return tags.filter(tag => tag.name.includes(searchText))
    },
    [fetchListAsReferencedEntity, fetched, projectUuid, tags]
  )
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea validationErros={validationErrors}>
        <MultiAutocomplete
          value={value}
          onChange={onChange}
          search={search}
          renderTags={renderTags}
        />
      </CellValueArea>
    </FormCell>
  )
}

const StyledChip = styled((props: ChipProps) => (
  <Chip {...props} size="small" />
))(({ backgroundColor }: { backgroundColor: string }) => {
  const color = useMemo(
    () => getTextColorOnBackgroundColor(backgroundColor),
    [backgroundColor]
  )
  return {
    color,
    backgroundColor,
  }
})

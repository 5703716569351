import { Divider, styled } from '@mui/material'
import { WbsItemSelectOptions } from '../../../hooks/wbsItemSelectOptions'
import { UpdateFormModelValue, WbsItemFormModel } from '../../../model'
import { StatusInput } from './StatusInput'
import { SubStatusInput } from './SubStatusInput'
import { PriorityInput } from './PriorityInput'
import { TeamInput } from './TeamInput'
import { ProjectMemberInput } from './ProjectMemberInput'
import { DateTermInput } from './DateTermInput'
import { SubHeaderDivider } from '../commons'
import { HourInput } from './HourInput'

export type ValuePanelProps = {
  projectUuid: string
  model: WbsItemFormModel
  statusLabel: string
  subStatusLabel: string
  priorityLabel: string
  teamLabel: string
  accountableLabel: string
  responsibleLabel: string
  assigneeLabel: string
  scheduledDateLabel: string
  actualDateLabel: string
  estimatedHourLabel: string
  actualHourLabel: string
  onChangeStatus: UpdateFormModelValue<'status'>
  onChangeSubStatus: UpdateFormModelValue<'substatus'>
  onChangePriority: UpdateFormModelValue<'priority'>
  onChangeTeam: UpdateFormModelValue<'team'>
  onChangeAccountable: UpdateFormModelValue<'accountable'>
  onChangeResponsible: UpdateFormModelValue<'responsible'>
  onChangeAssignee: UpdateFormModelValue<'assignee'>
  onChangeScheduledDate: UpdateFormModelValue<'scheduledDate'>
  onChangeActualDate: UpdateFormModelValue<'actualDate'>
  onChangeEstimatedHour?: UpdateFormModelValue<'estimatedHour'>
} & Omit<WbsItemSelectOptions, 'difficulties'>

export const ValuePanel = ({
  projectUuid,
  model,
  statusLabel,
  subStatusLabel,
  priorityLabel,
  teamLabel,
  accountableLabel,
  responsibleLabel,
  assigneeLabel,
  scheduledDateLabel,
  actualDateLabel,
  estimatedHourLabel,
  actualHourLabel,
  onChangeStatus,
  onChangeSubStatus,
  onChangePriority,
  onChangeTeam,
  onChangeAccountable,
  onChangeResponsible,
  onChangeAssignee,
  onChangeScheduledDate,
  onChangeActualDate,
  onChangeEstimatedHour,
  subStatuses,
  priorities,
}: ValuePanelProps) => {
  return (
    <>
      <StatusInput
        label={statusLabel}
        value={model.status}
        onChange={onChangeStatus}
      />
      <SubStatusInput
        label={subStatusLabel}
        value={model.substatus}
        onChange={onChangeSubStatus}
        options={subStatuses}
      />
      <SubHeaderDivider />
      <PriorityInput
        label={priorityLabel}
        value={model.priority}
        onChange={onChangePriority}
        options={priorities}
      />
      <SubHeaderDivider />
      <TeamInput
        projectUuid={projectUuid}
        label={teamLabel}
        value={model.team}
        onChange={onChangeTeam}
      />
      <SubHeaderDivider />
      <ProjectMemberInput
        projectUuid={projectUuid}
        label={accountableLabel}
        value={model.accountable}
        onChange={onChangeAccountable}
      />
      <ProjectMemberInput
        projectUuid={projectUuid}
        label={responsibleLabel}
        value={model.responsible}
        onChange={onChangeResponsible}
      />
      <ProjectMemberInput
        projectUuid={projectUuid}
        label={assigneeLabel}
        value={model.assignee}
        onChange={onChangeAssignee}
      />
      <SubHeaderDivider />
      <DoubleDateTermContainer>
        <DateTermInput
          label={scheduledDateLabel}
          value={model.scheduledDate}
          onChange={onChangeScheduledDate}
        />
        <DateTermInput
          label={actualDateLabel}
          value={model.actualDate}
          onChange={onChangeActualDate}
        />
      </DoubleDateTermContainer>
      <DoubleHourContainer>
        <HourInput
          label={estimatedHourLabel}
          value={model.estimatedHour}
          onChange={onChangeEstimatedHour}
          editable={Boolean(onChangeEstimatedHour)}
        />
        <HourInput
          label={actualHourLabel}
          value={model.actualHour}
          editable={false}
        />
      </DoubleHourContainer>
    </>
  )
}

const DoubleDateTermContainer = styled('div')({})
const DoubleHourContainer = styled('div')({})

import {
  ColDef,
  EditableCallbackParams,
  ValueGetterParams,
  CellClassParams,
  ValueFormatterParams,
  ICellEditorParams,
  ICellRendererParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  shouldHaveSelectOptions,
  WbsItemAdditionalPropertySelectOption,
  WbsItemAdditionalPropertyType,
} from '../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { selectOptions } from '../../../../page-properties/bulksheet-properties/wbsItemAdditionalProperty'
import {
  isAdditionalPropertyRow,
  isGroupHeaderRow,
  AdditionalPropertyRow,
} from '../../rowModel/wbsItemAdditionalPropertyRow'
import { DISABLE_COLOR } from './style'
import { WbsItemAdditionalPropertySelectOptionsCellEditor } from '../../../../containers/BulkSheetView/components/cellEditor/WbsItemAdditionalPropertySelectOptionsCellEditor'
import { intl } from '../../../../../i18n'

export const selectOptionsColumnDef: ColDef = {
  ...selectOptions,
  editable: (params: EditableCallbackParams) =>
    isAdditionalPropertyRow(params.node.data) &&
    shouldHaveSelectOptions(params.node.data.propertyType),
  valueFormatter: (params: ValueFormatterParams) => {
    if (!params.node || isGroupHeaderRow(params.node.data)) {
      return ''
    }
    const additionalPropertyRow = params.node.data as AdditionalPropertyRow
    return additionalPropertyRow.selectOptions
      ? additionalPropertyRow.selectOptions.map(v => v.selectOption).join(',')
      : ''
  },
  cellRendererParams: {
    validate: (
      value: WbsItemAdditionalPropertySelectOption[] | undefined,
      params: ICellRendererParams
    ): string | undefined => {
      if (!isAdditionalPropertyRow(params.data)) {
        return undefined
      }
      const additionalPropertyRow = params.node.data as AdditionalPropertyRow
      if (
        shouldHaveSelectOptions(additionalPropertyRow.propertyType) &&
        (!value || value.length === 0)
      ) {
        return intl.formatMessage({
          id: 'wbsItemAdditionalProperties.validation.selectOptions.required',
        })
      }
      return undefined
    },
  },
  cellEditor: WbsItemAdditionalPropertySelectOptionsCellEditor,
  cellEditorParams: {
    valueGetter: (params: ICellEditorParams) => {
      if (!params.node || isGroupHeaderRow(params.node.data)) {
        return ''
      }
      const additionalPropertyRow = params.node.data as AdditionalPropertyRow
      return additionalPropertyRow.selectOptions
        ? additionalPropertyRow.selectOptions.map(v => ({
            uuid: v.uuid,
            value: v.selectOption,
          }))
        : []
    },
  },
  cellStyle: (params: CellClassParams) =>
    isGroupHeaderRow(params.node.data) ||
    !shouldHaveSelectOptions(params.node.data.propertyType)
      ? {
          backgroundColor: DISABLE_COLOR,
        }
      : undefined,
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ICellRendererParams, RowNode } from 'ag-grid-community'
import { CellRendererRoot } from '.'
import { Checkbox } from '../../../../../components/inputs/Checkbox'
import { Row, TreeRow } from '../../../../../containers/BulkSheetView/model'
import { CopyRoot } from '../../../model'
import { ProjectPlanSelectRow } from '../model'

type CheckboxCellRendererProps = ICellRendererParams<
  ProjectPlanSelectRow,
  boolean
> & {
  projectUuid: string | undefined
  updateSelected: (uuid: CopyRoot, selected: boolean) => void
}

export const CheckboxCellRenderer = ({
  value,
  data,
  projectUuid,
  updateSelected,
}: CheckboxCellRendererProps) => {
  const onChange = useCallback(
    (_, value: boolean) => {
      if (!data) return
      if (!value) return
      if (data.uuid === projectUuid) {
        updateSelected(
          {
            type: 'PROJECT',
          },
          value
        )
        return
      }
      updateSelected(
        {
          type: 'WBS',
          uuid: data.uuid,
          name: data.displayName,
        },
        value
      )
    },
    [data, projectUuid, updateSelected]
  )
  return (
    <CellRendererRoot>
      <Checkbox checked={value} onChange={onChange} />
    </CellRendererRoot>
  )
}

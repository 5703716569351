import { useCallback, useMemo } from 'react'
import {
  toLabel,
  WbsItemStatus,
} from '../../../../../../domain/entity/WbsItemEntity'
import { Select } from '../../../../../components/select'
import { UpdateFormModelValue, WbsItemFormModel } from '../../../model'
import { styled } from '@mui/material'
import { ValuePanelInputRoot } from './commons'
import { WbsStatusLabel } from '../../../../../components/data-display/WbsStatusLabel'
import { SelectOption } from '../../../../../components/select/model'
import Tooltip from '../../../../../components/tooltips/Tooltip'

type StatusInputProps = {
  label: string
  value: WbsItemFormModel['status']
  onChange: UpdateFormModelValue<'status'>
}
export const StatusInput = ({ label, value, onChange }: StatusInputProps) => {
  const options = useMemo(
    () =>
      Object.values(WbsItemStatus).map(v => ({
        value: WbsItemStatus[v],
        name: toLabel(v),
      })),
    []
  )
  const renderOption = useCallback(
    (option: SelectOption<WbsItemStatus>) => (
      <WbsStatusLabel status={option.value} />
    ),
    []
  )
  const renderValue = useCallback(
    (option: SelectOption<WbsItemStatus> | undefined) => {
      if (!option) return <></>
      return <WbsStatusLabel status={option.value} />
    },
    []
  )

  return (
    <Tooltip message={label}>
      <Root>
        <Select
          value={value}
          onChange={onChange}
          options={options}
          renderOption={renderOption}
          renderValue={renderValue}
        />
      </Root>
    </Tooltip>
  )
}

const Root = styled(ValuePanelInputRoot)({
  width: '75px',
})

import { styled } from '@mui/material'
import { Select } from '../../../../../components/select'
import { UpdateFormModelValue, WbsItemFormModel } from '../../../model'
import { ValuePanelInputRoot } from './commons'
import { SelectOption } from '../../../../../components/select/model'
import Tooltip from '../../../../../components/tooltips/Tooltip'

type SubStatusInputProps = {
  label: string
  value: WbsItemFormModel['substatus']
  onChange: UpdateFormModelValue<'substatus'>
  options: SelectOption<string>[]
}
export const SubStatusInput = ({
  label,
  value,
  onChange,
  options,
}: SubStatusInputProps) => {
  return (
    <Tooltip message={label}>
      <Root>
        <Select value={value} onChange={onChange} options={options} />
      </Root>
    </Tooltip>
  )
}

const Root = styled(ValuePanelInputRoot)({
  minWidth: '100px',
})

import {
  ApplicationFunctionPropertyConfiguration,
  FunctionProperty,
} from '../../../../lib/commons/appFunction'
import {
  BulkSheetColumnDef,
  BulkSheetColumnGroupDef,
  EntityExtensionExternalIdToWbsItemAdditionalPropertyUuid,
} from '../model'

export const overwriteExtensionPropertiesByPropertyConfiguration = ({
  extensionProperties,
  propertyConfigurations,
}: {
  extensionProperties: FunctionProperty[]
  propertyConfigurations: ApplicationFunctionPropertyConfiguration[]
}) => {
  propertyConfigurations.forEach(propertyConfiguration => {
    const targetExtensionProperty = extensionProperties.find(
      v => v.externalId === propertyConfiguration.externalId
    )
    if (!targetExtensionProperty) return
    if (propertyConfiguration.parentProperty) {
      targetExtensionProperty.parentProperty =
        propertyConfiguration.parentProperty
    }
    if (propertyConfiguration.propertyLayout) {
      targetExtensionProperty.propertyLayout =
        propertyConfiguration.propertyLayout
    }
    if (propertyConfiguration.requiredIf) {
      targetExtensionProperty.requiredIf = propertyConfiguration.requiredIf
    }
    if (propertyConfiguration.editableIfC) {
      targetExtensionProperty.editableIfC = propertyConfiguration.editableIfC
    }
    if (propertyConfiguration.editableIfU) {
      targetExtensionProperty.editableIfU = propertyConfiguration.editableIfU
    }
    if (propertyConfiguration.hiddenIfC) {
      targetExtensionProperty.hiddenIfC = propertyConfiguration.hiddenIfC
    }
    if (propertyConfiguration.hiddenIfU) {
      targetExtensionProperty.hiddenIfU = propertyConfiguration.hiddenIfU
    }
  })
}
export const overwriteColumnByPropertyConfiguration = ({
  columns,
  propertyConfigurations,
  entityExtensionExternalIdToWbsItemAdditionalPropertyUuid,
}: {
  columns: (BulkSheetColumnGroupDef | BulkSheetColumnDef)[]
  propertyConfigurations: ApplicationFunctionPropertyConfiguration[]
  entityExtensionExternalIdToWbsItemAdditionalPropertyUuid: EntityExtensionExternalIdToWbsItemAdditionalPropertyUuid
}) => {
  const columnIndexMapByExternalId: Record<string, number> = {}
  // Set default indexes of the columns.
  let columnIndex = 0
  columns.forEach(column => {
    if (['uuid', 'drag', 'rowNumber'].includes(column.externalId)) return
    columnIndexMapByExternalId[column.externalId] = columnIndex
    columnIndex++
    const childColumns = (column as BulkSheetColumnGroupDef).children as
      | BulkSheetColumnDef[]
      | undefined
    if (!childColumns) return
    childColumns.forEach((childColumn, childIndex) => {
      columnIndexMapByExternalId[childColumn.externalId] = childIndex
    })
  })

  propertyConfigurations.forEach(propertyConfiguration => {
    const wbsItemAdditionalPropertyUuidOfColumnGroup =
      entityExtensionExternalIdToWbsItemAdditionalPropertyUuid[
        propertyConfiguration.externalId
      ]
    columns.some(columnGroup => {
      if (
        columnGroup.externalId === propertyConfiguration.externalId ||
        (wbsItemAdditionalPropertyUuidOfColumnGroup &&
          columnGroup.externalId === wbsItemAdditionalPropertyUuidOfColumnGroup)
      ) {
        overwriteColumnGroup(
          // For transfered additional property column from entity extension.
          wbsItemAdditionalPropertyUuidOfColumnGroup ||
            propertyConfiguration.externalId,
          columnGroup as BulkSheetColumnGroupDef,
          propertyConfiguration,
          columns,
          columnIndexMapByExternalId
        )
        return true
      }

      const childColumns = (columnGroup as BulkSheetColumnGroupDef)
        .children as BulkSheetColumnDef[]
      if (childColumns) {
        return childColumns.some(childColumn => {
          const wbsItemAdditionalPropertyUuidOfChildColumn =
            entityExtensionExternalIdToWbsItemAdditionalPropertyUuid[
              propertyConfiguration.externalId
            ]
          if (
            childColumn.externalId === propertyConfiguration.externalId ||
            (wbsItemAdditionalPropertyUuidOfChildColumn &&
              childColumn.externalId ===
                wbsItemAdditionalPropertyUuidOfChildColumn)
          ) {
            overwriteColumn(
              // For transfered additional property column from entity extension.
              wbsItemAdditionalPropertyUuidOfChildColumn ||
                propertyConfiguration.externalId,
              childColumn,
              columnGroup.externalId,
              propertyConfiguration,
              columns,
              columnIndexMapByExternalId
            )
            return true
          }
          return false
        })
      }
      return false
    })
  })

  // Sort all columns.
  columns.sort((a, b) => {
    const aIndex = columnIndexMapByExternalId[a.externalId] || 0
    const bIndex = columnIndexMapByExternalId[b.externalId] || 0
    return aIndex - bIndex
  })
  // Sort child columns.
  columns.forEach(column => {
    const childColumns = (column as BulkSheetColumnGroupDef).children as
      | BulkSheetColumnDef[]
      | undefined
    if (childColumns) {
      childColumns.sort((a, b) => {
        const aIndex = columnIndexMapByExternalId[a.externalId] || 0
        const bIndex = columnIndexMapByExternalId[b.externalId] || 0
        return aIndex - bIndex
      })
    }
  })
}

const overwriteColumnGroup = (
  targetExternalId: string,
  columnGroup: BulkSheetColumnGroupDef,
  propertyConfiguration: ApplicationFunctionPropertyConfiguration,
  allColumns: (BulkSheetColumnGroupDef | BulkSheetColumnDef)[],
  columnIndexMapByExternalId: Record<string, number>
) => {
  if (propertyConfiguration.name) {
    columnGroup.headerName = propertyConfiguration.name
  }
  if (propertyConfiguration.hiddenIfC || propertyConfiguration.hiddenIfU) {
    ;(columnGroup as BulkSheetColumnDef).hide =
      propertyConfiguration.hiddenIfC?.isTrue ||
      propertyConfiguration.hiddenIfU?.isTrue
  }
  if (propertyConfiguration.propertyLayout) {
    const oldColumnIndex = allColumns.findIndex(
      v => v.externalId === targetExternalId
    )
    const newColumnIndex =
      Number(propertyConfiguration.propertyLayout.split('-')[0]) - 1
    // Remove the target from the old poisition.
    allColumns.splice(oldColumnIndex, 1)
    // Add the target to the new poisition.
    allColumns.splice(newColumnIndex, 0, columnGroup)
    columnIndexMapByExternalId[targetExternalId] = newColumnIndex
  }
}

const overwriteColumn = (
  targetExternalId: string,
  column: BulkSheetColumnDef,
  parentExternalId: string,
  propertyConfiguration: ApplicationFunctionPropertyConfiguration,
  allColumns: (BulkSheetColumnGroupDef | BulkSheetColumnDef)[],
  columnIndexMapByExternalId: Record<string, number>
) => {
  if (propertyConfiguration.name) {
    column.headerName = propertyConfiguration.name
  }
  if (propertyConfiguration.requiredIf) {
    if (!column.cellRendererParams) {
      column.cellRendererParams = {}
    }
    if (!column.cellRendererParams.uiMeta) {
      column.cellRendererParams.uiMeta = {}
    }
    column.cellRendererParams.uiMeta = {
      ...column.cellRendererParams.uiMeta,
      requiredIf: propertyConfiguration.requiredIf,
    }
  }
  if (propertyConfiguration.editableIfC || propertyConfiguration.editableIfU) {
    column.hide =
      propertyConfiguration.editableIfC?.isTrue ||
      propertyConfiguration.editableIfU?.isTrue
  }
  if (propertyConfiguration.hiddenIfC || propertyConfiguration.hiddenIfU) {
    column.hide =
      propertyConfiguration.hiddenIfC?.isTrue ||
      propertyConfiguration.hiddenIfU?.isTrue
  }
  let currentParentExternalId = parentExternalId
  if (propertyConfiguration.parentProperty) {
    const oldParentColumn = allColumns.find(
      v => v.externalId === parentExternalId
    ) as BulkSheetColumnGroupDef | undefined
    const newParentColumn = allColumns.find(
      v => v.externalId === propertyConfiguration.parentProperty
    ) as BulkSheetColumnGroupDef | undefined
    if (oldParentColumn && newParentColumn) {
      // Remove the target from the old poisition.
      const oldColumnIndex = (
        oldParentColumn.children as BulkSheetColumnDef[]
      ).findIndex(v => v.externalId === targetExternalId)
      oldParentColumn.children.splice(oldColumnIndex, 1)
      // Add the target to the new poisition.
      if (!newParentColumn.children) {
        newParentColumn.children = []
      }
      newParentColumn.children.push(column)
      columnIndexMapByExternalId[targetExternalId] = 0
      currentParentExternalId = propertyConfiguration.parentProperty
    }
  }
  if (propertyConfiguration.propertyLayout) {
    const parentColumn = allColumns.find(
      v => v.externalId === currentParentExternalId
    ) as BulkSheetColumnGroupDef | undefined
    const layoutParts = propertyConfiguration.propertyLayout.split('-')
    if (parentColumn && layoutParts.length >= 2) {
      const newColumnIndex = Number(layoutParts[1]) - 1
      const oldColumnIndex = (
        parentColumn.children as BulkSheetColumnDef[]
      ).findIndex(v => v.externalId === targetExternalId)
      // Remove the target from the old poisition.
      parentColumn.children.splice(oldColumnIndex, 1)
      // Add the target to the new poisition.
      parentColumn.children.splice(newColumnIndex, 0, column)
      columnIndexMapByExternalId[targetExternalId] = newColumnIndex
    }
  }
}

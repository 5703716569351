import { useMemo } from 'react'
import { intl } from '../../../../../i18n'
import { styled } from '@mui/material'
import { Button } from '../../../../components/buttons'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'

const TaskActualWorkRegistrationButton = ({
  onClick,
}: {
  onClick: () => void
}) => {
  const label = useMemo(
    () => intl.formatMessage({ id: 'wbsItem.toolbar.registerWorkHour' }),
    []
  )
  return (
    <StyledButton
      variant="filled"
      colorPattern="skyBlue"
      size="s"
      onClick={onClick}
    >
      <ScheduleRoundedIcon fontSize="small" />
      {label}
    </StyledButton>
  )
}

export default TaskActualWorkRegistrationButton

// Style
const StyledButton = styled(Button)({
  width: '120px',
})

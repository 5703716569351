import { ColGroupDef, EditableCallbackParams, RowNode } from 'ag-grid-community'
import { useMemo } from 'react'
import { i18nLabelVoService } from '../../../../../domain/value-object/I18nLabelVO'
import {
  WbsItemAdditionalPropertyValuesVO,
  WbsItemAdditionalPropertyValueType,
} from '../../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import { WbsItemTypeVO } from '../../../../../domain/value-object/WbsItemTypeVO'
import { wbsItemAdditionalPropertyColumnDef } from './columnDef'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { WbsItemAdditionalPropertyEntity } from '../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { useEntitySearchReferenceEntityRepositories } from '../../../../hooks/useWbsItemAdditionalProperties'
import {
  BulkSheetColumnGroupDef,
  EntityExtensionExternalIdToWbsItemAdditionalPropertyUuid,
} from '../../model'

export interface Option<RowData> {
  getAdditionalPropertyValues: (
    rowData: RowData
  ) => WbsItemAdditionalPropertyValuesVO | undefined
  setAdditionalPropertyValues: (
    rowData: RowNode<RowData>,
    wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
    oldValue: WbsItemAdditionalPropertyValueType,
    newValue: WbsItemAdditionalPropertyValueType
  ) => void
  getWbsItemType: (rowData: RowData) => WbsItemTypeVO | undefined
  editable?: (params: EditableCallbackParams<RowData>) => boolean
  columnFilterType?: 'CLIENT_SIDE' | 'SERVER_SIDE'
  disableFloatingFilter?: boolean
}
type State<RowData> = {
  wbsItemAdditionalPropertyColumns: BulkSheetColumnGroupDef<RowData>[]
  entityExtensionExternalIdToWbsItemAdditionalPropertyUuid: EntityExtensionExternalIdToWbsItemAdditionalPropertyUuid
}
export const useWbsItemAdditionalPropertyColumns = <RowData>(
  projectUuid,
  wbsItemAdditionalProperties:
    | WbsItemAdditionalPropertyLayoutEntity
    | undefined,
  option: Option<RowData>
): State<RowData> => {
  const { getEntitySearchReferenceEntityRepository } =
    useEntitySearchReferenceEntityRepositories(projectUuid)
  const state = useMemo<State<RowData>>(() => {
    if (!wbsItemAdditionalProperties) {
      return {
        wbsItemAdditionalPropertyColumns: [],
        entityExtensionExternalIdToWbsItemAdditionalPropertyUuid: {},
      }
    }

    const entityExtensionExternalIdToWbsItemAdditionalPropertyUuid = {}
    const wbsItemAdditionalPropertyColumns =
      wbsItemAdditionalProperties.groupHeaderLayouts.map(
        ({ groupHeader, propertyLayouts }) => {
          if (groupHeader.entityExtensionExternalId) {
            entityExtensionExternalIdToWbsItemAdditionalPropertyUuid[
              groupHeader.entityExtensionExternalId
            ] = groupHeader.uuid
          }
          return {
            groupId: groupHeader.uuid,
            externalId: groupHeader.uuid,
            headerName: i18nLabelVoService.getLabel(groupHeader.headerNameI18n),
            children: propertyLayouts.map(({ wbsItemAdditionalProperty }) => {
              if (wbsItemAdditionalProperty.entityExtensionExternalId) {
                entityExtensionExternalIdToWbsItemAdditionalPropertyUuid[
                  wbsItemAdditionalProperty.entityExtensionExternalId
                ] = wbsItemAdditionalProperty.uuid
              }
              return wbsItemAdditionalPropertyColumnDef(
                projectUuid,
                wbsItemAdditionalProperty,
                option,
                getEntitySearchReferenceEntityRepository
              )
            }),
          } as BulkSheetColumnGroupDef<RowData>
        }
      )
    return {
      wbsItemAdditionalPropertyColumns,
      entityExtensionExternalIdToWbsItemAdditionalPropertyUuid,
    }
  }, [
    projectUuid,
    wbsItemAdditionalProperties,
    getEntitySearchReferenceEntityRepository,
  ])

  return state
}

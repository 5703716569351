import { useState, useEffect, useCallback } from 'react'
import { WbsItemType } from '../../../../../../domain/entity/WbsItemEntity'
import {
  WbsItemFormModel,
  createInitialValue,
  toCreateInput,
  toDeltaInput,
} from '../../../model'
import { IItemDelta } from '../../../../../../domain/value-object/ItemDeltaInputVO'
import { useDispatch } from 'react-redux'
import {
  addScreenMessage,
  MessageLevel,
} from '../../../../../../store/messages'
import { intl } from '../../../../../../i18n'
import {
  createTicketInitialValue,
  TicketFormModel,
  TicketInitialValue,
} from '../../../model/ticket'
import { ReferencedTicketListEntity } from '../../../../../../domain/entity/TicketListEntity'
import {
  isReferencedEntityValueDiffered,
  toReferencedEntityDeltaValue,
} from '../../../model/properties'
import {
  CreateRiskInput,
  CreateRiskRequest,
  RiskEntity,
} from '../../../../../../domain/entity/ticket/RiskEntity'
import {
  createRiskInitialValue,
  RiskFormModel,
  toRiskCreateInput,
  toRiskDeltaInput,
} from '../../../model/risk'
import { useRiskRepository } from '../../../../../../services/adaptors/riskRepositoryAdaptor'
import { usePageDataIsEdited } from '../../../../../hooks/usePageDataIsEdited'
import { RiskRepository } from '../../../../../../applications/ports/riskRepository'
import { CreateTicketInput } from '../../../../../../domain/entity/ticket'
import { WbsItemTypeVO } from '../../../../../../domain/value-object/WbsItemTypeVO'

export const useRiskSingleSheetData = (
  uuid: string,
  wbsItemTypeVo: WbsItemTypeVO,
  ticketInitialValue?: TicketInitialValue
) => {
  const repository = useRiskRepository()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [isCreatingWbsItem, setIsCreatingWbsItem] = useState(false)
  const { edited, editedPageData, refreshedPageData } = usePageDataIsEdited()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [entity, setEntity] = useState<RiskEntity | undefined>()
  const [wbsItemFormModel, setWbsItemFormModel] = useState<WbsItemFormModel>(
    createInitialValue(WbsItemType.TASK, ticketInitialValue?.wbsItem)
  )
  const [ticketFormModel, setTicketFormModel] = useState<TicketFormModel>(
    createTicketInitialValue('RISK', ticketInitialValue)
  )
  const [riskFormModel, setRiskFormModel] = useState<RiskFormModel>(
    createRiskInitialValue()
  )
  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await repository.fetch(uuid)
      setEntity(response)
      const formModel = structuredClone(response)
      setWbsItemFormModel(formModel.ticket.wbsItem)
      setTicketFormModel(formModel.ticket)
      setRiskFormModel(formModel)
    } catch (err: any) {
      if (err.code === 'NOT_FOUND' && ticketInitialValue) {
        // Ignore error because this wbs is unsaved.
        setIsCreatingWbsItem(true)
      } else {
        throw err
      }
    }
    setInitialized(true)
    setIsLoading(false)
    refreshedPageData()
  }, [repository, uuid, refreshedPageData, ticketInitialValue])
  useEffect(() => {
    fetchData()
  }, [fetchData, uuid])
  const onChangeWbsItem = useCallback(
    <K extends keyof WbsItemFormModel, T extends WbsItemFormModel[K]>(
      path: K,
      value: T
    ) => {
      setWbsItemFormModel(prev => {
        const newModel = {
          ...prev,
        }
        newModel[path] = value
        return newModel
      })
      editedPageData()
    },
    [editedPageData]
  )
  const onChangeTicketList = useCallback(
    (ticketList: ReferencedTicketListEntity | undefined) => {
      if (!ticketList) return
      setTicketFormModel(prev => ({
        ...prev,
        ticketList,
        ticketType: ticketList.ticketType,
      }))
      editedPageData()
    },
    [editedPageData]
  )
  const onChange = useCallback(
    <K extends keyof RiskFormModel, T extends RiskFormModel[K]>(
      path: K,
      value: T
    ) => {
      setRiskFormModel(prev => {
        const newModel = {
          ...prev,
        }
        newModel[path] = value
        return newModel
      })
      editedPageData()
    },
    [editedPageData]
  )
  const dispatch = useDispatch()
  const create = useCreate(
    dispatch,
    wbsItemTypeVo,
    riskFormModel,
    ticketFormModel,
    wbsItemFormModel,
    repository
  )
  const updateDelta = useUpdateDelta(
    dispatch,
    riskFormModel,
    ticketFormModel,
    wbsItemFormModel,
    repository,
    entity
  )
  const submit = useCallback(async () => {
    setIsLoading(true)
    if (isCreatingWbsItem) {
      await create()
    } else {
      await updateDelta()
    }
    await fetchData()
    setIsLoading(false)
  }, [isCreatingWbsItem, fetchData, create, updateDelta])
  return {
    initialized,
    isCreatingWbsItem,
    edited,
    isLoading,
    wbsItemFormModel,
    ticketFormModel,
    riskFormModel,
    onChangeWbsItem,
    onChangeTicketList,
    onChange,
    reload: fetchData,
    submit,
  }
}

const useCreate = (
  dispatch: ReturnType<typeof useDispatch>,
  wbsItemTypeVo: WbsItemTypeVO,
  riskFormModel: RiskFormModel,
  ticketFormModel: TicketFormModel,
  wbsItemFormModel: WbsItemFormModel,
  repository: RiskRepository,
  ticketInitialValue?: TicketInitialValue
) => {
  const create = useCallback(async () => {
    const projectUuid = ticketInitialValue?.wbsItem?.projectUuid || ''
    const wbsItem = toCreateInput(wbsItemFormModel, wbsItemTypeVo)
    const ticket: CreateTicketInput = {
      uuid: ticketFormModel.uuid,
      projectUuid,
      ticketType: ticketFormModel.ticketType,
      ticketListUuid: ticketFormModel.ticketList?.uuid || '',
      parentUuid: undefined,
      parentWbsItemUuid: ticketInitialValue?.parentWbsItem?.uuid,
      prevSiblingUuid: undefined,
      projectPlan: {
        uuid: ticketInitialValue?.projectPlanUuid || '',
        projectUuid,
        parentUuid: undefined,
        prevSiblingUuid: undefined,
        type: wbsItem.type,
        wbsItem,
      },
    }
    const input: CreateRiskInput = {
      ...toRiskCreateInput(riskFormModel),
      ticket,
    }
    const request: CreateRiskRequest = {
      input,
      watchers: (wbsItemFormModel.watchers || []).map(watcher => watcher.uuid),
      tags: {
        wbsItemUuid: wbsItemFormModel.uuid,
        tagUuids: (wbsItemFormModel.tags || []).map(tag => tag.uuid),
      },
    }
    await repository.create(request)
    dispatch(
      addScreenMessage('wbsItem', {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({ id: 'create.completed.title' }),
      })
    )
  }, [
    dispatch,
    riskFormModel,
    ticketFormModel.uuid,
    ticketFormModel.ticketType,
    ticketFormModel.ticketList,
    wbsItemFormModel,
    repository,
    ticketInitialValue,
  ])

  return create
}

// Private hooks.
const useUpdateDelta = (
  dispatch: ReturnType<typeof useDispatch>,
  riskFormModel: RiskFormModel,
  ticketFormModel: TicketFormModel,
  wbsItemFormModel: WbsItemFormModel,
  repository: RiskRepository,
  entity?: RiskEntity
) => {
  const updateDelta = useCallback(async () => {
    if (!entity) return
    const wbsItem = toDeltaInput(entity.ticket.wbsItem, wbsItemFormModel)
    const ticket = {
      uuid: entity.ticket.uuid,
      ticketListUuid: isReferencedEntityValueDiffered(
        entity.ticket.ticketList,
        ticketFormModel.ticketList
      )
        ? toReferencedEntityDeltaValue(
            entity.ticket.ticketList,
            ticketFormModel.ticketList
          )
        : undefined,
      wbsItem,
    }
    const input = {
      ...toRiskDeltaInput(entity, riskFormModel),
      ticket,
    }
    const request = {
      input,
      watchers: (wbsItemFormModel.watchers || []).map(watcher => watcher.uuid),
      tags: {
        wbsItemUuid: wbsItemFormModel.uuid,
        tagUuids: (wbsItemFormModel.tags || []).map(tag => tag.uuid),
      },
    }
    await repository.updateDelta(request)
    dispatch(
      addScreenMessage('wbsItem', {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({ id: 'update.completed.title' }),
      })
    )
  }, [
    dispatch,
    entity,
    riskFormModel,
    ticketFormModel.ticketList,
    wbsItemFormModel,
    repository,
  ])

  return updateDelta
}

import { ProjectPlanSelect } from '../ProjectPlanSelect'
import { useCallback, useMemo } from 'react'
import { ProjectEntity } from '../../../../../domain/entity/ProjectEntity'
import { ContentRoot, Description, Label, ProjectSelect, Root, Title } from '.'
import { styled } from '@mui/material'
import { CopyRoot, Project } from '../../model'

type SrcSelectPaneProps = {
  title: string
  projectUuid: string | undefined
  onChangeProject: (v: Project | undefined) => void
  rootProjectPlan: CopyRoot
  onChangeRootProjectPlan: (v: CopyRoot) => void

  projects: ProjectEntity[]
}
export const SrcSelectPane = ({
  title,
  projectUuid,
  onChangeProject,
  rootProjectPlan,
  onChangeRootProjectPlan,
  projects,
}: SrcSelectPaneProps) => {
  return (
    <Root>
      <Title>{title}</Title>
      <ContentRoot>
        <ProjectSelect
          projectUuid={projectUuid}
          onChangeProject={onChangeProject}
          projects={projects}
        />
        <ItemRoot>
          <Label>コピー対象の工程</Label>
          <Description>
            選択した工程と、配下のWBSをすべてコピーします。
          </Description>
          <ProjectPlanSelect
            projectUuid={projectUuid}
            selected={rootProjectPlan}
            updateSelected={onChangeRootProjectPlan}
            rootName={'すべての工程'}
          />
        </ItemRoot>
      </ContentRoot>
    </Root>
  )
}

const ItemRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  height: '100%',
})

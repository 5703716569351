import { useCallback, useMemo } from 'react'
import { useProjectMemberRepository } from '../../../../services/adaptors/projectMemberRepositoryAdaptor'
import { useTeamRepository } from '../../../../services/adaptors/teamRepositoryAdaptor'
import {
  useBooleanParser,
  useDateParser,
  useDateTimeParser,
  useReferencedEntityParser,
  useSelectParser,
  useTextParser,
} from './changeLogParser'
import { WbsItemSelectOptions } from './wbsItemSelectOptions'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { useWbsItemRepository } from '../../../../services/adaptors/wbsItemRepositoryAdaptor'
import { useWbsItemAdditionalProperyValueChangeLogParser } from './wbsItemAdditionalPropertyValueChangeLogParser'
import { useUserRepository } from '../../../../services/adaptors/userRepositoryAdaptor'
import { useTicketRepository } from '../../../../services/adaptors/ticketRepositoryAdaptor'
import { useEntityExtensionValueChangeLogParser } from './entityExtensionValueChangeLogParser'
import { WbsItemEntityExtensionProperty } from '../model/entityExtension'

export const useWbsItemChangeLogParser = (
  { subStatuses, difficulties, priorities }: WbsItemSelectOptions,
  entityExtensionProperties: WbsItemEntityExtensionProperty[],
  wbsItemAdditionalProperties: WbsItemAdditionalPropertyLayoutEntity | undefined
) => {
  const booleanParser = useBooleanParser()
  const textParser = useTextParser()
  const dateParser = useDateParser()
  const dateTimeParser = useDateTimeParser()
  const statusParser = useStatusParser()
  const subStatusParser = useSelectParser(subStatuses)
  const difficultyParser = useSelectParser(difficulties)
  const priorityParser = useSelectParser(priorities)
  const { fetchAsReferencedEntity: fetchTeam } = useTeamRepository()
  const teamParser = useReferencedEntityParser(fetchTeam)
  const { fetchAsReferencedEntity: fetchProjectMember } =
    useProjectMemberRepository()
  const projectMemberParser = useReferencedEntityParser(fetchProjectMember)
  const { fetchAsReferencedEntity: fetchUser } = useUserRepository()
  const userParser = useReferencedEntityParser(fetchUser)
  const { fetchAsReferencedEntity: fetchWbsItem } = useWbsItemRepository()
  const wbsItemParser = useReferencedEntityParser(fetchWbsItem)
  const { fetchAsReferencedEntity: fetchTicket } = useTicketRepository()
  const ticketParser = useReferencedEntityParser(fetchTicket)
  const {
    isEntityExtensionValueChangeLog,
    entityExtensionValueChangeLogParsers,
  } = useEntityExtensionValueChangeLogParser(
    entityExtensionProperties,
    booleanParser,
    textParser,
    dateParser,
    dateTimeParser,
    wbsItemParser,
    ticketParser
  )
  const {
    isWbsItemAdditionalPropertyValueChangeLog,
    wbsItemAdditionalPropertyValueParsers,
  } = useWbsItemAdditionalProperyValueChangeLogParser(
    wbsItemAdditionalProperties,
    booleanParser,
    textParser,
    dateParser,
    dateTimeParser,
    teamParser,
    userParser,
    wbsItemParser,
    ticketParser
  )
  const getChangeLogParser = useCallback(
    (path: string) => {
      switch (path) {
        case 'code':
        case 'displayName':
        case 'description':
        case 'startIf':
        case 'completeIf':
          return textParser
        case 'status':
          return statusParser
        case 'substatus':
          return subStatusParser
        case 'difficulty':
          return difficultyParser
        case 'priority':
          return priorityParser
        case 'teamUuid':
          return teamParser
        case 'accountableUuid':
        case 'responsibleUuid':
        case 'assigneeUuid':
          return projectMemberParser
        case 'estimatedStoryPoint':
        case 'estimatedHour':
          return textParser
        default:
          if (isWbsItemAdditionalPropertyValueChangeLog(path)) {
            return wbsItemAdditionalPropertyValueParsers[path]
          } else if (isEntityExtensionValueChangeLog(path)) {
            return entityExtensionValueChangeLogParsers[path]
          } else {
            return textParser
          }
      }
    },
    [
      difficultyParser,
      priorityParser,
      projectMemberParser,
      subStatusParser,
      statusParser,
      teamParser,
      textParser,
      isEntityExtensionValueChangeLog,
      entityExtensionValueChangeLogParsers,
      isWbsItemAdditionalPropertyValueChangeLog,
      wbsItemAdditionalPropertyValueParsers,
    ]
  )
  return getChangeLogParser
}

const useStatusParser = () => {
  const statusParser = useCallback((status?: string) => {
    return status ? status.replaceAll('"', '') : status
  }, [])

  return statusParser
}

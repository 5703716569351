import { styled } from '@mui/material'
import { PageRoot } from '../ProjectVersionReport/components/pages'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DestSelectPane } from './components/pane/DestSelectPane'
import { CopyWbsHeader } from './components/header'
import { useProjectPlanRepository } from '../../../services/adaptors/projectPlanRepositoryAdaptor'
import Loading from '../../components/process-state-notifications/Loading'
import { intl } from '../../../i18n'
import { useAssignedProject } from './hooks/projects'
import { CopyRoot, Project } from './model'
import { useProjectPrivateContext } from '../../context/projectContext'
import { useSelector } from 'react-redux'
import { AllState } from '../../../store'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { SrcSelectPane } from './components/pane/SrcSelectPane'
import { SubmitCompleteDialog } from './components/dialog/SubmitCompleteDialog'

export const CopyWbs = projectPrivate(() => {
  const [srcProject, setSrcProject] = useState<Project | undefined>()
  const [srcRootProjectPlan, setSrcProjectPlan] = useState<CopyRoot>({
    type: 'PROJECT',
  })
  const [destProject, setDestProject] = useState<Project | undefined>()
  const [destRootProjectPlan, setDestProjectPlan] = useState<CopyRoot>({
    type: 'PROJECT',
  })
  const { currentProject } = useSelector<
    AllState,
    { currentProject: Project | undefined }
  >(state => ({
    currentProject: state.project.current && {
      uuid: state.project.current.uuid,
      name: state.project.current.displayName,
    },
  }))
  const [isCurrentProject, setIsCurrentProject] = useState<boolean>(false)
  useEffect(() => {
    setDestProject(currentProject)
    if (currentProject) {
      setIsCurrentProject(true)
    }
  }, [currentProject])
  const [loading, setLoading] = useState<boolean>(false)
  const copiable = useMemo(() => {
    if (!srcProject || !destProject) {
      return false
    }
    if (srcProject.uuid === destProject.uuid) {
      return false
    }
    return true
  }, [destProject, srcProject])
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [copiedCount, setCopiedCount] = useState<number>(0)
  const closeDialog = useCallback(() => {
    setDialogOpen(false)
  }, [])
  const { copy } = useProjectPlanRepository()
  const doCopy = useCallback(async () => {
    if (!srcProject || !destProject) return
    if (srcProject.uuid === destProject.uuid) return
    setLoading(true)
    const srcRootProjectPlanUuid =
      srcRootProjectPlan?.type === 'WBS' ? srcRootProjectPlan.uuid : undefined
    const destRootProjectPlanUuid =
      destRootProjectPlan?.type === 'WBS' ? destRootProjectPlan.uuid : undefined
    const response = await copy({
      srcProjectUuid: srcProject.uuid,
      srcRootProjectPlanUuid,
      destProjectUuid: destProject.uuid,
      destRootProjectPlanUuid,
    })
    setCopiedCount(response.uuids.length)
    setDialogOpen(true)
    setLoading(false)
  }, [copy, destProject, destRootProjectPlan, srcProject, srcRootProjectPlan])
  const { assigned } = useAssignedProject()
  const [srcTitle, destTitle] = useMemo(
    () => [
      intl.formatMessage({ id: 'copyWbs.srcTitle' }),
      intl.formatMessage({ id: 'copyWbs.destTitle' }),
    ],
    []
  )
  return (
    <>
      <PageRoot>
        <CopyWbsHeader
          copiable={copiable}
          copy={doCopy}
          srcProject={srcProject}
          srcRootProjectPlan={srcRootProjectPlan}
          destProject={destProject}
          destRootProjectPlan={destRootProjectPlan}
        />
        <Root>
          <SrcSelectPane
            title={srcTitle}
            projectUuid={srcProject?.uuid}
            onChangeProject={setSrcProject}
            rootProjectPlan={srcRootProjectPlan}
            onChangeRootProjectPlan={setSrcProjectPlan}
            projects={assigned}
          />
          <DestSelectPane
            title={destTitle}
            projectUuid={destProject?.uuid}
            onChangeProject={setDestProject}
            rootProjectPlan={destRootProjectPlan}
            onChangeRootProjectPlan={setDestProjectPlan}
            projects={assigned}
            isCurrentProject={isCurrentProject}
          />
        </Root>
        <Loading isLoading={loading} />
      </PageRoot>
      <SubmitCompleteDialog
        open={dialogOpen}
        onClose={closeDialog}
        copiedCount={copiedCount}
      />
    </>
  )
})

const Root = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  gap: '24px',
})

import { useCallback, useMemo } from 'react'
import {
  StyledDialog,
  StyledDialogTitle,
  Title,
  StyledDialogContent,
  StyledDialogActions,
  HeaderText,
} from '.'
import { intl } from '../../../../../i18n'
import { Button } from '../../../../components/buttons'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../store'
import { useHistory } from 'react-router'

type SubmitCompleteDialogProps = {
  open: boolean
  onClose: () => void
  copiedCount: number
}
export const SubmitCompleteDialog = ({
  open,
  onClose,
  copiedCount,
}: SubmitCompleteDialogProps) => {
  const { currentProjectCode } = useSelector<
    AllState,
    { currentProjectCode: string | undefined }
  >(state => ({
    currentProjectCode: state.project.current?.code,
  }))
  const message = useMemo(
    () => `WBSのコピーが完了し、${copiedCount}件のWBSが作成されました。`,
    [copiedCount]
  )
  const history = useHistory()
  const toProjectPlan = useCallback(() => {
    history.push(`/projectPlan/${currentProjectCode}`)
    onClose()
  }, [currentProjectCode, history, onClose])
  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <Title>コピー完了</Title>
      </StyledDialogTitle>
      <StyledDialogContent>
        <HeaderText>{message}</HeaderText>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={toProjectPlan} colorPattern="skyBlue">
          プロジェクト計画を開く
        </Button>
        <Button onClick={onClose} colorPattern="monotone" variant="outlined">
          {intl.formatMessage({ id: 'dialog.close' })}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  )
}

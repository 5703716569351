import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ICellEditorParams } from 'ag-grid-community'
import { CustomEnumValue } from '../../../../../lib/commons/appFunction'
import { StatusComboBox } from '../ComboBox/StatusComboBox'
import { filterValuesByCombination } from '../../../../../lib/commons/customEnum'
import objects from '../../../../../utils/objects'
import { ProgressIcon } from '../../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus/ProgressIcon'
import {
  ProgressIconArea,
  ProjectPlanNewStatusArea,
} from '../CellRenderer/ProjectPlanNewStatusCellRenderer'

interface Props extends ICellEditorParams {
  customEnumCode?: string
  combinedValuePath?: (combinedEnumCode: string) => string
  valuesAllowed?: CustomEnumValue[]
}

export const ProjectPlanNewStatusCellEditor = forwardRef(
  (
    { customEnumCode, combinedValuePath, value, api, context, data }: Props,
    ref
  ) => {
    const [val, setVal] = useState(value ?? '')
    const [isOpen, setIsOpen] = useState(true)
    const wbsItem = data?.body?.wbsItem ?? data?.wbsItem

    useEffect(() => {
      if (!isOpen) {
        api.stopEditing()
      }
    }, [isOpen, api])

    let options: CustomEnumValue[] = []
    if (customEnumCode) {
      options = filterValuesByCombination(context[customEnumCode], code =>
        objects.getValue(data, combinedValuePath?.(code))
      )
    }

    useImperativeHandle(ref, () => ({
      getValue() {
        return val
      },
    }))

    if (!options) return <></>

    return (
      <ProjectPlanNewStatusArea>
        <ProgressIconArea>
          <ProgressIcon wbsItem={wbsItem} fontSize={20} />
        </ProgressIconArea>
        <StatusComboBox
          value={val}
          options={options}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onChange={newValue => {
            setVal(newValue)
            setIsOpen(false)
          }}
        />
      </ProjectPlanNewStatusArea>
    )
  }
)

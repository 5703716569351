import { useDispatch, useSelector } from 'react-redux'
import { AllState } from '../../store'
import { useCallback } from 'react'
import { requireSave, doNotRequireSave } from '../../store/requiredSaveData'

export const usePageDataIsEdited = () => {
  const edited = useSelector<AllState, boolean>(
    state => state.hasRequiredSaveData.hasRequiredSaveData
  )
  const dispatch = useDispatch()
  const editedPageData = useCallback(() => {
    dispatch(requireSave())
  }, [dispatch])
  const refreshedPageData = useCallback(() => {
    dispatch(doNotRequireSave())
  }, [dispatch])

  return {
    edited,
    editedPageData,
    refreshedPageData,
  }
}

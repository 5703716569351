import {
  EditableCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
  ICellRendererParams,
  ColDef,
  CellClassParams,
} from 'ag-grid-community'
import {
  isAssociatedWithTheWbsItemType,
  WbsItemAdditionalPropertyEntity,
  WbsItemAdditionalPropertyType,
} from '../../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { i18nLabelVoService } from '../../../../../../domain/value-object/I18nLabelVO'
import { wbsItemAdditionalPropertyValuesVoService } from '../../../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import store from '../../../../../../store'
import { requireSave } from '../../../../../../store/requiredSaveData'
import { DefaultCellRenderer } from '../../../components/cellRenderer'
import { Option } from '../'
import { checkboxColumnDef } from './checkbox'
import { dateColumnDef } from './date'
import { datetimeColumnDef } from './datetime'
import { entitySearchColumnDef } from './entitySearch'
import { multiLineTextColumnDef } from './multLlineText'
import { numberColumnDef } from './number'
import { selectColumnDef } from './select'
import { multiSelectColumnDef } from './multiSelect'
import { textColumnDef } from './text'
import { intl } from '../../../../../../i18n'
import { GetEntitySearchReferenceEntityRepository } from '../../../../../hooks/useWbsItemAdditionalProperties'
import { BulkSheetColumnDef } from '../../../model'

export const wbsItemAdditionalPropertyColumnDef = <RowData>(
  projectUuid: string,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>,
  getEntitySearchRepositories: GetEntitySearchReferenceEntityRepository
): BulkSheetColumnDef<RowData> => {
  const columnDef: BulkSheetColumnDef<RowData> = {
    externalId: wbsItemAdditionalProperty.uuid,
    colId: wbsItemAdditionalProperty.uuid,
    field: wbsItemAdditionalProperty.uuid,
    headerName: i18nLabelVoService.getLabel(
      wbsItemAdditionalProperty.propertyNameI18n
    ),
    hide: false,
    editable:
      option.editable ||
      ((params: EditableCallbackParams) =>
        isAssociatedWithTheWbsItemType(
          wbsItemAdditionalProperty,
          option.getWbsItemType(params.data)
        )),
    cellClassRules: {
      'ag-cell-disable': (params: CellClassParams) =>
        !isAssociatedWithTheWbsItemType(
          wbsItemAdditionalProperty,
          option.getWbsItemType(params.data)
        ),
    },
    resizable: true,
    valueGetter: (params: ValueGetterParams<RowData>) => {
      if (!params.data) return undefined
      const additionalPropertyValuesVO = option.getAdditionalPropertyValues(
        params.data
      )
      if (!additionalPropertyValuesVO) return undefined
      const additionalPropertyValueVO =
        wbsItemAdditionalPropertyValuesVoService.getValue(
          additionalPropertyValuesVO,
          wbsItemAdditionalProperty.uuid
        )
      return additionalPropertyValueVO
        ? additionalPropertyValueVO.value
        : undefined
    },
    valueSetter: (params: ValueSetterParams<RowData>) => {
      const { oldValue, newValue: _newValue, data, node } = params
      const newValue =
        wbsItemAdditionalPropertyValuesVoService.parseValueFromString(
          wbsItemAdditionalProperty.propertyType,
          _newValue
        )
      if (
        ((oldValue === null || oldValue === undefined) &&
          (newValue === null || newValue === undefined)) ||
        oldValue === newValue ||
        !data ||
        !node
      ) {
        return false
      }
      let additionalPropertyValues = option.getAdditionalPropertyValues(data)
      if (!additionalPropertyValues) {
        additionalPropertyValues =
          wbsItemAdditionalPropertyValuesVoService.construct()
      }
      option.setAdditionalPropertyValues(
        node,
        wbsItemAdditionalProperty,
        oldValue,
        newValue
      )
      store.dispatch(requireSave())
      return true
    },
    cellRenderer: DefaultCellRenderer,
    cellRendererParams: {
      validate: (value, params: ICellRendererParams) => {
        if (
          !isAssociatedWithTheWbsItemType(
            wbsItemAdditionalProperty,
            option.getWbsItemType(params.data)
          )
        ) {
          return
        }
        if (wbsItemAdditionalProperty.required && !value) {
          return intl.formatMessage(
            { id: 'validator.required' },
            {
              field: i18nLabelVoService.getLabel(
                wbsItemAdditionalProperty.propertyNameI18n
              ),
            }
          )
        }
      },
    },
  }

  switch (wbsItemAdditionalProperty.propertyType) {
    case WbsItemAdditionalPropertyType.NUMBER:
      numberColumnDef(columnDef, wbsItemAdditionalProperty, option)
      break
    case WbsItemAdditionalPropertyType.TEXT:
      textColumnDef(columnDef, wbsItemAdditionalProperty, option)
      break
    case WbsItemAdditionalPropertyType.MULTI_LINE_TEXT:
      multiLineTextColumnDef(columnDef, wbsItemAdditionalProperty, option)
      break
    case WbsItemAdditionalPropertyType.DATE:
      dateColumnDef(columnDef, wbsItemAdditionalProperty, option)
      break
    case WbsItemAdditionalPropertyType.DATE_TIME:
      datetimeColumnDef(columnDef, wbsItemAdditionalProperty, option)
      break
    case WbsItemAdditionalPropertyType.CHECKBOX:
      checkboxColumnDef(columnDef, wbsItemAdditionalProperty, option)
      break
    case WbsItemAdditionalPropertyType.SELECT:
      selectColumnDef(columnDef, wbsItemAdditionalProperty, option)
      break
    case WbsItemAdditionalPropertyType.MULTI_SELECT:
      multiSelectColumnDef(columnDef, wbsItemAdditionalProperty, option)
      break
    case WbsItemAdditionalPropertyType.ENTITY_SEARCH:
      entitySearchColumnDef(
        columnDef,
        projectUuid,
        wbsItemAdditionalProperty,
        option,
        getEntitySearchRepositories
      )
      break
    default:
      throw new Error(
        `This wbs item additional property type(=${wbsItemAdditionalProperty.propertyType}) is not supported.`
      )
  }
  return columnDef
}

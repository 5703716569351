export const convertDecimalHourToHM = (
  decimalHour: number
): string | undefined => {
  if (typeof decimalHour !== 'number') {
    return undefined
  }
  const hour = Math.floor(decimalHour)
  let min = Math.floor((decimalHour - hour) * 60)
  const sec = Math.round(((decimalHour - hour) * 60 - min) * 60)
  if (sec >= 30) {
    min += 1
  }
  return `${hour}:${min.toString().padStart(2, '0')}`
}

export const convertDecimalHourToHMS = (
  decimalHour: number
): string | undefined => {
  if (typeof decimalHour !== 'number') {
    return undefined
  }
  const hour = Math.floor(decimalHour)
  let min = Math.floor((decimalHour - hour) * 60)
  let sec = Math.round(((decimalHour - hour) * 60 - min) * 60)
  if (sec === 60) {
    sec = 0
    min += 1
  }
  return `${hour}:${min.toString().padStart(2, '0')}:${sec
    .toString()
    .padStart(2, '0')}`
}

export const convertUnixToHMS = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000)
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const sec = seconds % 60

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${sec.toString().padStart(2, '0')}`
}

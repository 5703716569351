import { useCallback, useMemo, useState } from 'react'
import { MyWbsItem, TaskTimerDailySummary, TaskTimer } from '../myWbsItems'
import { format } from 'date-fns'
import DateVO from '../../../../vo/DateVO'

export const useTimerPopper = () => {
  const [anchorEl, setAnchorEl] = useState<EventTarget>()
  const [wbsItem, setWbsItem] = useState<MyWbsItem>()
  const [timer, setTimer] = useState<TaskTimerDailySummary>()
  const [targetDate, setTargetDate] = useState<string>()

  const open = useCallback(
    (
      el: EventTarget,
      wbsItem: MyWbsItem,
      timer: TaskTimer,
      clickedDate?: string
    ) => {
      const keyDate = clickedDate
        ? new DateVO(clickedDate).formatForApi()!
        : format(new Date(), 'yyyy-MM-dd')
      const timerDailySummary = timer.dailySummaries[keyDate]
      if (!timerDailySummary) {
        return
      }
      setAnchorEl(el)
      setWbsItem(wbsItem)
      setTimer(timerDailySummary)
      setTargetDate(new DateVO(keyDate).format())
    },
    []
  )

  const close = useCallback(() => {
    setAnchorEl(undefined)
    setTimer(undefined)
    setWbsItem(undefined)
    setTargetDate(undefined)
  }, [])

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  return {
    anchorEl,
    timer,
    wbsItem,
    targetDate,

    open,
    close,
    isOpen,
  }
}

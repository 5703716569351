import React, { useState, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useTimerRepository } from '../../../../../../../../services/adaptors/timerRepositoryAdapter'
import { TimerStatus } from '../../../../../../../../domain/entity/TimerEntity'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchTimerItem,
  stopTimer,
} from '../../../../../../../../store/measuringTimer'
import { TaskTimer } from '../../../../../../../pages/MyWbsItems/myWbsItems'
import { AllState } from '../../../../../../../../store'
import styled from 'styled-components'

const StyledButton = styled('button')<{ status: string; isDisabled: boolean }>(
  props => ({
    backgroundColor: props.status === TimerStatus.MEASURING ? 'red' : '#00CC33',
    color: 'white',
    padding: '5px 10px',
    width: '100%',
    border: 'none',
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    minWidth: '100px',
  })
)

interface ButtonCellRendererProps {
  uuid: string
  data: {
    body: {
      timer: TaskTimer
    }
    uuid: string
  }
  context: any
}

const ButtonCellRenderer: React.FC<ButtonCellRendererProps> = ({
  data,
  context,
}) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const intl = useIntl()
  const dispatch = useDispatch()

  const status = useMemo(
    () => data.body.timer.timerStatus,
    [data.body.timer.timerStatus]
  )
  const taskUuid = useMemo(() => data.uuid, [data.uuid])
  const userUuid = useSelector((state: AllState) => state.user.user?.uuid)

  const buttonText = useMemo(() => {
    return status === TimerStatus.MEASURING
      ? intl.formatMessage({ id: 'myWbsItems.timer.stopButton' })
      : intl.formatMessage({ id: 'myWbsItems.timer.startButton' })
  }, [status, intl])

  const { start, stop } = useTimerRepository()

  const handleClick = useCallback(async () => {
    setIsDisabled(true)
    try {
      if (status === TimerStatus.MEASURING) {
        await stop(userUuid!, taskUuid)
        await context.refreshAll?.()
        dispatch(stopTimer())
      } else {
        await start(userUuid!, taskUuid)
        await context.refreshAll?.()
        dispatch(fetchTimerItem())
      }
    } finally {
      setIsDisabled(false)
    }
  }, [status, userUuid, taskUuid, start, stop, dispatch, context.refreshAll])

  return (
    <StyledButton
      status={status}
      isDisabled={isDisabled}
      onClick={handleClick}
      disabled={isDisabled}
    >
      {buttonText}
    </StyledButton>
  )
}
export default ButtonCellRenderer

import { useCallback, useEffect, useState } from 'react'
import { useWbsItemRepository } from '../../../../services/adaptors/wbsItemRepositoryAdaptor'
import {
  Attachment,
  AttachmentCandidate,
} from '../../../../domain/value-object/Attachment'

export const useAttachmentHandlers = (
  uuid: string,
  initialDeliverableAttachments?: Attachment[]
) => {
  const [deliverableAttachments, setDeliverableAttachments] = useState<
    Attachment[]
  >([])
  useEffect(() => {
    setDeliverableAttachments(initialDeliverableAttachments || [])
  }, [initialDeliverableAttachments])

  const {
    addAttachments: _addAttachments,
    deleteAttachments: _deleteAttachments,
  } = useWbsItemRepository()
  const addAttachments = useCallback(
    async (attachments: AttachmentCandidate[]) => {
      await _addAttachments({
        wbsItemUuid: uuid,
        attachmentType: 'DELIVERABLE',
        attachments,
      })
      setDeliverableAttachments(prev => {
        return [...prev, ...attachments]
      })
    },
    [_addAttachments, uuid]
  )
  const deleteAttachments = useCallback(
    async (attachments: Attachment[]) => {
      await _deleteAttachments({
        wbsItemUuid: uuid,
        attachmentType: 'DELIVERABLE',
        attachmentItemUuids: attachments.map(attachment => attachment.uuid),
      })
      setDeliverableAttachments(prev => {
        return prev.filter(v =>
          attachments.some(deleted => v.uuid !== deleted.uuid)
        )
      })
    },
    [_deleteAttachments, uuid]
  )
  return {
    deliverableAttachments,
    addAttachments,
    deleteAttachments,
  }
}

import { useCallback, useMemo, useState } from 'react'
import AlertDialog from '../../../../../components/dialogs/AlertDialog'
import { intl } from '../../../../../../i18n'
import { Attachment } from '../../../../../../domain/value-object/Attachment'
import MarkupViewer from '../../../../../components/viewers/MarkupViewer'

type DeleteAttachmentConfirmDialogProps = {
  open: boolean
  deleteTargetAttachment: Attachment | undefined
  submit: () => void
  closeDialog: () => void
}

export const DeleteAttachmentConfirmDialog = ({
  open,
  deleteTargetAttachment,
  submit,
  closeDialog,
}: DeleteAttachmentConfirmDialogProps) => {
  return (
    <AlertDialog
      isOpen={open}
      message={intl.formatMessage({
        id: 'attachment.delete.message',
      })}
      extraContent={
        deleteTargetAttachment ? (
          <MarkupViewer content={deleteTargetAttachment.name} />
        ) : (
          ''
        )
      }
      submitButtonTitle={intl.formatMessage({ id: 'dialog.ok' })}
      submitHandler={submit}
      closeButtonTitle={intl.formatMessage({
        id: 'dialog.cancel',
      })}
      closeHandler={closeDialog}
    />
  )
}

export const useDeleteAttachmentConfirmDialog = (
  onSubmit: (attachments: Attachment[]) => void
): DeleteAttachmentConfirmDialogProps & {
  openDialog: (deleteTargetAttachment: Attachment) => void
} => {
  const [open, setOpen] = useState(false)
  const [deleteTargetAttachment, setDeleteTargetAttachment] = useState<
    Attachment | undefined
  >(undefined)

  const openDialog = useCallback((attachment: Attachment) => {
    setOpen(true)
    setDeleteTargetAttachment(attachment)
  }, [])
  const closeDialog = useCallback(() => {
    setOpen(false)
    setDeleteTargetAttachment(undefined)
  }, [])
  const submit = useCallback(() => {
    if (deleteTargetAttachment) {
      onSubmit([deleteTargetAttachment])
    }
    setOpen(false)
  }, [onSubmit, deleteTargetAttachment])

  const state = useMemo(
    () => ({
      open,
      deleteTargetAttachment,
      submit,
      openDialog,
      closeDialog,
    }),
    [open, deleteTargetAttachment, submit, openDialog, closeDialog]
  )
  return state
}

import { useCallback, useMemo } from 'react'
import { WbsItemType } from '../../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../../i18n'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import { CheckboxSelectConditionItemLabel } from '../CheckboxSelectConditionItemLabel'
import { WbsItemTypeCheckboxSelectConditionProps } from '../../search-components/wbs-item-search-components/WbsItemTypeCheckboxSelectCondition'

type WbsItemTypeCheckboxSelectConditionItemLabelProps = Omit<
  WbsItemTypeCheckboxSelectConditionProps,
  'colSpan' | 'onEnter'
> & {
  isLoading?: boolean
  search: () => void
  onClose?: () => void
}

export const WbsItemTypeCheckboxSelectConditionItemLabel = ({
  value,
  isLoading,
  onChange,
  onClose,
  ...other
}: WbsItemTypeCheckboxSelectConditionItemLabelProps) => {
  const { wbsItemTypes } = useProjectPrivateContext()
  const options = useMemo(() => {
    return wbsItemTypes.getAll().map(vo => ({
      name: vo.name,
      value: vo.code,
      iconUrl: vo.iconUrl,
    }))
  }, [wbsItemTypes])
  const onChangeValue = useCallback((newValueStr: string[] | undefined) => {
    if (!newValueStr) {
      onChange(undefined)
      return
    }
    const newValue = newValueStr.map(v => WbsItemType[v])
    onChange(newValue)
  }, [])
  return (
    <CheckboxSelectConditionItemLabel
      {...other}
      header={intl.formatMessage({ id: 'wbsItemSearchFilterPanel.types' })}
      value={value}
      onChange={onChangeValue}
      onClose={onClose}
      options={options}
      isLoading={isLoading}
    />
  )
}

import { useCallback, useEffect, useRef, useState } from 'react'

export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<any>()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [value])
  return debouncedValue
}

export const useDebouncedCallback = <T, U>(
  callback: (...T) => U,
  delay: number
) => {
  const callbackRef = useRef<ReturnType<typeof setTimeout>>()
  const debouncedCallback = useCallback(
    (...args) => {
      if (callbackRef.current) {
        clearTimeout(callbackRef.current)
      }
      callbackRef.current = setTimeout(() => {
        callback(...args)
      }, delay)
    },
    [callback, delay]
  )
  useEffect(() => {
    return () => {
      if (callbackRef.current) {
        clearTimeout(callbackRef.current)
      }
    }
  }, [])
  return debouncedCallback
}

import {
  CellClassParams,
  ColDef,
  EditableCallbackParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { ColumnType } from '../../../containers/commons/AgGrid'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import AttachmentCellFilter from '../../../containers/commons/AgGrid/components/cell/custom/attachment/attachmentCellFilter'
import { DetailCellFilter } from '../../../containers/commons/AgGrid/components/cell/custom/detail'
import { getLabel, parse } from '../../../../lib/commons/i18nLabel'
import {
  CustomEnumCode,
  getByCodeAndGroupKeys,
} from '../../../../lib/functions/customEnumValue'
import {
  AttachmentCellRenderer,
  EntitySearchCellRenderer,
  ProjectPlanActionCellRenderer,
  MyWbsItemsCustomEnumCellRenderer,
  DefaultCellRenderer,
  ProjectPlanStatusCellRenderer,
} from '../../../containers/BulkSheetView/components/cellRenderer'
import {
  ClientSideNumberFilter,
  ClientSideSelectFilter,
  CustomEnumFilter,
} from '../../../containers/BulkSheetView/components/filter'
import {
  EntitySearchCellEditor,
  MyWbsItemsCustomEnumCellEditor,
  CustomEnumCellEditor,
} from '../../../containers/BulkSheetView/components/cellEditor'
import {
  getWbsItemStatusColorCode,
  getWbsItemTreeStatusColorCode,
} from '../../../../lib/functions/wbsItem'
import { intl } from '../../../../i18n'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import objects from '../../../../utils/objects'
import {
  CustomEnumCombinationDirection,
  CustomEnumValue,
} from '../../../../lib/commons/appFunction'
import { MyWbsItemRow } from '../myWbsItems'
import DateCell from '../../../containers/commons/AgGrid/components/cell/common/date'
import { toNumber } from '../../../../utils/number'
import ButtonCell from '../../../containers/commons/AgGrid/components/cell/custom/measureButton'
import { format } from 'date-fns'
import {
  useAccountable,
  useActualEndDate,
  useActualStartDate,
  useAssignee,
  useDescription,
  useDisplayName,
  useEstimatedWorkLoadDeliverable,
  useEstimatedWorkLoadTask,
  useLatestComment,
  useResponsible,
  useScheduledEndDate,
  useScheduledStartDate,
  useSprint,
  useTags,
  useWatchers,
} from '../../../page-properties/bulksheet-properties/wbsItem'
import { useCallback, useMemo } from 'react'
import { useTmpFeatureFlag } from '../hooks/featureFlag'
import { convertDecimalHourToHM } from '../../../../utils/time'
import { filterValuesByCombination } from '../../../../lib/commons/customEnum'

const FIELD_WBSITEM_SCHEDULED_START = 'body.wbsItem.scheduledDate.startDate'
const FIELD_WBSITEM_SCHEDULED_END = 'body.wbsItem.scheduledDate.endDate'
export const FIELD_WBSITEM_ACTUALDATE_START =
  'body.wbsItem.actualDate.startDate'
export const FIELD_WBSITEM_ACTUALDATE_END = 'body.wbsItem.actualDate.endDate'

export const editableExceptSummaryRow = (
  params: EditableCallbackParams<MyWbsItemRow>
) => !params.data?.isTotalRow

const statusCombinedValuePath = (combinedEnumCode: string) => {
  switch (combinedEnumCode) {
    case 'WBS_TYPE':
      return 'body.wbsItem.type'
    case 'TICKET_TYPE':
      return 'body.wbsItem.ticketType'
    case 'WBS_STATUS':
      return 'body.wbsItem.status'
    case 'WBS_SUBSTATUS':
      return 'body.wbsItem.substatus'
  }
  return undefined
}

const getSubStatusOptions = async (data: MyWbsItemRow) => {
  const groupKeys: string[] = []
  const wbsItem = data.body?.wbsItem
  if (wbsItem?.projectUuid) {
    groupKeys.push(wbsItem.projectUuid)
  }
  if (wbsItem && wbsItem.ticketType) {
    groupKeys.push(wbsItem.ticketType)
    groupKeys.push(wbsItem.ticketListUuid!)
  }

  const response = await getByCodeAndGroupKeys({
    customEnumCode: CustomEnumCode.WBS_SUBSTATUS,
    groupKeys: groupKeys,
  })
  return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
}

const customEnumValueSetter = (params: ValueSetterParams) => {
  const field = params.colDef.field || params.colDef.colId
  if (!field || params.oldValue === params.newValue) return false
  const { customEnumCode, combinedValuePath } = params.colDef.cellEditorParams
  const options: CustomEnumValue[] = params.context[customEnumCode]

  const customEnumValue = options.find(o => o.value === params.newValue)
  const bidirection = customEnumValue?.combinations?.find(
    v => v.direction === CustomEnumCombinationDirection.BIDIRECTION
  )
  objects.setValue(params.data, field, params.newValue)
  if (bidirection && combinedValuePath) {
    objects.setValue(
      params.data,
      combinedValuePath?.(bidirection.combinedEnumCode),
      bidirection.combinedValues?.[0]?.value
    )
  }
  params.data.edited = true
  if (!params.data?.editedData) {
    params.data.editedData = { [field]: params.oldValue }
  } else if (!params.data?.editedData.hasOwnProperty(field)) {
    params.data.editedData[field] = params.oldValue
  }
  store.dispatch(requireSave())
  return true
}

const useDefaultColumns = () => {
  const getWbsItemProjectUuid = useCallback(
    (row: MyWbsItemRow | undefined) => row?.body?.wbsItem?.projectUuid,
    []
  )
  const getWbsItemStatus = useCallback(
    (row: MyWbsItemRow | undefined) => row?.body?.wbsItem?.status,
    []
  )
  const getScheduledDate = useCallback(
    (row: MyWbsItemRow | undefined) => row?.body?.wbsItem?.scheduledDate,
    []
  )
  const getActualDate = useCallback(
    (row: MyWbsItemRow | undefined) => row?.body?.wbsItem?.actualDate,
    []
  )
  const getTeamUuid = useCallback(
    (row: MyWbsItemRow | undefined) => row?.body?.wbsItem?.team?.uuid,
    []
  )

  const displayNameCol = useDisplayName({
    field: 'body.wbsItem.displayName',
  })
  const descriptionCol = useDescription('body.wbsItem.description')
  const commentCol = useLatestComment('body.commentSummary.latestComment')

  // Basic information group column common def
  const tagsCol = useTags({
    field: 'body.tags',
    getWbsItemProjectUuid,
  })

  // Assignee group column common def
  const accountableCol = useAccountable({
    field: 'body.wbsItem.accountable',
    getWbsItemProjectUuid,
  })

  const responsibleCol = useResponsible({
    field: 'body.wbsItem.responsible',
    getWbsItemProjectUuid,
  })

  const assigneeCol = useAssignee({
    field: 'body.wbsItem.assignee',
    getWbsItemProjectUuid,
  })

  const watchersCol = useWatchers({
    field: 'body.wbsItem.watchers',
  })

  const sprintCol = useSprint({
    field: 'body.wbsItem.sprint',
    getWbsItemProjectUuid,
    getTeamUuid,
  })

  const setScheduledEndDate = useCallback(
    (
      params: ValueSetterParams<MyWbsItemRow>,
      newValue: string,
      oldValue: string
    ) => {
      if (!params.data?.body?.wbsItem) return
      if (!params.data.body.wbsItem?.scheduledDate) {
        params.data.body.wbsItem.scheduledDate = {}
      }
      params.data.body.wbsItem.scheduledDate.endDate = newValue
      if (!params.data?.editedData) {
        params.data.editedData = {}
      }
      params.data.editedData[FIELD_WBSITEM_SCHEDULED_END] = oldValue
    },
    []
  )
  const scheduledStartDateCol = useScheduledStartDate({
    field: FIELD_WBSITEM_SCHEDULED_START,
    getScheduledDate,
    setScheduledEndDate,
  })

  const setScheduledStartDate = useCallback(
    (
      params: ValueSetterParams<MyWbsItemRow>,
      newValue: string,
      oldValue: string
    ) => {
      if (!params.data?.body?.wbsItem) return
      if (!params.data?.body?.wbsItem?.scheduledDate) {
        params.data.body.wbsItem.scheduledDate = {}
      }
      params.data.body.wbsItem.scheduledDate.startDate = newValue
      if (!params.data?.editedData) {
        params.data.editedData = {}
      }
      params.data.editedData[FIELD_WBSITEM_SCHEDULED_START] = oldValue
    },
    []
  )
  const scheduledEndDateCol = useScheduledEndDate({
    field: FIELD_WBSITEM_SCHEDULED_END,
    getScheduledDate,
    setScheduledStartDate,
  })

  const setActualEndDate = useCallback(
    (
      params: ValueSetterParams<MyWbsItemRow>,
      newValue: string,
      oldValue: string
    ) => {
      if (!params.data?.body?.wbsItem) return
      if (!params.data?.body.wbsItem?.actualDate) {
        params.data.body.wbsItem.actualDate = {}
      }
      params.data.body.wbsItem.actualDate.endDate = newValue
      if (!params.data?.editedData) {
        params.data.editedData = {}
      }
      params.data.editedData[FIELD_WBSITEM_ACTUALDATE_END] = oldValue
    },
    []
  )
  const actualStartDateCol = useActualStartDate({
    field: FIELD_WBSITEM_ACTUALDATE_START,
    getWbsItemStatus,
    getScheduledDate,
    getActualDate,
    setActualEndDate,
  })

  const setActualStartDate = useCallback(
    (
      params: ValueSetterParams<MyWbsItemRow>,
      newValue: string,
      oldValue: string
    ) => {
      if (!params.data?.body?.wbsItem) return
      if (!params.data?.body?.wbsItem?.actualDate) {
        params.data.body.wbsItem.actualDate = {}
      }
      params.data.body.wbsItem.actualDate.startDate = newValue
      if (!params.data?.editedData) {
        params.data.editedData = {}
      }
      params.data.editedData[FIELD_WBSITEM_ACTUALDATE_START] = oldValue
    },
    []
  )
  const actualEndDateCol = useActualEndDate({
    field: FIELD_WBSITEM_ACTUALDATE_END,
    getWbsItemStatus,
    getScheduledDate,
    getActualDate,
    setActualStartDate,
  })

  const featureFlag = useTmpFeatureFlag()

  const columns = useMemo(
    () => [
      {
        externalId: 'uuid',
        field: 'uuid',
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        externalId: 'rowNumber',
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        pinned: true,
        resizable: false,
        cellRenderer: SequenceNoCellRenderer,
        cellRendererParams: params => {
          if (params.data?.isTotalRow) {
            return {
              value: intl.formatMessage({ id: 'total' }),
            }
          }
          return {
            value: params.node.rowIndex + 1,
          }
        },
      },
      {
        externalId: 'myWbsItems.information',
        headerName: intl.formatMessage({ id: 'myWbsItems.information' }),
        children: [
          {
            externalId: 'myWbsItems.wbsItem.code',
            field: 'body.wbsItem.code',
            headerName: intl.formatMessage({ id: 'myWbsItems.code' }),
            hide: true,
            pinned: true,
            lockPosition: true,
            width: 90,
          },
          {
            externalId: 'myWbsItems.wbsItem.type',
            field: 'body.wbsItem.type',
            headerName: intl.formatMessage({
              id: 'myWbsItems.type',
            }),
            hide: true,
            pinned: true,
            lockPosition: true,
            width: 100,
            valueGetter: (params: ValueGetterParams) =>
              params.data?.body?.wbsItem.baseWbsItemType,
            valueFormatter: params =>
              params.data?.body?.wbsItem?.baseWbsItemType?.getNameWithSuffix(),
            filter: ClientSideSelectFilter,
            filterParams: {
              getValue: option => (option && option.name ? option.name : ''),
              getLabel: option => option.name,
            },
            comparator: (valueA, valueB) => {
              if (valueA.name === valueB.name) return 0
              return valueA.name > valueB.name ? 1 : -1
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.status',
            field: 'body.wbsItem.status',
            headerName: intl.formatMessage({ id: 'myWbsItems.status' }),
            pinned: true,
            lockPosition: 'left',
            width: 90,
            editable: editableExceptSummaryRow,
            type: [ColumnType.customEnum],
            cellRenderer: ProjectPlanStatusCellRenderer,
            cellEditor: CustomEnumCellEditor,
            cellStyle: (params: CellClassParams<MyWbsItemRow>) => {
              const style = {}
              const wbsItem = params.data?.body?.wbsItem
              if (!wbsItem) return { ...style, backgroundColor: '#F7F7F7' }
              if (wbsItem.wbsItemType.isTask()) {
                return {
                  ...style,
                  backgroundColor: `${getWbsItemStatusColorCode(
                    wbsItem.status!
                  )}`,
                }
              }
              return {
                ...style,
                background: getWbsItemTreeStatusColorCode(wbsItem.status!),
              }
            },
            valueSetter: (params: ValueSetterParams<MyWbsItemRow>) => {
              // Update substatus
              const { combinedValuePath } = params.colDef.cellEditorParams
              const field = params.colDef.field || params.colDef.colId
              if (!field) return false
              objects.setValue(params.data, field, params.newValue)
              const substatusOptions = filterValuesByCombination(
                params.context['substatus'],
                code =>
                  objects.getValue(params.data, combinedValuePath?.(code)),
                true
              )
              objects.setValue(
                params.data,
                'body.wbsItem.substatus',
                substatusOptions?.[0]?.value
              )
              params.data.edited = true
              if (!params.data?.editedData) {
                params.data.editedData = { [field]: params.oldValue }
              } else if (!params.data?.editedData.hasOwnProperty(field)) {
                params.data.editedData[field] = params.oldValue
              }
              store.dispatch(requireSave())
              return true
            },
            cellEditorParams: {
              customEnumCode: 'status',
              combinedValuePath: statusCombinedValuePath,
            },
            filter: CustomEnumFilter,
            floatingFilter: true,
            filterParams: {
              customEnumCode: 'status',
              getValue: option => option.value,
              getLabel: option => getLabel(option.nameI18n),
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.substatus',
            field: 'body.wbsItem.substatus',
            headerName: intl.formatMessage({ id: 'myWbsItems.substatus' }),
            hide: true,
            pinned: true,
            lockPosition: 'left',
            width: 90,
            editable: editableExceptSummaryRow,
            type: [ColumnType.customEnum],
            cellEditor: MyWbsItemsCustomEnumCellEditor,
            cellEditorParams: {
              customEnumCode: 'substatus',
              combinedValuePath: statusCombinedValuePath,
              getOptions: getSubStatusOptions,
            },
            valueSetter: customEnumValueSetter,
            cellRenderer: MyWbsItemsCustomEnumCellRenderer,
            cellRendererParams: {
              customEnumCode: 'substatus',
            },
            filter: CustomEnumFilter,
            floatingFilter: true,
            filterParams: {
              customEnumCode: 'substatus',
              getValue: option => option.value,
              getLabel: option => getLabel(option.nameI18n),
            },
          },
          {
            externalId: 'myWbsItems.action',
            field: 'action',
            headerName: 'Action',
            pinned: true,
            sortable: false,
            lockPosition: 'left',
            width: 80,
            cellRendererSelector: (params: ValueGetterParams<MyWbsItemRow>) => {
              return !!params.data?.isTotalRow
                ? { component: DefaultCellRenderer }
                : { component: ProjectPlanActionCellRenderer }
            },
            cellRendererParams: {
              getCommentSummary: data => data?.body?.commentSummary,
              getWbsItem: data => data?.body?.wbsItem,
              openComment: true,
            },
            valueGetter: (params: ValueGetterParams) =>
              params.data?.body?.commentSummary?.hasComment ||
              params.data?.body?.wbsItem?.uuid, // This valueGetter is to notify the action cell of the necessary that it needs to be refreshed.
            filter: DetailCellFilter,
            floatingFilter: true,
          },
          {
            externalId: 'myWbsItems.deliverableAttachmentSummary',
            field: 'body.deliverableAttachmentSummary',
            headerName: intl.formatMessage({ id: 'myWbsItems.attachments' }),
            pinned: true,
            sortable: false,
            lockPosition: 'left',
            width: 50,
            hide: true,
            cellRenderer: AttachmentCellRenderer,
            filter: AttachmentCellFilter,
            cellRendererParams: {
              getAttachmentSummary: (data: MyWbsItemRow) =>
                data?.body?.deliverableAttachmentSummary,
            },
          },
          {
            externalId: 'myWbsItems.project',
            field: 'body.project',
            headerName: intl.formatMessage({ id: 'myWbsItems.project' }),
            hide: true,
            pinned: true,
            lockPosition: true,
            width: 90,
            type: [ColumnType.autocomplete],
            cellRenderer: IconCellRenderer,
            cellRendererParams: {
              labelField: 'body.project.displayName',
              iconUrlField: 'body.project.iconUrl',
            },
            filter: ClientSideSelectFilter,
            filterParams: {
              getValue: option => (option && option.uuid ? option.uuid : ''),
              getLabel: option => option.displayName,
            },
            comparator: (valueA, valueB) => {
              if (valueA.displayName === valueB.displayName) return 0
              return valueA.displayName > valueB.displayName ? 1 : -1
            },
          },
          {
            externalId: 'myWbsItems.path',
            field: 'body.path',
            headerName: intl.formatMessage({ id: 'myWbsItems.path' }),
            hide: true,
            pinned: true,
            lockPosition: true,
            width: 90,
          },
          {
            externalId: 'myWbsItems.parentWbsItem.displayName',
            field: 'body.parentWbsItem.displayName',
            headerName: intl.formatMessage({ id: 'myWbsItems.parentWbs' }),
            hide: true,
            pinned: true,
            lockPosition: true,
            width: 90,
          },
          {
            externalId: 'myWbsItems.wbsItem.displayName',
            ...displayNameCol,
            width: 400,
            editable: editableExceptSummaryRow,
          },
          {
            externalId: 'myWbsItems.wbsItem.priority',
            field: 'body.wbsItem.priority',
            headerName: intl.formatMessage({ id: 'myWbsItems.priority' }),
            width: 90,
            hide: true,
            editable: editableExceptSummaryRow,
            type: [ColumnType.myWbsItemCustomEnum],
            cellEditor: MyWbsItemsCustomEnumCellEditor,
            cellEditorParams: {
              customEnumCode: 'priority',
              getOptionKey: (data: MyWbsItemRow) =>
                data.body?.wbsItem.projectUuid,
            },
            cellRenderer: MyWbsItemsCustomEnumCellRenderer,
            cellRendererParams: {
              customEnumCode: 'priority',
              getOptionKey: (data: MyWbsItemRow) =>
                data.body?.wbsItem.projectUuid,
            },
            filter: CustomEnumFilter,
            floatingFilter: true,
            filterParams: {
              customEnumCode: 'priority',
              getValue: option => option.value,
              getLabel: option => getLabel(option.nameI18n),
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.difficulty',
            field: 'body.wbsItem.difficulty',
            headerName: intl.formatMessage({ id: 'myWbsItems.difficulty' }),
            hide: true,
            width: 90,
            editable: editableExceptSummaryRow,
            type: [ColumnType.myWbsItemCustomEnum],
            cellEditor: MyWbsItemsCustomEnumCellEditor,
            cellEditorParams: {
              customEnumCode: 'difficulty',
              getOptionKey: (data: MyWbsItemRow) =>
                data.body?.wbsItem.projectUuid,
            },
            cellRenderer: MyWbsItemsCustomEnumCellRenderer,
            cellRendererParams: {
              customEnumCode: 'difficulty',
              getOptionKey: (data: MyWbsItemRow) =>
                data.body?.wbsItem.projectUuid,
            },
            filter: CustomEnumFilter,
            floatingFilter: true,
            filterParams: {
              customEnumCode: 'difficulty',
              getValue: option => option.value,
              getLabel: option => getLabel(option.nameI18n),
            },
          },
          {
            externalId: 'myWbsItems.tags',
            ...tagsCol,
            editable: editableExceptSummaryRow,
          },
        ],
      },
      {
        externalId: 'myWbsItems.description',
        headerName: intl.formatMessage({
          id: 'myWbsItems.description.comment',
        }),
        children: [
          {
            externalId: 'myWbsItems.wbsItem.description',
            ...descriptionCol,
            width: 90,
            editable: editableExceptSummaryRow,
          },
          {
            externalId: 'myWbsItems.commentSummary.latestComment',
            ...commentCol,
            width: 90,
          },
        ],
      },
      {
        externalId: 'myWbsItems.assignment',
        headerName: intl.formatMessage({ id: 'myWbsItems.assignment' }),
        children: [
          {
            externalId: 'myWbsItems.wbsItem.team',
            field: 'body.wbsItem.team',
            headerName: intl.formatMessage({ id: 'myWbsItems.team' }),
            width: 65,
            editable: editableExceptSummaryRow,
            pinned: true,
            type: [ColumnType.autocomplete],
            cellRenderer: EntitySearchCellRenderer,
            cellEditor: EntitySearchCellEditor,
            cellEditorParams: {
              entity: 'team',
              optionsFilter: (option: any, data: any): boolean =>
                option.projectUuid === data?.body?.wbsItem?.projectUuid,
            },
            suppressKeyboardEvent: params =>
              (!params.editing &&
                ['Delete', 'Backspace'].includes(params.event.key)) ||
              (params.editing && params.event.key === 'Enter'),
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              valueGetter: ({ node, context }) => {
                const val = objects.getValue(node.data, 'body.wbsItem.team')
                return typeof val === 'string' ? undefined : val // Exclude unselected data
              },
              getValue: option => (option && option.uuid ? option.uuid : ''),
              getLabel: option => option.displayName,
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.accountable',
            ...accountableCol,
            width: 65,
            pinned: true,
            editable: editableExceptSummaryRow,
          },
          {
            externalId: 'myWbsItems.wbsItem.responsible',
            ...responsibleCol,
            width: 65,
            pinned: true,
            editable: editableExceptSummaryRow,
          },
          {
            externalId: 'myWbsItems.wbsItem.assignee',
            ...assigneeCol,
            width: 65,
            hide: true,
            editable: editableExceptSummaryRow,
          },
          {
            externalId: 'myWbsItems.wbsItem.watchers',
            ...watchersCol,
            editable: editableExceptSummaryRow,
          },
        ],
      },
      {
        externalId: 'myWbsItems.estimated',
        headerName: intl.formatMessage({ id: 'myWbsItems.estimated' }),
        children: [],
      },
      ...(featureFlag
        ? [
            {
              externalId: 'myWbsItems.timer',
              headerName: intl.formatMessage({ id: 'myWbsItems.timer' }),
              children: [],
            },
          ]
        : []),
      {
        externalId: 'myWbsItems.productivity',
        headerName: intl.formatMessage({ id: 'myWbsItems.productivity' }),
        children: [
          {
            externalId: 'myWbsItems.cumulation.actualHour',
            field: 'body.cumulation.actualHour',
            headerName: intl.formatMessage({
              id: 'myWbsItems.actualHour',
            }),
            width: 90,
            hide: true,
            valueGetter: (params: ValueGetterParams<MyWbsItemRow>) => {
              if (params.data?.isTotalRow) {
                let sum: number = 0
                const rate =
                  params.context.workloadUnitState?.hoursPerSelectedUnit || 1
                params.api.forEachNode(node => {
                  const value = node.data?.body?.cumulation?.actualHour
                  if (value !== undefined) {
                    const roundedValue = (value / rate).toFixed(2)
                    sum += Number(roundedValue)
                  }
                })
                return sum
              }
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (!w || !c) return
              return w.wbsItemType?.isTask() ? c.actualHour : c.sumActualHour
            },
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.value === undefined) return ''
              if (params.data?.isTotalRow) {
                return params.value.toFixed(2)
              }
              return (
                Number(params.value) /
                (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
              ).toFixed(2)
            },
            cellStyle: (params: CellClassParams<MyWbsItemRow>) => {
              const numberStyle = { justifyContent: 'flex-end' }
              const w = params.data?.body?.wbsItem
              const c = params.data?.body?.cumulation
              if (params.data?.isTotalRow) {
                return {
                  ...numberStyle,
                  fontWeight: 'bold',
                }
              }
              if (!w || !c) return numberStyle
              if (w.wbsItemType?.isTask() && 0 < c.actualHour) {
                return {
                  ...numberStyle,
                  cursor: 'pointer',
                  color: 'blue',
                }
              }
              return numberStyle
            },
            filter: ClientSideNumberFilter,
            floatingFilter: true,
          },
        ],
      },
      {
        externalId: 'myWbsItems.term',
        headerName: intl.formatMessage({ id: 'myWbsItems.term' }),
        children: [
          {
            externalId: 'myWbsItems.wbsItem.sprint',
            ...sprintCol,
            width: 90,
            editable: params => {
              const type = params.data?.body?.wbsItem?.wbsItemType
              return type?.isTask() || type?.isDeliverable()
            },
          },
          {
            externalId: 'myWbsItems.wbsItem.scheduledDate.startDate',
            ...scheduledStartDateCol,
            width: 90,
            pinned: true,
            filter: DateCell.cellFilter,
            editable: editableExceptSummaryRow,
          },
          {
            externalId: 'myWbsItems.wbsItem.scheduledDate.endDate',
            ...scheduledEndDateCol,
            width: 90,
            pinned: true,
            editable: editableExceptSummaryRow,
          },
          {
            externalId: 'myWbsItems.wbsItem.actualDate.startDate',
            ...actualStartDateCol,
            width: 90,
            hide: true,
            editable: editableExceptSummaryRow,
          },
          {
            externalId: 'myWbsItems.wbsItem.actualDate.endDate',
            ...actualEndDateCol,
            width: 90,
            hide: true,
            editable: editableExceptSummaryRow,
          },
        ],
      },
      {
        externalId: 'myWbsItems.changeLog',
        headerName: intl.formatMessage({ id: 'changeLog' }),
        children: [
          {
            externalId: 'myWbsItems.wbsItem.revision',
            field: 'body.wbsItem.revision',
            headerName: intl.formatMessage({ id: 'revision' }),
            hide: true,
            width: 90,
          },
          {
            externalId: 'myWbsItems.wbsItem.createdBy',
            field: 'body.wbsItem.createdBy',
            headerName: intl.formatMessage({ id: 'createdBy' }),
            hide: true,
            width: 150,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.body?.wbsItem?.createdBy?.name
            },
            cellRenderer: IconCellRenderer,
            cellRendererParams: {
              labelField: 'body.wbsItem.createdBy.name',
              iconUrlField: 'body.wbsItem.createdBy.iconUrl',
            },
            floatingFilter: true,
            filter: 'agSetColumnFilter',
          },
          {
            externalId: 'myWbsItems.wbsItem.createdAt',
            field: 'body.wbsItem.createdAt',
            headerName: intl.formatMessage({ id: 'createdAt' }),
            hide: true,
            width: 120,
            type: [ColumnType.dateTime],
            floatingFilter: true,
          },
          {
            externalId: 'myWbsItems.wbsItem.updatedBy',
            field: 'body.wbsItem.updatedBy',
            headerName: intl.formatMessage({ id: 'updatedBy' }),
            hide: true,
            width: 150,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.body?.wbsItem?.updatedBy?.name
            },
            cellRenderer: IconCellRenderer,
            cellRendererParams: {
              labelField: 'body.wbsItem.updatedBy.name',
              iconUrlField: 'body.wbsItem.updatedBy.iconUrl',
            },
            floatingFilter: true,
            filter: 'agSetColumnFilter',
          },
          {
            externalId: 'myWbsItems.wbsItem.updatedAt',
            field: 'body.wbsItem.updatedAt',
            headerName: intl.formatMessage({ id: 'updatedAt' }),
            hide: true,
            width: 120,
            type: [ColumnType.dateTime],
            floatingFilter: true,
          },
        ],
      },
    ],
    [
      displayNameCol,
      descriptionCol,
      tagsCol,
      commentCol,
      watchersCol,
      accountableCol,
      responsibleCol,
      assigneeCol,
      sprintCol,
      scheduledStartDateCol,
      scheduledEndDateCol,
      actualStartDateCol,
      actualEndDateCol,
      featureFlag,
    ]
  )
  return columns
}

export const useTaskEstimatedColumnDef = () => {
  const getWbsItemType = useCallback(
    (row: MyWbsItemRow | undefined) => row?.body?.wbsItem?.wbsItemType,
    []
  )
  const estimatedWorkLoadTaskCol = useEstimatedWorkLoadTask({
    field: 'body.wbsItem.estimatedHour',
    getWbsItemType,
  })
  const columns = useMemo(
    () =>
      ({
        externalId: 'myWbsItems.wbsItem.estimatedWorkload.task',
        ...estimatedWorkLoadTaskCol,
        colId: 'body.wbsItem.estimatedHour.task',
        hide: true,
        valueSetter: (params: ValueSetterParams<MyWbsItemRow>) => {
          if (
            params.oldValue === params.newValue ||
            !params.data.body?.wbsItem
          ) {
            return false
          }
          const value = toNumber(params.newValue)
          params.data.body.wbsItem.estimatedHour = value
            ? value *
              (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
            : 0
          params.data.edited = true
          const field = params.colDef.field ?? params.colDef.colId ?? ''
          if (!params.data.editedData) {
            params.data.editedData = { [field]: params.oldValue }
          } else if (!params.data.editedData.hasOwnProperty(field)) {
            params.data.editedData[field] = params.oldValue
          }
          store.dispatch(requireSave())
          return true
        },
        valueGetter: (params: ValueGetterParams<MyWbsItemRow>) => {
          const getEstimatedWorkloadValue = (
            params: ValueGetterParams<MyWbsItemRow>
          ) => {
            const w = params.data?.body?.wbsItem
            const c = params.data?.body?.cumulation
            if (!w) return undefined
            const hour = w.estimatedHour ?? 0
            const rate =
              params.context.workloadUnitState?.hoursPerSelectedUnit || 1
            if (w.wbsItemType.isTask()) return hour / rate
            if (!c) return undefined
            return (
              (c.sumTaskEstimatedHour - c.sumTaskEstimatedHourDiscard) / rate
            )
          }
          if (params.data?.isTotalRow) {
            let sum: number = 0
            params.api.forEachNode(node => {
              const data = node.data
              const value = getEstimatedWorkloadValue({ ...params, data })
              if (value !== undefined) {
                sum += Number(value.toFixed(2))
              }
            })
            return sum
          }
          return getEstimatedWorkloadValue(params)
        },
        valueFormatter: params =>
          Number.isFinite(params.value)
            ? parseFloat(params.value).toFixed(2)
            : '',
        cellStyle: (params: CellClassParams<MyWbsItemRow>) => {
          if (params.data?.isTotalRow) {
            return {
              fontWeight: 'bold',
            }
          }
        },
      } as ColDef),
    [estimatedWorkLoadTaskCol]
  )
  return columns
}

export const useDeliverableEstimatedColumnDef = () => {
  const getWbsItemType = useCallback(
    (row: MyWbsItemRow | undefined) => row?.body?.wbsItem?.wbsItemType,
    []
  )
  const estimatedWorkLoadDeliverableCol = useEstimatedWorkLoadDeliverable({
    field: 'body.wbsItem.estimatedHour',
    getWbsItemType,
  })
  const columns = useMemo(
    () =>
      ({
        externalId: 'myWbsItems.wbsItem.estimatedWorkload.deliverable',
        ...estimatedWorkLoadDeliverableCol,
        colId: 'body.wbsItem.estimatedHour.deliverable',
        hide: true,
        valueSetter: (params: ValueSetterParams<MyWbsItemRow>) => {
          if (
            params.oldValue === params.newValue ||
            !params.data.body?.wbsItem
          ) {
            return false
          }
          const value = toNumber(params.newValue)
          params.data.body.wbsItem.estimatedHour = value
            ? value *
              (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
            : 0
          params.data.edited = true
          const field = params.colDef.field ?? params.colDef.colId ?? ''
          if (!params.data.editedData) {
            params.data.editedData = { [field]: params.oldValue }
          } else if (!params.data.editedData.hasOwnProperty(field)) {
            params.data.editedData[field] = params.oldValue
          }
          store.dispatch(requireSave())
          return true
        },
        valueGetter: (params: ValueGetterParams) => {
          const w = params.data?.body?.wbsItem
          const c = params.data?.body?.cumulation
          if (!w || w.wbsItemType?.isTask()) return undefined
          const hour = w.estimatedHour ?? 0
          const rate =
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
          if (
            w.wbsItemType.isWorkgroup() ||
            w.wbsItemType.isProcess() ||
            w.wbsItemType.isDeliverableList()
          ) {
            if (!c) return undefined
            return (
              (c.sumDeliverableEstimatedHour -
                c.sumDeliverableEstimatedHourDiscard) /
              rate
            )
          }
          return hour / rate
        },
        valueFormatter: params =>
          Number.isFinite(params.value)
            ? parseFloat(params.value).toFixed(2)
            : '',
      } as ColDef),
    [estimatedWorkLoadDeliverableCol]
  )
  return columns
}

export const taskTimerColumnDef = [
  {
    field: 'body.timerStatus',
    headerName: intl.formatMessage({ id: 'myWbsItems.measure' }),
    hide: true,
    width: 100,
    cellRendererSelector: (params: ValueGetterParams<MyWbsItemRow>) => {
      return !!params.data?.isTotalRow
        ? { component: DefaultCellRenderer }
        : { component: ButtonCell }
    },
    filter: false,
    floatingFilter: false,
    sortable: false,
    resizable: false,
  },
  {
    field: 'body.timer.actualHour.today',
    headerName: intl.formatMessage({
      id: 'myWbsItems.actualHour.today',
    }),
    width: 110,
    hide: true,
    resizable: false,
    valueGetter: (params: ValueGetterParams<MyWbsItemRow>) => {
      const today = format(new Date(), 'yyyy-MM-dd')
      if (params.data?.isTotalRow) {
        let sum: number = 0
        params.api.forEachNode(node => {
          const sumByTask =
            node.data?.body?.timer?.dailySummaries[today]?.elapsedTimeSum || 0
          sum += sumByTask
        })
        return sum
      }
      return (
        params.data?.body?.timer?.dailySummaries[today]?.elapsedTimeSum || 0
      )
    },
    valueFormatter: (params: ValueFormatterParams) => {
      const value = params.value
      if (value === undefined) return ''
      return convertDecimalHourToHM(value)
    },
    cellStyle: (params: CellClassParams<MyWbsItemRow>) => {
      const numberStyle = { justifyContent: 'flex-end', display: 'flex' }
      const today = format(new Date(), 'yyyy-MM-dd')
      const todaysHistory =
        params.data?.body?.timer?.dailySummaries[today]?.histories || []
      if (todaysHistory.length > 0) {
        return {
          ...numberStyle,
          cursor: 'pointer',
          color: 'blue',
        }
      }
      if (params.data?.isTotalRow) {
        return {
          ...numberStyle,
          fontWeight: 'bold',
        }
      }
      return numberStyle
    },
  },
] as ColDef[]

export default useDefaultColumns

import {
  CreateRefinementRequest,
  refinementService,
  UpdateRefinementDeltaRequest,
} from './../../domain/entity/ticket/RefinementEntity'
import { useCallback, useMemo } from 'react'
import { RefinementRepository } from '../../applications/ports/refinementRepository'
import api from '../../lib/commons/api'
import {
  ReferencedEntity,
  ReferencedEntityWithIcon,
} from '../../domain/value-object/ReferencedEntity'

export const useRefinementRepository = (): RefinementRepository => {
  const fetch = useCallback(async (uuid: string) => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/projects/refinements/detail',
      { wbsItemUuid: uuid },
      true
    )
    return refinementService.fromApiResponseToEntity(response.json)
  }, [])
  const fetchAsReferencedEntity = useCallback(
    async (uuid: string): Promise<ReferencedEntityWithIcon | undefined> => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/refinements/detail',
        { uuid },
        true
      )
      const refinement = response.json
      return refinement
        ? {
            uuid: refinement.uuid,
            name: refinement.ticket.wbsItem.displayName,
            iconUrl: refinement.ticket.wbsItem.typeDto.iconUrl,
          }
        : undefined
    },
    []
  )
  const create = useCallback(async (request: CreateRefinementRequest) => {
    await api.functional.request(
      'POST',
      '/api/v1/projects/refinements',
      request,
      true
    )
  }, [])
  const updateDelta = useCallback(
    async (request: UpdateRefinementDeltaRequest) => {
      const response = await api.functional.request(
        'PUT',
        '/api/v1/projects/refinements/delta',
        request,
        true
      )
      return {}
    },
    []
  )
  const repository = useMemo(
    () => ({ fetch, fetchAsReferencedEntity, create, updateDelta }),
    [fetch, fetchAsReferencedEntity, create, updateDelta]
  )
  return repository
}

import { ICellRendererParams } from 'ag-grid-community'
import { ProjectPlanCumulation } from '../../../../../lib/functions/projectPlan'
import { RootDiv } from '../../../commons/AgGrid/components/cell/CellRendererRoot'
import {
  FractionLabel,
  IconArea,
  PercentageLabel,
  StatusNameLabel,
} from '../../../commons/AgGrid/components/cell/custom/wbsItemStatus/cellRenderer'
import { FloatingEditableIcon } from '../../../commons/AgGrid/components/cell/common/text'
import { NewWbsItemRow } from '../../../../pages/ProjectPlanNew/projectPlanNew'
import { getWbsItemStatusLabel } from '../../../../../lib/functions/wbsItem'
import { ProgressIcon } from '../../../commons/AgGrid/components/cell/custom/wbsItemStatus/ProgressIcon'
import { DelayIcon } from '../../../commons/AgGrid/components/cell/custom/wbsItemStatus/delayIcon'
import { isProduction } from '../../../../../utils/urls'

export interface Params extends ICellRendererParams {
  hideChildrenCount?: boolean
}

export const ProjectPlanStatusCellRenderer = (params: Params) => {
  const wbsItem: NewWbsItemRow =
    params.data?.body?.wbsItem ?? params.data?.wbsItem
  const cumulation: ProjectPlanCumulation =
    params.data?.body?.cumulation ?? params.data?.cumulation
  if (!wbsItem || !params.colDef) {
    return <></>
  }
  if (!cumulation) {
    return (
      <RootDiv>
        <StatusNameLabel>
          {getWbsItemStatusLabel(wbsItem.status!)}
        </StatusNameLabel>
      </RootDiv>
    )
  }
  if (wbsItem.wbsItemType.isTask()) {
    const total =
      cumulation.countTaskOfDirectChildren -
      cumulation.countTaskDiscardOfDirectChildren
    const done = cumulation.countTaskDoneOfDirectChildren
    return (
      <RootDiv>
        <IconArea>
          {isProduction ? (
            <DelayIcon
              wbsItem={{
                ...wbsItem,
                createdAt: undefined,
                updatedAt: undefined,
              }}
            />
          ) : (
            <ProgressIcon
              wbsItem={{
                ...wbsItem,
                createdAt: undefined,
                updatedAt: undefined,
              }}
            />
          )}
        </IconArea>
        {wbsItem.wbsItemType.isTask() && (
          <StatusNameLabel>
            {getWbsItemStatusLabel(wbsItem.status!)}
          </StatusNameLabel>
        )}
        <FractionLabel>
          {0 < total && !params.hideChildrenCount
            ? `(${done} / ${total})`
            : undefined}
        </FractionLabel>
        <FloatingEditableIcon {...params} />
      </RootDiv>
    )
  }

  const total = wbsItem.wbsItemType.isDeliverable()
    ? cumulation.countTaskOfDirectChildren -
      cumulation.countTaskDiscardOfDirectChildren
    : cumulation.countDeliverable - cumulation.countStatusDeliverableDiscard
  const done = wbsItem.wbsItemType.isDeliverable()
    ? cumulation.countTaskDoneOfDirectChildren
    : cumulation.countStatusDeliverableDone
  return (
    <RootDiv>
      <IconArea>
        {isProduction ? (
          <DelayIcon
            wbsItem={{ ...wbsItem, createdAt: undefined, updatedAt: undefined }}
          />
        ) : (
          <ProgressIcon
            wbsItem={{ ...wbsItem, createdAt: undefined, updatedAt: undefined }}
          />
        )}
      </IconArea>
      <PercentageLabel total={total}>
        {!total ? '0.0%' : `${((done / total) * 100).toFixed(1)}%`}
      </PercentageLabel>
      <FractionLabel>{`(${done} / ${total})`}</FractionLabel>
      <FloatingEditableIcon {...params} />
    </RootDiv>
  )
}

import {
  CellClassParams,
  CellStyle,
  CellStyleFunc,
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import moment from 'moment'
import {
  ColumnType,
  CURRENT_MONTH_BACKGROUND_COLOR,
} from '../../../containers/commons/AgGrid'
import DateVO from '../../../../vo/DateVO'
import { intl } from '../../../../i18n'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import {
  DateTerm,
  isSaturday,
  DISPLAY_DATE_FORMAT_WITH_DAY,
} from '../../../../utils/date'
import { ActualWork, MyWbsItemRow } from '../myWbsItems'
import {
  getOrganizationWorkingDayCalendar,
  OrganizationWorkingDayCalendar,
} from '../../../../lib/functions/organizationWorkingDayCalendar'
import useDefaultColumns, {
  useTaskEstimatedColumnDef,
  taskTimerColumnDef,
  editableExceptSummaryRow,
} from './defaultColumns'
import defaultGridOptions from './defaultGridOptions'
import { useMemo } from 'react'
import { DecimalAndSexagesimalScale } from '../../../../lib/functions/decimalAndSexagesimalScale'
import { convertDecimalHourToHM } from '../../../../utils/time'
import { ActualWorkTimeCellEditor } from '../../../containers/BulkSheetView/components/cellEditor/ActualWorkTimeCellEditor'

export type RefreshDynamicColumnDefProps = {
  api: GridApi | null | undefined
  dateTerm: DateTerm
}

const ACTUAL_WORKS_FIELD_PREFIX = 'body.actualWorks.'

export const refreshDynamicColumnDef = async ({
  api,
  dateTerm,
}: RefreshDynamicColumnDefProps) => {
  if (!api) return
  const response = await getOrganizationWorkingDayCalendar({
    startDate: new Date(dateTerm.startDate!),
    endDate: new Date(dateTerm.endDate!),
  })
  const workingDayCalendar = new OrganizationWorkingDayCalendar(response.json)
  const dateList = getDateTerm(dateTerm)

  const columnDefs = api?.getColumnDefs()
  const newColDefs = columnDefs?.filter(def => {
    const field = (def as ColDef)?.field
    const groupId = (def as ColGroupDef)?.groupId
    return (
      (!!field && !field.startsWith(ACTUAL_WORKS_FIELD_PREFIX)) ||
      (!!groupId && !groupId.startsWith(ACTUAL_WORKS_FIELD_PREFIX))
    )
  })
  const workDateColumnDefs: ColDef[] = []

  dateList.forEach(date => {
    const dateVO = new DateVO(date)
    const value = dateVO.format('YYYY-MM-DD')

    let headerClass = ''
    if (workingDayCalendar.isHoliday(date)) {
      headerClass = 'sevend-ag-grid-date-header-holiday'
    }
    if (isSaturday(date)) {
      headerClass = 'sevend-ag-grid-date-header-saturday'
    }
    const col: ColDef = {
      field: value,
      headerName: moment(date).format(DISPLAY_DATE_FORMAT_WITH_DAY),
      headerClass: headerClass,
      type: ColumnType.number,
      hide: false,
      width: 80,
      editable: editableExceptSummaryRow,
      cellEditor: ActualWorkTimeCellEditor,
      cellRendererParams: params => {
        const field = params.colDef?.field || ''
        const hour = (params.data.body?.actualWorks ?? []).find(
          v => v.actualDate === field
        )?.hour
        return { value: hour }
      },
      cellClass: 'ag-numeric-cell',
      cellStyle: (params: CellClassParams) => {
        let style: CellStyle | CellStyleFunc = {
          justifyContent: 'flex-end',
        }
        if (params.colDef.field === moment(new Date()).format('YYYY-MM-DD')) {
          style.color = 'blue'
          style.backgroundColor = CURRENT_MONTH_BACKGROUND_COLOR
        }
        if (params.data?.isTotalRow) {
          style.fontWeight = 'bold'
        }
        return style
      },
      valueSetter: (params: ValueSetterParams) => {
        if (
          params.oldValue === params.newValue ||
          !params.node ||
          params.node.group
        ) {
          return false
        }
        store.dispatch(requireSave())
        const newValue = Number(Number(params.newValue || 0))
        const field = params.column.getColDef().field
        params.data[field!] = newValue
        const index = params.data.body.actualWorks.findIndex(
          (v: ActualWork) => v.actualDate === field
        )
        if (0 <= index) {
          params.data.body.actualWorks[index] = {
            ...params.data.body.actualWorks[index],
            hour: newValue,
            isEdited: true,
          }
        } else {
          params.data.body.actualWorks.push({
            actualDate: field,
            hour: newValue,
            isEdited: true,
          })
        }
        if (!params.data?.editedData) {
          params.data.editedData = { [field!]: params.oldValue }
        } else if (!params.data.editedData.hasOwnProperty(field)) {
          params.data.editedData[field!] = params.oldValue
        }
        params.data.edited = true
        return true
      },
      valueGetter: (params: ValueGetterParams) => {
        const field = params.colDef?.field || ''
        const data: MyWbsItemRow = params.data
        function getActualWorkHour(data: any, field: any): number | undefined {
          return (data.body?.actualWorks ?? []).find(
            v => v.actualDate === field
          )?.hour
        }
        let sum: number = 0
        if (params.data?.isTotalRow) {
          params.api.forEachNode(node => {
            if (node.data && !node.data.isTotalRow) {
              const hour = getActualWorkHour(node.data, field)
              if (hour !== undefined) {
                sum += hour
              }
            }
          })
          return sum
        }
        if (!field) return undefined
        return getActualWorkHour(data, field)
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (
          params.context.decimalScaleUnit ===
          DecimalAndSexagesimalScale.SEXAGESIMAL
        ) {
          const value = params.value
          if (value === undefined) return ''
          return convertDecimalHourToHM(value)
        }
        return params.value !== undefined ? params.value.toFixed(2) : ''
      },
    }
    workDateColumnDefs?.push(col)
  })
  const workDateColumnGroupDef: ColGroupDef = {
    groupId: ACTUAL_WORKS_FIELD_PREFIX + 'actualWorks',
    headerName: intl.formatMessage({
      id: 'myWbsItems.actualWork',
    }),
    children: workDateColumnDefs,
  }
  newColDefs?.push(workDateColumnGroupDef)
  api.setColumnDefs(newColDefs!)
}

const getDateTerm = (term: DateTerm): Array<string> => {
  if (!term.startDate || !term.endDate) {
    return []
  }
  const startDate = new DateVO(term.startDate)
  const endDate = new DateVO(term.endDate)
  const diff =
    moment(endDate.toDate()).diff(moment(startDate.toDate()), 'days') + 1
  return Array.from({ length: diff }).map((_, index) =>
    moment(startDate.toDate()).add(index, 'day').format('YYYY/MM/DD')
  )
}

const useTaskTabColumnDef = () => {
  const defaultColDef = useDefaultColumns()
  const taskEstimatedColumnDef = useTaskEstimatedColumnDef()
  const columnDefs = useMemo(() => {
    const estimatedGroup = defaultColDef.find(
      colDef => colDef.externalId === 'myWbsItems.estimated'
    ) as ColGroupDef | undefined
    if (estimatedGroup && estimatedGroup.children) {
      estimatedGroup.children.push(taskEstimatedColumnDef)
    }
    const timerGroup = defaultColDef.find(
      colDef => colDef.externalId === 'myWbsItems.timer'
    ) as ColGroupDef | undefined
    if (timerGroup && timerGroup.children) {
      timerGroup.children.push(...taskTimerColumnDef)
    }
    return defaultColDef
  }, [defaultColDef, taskEstimatedColumnDef])
  return columnDefs
}

export const useMyWbsItemTaskTabGridOptions = (): GridOptions => {
  const columnDefs = useTaskTabColumnDef()
  const gridOptions = useMemo(
    () => ({
      ...defaultGridOptions(),
      columnDefs,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return gridOptions
}

import { Map } from 'immutable'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../store'
import { CustomComponent, FunctionLayer } from '../../../../store/functionLayer'
import { useMemo } from 'react'

export const useFunctionLayerDialogHeaderComponents = (
  functionLayerDepth: number
) => {
  const functionLayers = useSelector<AllState, Map<number, FunctionLayer>>(
    state => state.functionLayer.layers
  )
  const functionLayer = useMemo(
    () => functionLayers.get(functionLayerDepth),
    [functionLayers, functionLayerDepth]
  )

  const functionLayerDialogHeaderComponents = useMemo(() => {
    if (!functionLayer || !functionLayer.customHeaderComponents) {
      return {
        leftHeaderComponents: [],
        rightHeaderComponents: [],
      }
    }
    const headerComponents = functionLayer.customHeaderComponents.toArray()
    const leftHeaderComponents: JSX.Element[] = []
    const rightHeaderComponents: JSX.Element[] = []
    headerComponents.forEach(headerComponent => {
      if ('left' === headerComponent.layout.position) {
        leftHeaderComponents.push(headerComponent.component)
      } else if ('right' === headerComponent.layout.position) {
        rightHeaderComponents.push(headerComponent.component)
      }
    })
    return {
      leftHeaderComponents,
      rightHeaderComponents,
    }
  }, [functionLayer])

  return functionLayerDialogHeaderComponents
}

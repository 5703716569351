import {
  ColDef,
  EditableCallbackParams,
  CellClassParams,
  ValueFormatterParams,
  ICellRendererParams,
} from 'ag-grid-community'
import {
  EntitySearchReferenceEntity,
  WbsItemAdditionalPropertyType,
  getEntitySearchReferenceEntityLabel,
  shouldHaveEntitySearchReferenceEntity,
} from '../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { entitySearchReferenceEntity } from '../../../../page-properties/bulksheet-properties/wbsItemAdditionalProperty'
import {
  isAdditionalPropertyRow,
  isGroupHeaderRow,
  AdditionalPropertyRow,
} from '../../rowModel/wbsItemAdditionalPropertyRow'
import { DISABLE_COLOR } from './style'
import { SelectCellEditor } from '../../../../containers/BulkSheetView/components/cellEditor'
import { ClientSideSelectFilter } from '../../../../containers/BulkSheetView/components/filter'
import { intl } from '../../../../../i18n'

export const entitySearchReferenceEntityColumnDef: ColDef = {
  ...entitySearchReferenceEntity,
  editable: (params: EditableCallbackParams) =>
    isAdditionalPropertyRow(params.node.data) &&
    shouldHaveEntitySearchReferenceEntity(params.node.data.propertyType),
  valueFormatter: (params: ValueFormatterParams) => {
    if (!params.node || isGroupHeaderRow(params.node.data)) {
      return ''
    }
    const additionalPropertyRow = params.node.data as AdditionalPropertyRow
    return additionalPropertyRow.entitySearchReferenceEntity
      ? getEntitySearchReferenceEntityLabel(
          additionalPropertyRow.entitySearchReferenceEntity
        )
      : ''
  },
  cellRendererParams: {
    validate: (
      value: EntitySearchReferenceEntity | undefined,
      params: ICellRendererParams
    ): string | undefined => {
      if (!isAdditionalPropertyRow(params.data)) {
        return undefined
      }
      const additionalPropertyRow = params.node.data as AdditionalPropertyRow
      if (
        shouldHaveEntitySearchReferenceEntity(
          additionalPropertyRow.propertyType
        ) &&
        !value
      ) {
        return intl.formatMessage({
          id: 'wbsItemAdditionalProperties.validation.entitySearchReferenceEntity.required',
        })
      }
      return undefined
    },
  },
  cellEditor: SelectCellEditor,
  cellEditorParams: {
    options: Object.values(EntitySearchReferenceEntity).map(value => ({
      label: getEntitySearchReferenceEntityLabel(value),
      value,
    })),
  },
  filter: ClientSideSelectFilter,
  floatingFilter: true,
  filterParams: {
    valueGetter: (params: { node; context }) => {
      if (!params.node || isGroupHeaderRow(params.node.data)) {
        return undefined
      }
      const additionalPropertyRow = params.node.data as AdditionalPropertyRow
      return additionalPropertyRow.entitySearchReferenceEntity
    },
    getValue: o => o,
    getLabel: o => o,
    filterChangedCallback: () => {
      return
    },
  },
  cellStyle: (params: CellClassParams) =>
    isGroupHeaderRow(params.node.data) ||
    !shouldHaveEntitySearchReferenceEntity(params.node.data.propertyType)
      ? {
          backgroundColor: DISABLE_COLOR,
        }
      : undefined,
}

import { ComponentProps, PropsWithChildren, ReactElement } from 'react'
import { Tooltip as MuiTooltip } from '@mui/material'
import { styled } from '@mui/system'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { colorPalette } from '../../../style/colorPallete'

type MuiTooltipProps = ComponentProps<typeof MuiTooltip>
const Tooltip = styled(({ className, ...props }: MuiTooltipProps) => (
  <MuiTooltip {...props} componentsProps={{ tooltip: { className } }} />
))({
  color: colorPalette.pink[5],
  backgroundColor: colorPalette.monotone[1],
})
const TooltipTitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const ErrorIcon = styled(ErrorOutlineOutlinedIcon)({
  fontSize: '11px',
  marginRight: '5px',
})

export const ErrorTooltip = ({
  errorMessage,
  children,
}: PropsWithChildren<{
  errorMessage?: string
}>) => {
  return (
    <Tooltip
      title={
        errorMessage && (
          <TooltipTitle>
            <ErrorIcon />
            {errorMessage}
          </TooltipTitle>
        )
      }
      placement="bottom-start"
      PopperProps={{
        style: {
          zIndex: 1000,
        },
      }}
    >
      {children as ReactElement}
    </Tooltip>
  )
}

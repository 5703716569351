import { useCallback, useEffect, useMemo, useState } from 'react'
import { Tab, Tabs } from '../../../../containers/SingleSheetV2/Tab'

export enum WbsItemLeftTab {
  BASIC_INFORMATION,
  DESCRIPTION,
  START_IF,
  COMPLETE_IF,
  SUB_TASK,
  ATTACHMENT,
}
export const WbsItemLeftTabs = ({
  isCreatingWbsItem,
  leftTab,
  onChangeLeftTab,
}: WbsItemLeftTabsProps) => {
  if (isCreatingWbsItem) {
    return (
      <Tabs value={leftTab} onChange={onChangeLeftTab}>
        <Tab label="基本情報" value={WbsItemLeftTab.BASIC_INFORMATION} />
      </Tabs>
    )
  }
  return (
    <Tabs value={leftTab} onChange={onChangeLeftTab}>
      <Tab label="基本情報" value={WbsItemLeftTab.BASIC_INFORMATION} />
      <Tab label="説明" value={WbsItemLeftTab.DESCRIPTION} />
      <Tab label="サブタスク" value={WbsItemLeftTab.SUB_TASK} />
      <Tab label="添付" value={WbsItemLeftTab.ATTACHMENT} />
    </Tabs>
  )
}
export const ProcessLeftTabs = ({
  isCreatingWbsItem,
  leftTab,
  onChangeLeftTab,
}: WbsItemLeftTabsProps) => {
  if (isCreatingWbsItem) {
    return (
      <Tabs value={leftTab} onChange={onChangeLeftTab}>
        <Tab label="基本情報" value={WbsItemLeftTab.BASIC_INFORMATION} />
      </Tabs>
    )
  }
  return (
    <Tabs value={leftTab} onChange={onChangeLeftTab}>
      <Tab label="基本情報" value={WbsItemLeftTab.BASIC_INFORMATION} />
      <Tab label="説明" value={WbsItemLeftTab.DESCRIPTION} />
      <Tab label="開始条件" value={WbsItemLeftTab.START_IF} />
      <Tab label="完了条件" value={WbsItemLeftTab.COMPLETE_IF} />
      <Tab label="サブタスク" value={WbsItemLeftTab.SUB_TASK} />
    </Tabs>
  )
}
export const DeliverableAndDeliverableListLeftTabs = ({
  isCreatingWbsItem,
  leftTab,
  onChangeLeftTab,
}: WbsItemLeftTabsProps) => {
  if (isCreatingWbsItem) {
    return (
      <Tabs value={leftTab} onChange={onChangeLeftTab}>
        <Tab label="基本情報" value={WbsItemLeftTab.BASIC_INFORMATION} />
      </Tabs>
    )
  }
  return (
    <Tabs value={leftTab} onChange={onChangeLeftTab}>
      <Tab label="基本情報" value={WbsItemLeftTab.BASIC_INFORMATION} />
      <Tab label="説明" value={WbsItemLeftTab.DESCRIPTION} />
      <Tab label="開始条件" value={WbsItemLeftTab.START_IF} />
      <Tab label="完了条件" value={WbsItemLeftTab.COMPLETE_IF} />
      <Tab label="サブタスク" value={WbsItemLeftTab.SUB_TASK} />
      <Tab label="添付" value={WbsItemLeftTab.ATTACHMENT} />
    </Tabs>
  )
}

export type WbsItemLeftTabsProps = {
  isCreatingWbsItem: boolean
  leftTab: WbsItemLeftTab
  onChangeLeftTab: (
    event: React.SyntheticEvent,
    newValue: WbsItemLeftTab
  ) => void
}
export const useWbsItemLeftTabs = (
  isCreatingWbsItem: boolean
): Omit<WbsItemLeftTabsProps, 'isCreatingWbsItem'> => {
  const [leftTab, setLeftTab] = useState<WbsItemLeftTab>(
    WbsItemLeftTab.BASIC_INFORMATION
  )
  const onChangeLeftTab = useCallback(
    (_: React.SyntheticEvent, newValue: WbsItemLeftTab) => {
      setLeftTab(newValue)
    },
    []
  )

  // Set default tab.
  useEffect(() => {
    if (isCreatingWbsItem) {
      setLeftTab(WbsItemLeftTab.BASIC_INFORMATION)
    } else {
      setLeftTab(WbsItemLeftTab.DESCRIPTION)
    }
  }, [isCreatingWbsItem])

  const state = useMemo(
    () => ({
      leftTab,
      onChangeLeftTab,
    }),
    [leftTab, onChangeLeftTab]
  )

  return state
}

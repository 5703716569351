import { useMemo } from 'react'
import { WbsItemAdditionalPropertyType } from '../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { useEntityExtension } from '../../../hooks/useEntityExtension'
import {
  WbsItemEntityExtension,
  WbsItemEntityExtensionGroupHeader,
  WbsItemEntityExtensionProperty,
  PropertiesGroupByParent,
} from '../model/entityExtension'
import { reportError } from '../../../../utils/monitoring'

/**
 * @deprecated Remove this hook after all entity extensions are transfered.
 */
export const useWbsItemEntityExtension = (
  functionUuid: string,
  projectUuid: string,
  ticketType?: string
): WbsItemEntityExtension => {
  const { extensions } = useEntityExtension(
    functionUuid,
    projectUuid,
    ticketType
  )
  const wbsItemEntityExtension = useMemo(() => {
    if (!extensions) {
      return {
        groupHeaders: [],
        childrenUnderBasicInfoHeader: [],
        childrenUnderPlanHeader: [],
        entityExtensionProperties: [],
      }
    }
    const { extensionProperties, extensionOptions } = extensions
    const groupHeaders: WbsItemEntityExtensionGroupHeader[] = []
    const entityExtensionProperties: WbsItemEntityExtensionProperty[] = []
    const propertiesGroupByParent: PropertiesGroupByParent = {}
    extensionProperties.forEach(v => {
      const propertyLayout = v.propertyLayout.split('-')
      if (!v.parentProperty) {
        const displayOrder = Number(propertyLayout[0])
        groupHeaders.push({
          uuid: v.entityExtensionUuid,
          externalId: v.externalId,
          name: v.name,
          hidden: v.hiddenIfC.isTrue || v.hiddenIfU.isTrue,
          displayOrder,
          children: [],
        })
      } else {
        const displayOrder = Number(propertyLayout[1])
        if (!propertiesGroupByParent[v.parentProperty]) {
          propertiesGroupByParent[v.parentProperty] = []
        }
        const entityExtensionProperty = {
          uuid: v.entityExtensionUuid,
          externalId: v.externalId,
          name: v.name,
          propertyType: WbsItemAdditionalPropertyType[v.propertyType],
          parentProperty: v.parentProperty,
          required: v.requiredIf.isTrue,
          editable: v.editableIfC.isTrue || v.editableIfU.isTrue,
          hidden: v.hiddenIfC.isTrue || v.hiddenIfU.isTrue,
          valuesAllowed: v.valuesAllowed,
          referenceEntity: v.referenceEntity,
          searchOptions: v.searchOptions,
          displayOrder,
        }
        propertiesGroupByParent[v.parentProperty].push(entityExtensionProperty)
        entityExtensionProperties.push(entityExtensionProperty)
      }
    })
    const childrenUnderBasicInfoHeader: WbsItemEntityExtensionProperty[] = []
    const childrenUnderPlanHeader: WbsItemEntityExtensionProperty[] = []
    Object.keys(propertiesGroupByParent).forEach(parentExternalId => {
      const children = propertiesGroupByParent[parentExternalId].sort(
        (a, b) => a.displayOrder - b.displayOrder
      )
      const groupHeader = groupHeaders.find(
        v => v.externalId === parentExternalId
      )
      if (groupHeader) {
        groupHeader.children = children
      } else {
        const lastPartOfParentExternalId =
          getLastPartOfExternalId(parentExternalId)
        if ('basic' === lastPartOfParentExternalId) {
          childrenUnderBasicInfoHeader.push(...children)
        } else if ('term' === lastPartOfParentExternalId) {
          childrenUnderPlanHeader.push(...children)
        } else {
          reportError(
            `Unexpected entity extension parent external id.(externalId=${parentExternalId})`
          )
        }
      }
    })
    groupHeaders.sort((a, b) => a.displayOrder - b.displayOrder)

    return {
      groupHeaders,
      entityExtensionProperties,
      childrenUnderBasicInfoHeader,
      childrenUnderPlanHeader,
    }
  }, [extensions])
  return wbsItemEntityExtension
}

const getLastPartOfExternalId = (externalId: string) => {
  const externalIdParts = externalId.split('.')
  return externalIdParts[externalIdParts.length - 1]
}

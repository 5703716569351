import { useCallback, useMemo } from 'react'
import { WbsItemEditableField, WbsItemFormModel } from '../model'
import { StatusAndSubStatusRelation } from './wbsItemSelectOptions'
import {
  WbsItemAdditionalPropertyValuesVO,
  WbsItemAdditionalPropertyValueType,
} from '../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import { ValidateSingleSheetPropertyValue } from '../../../containers/SingleSheetV2/hooks/formValidation'

export const useWbsItemModelModifier = (
  onChange: <K extends keyof WbsItemFormModel, T extends WbsItemFormModel[K]>(
    path: K,
    value: T
  ) => void,
  { getStatusBySubStatus, getSubStatusByStatus }: StatusAndSubStatusRelation,
  validate: ValidateSingleSheetPropertyValue
) => {
  const onChangeDisplayName = useCallback(
    (value: WbsItemFormModel['displayName']) => {
      onChange('displayName', value)
      validate('displayName', value)
    },
    [onChange, validate]
  )
  const onChangeStatus = useCallback(
    (value: WbsItemFormModel['status']) => {
      onChange('status', value)
      validate('status', value)
      const substatus = getSubStatusByStatus(value)?.value
      onChange('substatus', substatus)
      validate('substatus', substatus)
    },
    [getSubStatusByStatus, onChange, validate]
  )
  const onChangeSubStatus = useCallback(
    (value: WbsItemFormModel['substatus']) => {
      onChange('substatus', value)
      validate('substatus', value)
      const status = getStatusBySubStatus(value)
      onChange('status', status)
      validate('substatus', status)
    },
    [getStatusBySubStatus, onChange, validate]
  )
  const onChangeTags = useCallback(
    (value: WbsItemFormModel['tags']) => {
      onChange('tags', value)
      validate('tags', value)
    },
    [onChange, validate]
  )
  const onChangeTeam = useCallback(
    (value: WbsItemFormModel['team']) => {
      onChange('team', value)
      validate('team', value)
      const sprint = undefined
      onChange('sprint', sprint)
      validate('sprint', sprint)
    },
    [onChange, validate]
  )
  const onChangeAccountable = useCallback(
    (value: WbsItemFormModel['accountable']) => {
      onChange('accountable', value)
      validate('accountable', value)
    },
    [onChange, validate]
  )
  const onChangeResponsible = useCallback(
    (value: WbsItemFormModel['responsible']) => {
      onChange('responsible', value)
      validate('responsible', value)
    },
    [onChange, validate]
  )
  const onChangeAssignee = useCallback(
    (value: WbsItemFormModel['assignee']) => {
      onChange('assignee', value)
      validate('assignee', value)
    },
    [onChange, validate]
  )
  const onChangeWatchers = useCallback(
    (value: WbsItemFormModel['watchers']) => {
      onChange('watchers', value)
      validate('watchers', value)
    },
    [onChange, validate]
  )
  const onChangeDifficulty = useCallback(
    (value: WbsItemFormModel['difficulty']) => {
      onChange('difficulty', value)
      validate('difficulty', value)
    },
    [onChange, validate]
  )
  const onChangePriority = useCallback(
    (value: WbsItemFormModel['priority']) => {
      onChange('priority', value)
      validate('priority', value)
    },
    [onChange, validate]
  )
  const onChangeEstimatedHour = useCallback(
    (value: WbsItemFormModel['estimatedHour']) => {
      onChange('estimatedHour', value)
      validate('estimatedHour', value)
    },
    [onChange, validate]
  )
  const onChangeSprint = useCallback(
    (value: WbsItemFormModel['sprint']) => {
      onChange('sprint', value)
      validate('sprint', value)
    },
    [onChange, validate]
  )
  const onChangeScheduledDate = useCallback(
    (value: WbsItemFormModel['scheduledDate']) => {
      onChange('scheduledDate', value)
      validate('scheduledDate', value)
    },
    [onChange, validate]
  )
  const onChangeActualDate = useCallback(
    (value: WbsItemFormModel['actualDate']) => {
      onChange('actualDate', value)
      validate('actualDate', value)
    },
    [onChange, validate]
  )
  const onChangeDescription = useCallback(
    (value: WbsItemFormModel['description']) => {
      onChange('description', value)
      validate('description', value)
    },
    [onChange, validate]
  )
  const onChangeStartIf = useCallback(
    (value: WbsItemFormModel['startIf']) => {
      onChange('startIf', value)
      validate('startIf', value)
    },
    [onChange, validate]
  )
  const onChangeCompleteIf = useCallback(
    (value: WbsItemFormModel['completeIf']) => {
      onChange('completeIf', value)
      validate('completeIf', value)
    },
    [onChange, validate]
  )
  const onChangeAdditionalPropertyValues = useCallback(
    (value: WbsItemAdditionalPropertyValuesVO) => {
      onChange('additionalPropertyValues', value)
      // TODO Validate.
    },
    [onChange]
  )
  const onChangeEntityExtensionValues = useCallback(
    (value: WbsItemAdditionalPropertyValuesVO) => {
      onChange('entityExtensionValues', value)
      // TODO Validate.
    },
    [onChange]
  )
  const modifiers = useMemo(
    () => ({
      onChangeDisplayName,
      onChangeStatus,
      onChangeSubStatus,
      onChangeTags,
      onChangeTeam,
      onChangeAccountable,
      onChangeResponsible,
      onChangeAssignee,
      onChangeWatchers,
      onChangeDifficulty,
      onChangePriority,
      onChangeEstimatedHour,
      onChangeSprint,
      onChangeScheduledDate,
      onChangeActualDate,
      onChangeDescription,
      onChangeStartIf,
      onChangeCompleteIf,
      onChangeAdditionalPropertyValues,
      onChangeEntityExtensionValues,
    }),
    [
      onChangeAccountable,
      onChangeActualDate,
      onChangeAssignee,
      onChangeDescription,
      onChangeStartIf,
      onChangeCompleteIf,
      onChangeDifficulty,
      onChangeDisplayName,
      onChangePriority,
      onChangeEstimatedHour,
      onChangeResponsible,
      onChangeScheduledDate,
      onChangeSprint,
      onChangeStatus,
      onChangeSubStatus,
      onChangeTags,
      onChangeTeam,
      onChangeWatchers,
      onChangeAdditionalPropertyValues,
      onChangeEntityExtensionValues,
    ]
  )
  return modifiers
}

import React, { useCallback, useState } from 'react'
import AlertDialog from './AlertDialog'
import { intl } from '../../../i18n'

type CancelConfirmDialogProps = {
  open: boolean
  onConfirm: () => void
  onClose: () => void
}
const CancelConfirmDialog = (props: CancelConfirmDialogProps) => {
  return (
    <AlertDialog
      isOpen={props.open}
      title={intl.formatMessage({ id: 'dialog.title.cancelInput' })}
      message={intl.formatMessage({
        id: 'dialog.message.cancelInitializeScreen',
      })}
      submitButtonTitle={intl.formatMessage({ id: 'dialog.ok' })}
      submitHandler={props.onConfirm}
      closeButtonTitle={intl.formatMessage({ id: 'dialog.cancel' })}
      closeHandler={props.onClose}
    />
  )
}

export default CancelConfirmDialog

export const useCancelConfirmDialog = (
  confirm: () => void
): CancelConfirmDialogProps & {
  openDialog: () => void
} => {
  const [open, setOpen] = useState(false)
  const openDialog = useCallback(() => {
    setOpen(true)
  }, [])
  const closeDialog = useCallback(() => {
    setOpen(false)
  }, [])
  const onConfirm = useCallback(() => {
    confirm()
    closeDialog()
  }, [confirm, closeDialog])

  return { open, openDialog, onConfirm, onClose: closeDialog }
}

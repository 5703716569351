import { styled } from '@mui/material'
import { Button } from '../../../../components/buttons'
import { intl } from '../../../../../i18n'
import { useMemo } from 'react'

const ReloadButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void
  disabled?: boolean
}) => {
  const label = useMemo(() => intl.formatMessage({ id: 'reload' }), [])
  return (
    <StyledButton
      variant="outlined"
      colorPattern="monotone"
      size="s"
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </StyledButton>
  )
}

export default ReloadButton

// Style
const StyledButton = styled(Button)({
  width: '100px',
})

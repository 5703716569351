import { Avatar, styled } from '@mui/material'
import { SelectOption, SelectOptionWithIcon } from './model'
import { colorPalette } from '../../style/colorPallete'

export const renderOptionWithIcon = <Option extends SelectOptionWithIcon<any>>(
  option: Option
) => {
  return (
    <OptionWithIconRoot>
      <StyledAvatar src={option.iconUrl} />
      <OptionName>{option.name}</OptionName>
    </OptionWithIconRoot>
  )
}
const OptionWithIconRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
})
const StyledAvatar = styled(Avatar)({
  height: '20px',
  width: '20px',
  margin: '0',
})

const OptionName = styled('span')({
  color: colorPalette.monotone[10],
  fontSize: '11px',
})

export const renderValueOnlyIcon = <Option extends SelectOptionWithIcon<any>>(
  option: Option | undefined
) => {
  if (!option) return <></>
  return <StyledAvatar src={option.iconUrl} />
}
export const renderValueWithIcon = <Option extends SelectOptionWithIcon<any>>(
  option: Option | undefined
) => {
  if (!option) return <></>
  return renderOptionWithIcon(option)
}

export const wrapRenderUnselectedExplicitly =
  <Option extends SelectOption<any>>(
    renderer: (option: Option) => JSX.Element
  ) =>
  (option: Option | undefined) => {
    if (!option) {
      return <OptionName>未設定</OptionName>
    }
    return renderer(option)
  }

import { useCallback, useMemo } from 'react'
import { CellTitle, CellValueArea, FormCell, CellPropsBase } from '.'
import { DateTimeV2 } from '../../../../../domain/value-object/DateTimeV2'
import { DateTimeInput } from '../../../../components/inputs/DateTimePicker'

type DateTimeCellProps = {
  value: DateTimeV2 | undefined
  onChange?: (v: DateTimeV2 | undefined) => void
} & CellPropsBase

export const DateTimeCell = ({
  value,
  onChange: _onChange,
  cellWidth,
  label,
  editable = true,
  required = false,
  validationErrors,
}: DateTimeCellProps) => {
  const onChange = useCallback(
    (v: Date | undefined) => {
      _onChange && _onChange(v)
    },
    [_onChange]
  )
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea validationErros={validationErrors}>
        <DateTimeInput
          editable={editable}
          value={value}
          onChange={onChange}
          onSelect={onChange}
        />
      </CellValueArea>
    </FormCell>
  )
}

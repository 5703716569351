import { useCallback } from 'react'
import { SearchItemLabel, usePopup, FocusableSearchItemLabelProps } from '.'
import { TextConditionProps } from '../search-components/TextCondition'
import { Box, Popover } from '@mui/material'
import { TextInputCell } from '../../tables/table-cells/TextInputCell'

type TextConditionItemLabelProps =
  FocusableSearchItemLabelProps<TextConditionProps> & {
    isLoading?: boolean
    onClose?: () => void
  }

export const TextConditionItemLabel = ({
  header,
  value,
  isLoading,
  onChange,
  onClose,
  search,
}: TextConditionItemLabelProps) => {
  const { ref, open, openPopup, closePopup } = usePopup()

  const onClear = useCallback(() => {
    onChange(undefined)
    onClose && onClose()
  }, [])

  const onSearch = useCallback(() => {
    closePopup()
    search()
  }, [])

  const onChangeText = useCallback((newValue: string | undefined) => {
    onChange(newValue)
    if (!newValue && onClose) onClose()
  }, [])

  if (!value) return <></>
  return (
    <>
      <SearchItemLabel
        ref={ref}
        active={open}
        isLoading={isLoading}
        onClick={openPopup}
        onClear={onClear}
      >
        {header}: {value}
      </SearchItemLabel>
      <Popover
        open={open && !isLoading}
        anchorEl={ref.current || null}
        onClose={onSearch}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: '8px' }}>
          <TextInputCell
            value={value}
            onChange={onChangeText}
            onEnter={onSearch}
          />
        </Box>
      </Popover>
    </>
  )
}

import { useCallback } from 'react'
import projectMember, {
  ProjectMemberDetail,
} from '../../../../../lib/functions/projectMember'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import { FocusableSearchItemLabelProps } from '..'
import { ProjectMemberAutocompleteConditionProps } from '../../search-components/wbs-item-search-components/ProjectMemberAutocompleteCondition'
import { AutocompleteConditionItemLabel } from '../AutocompleteConditionItemLabel'
import user, { UserDetail } from '../../../../../lib/functions/user'

type ProjectMemberAutocompleteConditionLabelItemProps =
  FocusableSearchItemLabelProps<ProjectMemberAutocompleteConditionProps> & {
    isLoading?: boolean
    onClose?: () => void
  }

export const ProjectMemberAutocompleteConditionItemLabel = ({
  value,
  isLoading,
  onChange,
  onClose,
  search,
  ...other
}: ProjectMemberAutocompleteConditionLabelItemProps) => {
  const { projectUuid } = useProjectPrivateContext()

  const fetchOptions = useCallback(async () => {
    if (!projectUuid) return []
    const projectMembers = await projectMember.getProjectMembers({
      projectUuid,
    })
    return projectMembers.json.map(projectMember => ({
      value: projectMember.user.uuid,
      name: projectMember.user.name,
      iconUrl: projectMember.user.iconUrl,
    }))
  }, [projectUuid])
  const fetchSelectedOption = useCallback(async (value: string) => {
    try {
      const response = await user.getDetail({ uuid: value })
      const userDetail = response.json as UserDetail
      return {
        value: userDetail.uuid,
        name: userDetail.name,
        iconUrl: userDetail.iconUrl,
      }
    } catch (err) {
      return undefined
    }
  }, [])

  return (
    <AutocompleteConditionItemLabel
      {...other}
      value={value}
      onChange={onChange}
      onClose={onClose}
      search={search}
      options={[]}
      fetchOptions={fetchOptions}
      fetchSelectedOption={fetchSelectedOption}
      isLoading={isLoading}
    />
  )
}

import { useCallback } from 'react'
import API from '../../lib/commons/api'
import { UserRepository } from '../../applications/ports/userRepository'

export const useUserRepository = (): UserRepository => {
  const fetchAsReferencedEntity = useCallback(async (uuid: string) => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/users/detail',
      { userUuid: uuid },
      true
    )
    return (
      response.json && {
        uuid: response.json.uuid,
        name: response.json.name,
        iconUrl: response.json.iconUrl,
      }
    )
  }, [])

  return { fetchAsReferencedEntity }
}

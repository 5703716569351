import { useCallback, useEffect, useMemo, useState } from 'react'
import { ProjectPlanSelectRow } from '../model'
import { useProjectPlanRepository } from '../../../../../../services/adaptors/projectPlanRepositoryAdaptor'
import { useProjectPrivateContext } from '../../../../../context/projectContext'
import { WbsItemType } from '../../../../../../domain/entity/WbsItemEntity'
import { ProjectPlanEntity } from '../../../../../../domain/entity/ProjectPlanEntity'
import { AgGridTreeHelper } from '../../../../../containers/BulkSheetView/lib/tree'

type PartialRow = Omit<ProjectPlanSelectRow, 'selected'>
export const useProjectPlanSelectData = (
  projectUuid: string | undefined,
  selectedUuids: string[],
  rootName: string
) => {
  const [partialRows, setPartialRows] = useState<PartialRow[]>([])
  const { getWithChildren } = useProjectPlanRepository()
  useEffect(() => {
    const fn = async () => {
      if (!projectUuid) {
        setPartialRows([])
        return
      }
      const response = await getWithChildren(
        projectUuid,
        [WbsItemType.PROCESS],
        undefined
      )
      const rows = response.children.flatMap(resp =>
        AgGridTreeHelper.convert(
          resp,
          (resp: ProjectPlanEntity): PartialRow =>
            ({
              uuid: resp.uuid,
              displayName: resp.wbsItem.displayName,
              type: resp.type,
            } as PartialRow),
          [projectUuid]
        )
      )
      const root = {
        uuid: projectUuid,
        treeValue: [projectUuid],
        displayName: rootName,
        type: WbsItemType.WORKGROUP,
      }
      setPartialRows([root, ...rows])
    }
    fn()
  }, [getWithChildren, projectUuid, rootName])
  const rows = useMemo(
    () =>
      partialRows.map(r => {
        return {
          ...r,
          selected: selectedUuids.includes(r.uuid),
        } as ProjectPlanSelectRow
      }),
    [partialRows, selectedUuids]
  )
  return { rows }
}

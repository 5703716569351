import { InputBase, styled } from '@mui/material'
import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce'
import { Switch } from '../../../../components/inputs/Switch'

type ToggleCellProps = {
  value: boolean | undefined
  onChange?: (v: boolean | undefined) => void
} & CellPropsBase
export const ToggleCell = ({
  label,
  cellWidth,
  value,
  onChange: _onChange,
  editable = true,
  required = false,
  validationErrors,
}: ToggleCellProps) => {
  const onChange = useCallback(
    (_, newValue: boolean) => {
      _onChange && _onChange(newValue)
    },
    [_onChange]
  )

  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea validationErros={validationErrors}>
        <Switch checked={value} onChange={onChange} disabled={!editable} />
      </CellValueArea>
    </FormCell>
  )
}

import { useCallback, useMemo } from 'react'
import api from '../../lib/commons/api'
import { TicketRepository } from '../../applications/ports/ticketRepository'
import {
  CreateTicketRequest,
  ticketService,
  UpdateTicketDeltaRequest,
} from '../../domain/entity/ticket'
import { ReferencedEntityWithIcon } from '../../domain/value-object/ReferencedEntity'

export const useTicketRepository = (): TicketRepository => {
  const fetchByWbsItemUuid = useCallback(async (wbsItemUuid: string) => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/tickets/detail/wbs-item-uuid',
      { wbsItemUuid },
      true
    )
    return ticketService.fromApiResponseToEntity(response.json)
  }, [])
  const searchAsReferencedEntity = useCallback(
    async (
      projectUuid: string,
      ticketTypes: string[] | undefined,
      searchText: string
    ): Promise<ReferencedEntityWithIcon[]> => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/tickets/find',
        { projectUuid, ticketTypes, all: searchText },
        true
      )
      return response.json.data.map(ticket => {
        return {
          uuid: ticket.uuid,
          name: ticket.wbsItem.displayName,
          iconUrl: ticket.wbsItem.typeDto.iconUrl,
        }
      })
    },
    []
  )
  const fetchAsReferencedEntity = useCallback(
    async (uuid: string): Promise<ReferencedEntityWithIcon | undefined> => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/tickets/basic',
        { uuid },
        true
      )
      const ticket = response.json
      return {
        uuid: ticket.uuid,
        name: ticket.displayName,
        iconUrl: ticket.typeDto.iconUrl,
      }
    },
    []
  )
  const create = useCallback(async (request: CreateTicketRequest) => {
    await api.functional.request('POST', '/api/v1/tickets', request, true)
  }, [])
  const updateDelta = useCallback(async (request: UpdateTicketDeltaRequest) => {
    const response = await api.functional.request(
      'PUT',
      '/api/v1/tickets/delta',
      request,
      true
    )
    return {}
  }, [])

  const repository = useMemo(
    () => ({
      fetchByWbsItemUuid,
      searchAsReferencedEntity,
      fetchAsReferencedEntity,
      create,
      updateDelta,
    }),
    [
      fetchByWbsItemUuid,
      searchAsReferencedEntity,
      fetchAsReferencedEntity,
      create,
      updateDelta,
    ]
  )
  return repository
}

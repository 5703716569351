import { useIntl } from 'react-intl'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { DialogActions } from '@mui/material'
import Button from '@mui/material/Button'
import TimerMeasuringTaskList from '../tables/TimerMeasuringTaskList'

interface Props {
  dialogOpen: boolean
  closeDialog: () => void
}

const TimerMeasuringTaskListDialog = ({ dialogOpen, closeDialog }: Props) => {
  const intl = useIntl()
  return (
    <Dialog open={dialogOpen} onClose={closeDialog} maxWidth={'xl'}>
      <DialogContent sx={{ width: '800px' }}>
        <TimerMeasuringTaskList />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {intl.formatMessage({ id: 'dialog.close' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TimerMeasuringTaskListDialog

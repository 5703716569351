import { CloseRounded } from '@mui/icons-material'
import { styled } from '@mui/material'
import { colorPalette } from '../../style/colorPallete'

const ClearIcon = styled(CloseRounded)({
  color: colorPalette.monotone[4],
  fontSize: '14px',
})

export default ClearIcon

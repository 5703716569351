import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { APIResponse, successDummyResponse } from '../../../../lib/commons/api'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import SprintBacklogApi, {
  UpdateBatchSprintBacklogProps,
} from '../../../../lib/functions/sprintBacklog'
import TicketListApi from '../../../../lib/functions/ticketList'
import { WbsItemDeltaInput } from '../../../../lib/functions/wbsItem'
import store from '../../../../store'
import {
  doNotRequireSave,
  requireSave,
} from '../../../../store/requiredSaveData'
import { serializeExtensionValueDelta } from '../../../containers/BulkSheetView/gridOptions/extension'
import { EntityExtensionValueDelta } from '../../../containers/meta/entityExtension'
import {
  generateUpdateWbsItemDeltaInput,
  TicketDataManager,
} from '../dataManager'
import { TicketRow } from '../tickets'
import { TicketBasic } from '../../../../lib/functions/ticket'

export const useTicketsData = (
  projectUuid: string,
  ticketListUuid: string | undefined,
  extensionProperties: FunctionProperty[] | undefined,
  dataManager: TicketDataManager | undefined
) => {
  const [data, setDataInternal] = useState<TicketRow[]>([])
  const originalData = useRef<TicketRow[]>([])
  const [deletedRows, setDeletedRows] = useState<TicketRow[]>([])
  const dispatch = useDispatch()

  // fetch functions ---
  const fetch = useCallback(async (): Promise<TicketRow[]> => {
    if (!ticketListUuid || !dataManager || !extensionProperties) {
      return []
    }
    const response = await dataManager.getByTicketListUuid({
      ticketListUuid,
    })
    const { dailyWorkHours, monthlyWorkDays } =
      store.getState().tenant.organization!
    return response?.json?.map(v =>
      dataManager.createRowByResponse(
        v,
        dailyWorkHours,
        monthlyWorkDays,
        extensionProperties
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketListUuid, extensionProperties])

  const refresh = useCallback(async () => {
    const tickets: TicketRow[] = await fetch()
    if (_.isEmpty(tickets) && ticketListUuid && extensionProperties) {
      const ticketList: TicketBasic = await TicketListApi.getByUuid(
        ticketListUuid
      )
      const newRow = dataManager?.createNewRow(ticketList)
      newRow && tickets.push(newRow)
    }
    setDataInternal(tickets)
    originalData.current = _.cloneDeep(tickets)
    setDeletedRows([])
    store.dispatch(doNotRequireSave())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch])

  useEffect(() => {
    refresh()
  }, [refresh])

  const refreshSingleRow = useCallback(
    async (wbsItemUuid: string | undefined) => {
      if (!wbsItemUuid || !dataManager) return
      const targetData: TicketRow | undefined = data.find(
        d => d.wbsItem?.uuid === wbsItemUuid
      )
      if (!targetData) return
      const targetUuid = dataManager.getUuid(targetData)
      if (!targetUuid) return
      const response = await dataManager.getDetail({ uuid: targetUuid })
      const { dailyWorkHours, monthlyWorkDays } =
        store.getState().tenant.organization!
      const newRow = dataManager.createRowByResponse(
        response.json,
        dailyWorkHours,
        monthlyWorkDays,
        extensionProperties
      )
      const newData: TicketRow[] = data.map((row: TicketRow) => {
        if (row.uuid !== newRow.uuid) return row
        return newRow
      })
      originalData.current = originalData.current.map((row: TicketRow) => {
        if (row.uuid !== newRow.uuid) return row
        return _.cloneDeep(newRow)
      })
      setDataInternal(newData)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, extensionProperties]
  )

  // save functions ---
  const saveSprintBacklog = useCallback(
    async (
      changed: TicketRow[],
      originalData: TicketRow[],
      wbsItemLockVersionMap: { [wbsItemUuid: string]: number }
    ): Promise<APIResponse> => {
      if (_.isEmpty(changed)) return successDummyResponse
      const sprintBacklogRequest: UpdateBatchSprintBacklogProps = {
        sprintBacklogItems: [],
        sprintPlans: {
          added: [],
          deleted: [],
        },
      }
      changed.forEach(row => {
        const wbsItem = row.wbsItem!
        const sprint = wbsItem.sprint
        const originalSprint = originalData.find(v => v.uuid === row.uuid)
          ?.wbsItem?.sprint
        if (!wbsItem?.uuid) return
        if (
          !!sprint &&
          (row.added ||
            (row.edited &&
              (!originalSprint || originalSprint.uuid !== sprint.uuid)))
        ) {
          sprintBacklogRequest.sprintPlans.added.push({
            taskUuid: wbsItem.uuid,
            taskLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid] ?? wbsItem.lockVersion!,
            sprintUuid: sprint.uuid,
          })
        } else if (row.edited && !sprint && !!originalSprint) {
          sprintBacklogRequest.sprintPlans.deleted.push({
            taskUuid: wbsItem.uuid,
            taskLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid] ?? wbsItem.lockVersion!,
            sprintUuid: originalSprint.uuid,
          })
        }
      })
      if (
        _.isEmpty(sprintBacklogRequest.sprintPlans.added) &&
        _.isEmpty(sprintBacklogRequest.sprintPlans.deleted)
      ) {
        return successDummyResponse
      }
      return SprintBacklogApi.updateBatchSprintBacklog(sprintBacklogRequest)
    },
    []
  )

  const updateTickets = useCallback(
    async (rows: TicketRow[], deleteTargetRows: TicketRow[]) => {
      if (!dataManager) {
        throw new Error('Can not find ticket update function')
      }
      const request = dataManager.createUpdateDeltaBatchRequest(
        projectUuid,
        originalData.current,
        rows,
        deleteTargetRows
      )
      const response = await dataManager.updateBatchDelta(request)

      // Update sprint backlogs
      const wbsItemLockVersionMap = Object.fromEntries(
        [...response.json?.added, ...response.json?.edited].map(v => [
          v.wbsItemUuid,
          v.wbsItemLockVersion,
        ])
      )
      const sprintResponse = await saveSprintBacklog(
        data.filter(row => row.added || row.edited),
        originalData.current,
        wbsItemLockVersionMap
      )
      return [response, sprintResponse]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectUuid, originalData, data, saveSprintBacklog]
  )

  const save = useCallback(async (): Promise<APIResponse[]> => {
    return updateTickets(data, deletedRows)
  }, [data, deletedRows, updateTickets])

  const generateWbsItemDeltaRequest = useCallback(
    (row: TicketRow): WbsItemDeltaInput | undefined => {
      const original = originalData.current.find(v => v.uuid === row.uuid)
      if (row?.added || !row?.wbsItem || !original?.wbsItem) return undefined
      const originalExtensions = original.extensions ?? []
      const extensionInput = (row.extensions ?? [])
        .map(v =>
          serializeExtensionValueDelta(v.uuid, {
            oldValue: originalExtensions.find(e => e.uuid === v.uuid)?.value,
            newValue: v.value,
          })
        )
        .filter(v => !!v) as EntityExtensionValueDelta[]
      return generateUpdateWbsItemDeltaInput(
        original.wbsItem,
        row.wbsItem,
        extensionInput
      )
    },
    []
  )

  const saveSingleRow = useCallback(
    async (uuid: string) => {
      const target = data.filter(
        row => row.uuid === uuid && (row.added || row.edited)
      )
      if (!target) return
      await updateTickets(target, [])
    },
    [data, updateTickets]
  )

  // update data functions ---
  const setData = useCallback(
    (data: TicketRow[]) => {
      setDataInternal(data)
      dispatch(requireSave())
    },
    [dispatch]
  )

  const clearData = useCallback(() => {
    setDataInternal([])
  }, [])

  const deleteRows = useCallback(
    (rows: TicketRow[]) => {
      setDeletedRows([...deletedRows, ...rows.filter(v => !v.added)])
    },
    [deletedRows]
  )

  return {
    data,
    setData,
    clearData,
    refresh,
    refreshSingleRow,
    save,
    saveSingleRow,
    generateWbsItemDeltaRequest,
    deleteRows,
  }
}

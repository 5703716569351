import { useEffect, useMemo } from 'react'
import { useWbsItemLabel } from '../../label'
import { intl } from '../../../../../../i18n'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'

export const useRiskLabel = (
  wbsItemAdditionalProperties: WbsItemAdditionalPropertyLayoutEntity | undefined
) => {
  // TODO Remove after all entity extensions are transfered.
  const emptyList = useMemo(() => [], [])
  const labelMap = useWbsItemLabel(emptyList, wbsItemAdditionalProperties)
  useEffect(() => {
    labelMap.set('riskType', intl.formatMessage({ id: 'risks.riskType' }))
    labelMap.set('score', intl.formatMessage({ id: 'risks.score' }))
    labelMap.set('probability', intl.formatMessage({ id: 'risks.probability' }))
    labelMap.set('impact', intl.formatMessage({ id: 'risks.impact' }))
    labelMap.set('strategy', intl.formatMessage({ id: 'risks.strategy' }))
  }, [labelMap])
  return labelMap
}

import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import SearchIcon from '@mui/icons-material/SearchRounded'
import ClearIcon from '@mui/icons-material/ClearRounded'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import { getWbsItemFunctionUuid } from '../../../../lib/functions/wbsItem'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FontSize } from '../../../../styles/commonStyles'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import WbsItemStatusLabel from '../../../components/labels/WbsItemStatusLabel'
import { WbsItemIcon } from '../../../components/icons/WbsItemIcon'
import {
  closeWbsItemCommentSummaries,
  openWbsItemCommentSummaries,
} from '../../../../store/information'
import { open as openWindow } from '../../../router'
import CommentHeaderWbsItem from '../../Comment/CommentHeaderWbsItem'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { useDispatch, useSelector } from 'react-redux'
import { AllState } from '../../../../store'
import {
  confirmWbsItemCommentUserSummary,
  editWbsItemCommentUserSummariesSearchCondition,
  filterWbsItemCommentUserSummaries,
  openedWbsItemCommentUserSummaries,
  WbsItemCommentUserSummary,
} from '../../../../store/wbsItemCommentUserSummary'
import { List } from 'immutable'
import { colorPalette } from '../../../style/colorPallete'
import {
  Avatar,
  Box,
  Button,
  Link,
  ListItem,
  ListItemAvatar,
  List as MuiList,
  Slide,
  Typography,
} from '@mui/material'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import CompletionIcon from '../../../components/icons/CompletionIcon'

type Props = {
  className?: string
  open: boolean
  onClose: () => void
}

const Container = styled('div')({
  whiteSpace: 'nowrap',
  height: '100%',
})
const Header = styled('div')({
  width: '100%',
  height: '60px',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #DDDDDD',
  padding: '15px 0',
})
const Title = styled('div')({
  fontSize: FontSize.LARGE,
  fontWeight: 700,
  color: colorPalette.monotone[10],
  margin: 'auto 7px auto 20px',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
})
const HelpIcon = styled(HelpOutlineRoundedIcon)({
  width: '14px',
  height: '14px',
  color: '#888888',
})
const SearchTextInputBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '0 10px 0 auto',
})
const SearchTextInput = styled('span')({
  border: '1px solid #DDDDDD',
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginRight: '5px',
})

const WbsItemIconButton = styled('button')({
  padding: '0',
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
  transition: 'all 0.1s ease',
  borderRadius: '50%',

  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    filter: 'brightness(80%)',
    transform: 'scale(1.2)',
  },
})

const StyledWbsItemIcon = styled(WbsItemIcon)({
  verticalAlign: 'middle',
  height: '22px',
  width: '22px',
  marginLeft: '3px',
})
const ParentWbsItemIcon = styled(WbsItemIcon)({
  verticalAlign: 'middle',
  height: '18px',
  width: '18px',
  margin: '0 5px',
})

const WbsItemCommentUserSummaries = ({
  open,
  className,
  intl,
  onClose,
}: Props & WrappedComponentProps) => {
  const [selectedWbsItemCommentSummary, setSelectedWbsItemCommentSummary] =
    useState<WbsItemCommentUserSummary | undefined>(undefined)
  const searchText = useSelector<AllState, string>(
    state => state.wbsItemCommentUserSummary.searchCondition.searchText
  )

  const [openCompletedButton, setOpenCompletedButton] = useState<
    number | undefined
  >(undefined)

  const wbsItemCommentUserSummaries = useSelector<
    AllState,
    List<WbsItemCommentUserSummary>
  >(
    state => state.wbsItemCommentUserSummary.filteredWbsItemCommentUserSummaries
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (open) {
      dispatch(openedWbsItemCommentUserSummaries())
    } else {
      dispatch(closeWbsItemCommentSummaries())
    }
  }, [dispatch, open])

  useEffect(() => {
    return () => {
      dispatch(closeWbsItemCommentSummaries())
    }
  }, [dispatch])

  const onClickWbsItem = useCallback(
    (summary: WbsItemCommentUserSummary) => {
      const applicationFunctionUuid = getWbsItemFunctionUuid()
      if (!applicationFunctionUuid) {
        return
      }
      const { wbsItem, project } = summary
      dispatch(
        openWbsItemCommentSummaries({
          applicationFunctionUuid,
          dataUuid: wbsItem.uuid,
          projectUuid: project.uuid,
          headerComponents: [
            <CommentHeaderWbsItem
              key={'comment-header-wbs-item-' + wbsItem.uuid}
              wbsItem={{
                uuid: wbsItem.uuid,
                lockVersion: 0,
                projectUuid: project.uuid,
                displayName: wbsItem.displayName,
                type: wbsItem.type.rootType,
                status: wbsItem.status,
                wbsItemType: new WbsItemTypeVO(wbsItem.type),
              }}
              readonly={true}
            />,
          ],
        })
      )
      setSelectedWbsItemCommentSummary(summary)
    },
    [dispatch, setSelectedWbsItemCommentSummary]
  )

  useEffect(() => {
    if (wbsItemCommentUserSummaries.size === 0 || !open) return
    const latestWbsItemCommentUserSummary = wbsItemCommentUserSummaries.get(0)
    onClickWbsItem(latestWbsItemCommentUserSummary!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const onChangeSearchText = useCallback(
    event => {
      const text = event.target.value
      dispatch(editWbsItemCommentUserSummariesSearchCondition(text))
    },
    [dispatch]
  )

  const onKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        dispatch(filterWbsItemCommentUserSummaries(searchText))
      }
    },
    [dispatch, searchText]
  )

  const clearSearchText = useCallback(() => {
    dispatch(editWbsItemCommentUserSummariesSearchCondition(''))
  }, [dispatch])

  return (
    <Container className={className}>
      <Header>
        <Title>
          {intl.formatMessage({
            id: 'wbsItemCommentUserSummary.title',
          })}
          <BootstrapTooltip
            sx={{
              marginLeft: '10px',
            }}
            title={intl.formatMessage({
              id: 'wbsItemCommentUserSummary.help.text',
            })}
            arrow={true}
          >
            <HelpIcon />
          </BootstrapTooltip>
        </Title>
        <SearchTextInputBox>
          <SearchTextInput>
            <SearchIcon color="action" sx={{ margin: '10px' }} />
            <InputBase
              sx={{ color: 'inherit', width: '180px' }}
              placeholder={intl.formatMessage({
                id: 'wbsItemCommentUserSummary.searchTextInput.placeholder',
              })}
              value={searchText}
              onChange={onChangeSearchText}
              onKeyDown={onKeyDown}
            />
            <IconButton onClick={clearSearchText} sx={{ padding: '4px' }}>
              <ClearIcon sx={{ color: colorPalette.monotone[4] }} />
            </IconButton>
          </SearchTextInput>
        </SearchTextInputBox>
        <IconButton
          onClick={onClose}
          sx={{ padding: '4px', marginRight: '20px' }}
        >
          <ClearIcon sx={{ color: colorPalette.monotone[4] }} />
        </IconButton>
      </Header>
      <Box
        sx={{
          height: 'calc(100% - 60px)',
          overflowY: 'auto',
        }}
      >
        <MuiList
          sx={{
            width: '100%',
            paddingTop: 0,
            overflowX: 'hidden',
          }}
        >
          {wbsItemCommentUserSummaries.map(
            (summary: WbsItemCommentUserSummary, index) => {
              const latestCommentedAt = new Date(summary.latestCommentedAt)
              const onClickListItem = () => onClickWbsItem(summary)
              return (
                <ListItem
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: `1px solid ${colorPalette.monotone[2]}`,
                    height: '86px',
                    paddingRight: 0,
                    background:
                      selectedWbsItemCommentSummary?.wbsItem.uuid ===
                      summary.wbsItem.uuid
                        ? colorPalette.monotone[1]
                        : colorPalette.monotone[0],
                    '&:hover': { background: colorPalette.monotone[1] },
                  }}
                  key={`wbs-item-comment-user-summary-row-${summary.wbsItem.uuid}`}
                  onClick={onClickListItem}
                >
                  <ListItemAvatar sx={{ display: 'flex' }}>
                    <BootstrapTooltip
                      title={summary.project.displayName}
                      arrow={true}
                    >
                      <Avatar
                        variant="rounded"
                        sx={{
                          height: '32px',
                          width: '32px',
                          border: `1px solid ${colorPalette.monotone[2]}`,
                        }}
                        src={summary.project.iconUrl}
                      />
                    </BootstrapTooltip>
                  </ListItemAvatar>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '80%',
                      height: '50px',
                      flexDirection: 'column',
                      marginRight: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '25px',
                        alignItems: 'center',
                      }}
                    >
                      <WbsItemIconButton
                        onClick={() => {
                          openWindow(
                            `/wbsItem/${summary.wbsItem.code}`,
                            null,
                            undefined,
                            true
                          )
                        }}
                      >
                        <StyledWbsItemIcon
                          type={new WbsItemTypeVO(summary.wbsItem.type)}
                        />
                      </WbsItemIconButton>
                      <Link
                        sx={{
                          color: colorPalette.monotone[10],
                          cursor: 'pointer',
                          margin: '0 auto 0 5px',
                          textDecorationColor: colorPalette.monotone[10],
                          fontSize: '14px',
                          fontWeight: 400,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          letterSpacing: '0.1em',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                          maxWidth: '70%',
                        }}
                        onClick={() => {
                          openWindow(
                            `/wbsItem/${summary.wbsItem.code}`,
                            null,
                            undefined,
                            true
                          )
                        }}
                      >
                        {summary.wbsItem.displayName}
                      </Link>
                      <Box
                        sx={{
                          margin: '0 10px 0 10px',
                          color: colorPalette.monotone[4],
                          fontSize: '10px',
                        }}
                      >
                        {`${latestCommentedAt.getFullYear()}-${(
                          '0' +
                          (latestCommentedAt.getMonth() + 1)
                        ).slice(-2)}-${(
                          '0' + latestCommentedAt.getDate()
                        ).slice(-2)} ${(
                          '0' + latestCommentedAt.getHours()
                        ).slice(-2)}:${(
                          '0' + latestCommentedAt.getMinutes()
                        ).slice(-2)}`}
                      </Box>
                      <Box sx={{ minWidth: '50px', textAlign: 'center' }}>
                        <WbsItemStatusLabel
                          status={summary.wbsItem.status}
                          readonly={true}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        height: '25px',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}
                    >
                      {summary.parentWbsItem && (
                        <>
                          <WbsItemIconButton
                            onClick={() => {
                              openWindow(
                                `/wbsItem/${summary.parentWbsItem!.code}`,
                                null,
                                undefined,
                                true
                              )
                            }}
                          >
                            <ParentWbsItemIcon
                              type={
                                new WbsItemTypeVO(summary.parentWbsItem.type)
                              }
                            />
                          </WbsItemIconButton>
                          <Link
                            sx={{
                              color: colorPalette.monotone[4],
                              cursor: 'pointer',
                              marginLeft: '3px',
                              textDecorationColor: colorPalette.monotone[4],
                              fontSize: '12px',
                              fontWeight: 400,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              letterSpacing: '0.1em',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              maxWidth: '70%',
                            }}
                            onClick={() => {
                              openWindow(
                                `/wbsItem/${summary.wbsItem.code}`,
                                null,
                                undefined,
                                true
                              )
                            }}
                          >
                            {summary.parentWbsItem.displayName}
                          </Link>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Button
                    sx={{
                      margin: '0 0 0 auto',
                      maxWidth: '60px',
                      display: 'flex',
                      height: '100%',
                      minWidth: 0,
                      width: '100%',
                      padding: 0,
                      justifyContent: 'center',
                      '&:hover': {
                        background: colorPalette.monotone[1],
                      },
                    }}
                    onClick={() => {
                      if (openCompletedButton !== index) {
                        setOpenCompletedButton(index)
                      } else {
                        dispatch(confirmWbsItemCommentUserSummary(summary))
                        setOpenCompletedButton(undefined)
                      }
                    }}
                  >
                    {openCompletedButton === index ? (
                      <Slide
                        direction="left"
                        in={openCompletedButton === index}
                        {...{ timeout: 500 }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            color: colorPalette.monotone[0],
                            height: '100%',
                            background: colorPalette.skyBlue[7],
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '&:hover': {
                              background:
                                openCompletedButton === index
                                  ? colorPalette.skyBlue[7]
                                  : 'transparent',
                            },
                          }}
                        >
                          <CompletionIcon />
                          <Typography sx={{ marginTop: '8px' }}>
                            {intl.formatMessage({ id: 'completed' })}
                          </Typography>
                        </Box>
                      </Slide>
                    ) : (
                      <Box sx={{ display: 'flex' }}>
                        <NavigateNextRoundedIcon
                          sx={{ color: colorPalette.monotone[4] }}
                        />
                      </Box>
                    )}
                  </Button>
                </ListItem>
              )
            }
          )}
        </MuiList>
      </Box>
    </Container>
  )
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow={true} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: colorPalette.monotone[1],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colorPalette.monotone[1],
    color: colorPalette.monotone[4],
    fontSize: '14px',
    boxShadow: '0px 2px 6px 0px #7B8CAA66',
  },
}))

export default injectIntl(WbsItemCommentUserSummaries)

export type MultiLineTextV2 = string

const isMultiLineText = (value: string) => {
  if (!value) return false
  return new RegExp(/\n/g).test(value)
}

export const multiLineTextV2Service = {
  isMultiLineText,
}

import { ComponentProps, useCallback, useMemo } from 'react'
import _ from 'lodash'
import {
  EntitySearchReferenceEntityRepository,
  GetEntitySearchReferenceEntityRepository,
  useEntitySearchReferenceEntityRepositories,
} from '../../../../hooks/useWbsItemAdditionalProperties'
import { TableHeader } from '../../../../containers/SingleSheetV2/table/Header'
import { TableRow } from '../../../../containers/SingleSheetV2/table/cells'
import { NumberCell as SingleSheetNumberCell } from '../../../../containers/SingleSheetV2/table/cells/NumberCell'
import { TextCell as SingleSheetTextCell } from '../../../../containers/SingleSheetV2/table/cells/TextCell'
import { DateCell as SingleSheetDateCell } from '../../../../containers/SingleSheetV2/table/cells/DateCell'
import { DateTimeCell as SingleSheetDateTimeCell } from '../../../../containers/SingleSheetV2/table/cells/DateTimeCell'
import { CheckboxCell as SingleSheetCheckboxCell } from '../../../../containers/SingleSheetV2/table/cells/CheckboxCell'
import { SelectCell as SingleSheetSelectCell } from '../../../../containers/SingleSheetV2/table/cells/SelectCell'
import { MultiSelectCell as SingleSheetMultiSelectCell } from '../../../../containers/SingleSheetV2/table/cells/MultiSelectCell'
import { AutocompleteCell as SingleSheetAutocompleteCell } from '../../../../containers/SingleSheetV2/table/cells/AutocompleteCell'
import { i18nLabelVoService } from '../../../../../domain/value-object/I18nLabelVO'
import {
  EntitySearchReferenceEntity,
  WbsItemAdditionalPropertyEntity,
  WbsItemAdditionalPropertyType,
} from '../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import {
  WbsItemAdditionalPropertyEntitySearchValueType,
  WbsItemAdditionalPropertyMultiLineTextValueType,
  WbsItemAdditionalPropertyCheckboxValueType,
  WbsItemAdditionalPropertyNumberValueType,
  WbsItemAdditionalPropertySelectValueType,
  WbsItemAdditionalPropertyMultiSelectValueType,
  WbsItemAdditionalPropertyTextValueType,
  wbsItemAdditionalPropertyValuesVoService,
  WbsItemAdditionalPropertyValueType,
  WbsItemAdditionalPropertyValuesVO,
  WbsItemAdditionalPropertyDateValueType,
  WbsItemAdditionalPropertyDateTimeValueType,
} from '../../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import { MultilineTextCell } from '../../../../containers/SingleSheetV2/table/cells/MultilineTextCell'
import { DateV2 } from '../../../../../domain/value-object/DateV2'
import { DateTimeV2 } from '../../../../../domain/value-object/DateTimeV2'
import {
  WbsItemEntityExtensionGroupHeader,
  WbsItemEntityExtensionProperty,
} from '../../model/entityExtension'
import { CustomEnumValue } from '../../../../../lib/commons/appFunction'
import SearchOptions from '../../../../../utils/searchOptions'
import {
  SingleSheetPropertiesValidationErrors,
  SingleSheetPropertyValidationErrors,
  ValidateSingleSheetEntityExtensionPropertyValue,
} from '../../../../containers/SingleSheetV2/hooks/formValidation'

/**
 * @deprecated Remove this component after all entity extensions are transfered.
 */
export const WbsItemEntityExtensionCells = ({
  projectUuid,
  wbsItemUuid,
  wbsItemEntityExtensionPropertyGroupHeaders,
  wbsItemEntityExtensionValues,
  onChange: _onChange,
  validate,
  validationErrors,
}: {
  projectUuid: string
  wbsItemUuid: string
  wbsItemEntityExtensionPropertyGroupHeaders: WbsItemEntityExtensionGroupHeader[]
  wbsItemEntityExtensionValues: WbsItemAdditionalPropertyValuesVO | undefined
  onChange: (value: WbsItemAdditionalPropertyValuesVO) => void
  validate: ValidateSingleSheetEntityExtensionPropertyValue
  validationErrors: SingleSheetPropertiesValidationErrors
}) => {
  const { getEntitySearchReferenceEntityRepository } =
    useEntitySearchReferenceEntityRepositories(projectUuid)
  const onChange = useCallback(
    (
      entityExtensionProperty: WbsItemEntityExtensionProperty,
      value: WbsItemAdditionalPropertyValueType
    ) => {
      let vo =
        wbsItemEntityExtensionValues ||
        wbsItemAdditionalPropertyValuesVoService.construct()
      wbsItemAdditionalPropertyValuesVoService.setValue(
        vo,
        entityExtensionProperty.uuid,
        value
      )
      _onChange(vo)
      validate(entityExtensionProperty, value)
    },
    [_onChange, wbsItemEntityExtensionValues, validate]
  )

  return (
    <>
      {wbsItemEntityExtensionPropertyGroupHeaders.map(groupHeader => {
        return (
          <>
            <TableHeader title={groupHeader.name} />
            <EntityExtensionPropertyCells
              groupHeaderUuid={groupHeader.uuid}
              wbsItemUuid={wbsItemUuid}
              entityExtensionProperties={groupHeader.children}
              entityExtensionValues={wbsItemEntityExtensionValues}
              getEntitySearchReferenceEntityRepository={
                getEntitySearchReferenceEntityRepository
              }
              onChange={onChange}
              validationErrors={validationErrors}
            />
          </>
        )
      })}
    </>
  )
}
/**
 * @deprecated Remove this component after all entity extensions are transfered.
 */
export const WbsItemEntityExtensionCellsUnderDefaultHeader = ({
  defaultHeaderId,
  projectUuid,
  wbsItemUuid,
  entityExtensionProperties,
  wbsItemEntityExtensionValues,
  onChange: _onChange,
  validate,
  validationErrors,
}: {
  defaultHeaderId: string
  projectUuid: string
  wbsItemUuid: string
  entityExtensionProperties: WbsItemEntityExtensionProperty[]
  wbsItemEntityExtensionValues: WbsItemAdditionalPropertyValuesVO | undefined
  onChange: (value: WbsItemAdditionalPropertyValuesVO) => void
  validate: ValidateSingleSheetEntityExtensionPropertyValue
  validationErrors: SingleSheetPropertiesValidationErrors
}) => {
  const { getEntitySearchReferenceEntityRepository } =
    useEntitySearchReferenceEntityRepositories(projectUuid)
  const onChange = useCallback(
    (
      entityExtensionProperty: WbsItemEntityExtensionProperty,
      value: WbsItemAdditionalPropertyValueType
    ) => {
      let vo =
        wbsItemEntityExtensionValues ||
        wbsItemAdditionalPropertyValuesVoService.construct()
      wbsItemAdditionalPropertyValuesVoService.setValue(
        vo,
        entityExtensionProperty.uuid,
        value
      )
      _onChange(vo)
      validate(entityExtensionProperty, value)
    },
    [_onChange, wbsItemEntityExtensionValues, validate]
  )
  return (
    <EntityExtensionPropertyCells
      groupHeaderUuid={defaultHeaderId}
      wbsItemUuid={wbsItemUuid}
      entityExtensionProperties={entityExtensionProperties}
      entityExtensionValues={wbsItemEntityExtensionValues}
      getEntitySearchReferenceEntityRepository={
        getEntitySearchReferenceEntityRepository
      }
      onChange={onChange}
      validationErrors={validationErrors}
    />
  )
}

const EntityExtensionPropertyCells = ({
  groupHeaderUuid,
  wbsItemUuid,
  entityExtensionProperties,
  entityExtensionValues,
  getEntitySearchReferenceEntityRepository,
  onChange,
  validationErrors,
}: {
  groupHeaderUuid: string
  wbsItemUuid: string
  entityExtensionProperties: WbsItemEntityExtensionProperty[]
  entityExtensionValues: WbsItemAdditionalPropertyValuesVO | undefined
  getEntitySearchReferenceEntityRepository: (
    entitySearchReferenceEntity: EntitySearchReferenceEntity
  ) => EntitySearchReferenceEntityRepository
  onChange: (
    entityExtensionProperty: WbsItemEntityExtensionProperty,
    value: WbsItemAdditionalPropertyValueType
  ) => void
  validationErrors: SingleSheetPropertiesValidationErrors
}) => {
  const entityExtensionPropertyChunks: WbsItemEntityExtensionProperty[][] =
    _.chunk(entityExtensionProperties, 2)
  return (
    <>
      {entityExtensionPropertyChunks.map(entityExtensionPropertyChunk => {
        return (
          <TableRow key={`entity-extension-cell-group-${groupHeaderUuid}`}>
            {entityExtensionPropertyChunk.map(entityExtensionProperty => {
              return (
                <EntityExtensionValueCell
                  entityExtensionProperty={entityExtensionProperty}
                  key={entityExtensionProperty.uuid}
                  wbsItemUuid={wbsItemUuid}
                  value={
                    entityExtensionValues
                      ? wbsItemAdditionalPropertyValuesVoService.getValue(
                          entityExtensionValues,
                          entityExtensionProperty.uuid
                        )?.value
                      : undefined
                  }
                  getEntitySearchReferenceEntityRepository={
                    getEntitySearchReferenceEntityRepository
                  }
                  onChange={onChange}
                  validationErrors={
                    validationErrors[entityExtensionProperty.uuid]
                  }
                />
              )
            })}
          </TableRow>
        )
      })}
    </>
  )
}
const EntityExtensionValueCell = ({
  entityExtensionProperty,
  wbsItemUuid,
  value,
  getEntitySearchReferenceEntityRepository,
  onChange,
  validationErrors,
}: {
  entityExtensionProperty: WbsItemEntityExtensionProperty
  wbsItemUuid: string
  value: WbsItemAdditionalPropertyValueType
  getEntitySearchReferenceEntityRepository: GetEntitySearchReferenceEntityRepository
  onChange: (
    entityExtensionProperty: WbsItemEntityExtensionProperty,
    value: WbsItemAdditionalPropertyValueType
  ) => void
  validationErrors: SingleSheetPropertyValidationErrors
}) => {
  const {
    uuid,
    name: label,
    propertyType,
    required,
    valuesAllowed,
    referenceEntity,
    searchOptions,
  } = entityExtensionProperty
  const onChangeValue = useCallback(
    (newValue: WbsItemAdditionalPropertyValueType) => {
      onChange(entityExtensionProperty, newValue)
    },
    [uuid, onChange]
  )

  switch (propertyType) {
    case WbsItemAdditionalPropertyType.NUMBER:
      return (
        <NumberCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyNumberValueType}
          editable={true}
          required={required}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.TEXT:
      return (
        <TextCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyTextValueType}
          editable={true}
          required={required}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.MULTI_LINE_TEXT:
      return (
        <MultilineTextCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyMultiLineTextValueType}
          dataUuid={wbsItemUuid}
          externalId={uuid}
          editable={true}
          required={required}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.DATE:
      return (
        <DateCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyDateValueType}
          editable={true}
          required={required}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.DATE_TIME:
      return (
        <DateTimeCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyDateTimeValueType}
          editable={true}
          required={required}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.CHECKBOX:
      return (
        <CheckboxCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as string}
          editable={true}
          required={required}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.SELECT:
      return (
        <SelectCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertySelectValueType}
          editable={true}
          required={required}
          valuesAllowed={valuesAllowed}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.MULTI_SELECT:
      return (
        <MultiSelectCell
          label={label}
          cellWidth="half"
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyMultiSelectValueType}
          editable={true}
          required={required}
          valuesAllowed={valuesAllowed}
          validationErrors={validationErrors}
        />
      )
    case WbsItemAdditionalPropertyType.ENTITY_SEARCH:
      return (
        <AutocompleteCell
          label={label}
          cellWidth="half"
          // @ts-ignore
          onChange={onChangeValue}
          value={value as WbsItemAdditionalPropertyEntitySearchValueType}
          editable={true}
          required={required}
          referenceEntity={referenceEntity}
          searchOptions={searchOptions}
          getEntitySearchReferenceEntity={
            getEntitySearchReferenceEntityRepository
          }
          validationErrors={validationErrors}
        />
      )
  }
}

// Custom single sheet cells.
const NumberCell = SingleSheetNumberCell
const TextCell = SingleSheetTextCell
const DateCell = ({
  value,
  onChange,
  ...otherProps
}: Omit<SingleSheetDateCellProps, 'value' | 'onChange'> & {
  value: WbsItemAdditionalPropertyDateValueType
  onChange: (value: WbsItemAdditionalPropertyValueType) => void
}) => {
  const dateValue = useMemo(
    () => (value ? new Date(value) : undefined),
    [value]
  )
  const onChangeValue = useCallback(
    (newValue: DateV2 | undefined) => {
      onChange(
        newValue
          ? wbsItemAdditionalPropertyValuesVoService.toValueFromDate(newValue)
          : undefined
      )
    },
    [onChange]
  )
  return (
    <SingleSheetDateCell
      {...otherProps}
      value={dateValue}
      onChange={onChangeValue}
    />
  )
}
const DateTimeCell = ({
  value,
  onChange,
  ...otherProps
}: Omit<SingleSheetDateTimeCellProps, 'value' | 'onChange'> & {
  value: WbsItemAdditionalPropertyDateTimeValueType
  onChange: (value: WbsItemAdditionalPropertyValueType) => void
}) => {
  const dateTimeValue = useMemo(
    () => (value ? new Date(value) : undefined),
    [value]
  )
  const onChangeValue = useCallback(
    (newValue: DateTimeV2 | undefined) => {
      onChange(newValue ? newValue.getTime() : undefined)
    },
    [onChange]
  )
  return (
    <SingleSheetDateTimeCell
      {...otherProps}
      value={dateTimeValue}
      onChange={onChangeValue}
    />
  )
}
const CheckboxCell = ({
  value,
  onChange: _onChange,
  ...otherProps
}: Omit<SingleSheetCheckboxCellProps, 'value' | 'onChange'> & {
  value: string | undefined
  onChange: (value: string | undefined) => void
}) => {
  const checked = useMemo(() => {
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true'
    }
    return value
  }, [value])
  const onChange = useCallback(
    (newValue: boolean | undefined) => {
      _onChange(newValue ? 'true' : 'false')
    },
    [_onChange]
  )
  return (
    <SingleSheetCheckboxCell
      {...otherProps}
      value={checked}
      onChange={onChange}
    />
  )
}
const SelectCell = ({
  valuesAllowed,
  ...otherProps
}: Omit<SingleSheetSelectCellProps, 'options'> & {
  valuesAllowed: CustomEnumValue[] | undefined
}) => {
  const options = useMemo(
    () =>
      valuesAllowed
        ? valuesAllowed.map(o => ({
            name: o.name,
            value: o.value,
          }))
        : [],
    [valuesAllowed]
  )
  return <SingleSheetSelectCell {...otherProps} options={options} />
}
const MultiSelectCell = ({
  valuesAllowed,
  ...otherProps
}: Omit<SingleSheetMultiSelectCellProps, 'options'> & {
  valuesAllowed: CustomEnumValue[] | undefined
}) => {
  const options = useMemo(
    () =>
      valuesAllowed
        ? valuesAllowed.map(o => ({
            name: o.name,
            value: o.value,
          }))
        : [],
    [valuesAllowed]
  )
  return <SingleSheetMultiSelectCell {...otherProps} options={options} />
}
const AutocompleteCell = ({
  referenceEntity,
  searchOptions,
  getEntitySearchReferenceEntity,
  ...otherProps
}: Omit<SingleSheetAutocompleteCellProps, 'search'> & {
  referenceEntity: string | undefined
  searchOptions: SearchOptions | undefined
  getEntitySearchReferenceEntity: GetEntitySearchReferenceEntityRepository
}) => {
  const search = useMemo(() => {
    const entitySearchReferenceEntity = convertReferenceEntity(
      referenceEntity,
      searchOptions
    )
    if (!entitySearchReferenceEntity) {
      throw new Error(
        `Unsupported referenceEntity(=${referenceEntity}) of entity extension.`
      )
    }
    return getEntitySearchReferenceEntity(entitySearchReferenceEntity).search
  }, [referenceEntity, searchOptions, getEntitySearchReferenceEntity])
  return <SingleSheetAutocompleteCell {...otherProps} search={search} />
}
const convertReferenceEntity = (
  referenceEntity: string | undefined,
  searchOptions: SearchOptions | undefined
): EntitySearchReferenceEntity | undefined => {
  if ('Process' === referenceEntity) {
    return EntitySearchReferenceEntity.PROCESS
  } else if ('Deliverable' === referenceEntity) {
    return EntitySearchReferenceEntity.DELIVERABLE
  } else if ('Ticket' === referenceEntity) {
    const ticketType =
      searchOptions && searchOptions.values?.length > 0
        ? searchOptions.values[0]
        : undefined
    if ('"REFINEMENT"' === ticketType) {
      return EntitySearchReferenceEntity.REFINEMENT
    } else if ('"ISSUE"' === ticketType) {
      return EntitySearchReferenceEntity.ISSUE
    }
  }
  return undefined
}

// Single sheet cell props types.
type SingleSheetCheckboxCellProps = ComponentProps<
  typeof SingleSheetCheckboxCell
>
type SingleSheetDateCellProps = ComponentProps<typeof SingleSheetDateCell>
type SingleSheetDateTimeCellProps = ComponentProps<
  typeof SingleSheetDateTimeCell
>
type SingleSheetSelectCellProps = ComponentProps<typeof SingleSheetSelectCell>
type SingleSheetMultiSelectCellProps = ComponentProps<
  typeof SingleSheetMultiSelectCell
>
type SingleSheetAutocompleteCellProps = ComponentProps<
  typeof SingleSheetAutocompleteCell
>

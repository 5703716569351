import SubmitButton from '../button/SubmitButton'
import ReloadButton from '../button/ReloadButton'

export type DataHandlerButtonsProps = {
  submit: () => Promise<void>
  reload: () => Promise<void> | void
  submitIsActive: boolean
}

export const DataHandlerButtons = ({
  submit,
  reload,
  submitIsActive,
}: DataHandlerButtonsProps) => {
  return (
    <>
      <SubmitButton onClick={submit} disabled={!submitIsActive} />
      <ReloadButton onClick={reload} />
    </>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { ClickAwayListener, Typography } from '@mui/material'
import { ColDef } from 'ag-grid-community'
import { intl } from '../../../../../i18n'
import { Color, FontSize, IconColor } from '../../../../../styles/commonStyles'
import { colorPalette } from '../../../../style/colorPallete'
import FilterPopper from './FilterPopper'
import FilterIcon from '../../../../components/icons/FilterIcon'
import { BootstrapTooltip } from '../../../../components/tooltips/Tooltip/BootStrapToopTip'
import { isProduction } from '../../../../../utils/urls'

type Props = {
  filteredColumns: ColDef[]
  onDeleteFilteredColumn?: (column: ColDef) => void
  resetFilters: () => void
  getDisplayColumnName?: (colDef: ColDef) => string | undefined
}

const FilterButton = ({
  filteredColumns,
  onDeleteFilteredColumn,
  resetFilters,
  getDisplayColumnName,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element>()
  const [mouseOver, setMouseOver] = useState(false)

  const handleClose = useCallback(() => setAnchorEl(undefined), [])
  const handleClick = useCallback(event => setAnchorEl(event.currentTarget), [])

  const handleMouseEnter = useCallback(() => setMouseOver(true), [])
  const handleMouseLeave = useCallback(() => setMouseOver(false), [])

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div style={{ minWidth: 'fit-content' }}>
          {isProduction && (
            <button
              style={{
                background: 'transparent',
                border: 0,
                fontSize: FontSize.MEDIUM,
                fontWeight: 500,
                display: 'flex',
                flexDirection: 'row',
                padding: '5px 10px',
                cursor: filteredColumns.length >= 1 ? 'pointer' : '',
                minWidth: 'fit-content',
              }}
              onClick={handleClick}
            >
              <Typography sx={{ color: colorPalette.monotone[5] }}>
                {intl.formatMessage({ id: 'wbs.header.filter.title' })}
              </Typography>
              {filteredColumns.length >= 1 ? (
                <Typography
                  sx={{
                    cursor: 'pointer',
                    color: colorPalette.skyBlue[7],
                  }}
                >
                  {filteredColumns.length}
                </Typography>
              ) : (
                <Typography sx={{ color: colorPalette.monotone[3] }}>
                  {intl.formatMessage({ id: 'wbs.header.none.label' })}
                </Typography>
              )}
            </button>
          )}

          {!isProduction && (
            <BootstrapTooltip
              arrow={true}
              title={intl.formatMessage({
                id: 'wbs.header.filtered.table.column.count',
              })}
              open={mouseOver && !anchorEl}
              onClose={() => setMouseOver(false)}
              disableInteractive={true}
            >
              <button
                style={{
                  background: 'transparent',
                  border: 0,
                  fontSize: FontSize.MEDIUM,
                  fontWeight: 500,
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '5px 10px',
                  cursor: filteredColumns.length >= 1 ? 'pointer' : '',
                }}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Typography sx={{ color: colorPalette.monotone[5] }}>
                  <FilterIcon
                    style={{
                      width: '13px',
                      height: '13px',
                      marginRight: '3px',
                      color: IconColor.GREY,
                      verticalAlign: 'middle',
                    }}
                  />
                </Typography>
                {filteredColumns.length >= 1 ? (
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      color: colorPalette.skyBlue[7],
                    }}
                  >
                    <span style={{ color: colorPalette.monotone[3] }}>
                      {intl.formatMessage({
                        id: 'wbs.header.filter.sort.label.separator',
                      })}
                    </span>
                    {filteredColumns.length}
                  </Typography>
                ) : (
                  <Typography sx={{ color: colorPalette.monotone[3] }}>
                    {intl.formatMessage({
                      id: 'wbs.header.filter.sort.label.separator',
                    }) + intl.formatMessage({ id: 'wbs.header.none.label' })}
                  </Typography>
                )}
              </button>
            </BootstrapTooltip>
          )}
        </div>
      </ClickAwayListener>
      {filteredColumns.length >= 1 && (
        <FilterPopper
          anchorEl={anchorEl}
          filteredColumns={filteredColumns}
          placement={'bottom-start'}
          onDeleteFilteredColumn={onDeleteFilteredColumn}
          resetFilters={resetFilters}
          getDisplayColumnName={getDisplayColumnName}
        />
      )}
    </>
  )
}
export default FilterButton

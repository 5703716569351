import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react'
import {
  DEFAULT_MAX_FILE_SIZE_MB,
  exceedMaxSize,
  FileType,
  uploadFiles,
} from '../../../../../../utils/attachment'
import { ClickAwayListener, Popper, styled } from '@mui/material'
import Dropzone, { DropzoneState } from 'react-dropzone'
import mime from 'mime'
import { intl } from '../../../../../../i18n'
import AttachmentList from '../../../../../containers/meta/AttachmentList'
import {
  addScreenMessage,
  MessageLevel,
} from '../../../../../../store/messages'
import { useDispatch } from 'react-redux'
import { Button } from '../../../../../components/buttons'
import {
  Attachment,
  AttachmentCandidate,
} from '../../../../../../domain/value-object/Attachment'
import { PopperRoot } from '../../../../../components/menu'
import { TextInput } from '../../../../../components/inputs/TextInput'
import {
  DeleteAttachmentConfirmDialog,
  useDeleteAttachmentConfirmDialog,
} from './DeleteAttachmentConfirmDialog'
import { AddAttachmentLinkPopperContent } from './AddAttachmentLinkPopperContent'
import { generateUuid } from '../../../../../../utils/uuids'
import Loading, {
  useLoading,
} from '../../../../../components/process-state-notifications/Loading'

// type AttachingFile = Pick<Attachment, ''>
type AttachmentTabProps = {
  attachments: Attachment[] | undefined
  onUpload: (v: AttachmentCandidate[]) => Promise<void>
  onDelete: (v: Attachment[]) => Promise<void>
  acceptingFileTypes: string[]
  maxFileSize?: number
}
export const AttachmentTab = ({
  attachments: _attachments,
  acceptingFileTypes,
  onUpload,
  onDelete: _onDelete,
  maxFileSize,
}: AttachmentTabProps) => {
  const attachments = useMemo(() => _attachments || [], [_attachments])
  const accept = useMemo(
    () => acceptingFileTypes.map(v => mime.getType(v)).join(','),
    [acceptingFileTypes]
  )
  const dispatch = useDispatch()
  const { isLoading, showLoading, hideLoading } = useLoading()
  const showSuccessUploadMessage = useCallback(
    (uploadedFileNames: string[]) => {
      dispatch(
        addScreenMessage('wbsItem', {
          type: MessageLevel.SUCCESS,
          title: intl.formatMessage({
            id: 'attachment.title.uploadComplete',
          }),
          text: intl.formatMessage(
            { id: 'attachment.message.uploadComplete' },
            { attach: uploadedFileNames.join('\n') }
          ),
        })
      )
    },
    [dispatch]
  )
  const showSuccessDeleteMessage = useCallback(
    (deletedFileNames: string[]) => {
      dispatch(
        addScreenMessage('wbsItem', {
          type: MessageLevel.SUCCESS,
          title: intl.formatMessage({
            id: 'attachment.title.deleteComplete',
          }),
          text: intl.formatMessage(
            { id: 'attachment.message.deleteComplete' },
            { removedItem: deletedFileNames.join('\n') }
          ),
        })
      )
    },
    [dispatch]
  )
  const dropAccepted = useCallback(
    async (acceptingFiles: File[]) => {
      if (
        exceedMaxSize(acceptingFiles, maxFileSize || DEFAULT_MAX_FILE_SIZE_MB)
      ) {
        return
      }
      showLoading()
      const uploaded = await uploadFiles(acceptingFiles)
      await onUpload(uploaded)
      hideLoading()
      showSuccessUploadMessage(uploaded.map(v => v.name))
    },
    [showSuccessUploadMessage, maxFileSize, onUpload, showLoading, hideLoading]
  )
  const dropRejected = useCallback(() => {
    dispatch(
      addScreenMessage('wbsItem', {
        type: MessageLevel.WARN,
        title: intl.formatMessage({ id: 'attachment.title.fileCannotUpload' }),
      })
    )
  }, [dispatch])

  const deleteAttachment = useCallback(
    async (targets: Attachment[]) => {
      showLoading()
      await _onDelete(targets)
      hideLoading()
      showSuccessDeleteMessage(targets.map(v => v.name))
    },
    [_onDelete, showSuccessDeleteMessage, showLoading, hideLoading]
  )
  const deleteAttachmentConfirmDialogState =
    useDeleteAttachmentConfirmDialog(deleteAttachment)
  const onClickDelete = useCallback(
    (attachment: Attachment, index: number) => {
      deleteAttachmentConfirmDialogState.openDialog(attachment)
    },
    [deleteAttachmentConfirmDialogState]
  )

  const [isOpenAddLinkPopper, setIsOpenAddLinkPopper] = useState<boolean>(false)
  const openAddLinkPopper = useCallback(() => setIsOpenAddLinkPopper(true), [])
  const closeAddLinkPopper = useCallback(
    () => setIsOpenAddLinkPopper(false),
    []
  )

  const addFileLink = useCallback(
    async (url: string, name: string) => {
      showLoading()
      await onUpload([
        {
          uuid: generateUuid(),
          source: FileType.LINK,
          url,
          name,
        },
      ])
      hideLoading()
      showSuccessUploadMessage([name])
    },
    [onUpload, showLoading, hideLoading, showSuccessUploadMessage]
  )

  const ref = useRef<HTMLButtonElement>(null)
  // Define child component in dropzone as function since Dropzone requires.
  const dropzoneContents = useCallback(
    ({ getRootProps, getInputProps }: DropzoneState) => {
      return (
        <DropzoneContent {...getRootProps()}>
          {/* This input tag is neeeded by react-dropzone. */}
          <input {...getInputProps()} />
          <TableContent>
            <AttachmentList
              attachments={attachments}
              onDelete={onClickDelete}
            />
          </TableContent>
          <FileDropArea>
            {intl.formatMessage({
              id: 'attachment.fileDropAttach',
            })}
          </FileDropArea>
          <Button
            ref={ref}
            size="m"
            colorPattern="skyBlue"
            variant="outlined"
            onClick={openAddLinkPopper}
          >
            ファイルのリンクを追加
          </Button>
        </DropzoneContent>
      )
    },
    [attachments, openAddLinkPopper, onClickDelete]
  )
  return (
    <>
      <Loading isLoading={isLoading} />
      <Root>
        <Dropzone
          onDropAccepted={dropAccepted}
          onDropRejected={dropRejected}
          multiple={true}
          noClick={true}
          accept={accept}
        >
          {dropzoneContents}
        </Dropzone>
      </Root>
      <Popper open={isOpenAddLinkPopper} anchorEl={ref.current}>
        <ClickAwayListener onClickAway={closeAddLinkPopper}>
          <PopperRoot width={300}>
            <AddAttachmentLinkPopperContent
              submit={addFileLink}
              close={closeAddLinkPopper}
            />
          </PopperRoot>
        </ClickAwayListener>
      </Popper>
      <DeleteAttachmentConfirmDialog {...deleteAttachmentConfirmDialogState} />
    </>
  )
}

const Root = styled('div')({
  display: 'flex',
  height: '100%',
})

const DropzoneContent = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '12px',
})
const TableContent = styled('div')({
  width: '100%',
  overflowX: 'scroll',
})
const FileDropArea = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '52px',
  width: '60%',
  border: '1px dashed #DDDDDD',
})

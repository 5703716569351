import {
  Button,
  ClickAwayListener,
  Popper,
  styled,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material'
import AddIcon from '../../../../assets/add.svg'
import ArrowIcon from '../../../../assets/subdirectory_arrow_right.svg'
import { colorPalette } from '../../../style/colorPallete'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import { PropsWithChildren, useMemo } from 'react'
import { intl } from '../../../../i18n'

const BUTTON_SIZE: number = 14
const BUTTON_MARGIN: number = 1
const BUTTON_MARGIN_WITH_SECOND_ICON: number = 6
const ADD_BUTTON_X: number = -BUTTON_SIZE * 1.5
const TOP_BUTTON_Y: number = -BUTTON_SIZE / 2
const CHILD_BUTTON_X: number = 0
const CHILD_BUTTON_MARGIN: number = 2
const CHILD_BUTTON_OFFSET: number = BUTTON_SIZE * 2 + CHILD_BUTTON_MARGIN

const StyledIcon = styled('img')({
  width: '10px',
  height: '10px',
})

type StyledButtonProps = PropsWithChildren<{
  numOfIcon: number
}>

const StyledButton = styled(Button)<StyledButtonProps>(({ numOfIcon }) => ({
  position: 'absolute',
  padding: '2px 0px 0px 0px',
  width: `${BUTTON_SIZE * numOfIcon} px`,
  height: `${BUTTON_SIZE}px`,
  gap: '0px',
  borderRadius: 1 < numOfIcon ? '25%' : '50%',
  border: `1px solid ${colorPalette.monotone[1]}`,
  opacity: '0px',
  color: colorPalette.monotone[4],
  background: colorPalette.monotone[0],
  minWidth: `${BUTTON_SIZE}px`,
  cursor: 'pointer',
  boxShadow: `0px 2px 4px 0px #7B8CAA4D`,
  '&:hover': {
    background: colorPalette.skyBlue[1],
    border: `1px solid ${colorPalette.skyBlue[1]}`,
  },
}))

export type ActionPopperButtonProps = {
  iconUrl: string
  onClick: () => void
}
export type ActionPopperAddChildButtonProps = ActionPopperButtonProps & {
  tooltipTitle: string
  onClick: (type?: string) => void
}

type Props = {
  anchorEl: HTMLElement | undefined
  originX?: number
  originY?: number
  height?: number | undefined
  addAboveButtonProps?: ActionPopperButtonProps
  addBelowButtonProps?: ActionPopperButtonProps
  addChildButtonProps?: ActionPopperAddChildButtonProps[]
  onClose: () => void
}

const ActionButton = ({
  tooltipLabelId,
  tooltipPlacement,
  onClick,
  posX,
  posY,
  iconSource,
  iconSx,
  subIconSource,
  subIconSx,
}: {
  tooltipLabelId: string
  tooltipPlacement:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  onClick: () => void
  posX: number
  posY: number
  iconSource: string
  iconSx?: SxProps<Theme>
  subIconSource?: string
  subIconSx?: SxProps<Theme>
}) => {
  return (
    <Tooltip
      arrow={true}
      title={intl.formatMessage({
        id: tooltipLabelId,
      })}
      placement={tooltipPlacement}
    >
      <StyledButton
        numOfIcon={subIconSource ? 2 : 1}
        onClick={onClick}
        sx={{
          top: `${posY}px`,
          left: `${posX}px`,
        }}
      >
        <StyledIcon src={iconSource} sx={iconSx} />
        {subIconSource && <StyledIcon src={subIconSource} sx={subIconSx} />}
      </StyledButton>
    </Tooltip>
  )
}

const ActionPopper = ({
  anchorEl,
  originX = 0,
  originY = 0,
  height = ROW_HEIGHT.SMALL,
  addAboveButtonProps,
  addBelowButtonProps,
  addChildButtonProps,
  onClose,
}: Props) => {
  const bottomButtonY = useMemo(() => height - BUTTON_SIZE / 2, [height])

  if (!anchorEl) return <></>
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement={'top-start'}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [originX, -originY - height],
            },
          },
        ]}
        sx={{
          height: `${height}px`,
          zIndex: 9999,
        }}
      >
        {addAboveButtonProps && (
          <ActionButton
            tooltipLabelId={'action.add.row.above'}
            tooltipPlacement={'top'}
            onClick={addAboveButtonProps.onClick}
            posX={
              ADD_BUTTON_X -
              (addAboveButtonProps.iconUrl
                ? BUTTON_MARGIN_WITH_SECOND_ICON
                : BUTTON_MARGIN)
            }
            posY={TOP_BUTTON_Y}
            iconSource={AddIcon}
            iconSx={{
              margin: '0 0 2px 0',
            }}
            subIconSource={addAboveButtonProps.iconUrl}
          />
        )}
        {addBelowButtonProps && (
          <ActionButton
            tooltipLabelId={'action.add.row.below'}
            tooltipPlacement={'top'}
            onClick={addBelowButtonProps.onClick}
            posX={
              ADD_BUTTON_X -
              (addBelowButtonProps.iconUrl
                ? BUTTON_MARGIN_WITH_SECOND_ICON
                : BUTTON_MARGIN)
            }
            posY={bottomButtonY}
            iconSource={AddIcon}
            iconSx={{
              margin: '0 0 2px 0',
            }}
            subIconSource={addBelowButtonProps.iconUrl}
            subIconSx={{
              margin: '0 2px 2px 0',
            }}
          />
        )}
        {addChildButtonProps &&
          addChildButtonProps.map(
            (buttonProps: ActionPopperAddChildButtonProps, index: number) => (
              <ActionButton
                key={`action-popper-add-child-btn-${index}`}
                tooltipLabelId={buttonProps.tooltipTitle}
                tooltipPlacement={'top'}
                onClick={buttonProps.onClick}
                posX={CHILD_BUTTON_X + index * CHILD_BUTTON_OFFSET}
                posY={bottomButtonY}
                iconSource={ArrowIcon}
                iconSx={{
                  margin: '0 0 2px 2px',
                }}
                subIconSource={buttonProps.iconUrl}
                subIconSx={{
                  margin: '0 2px 2px 0',
                }}
              />
            )
          )}
      </Popper>
    </ClickAwayListener>
  )
}

export default ActionPopper

import { useCallback, useEffect, useMemo, useState } from 'react'
import { Tab, Tabs } from '../../../../containers/SingleSheetV2/Tab'

export enum WbsItemRightTab {
  DISCUSSION,
  ACTUAL_WORK,
  CHANGE_LOG,
  DEVELOPMENT,

  /**
   * Show the description tab only during creating wbs item on SingleSheet.
   * Now this case is only SingleSheet is opened on ProjectPlan when users create a ticket.
   */
  DESCRIPTION,
}
export const WbsItemRightTabs = ({
  isCreatingWbsItem,
  rightTab,
  onChangeRightTab,
}: WbsItemRightTabsProps) => {
  if (isCreatingWbsItem) {
    return (
      <Tabs value={rightTab} onChange={onChangeRightTab}>
        <Tab label="説明" value={WbsItemRightTab.DESCRIPTION} />
      </Tabs>
    )
  }
  return (
    <Tabs value={rightTab} onChange={onChangeRightTab}>
      <Tab label="ディスカッション" value={WbsItemRightTab.DISCUSSION} />
      <Tab label="変更履歴" value={WbsItemRightTab.CHANGE_LOG} />
      <Tab label="開発情報" value={WbsItemRightTab.DEVELOPMENT} />
    </Tabs>
  )
}
export const TaskRightTabs = ({
  isCreatingWbsItem,
  rightTab,
  onChangeRightTab,
}: WbsItemRightTabsProps) => {
  if (isCreatingWbsItem) {
    return (
      <Tabs value={rightTab} onChange={onChangeRightTab}>
        <Tab label="説明" value={WbsItemRightTab.DESCRIPTION} />
      </Tabs>
    )
  }
  return (
    <Tabs value={rightTab} onChange={onChangeRightTab}>
      <Tab label="ディスカッション" value={WbsItemRightTab.DISCUSSION} />
      <Tab label="実績" value={WbsItemRightTab.ACTUAL_WORK} />
      <Tab label="変更履歴" value={WbsItemRightTab.CHANGE_LOG} />
      <Tab label="開発情報" value={WbsItemRightTab.DEVELOPMENT} />
    </Tabs>
  )
}

export type WbsItemRightTabsProps = {
  isCreatingWbsItem: boolean
  rightTab: WbsItemRightTab
  onChangeRightTab: (
    event: React.SyntheticEvent,
    newValue: WbsItemRightTab
  ) => void
}
export const useWbsItemRightTabs = (
  isCreatingWbsItem: boolean
): Omit<WbsItemRightTabsProps, 'isCreatingWbsItem'> => {
  const [rightTab, setRightTab] = useState<WbsItemRightTab>(
    WbsItemRightTab.DISCUSSION
  )
  const onChangeRightTab = useCallback(
    (_: React.SyntheticEvent, newValue: WbsItemRightTab) => {
      setRightTab(newValue)
    },
    []
  )

  // Set default tab.
  useEffect(() => {
    if (isCreatingWbsItem) {
      setRightTab(WbsItemRightTab.DESCRIPTION)
    } else {
      setRightTab(WbsItemRightTab.DISCUSSION)
    }
  }, [isCreatingWbsItem])

  const state = useMemo(
    () => ({
      rightTab,
      onChangeRightTab,
    }),
    [rightTab, onChangeRightTab]
  )

  return state
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ICellRendererParams, RowNode } from 'ag-grid-community'
import { useProjectPrivateContext } from '../../../../../context/projectContext'
import { WbsItemIcon } from '../../../../../components/icons/WbsItemIcon'
import { CellRendererRoot } from '.'
import { styled } from '@mui/material'
import { colorPalette } from '../../../../../style/colorPallete'
import { WbsItemType } from '../../../../../../domain/entity/WbsItemEntity'

type ProjectPlanRowBase = {
  type: WbsItemType
}
type ProjectPlanNameCellRendererProps = ICellRendererParams<ProjectPlanRowBase>

export const ProjectPlanNameCellRenderer = ({
  value,
  data,
}: ProjectPlanNameCellRendererProps) => {
  return (
    <CellRendererRoot>
      <Name>{value}</Name>
    </CellRendererRoot>
  )
}

const IconWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const Name = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: colorPalette.monotone[9],
  fontSize: '14px',
  lineHeight: 1,
  padding: '0 6px',
})

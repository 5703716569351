import * as React from 'react'

const CoinIcon = React.forwardRef(
  (
    props: { style?: React.CSSProperties },
    ref: React.LegacyRef<SVGSVGElement>
  ) => (
    <svg
      {...props}
      width={props.style?.fontSize || 25}
      height={props.style?.fontSize || 25}
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
    >
      <g clip-path="url(#clip0_4678_162294)">
        <path
          d="M221.968 54.6133L240.276 72.9211C286.54 119.185 286.54 194.318 240.276 240.582C194.005 286.853 118.879 286.847 72.6143 240.582L54.3065 222.275L221.968 54.6133Z"
          fill="#FF7B22"
        />
        <path
          d="M143.554 262.32C209.03 262.32 262.109 209.241 262.109 143.765C262.109 78.2896 209.03 25.2109 143.554 25.2109C78.0786 25.2109 25 78.2896 25 143.765C25 209.241 78.0786 262.32 143.554 262.32Z"
          fill="#EAD303"
        />
        <path
          d="M143.555 66.6191L174.043 102.912L217.976 120.691L192.884 160.909L189.55 208.185L143.555 196.747L97.5591 208.185L94.225 160.909L69.1333 120.691L113.067 102.912L143.555 66.6191Z"
          fill="#FF7B22"
        />
        <path
          opacity="0.4"
          d="M60.0667 227.353C13.7714 181.058 13.7714 105.991 60.0667 59.6961C106.362 13.4008 181.428 13.4008 227.724 59.6961L60.0667 227.353Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4678_162294">
          <rect
            width="250"
            height="250.345"
            fill="white"
            transform="translate(25 24.9653)"
          />
        </clipPath>
      </defs>
    </svg>
  )
)

export default CoinIcon

import { useMemo } from 'react'
import { useKeyBind } from '../../../hooks/useKeyBind'
import { KEY_SAVE, KeyBindListener } from '../../../model/keyBind'

export const useSingleSheetKeyBind = (submit: () => void) => {
  const keySaveListher = useMemo<KeyBindListener>(() => {
    return {
      key: KEY_SAVE,
      fn: () => {
        submit()
      },
      stopDefaultBehavior: true,
    }
  }, [submit])
  const listhers = useMemo(() => [keySaveListher], [keySaveListher])
  useKeyBind(listhers, [listhers])
}

import {
  CellClickedEvent,
  CellStyle,
  CellStyleFunc,
  ColDef,
  GetContextMenuItemsParams,
  MenuItemDef,
  NewValueParams,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  ColumnType,
  columnTypes,
  dateValueParser,
  refreshAncestors,
} from '../../containers/commons/AgGrid'
import ProjectApi from '../../../lib/functions/project'
import {
  getAll,
  getEarliestScheduledDate,
  getLatestScheduledDate,
  getTree,
  getUpdatedRowAncestors,
  ProductBacklogItemInput,
  ProjectPlanBatchDeltaInput,
  ProjectPlanBatchInput,
  ProjectPlanCumulation,
  ProjectPlanDetail,
  ProjectPlanUpdateBatchDeltaRequest,
  RequestOfUpdateBatchDelta,
  SprintItemInput,
  TicketInput,
  TicketListInput,
  updateBatchDelta,
  WatchersInput,
} from '../../../lib/functions/projectPlan'
import WbsItemApi, {
  createDeltaRequestByRow,
  createRequestByRow,
  createRowByResponse,
  getOpenWbsItemDetailSpec,
  getWbsItemFunctionUuid,
  succeedAccountableToAddRow,
  succeedResponsibleToAddRow,
  succeedScheduledDateToAddRow,
  taskActualResultExists,
  WbsItemDeltaInput,
  WbsItemDetail,
  WbsItemRow,
} from '../../../lib/functions/wbsItem'
import ViewMeta from '../../containers/meta/ViewMeta'
import {
  AgGridValueGetter,
  AgGridValueSetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetProps,
  BulkSheetSpecificProps,
  BulkSheetState,
  OpenDetailSpec,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import { generateUuid } from '../../../utils/uuids'
import { SprintStatus } from '../../../lib/functions/sprint'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { APIResponse } from '../../../lib/commons/api'
import ContextMenu, {
  ContextMenuGroup,
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import { dateTermToViewConfig, openProgressReport } from '../ProgressReport'
import Workload, {
  getWorkloadUnitValue,
  round,
  WorkloadUnit,
} from '../../../lib/functions/workload'
import {
  AggregateTargetValue,
  deliverableAggregator,
  taskAggregator,
} from '../../containers/commons/AgGrid/lib/aggregator'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import {
  openWbsItemSearch,
  SearchConditionEndDelayed,
} from '../WbsItemSearch/wbsItemSearchOptions'
import { CSSProperties } from 'react'
import objects from '../../../utils/objects'
import store from '../../../store'
import {
  TicketCumulation,
  TicketListDetail,
} from '../../../lib/functions/ticketList'
import { intl } from '../../../i18n'
import { openTicketList } from '../TicketLists/TicketListsOptions'
import {
  Comment,
  commentListToSummary,
  CommentSummary,
} from '../../../store/comments'
import CommentHeaderWbsItem, {
  mapRowDataForCommentHeader,
} from '../../containers/Comment/CommentHeaderWbsItem'
import { AddMultipleTicketDialog } from '../../components/dialogs/AddMultipleTicketDialog'
import DateVO from '../../../vo/DateVO'
import { openComment } from '../../../store/information'
import { AttachmentSummary } from '../../../utils/attachment'
import { open } from '../../router'
import estimatedHourCellRenderer from './estimatedHourCellRenderer'
import actualWorkCellRenderer from './actualWorkCellRenderer'
import { SpeedDialActionProps } from '../../containers/commons/AgGrid/components/cell/custom/detail/cellRenderer'
import projectPlanFacadeAggregator from '../../containers/commons/AgGrid/lib/aggregator/projectPlanAggregator'
import { canParseDate, removeDateWhenPast } from '../../../utils/date'
import _ from 'lodash'
import { requireSave } from '../../../store/requiredSaveData'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import { getSelectedWorkloadUnit } from '../../components/toggleGroups'
import { addScreenMessage, MessageLevel } from '../../../store/messages'
import SprintBacklogApi, {
  createSprintBacklogUpdateBatchRequest,
} from '../../../lib/functions/sprintBacklog'
import { List } from 'immutable'
import {
  getTypeIndex,
  WbsItemTypeCellValue,
} from '../../containers/commons/AgGrid/components/cell/custom/wbsItemType'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { getTicketType } from '../../containers/commons/AgGrid/components/cell/custom/ticketType'
import { KeyBindListener } from '../../model/keyBind'
import {
  GanttDisplayUnit,
  GanttParameterVO,
} from '../../../domain/value-object/GanttParameterVO'
import moment from 'moment'
import {
  SprintProductItemProp,
  UpdateSprintProductItemProps,
  updateSprintProductItems,
} from '../../../lib/functions/sprintProductItem'
import {
  runAsyncWithPerfMonitoring,
  runUseCaseWithPerfMonitoring,
  runWithPerfMonitoring,
} from '../../../utils/monitoring'

export interface ProjectPlanState extends BulkSheetState {}

export const duplicateWbsItem = (original: WbsItemRow): WbsItemRow => {
  const createRandomCode = Math.random().toString(36).slice(-8).toUpperCase()
  return {
    ...original,
    uuid: generateUuid(),
    lockVersion: 0,
    code: createRandomCode,
    status: original.status ?? WbsItemStatus.TODO,
    sprints: [],
    scheduledDate: original.scheduledDate ?? {
      startDate: undefined,
      endDate: undefined,
    },
    actualDate: original.actualDate ?? {
      startDate: undefined,
      endDate: undefined,
    },
    revision: undefined,
    createdBy: undefined,
    createdAt: undefined,
    updatedBy: undefined,
    updatedAt: undefined,
  }
}

export const copyWbsItemOnlySelectedFields = (
  original: WbsItemRow,
  selectedFields: string[]
) => {
  const copied = { ...original }
  const newWbsItem = new WbsItemRow()
  selectedFields.forEach(field => {
    const copyValue = objects.getValue(copied, field)
    objects.setValue(newWbsItem, field, copyValue)
  })
  return newWbsItem
}

interface ICreateWatcher {
  uuid: string
  wbsItem: WbsItemRow
}

export const createWatchers = (
  added?: ICreateWatcher[],
  edited?: {
    before: ICreateWatcher
    after: ICreateWatcher
  }[]
): WatchersInput[] => {
  let input: WatchersInput[] = []
  const createWatchersInput = (row: ICreateWatcher): WatchersInput => ({
    uuid: row.uuid,
    wbsItemUuid: row.wbsItem.uuid,
    userUuids:
      row.wbsItem.watchers && Array.isArray(row.wbsItem.watchers)
        ? row.wbsItem.watchers.map(userBasic => userBasic.uuid)
        : [],
  })

  if (added) added.forEach(row => input.push(createWatchersInput(row)))
  if (edited) edited.forEach(row => input.push(createWatchersInput(row.after)))
  return input
}

export class ProjectPlanRow extends RowData {
  type?: WbsItemType
  wbsItem: WbsItemRow = {}
  cumulation?: ProjectPlanCumulation
  ticketCumulation?: TicketCumulation
  productBacklogItem: boolean = false
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
  ticketListUuid?: string
  typeIndex?: number = -1
}

export class ProjectPlanRowDataSpec extends RowDataSpec<
  ProjectPlanDetail,
  ProjectPlanRow
> {
  columnTypes(): { [key: string]: ColDef } {
    return {
      estimatedWorkload: {
        editable: params =>
          params.data.wbsItem &&
          (params.data.wbsItem.wbsItemType.isDeliverable() ||
            params.data.wbsItem.wbsItemType.isTask()),
      },
      currentSprint: {
        editable: params =>
          !params.data.wbsItem.wbsItemType.isWorkgroup() &&
          !params.data.wbsItem.wbsItemType.isProcess(),
      },
      deliverableWorkload: {
        editable: params =>
          params.data.wbsItem.wbsItemType &&
          params.data.wbsItem.wbsItemType.isDeliverable(),
      },
      taskWorkload: {
        editable: params =>
          params.data.wbsItem.wbsItemType &&
          params.data.wbsItem.wbsItemType.isTask(),
        cellRenderer: estimatedHourCellRenderer,
      },
      actualWork: {
        cellRenderer: actualWorkCellRenderer,
      },
      priorityColumn: {
        valueFormatter: () => {
          return ''
        },
      },
      wbsItemTypeColumn: {
        ...columnTypes().wbsItemTypeColumn,
        filterValueGetter: (params: ValueGetterParams) => {
          return params.data?.wbsItem?.baseWbsItemType?.getNameWithSuffix()
        },
      },
      ticketTypeColumn: {
        ...columnTypes().ticketTypeColumn,
        filterValueGetter: (params: ValueGetterParams) => {
          return getTicketType(params.data?.wbsItem?.baseWbsItemType)?.name
        },
      },
      estimatedAmountDeliverable: {
        editable: params =>
          params.data.wbsItem.wbsItemType &&
          params.data.wbsItem.wbsItemType.isDeliverable(),
      },
      estimatedAmountTask: {
        editable: params =>
          params.data.wbsItem.wbsItemType &&
          params.data.wbsItem.wbsItemType.isTask(),
      },
      actualAmount: {
        editable: params =>
          params.data.wbsItem.wbsItemType &&
          params.data.wbsItem.wbsItemType.isTask(),
      },
    }
  }

  importNewRow(
    v: ProjectPlanDetail,
    ctx: ProjectPlanBulkSheetContext
  ): boolean {
    if (!v.wbsItem.code) {
      const createRandomCode = Math.random()
        .toString(36)
        .slice(-8)
        .toUpperCase()
      v.wbsItem.code = createRandomCode
    }
    const baseTypes = [
      ...store.getState().project.wbsItemTypes.getAll(),
      ...store.getState().project.ticketListTypes,
      ...store.getState().project.ticketTypes,
    ]
    const typeVO = baseTypes.find(t =>
      [t.code, t.getNameWithSuffix()].includes(v.wbsItem.type)
    )
    if (typeVO) {
      v.wbsItem.type = typeVO.isWorkgroup()
        ? WbsItemType.PROCESS
        : typeVO.rootType
      v.wbsItem.typeDto = typeVO.toWbsItemTypeObject()
      v.wbsItem.baseTypeDto = typeVO.toWbsItemTypeObject()
    }
    if (typeVO?.isDeliverable()) {
      v.wbsItem.estimatedHour = round(
        objects.getValue(v.wbsItem, 'estimatedWorkload.deliverable') /
          (ctx.state.gridOptions.context?.workloadUnitState
            ?.hoursPerSelectedUnit || 1)
      )
    }
    if (typeVO?.isTask()) {
      v.wbsItem.estimatedHour = round(
        objects.getValue(v.wbsItem, 'estimatedWorkload.task') /
          (ctx.state.gridOptions.context?.workloadUnitState
            ?.hoursPerSelectedUnit || 1)
      )
    }
    delete v.updatedBy
    delete v.updatedAt
    return true
  }

  createNewRow(
    ctx: ProjectPlanBulkSheetContext,
    params: {
      parent?: ProjectPlanRow
      type: WbsItemTypeVO
      ticketListUuid?: string
    } = {
      parent: undefined,
      type: store.getState().project.wbsItemTypes.process,
      ticketListUuid: undefined,
    }
  ): ProjectPlanRow {
    const { parent, type, ticketListUuid } = params
    const parentWbsItem = parent?.wbsItem
    const parentType = parent?.wbsItem.wbsItemType
    const createRandomCode = Math.random().toString(36).slice(-8).toUpperCase()
    return {
      uuid: generateUuid(),
      type: type.isWorkgroup() ? WbsItemType.PROCESS : type.rootType,
      wbsItem: {
        uuid: '',
        code: createRandomCode,
        type: type.isWorkgroup() ? WbsItemType.PROCESS : type.rootType,
        wbsItemType: type,
        baseWbsItemType: type,
        displayName: '',
        status: WbsItemStatus.TODO,
        team: parentWbsItem?.team ? { ...parentWbsItem.team } : undefined,
        accountable:
          parentWbsItem?.accountable && succeedAccountableToAddRow(parentType)
            ? { ...parentWbsItem.accountable }
            : undefined,
        responsible:
          parentWbsItem?.responsible && succeedResponsibleToAddRow(type)
            ? { ...parentWbsItem.responsible }
            : undefined,
        scheduledDate:
          parentWbsItem?.scheduledDate &&
          succeedScheduledDateToAddRow(type, parentType)
            ? removeDateWhenPast(parentWbsItem.scheduledDate)
            : { startDate: undefined, endDate: undefined },
        actualDate: { startDate: undefined, endDate: undefined },
        ticketType: type.isTicket()
          ? type.code.replace('_LIST', '')
          : undefined,
      },
      productBacklogItem: false,
      parentUuid: parent ? parent.uuid : undefined,
      ticketListUuid: ticketListUuid,
      isAdded: false,
      isEdited: false,
    }
  }

  overwriteRowItemsWithParents(
    params: {
      child: ProjectPlanRow
      parent: ProjectPlanRow
    },
    selectedColIds?: string[]
  ): ProjectPlanRow {
    const { child, parent } = params
    const childType = child.wbsItem.wbsItemType
    const overwritten = { ...child }
    overwritten.wbsItem.status = selectedColIds?.includes('wbsItem.status')
      ? overwritten.wbsItem.status
      : WbsItemStatus.TODO
    overwritten.wbsItem.team = selectedColIds?.includes('wbsItem.team')
      ? overwritten.wbsItem.team
      : parent?.wbsItem?.team
      ? { ...parent.wbsItem.team }
      : undefined
    overwritten.wbsItem.accountable = selectedColIds?.includes(
      'wbsItem.accountable'
    )
      ? overwritten.wbsItem.accountable
      : parent?.wbsItem?.accountable &&
        succeedAccountableToAddRow(parent?.wbsItem.wbsItemType)
      ? { ...parent.wbsItem.accountable }
      : undefined
    overwritten.wbsItem.responsible = selectedColIds?.includes(
      'wbsItem.responsible'
    )
      ? overwritten.wbsItem.responsible
      : parent?.wbsItem?.responsible && succeedResponsibleToAddRow(childType)
      ? { ...parent.wbsItem.responsible }
      : undefined
    overwritten.wbsItem.scheduledDate = selectedColIds?.some(v =>
      v.match(/wbsItem\.scheduledDate.*/)
    )
      ? overwritten.wbsItem.scheduledDate
      : parent?.wbsItem?.scheduledDate &&
        succeedScheduledDateToAddRow(childType, parent.wbsItem?.wbsItemType)
      ? removeDateWhenPast(parent.wbsItem.scheduledDate)
      : { startDate: undefined, endDate: undefined }
    overwritten.parentUuid = parent ? parent.uuid : undefined
    return overwritten
  }

  createRowByResponse(
    response: ProjectPlanDetail,
    viewMeta: ViewMeta
  ): ProjectPlanRow {
    // Response can be the imported data from excel sheet
    const wbsItem: WbsItemDetail = response.wbsItem
    const baseType = new WbsItemTypeVO(wbsItem.baseTypeDto!)
    const typeIndex = getTypeIndex(baseType)

    const base = {
      uuid: response.uuid,
      lockVersion: response.lockVersion,
      type: wbsItem.type,
      displayName: '',
      wbsItem: {},
      cumulation: response.cumulation,
      productBacklogItem: baseType.isDeliverable()
        ? response.productBacklogItem
        : false,
      parentUuid: response.parentUuid,
      prevSiblingUuid: response.prevSiblingUuid,
      ticketCumulation: response.ticketCumulation,
      ticketListUuid: response.ticketListUuid,
      commentSummary: response.commentSummary,
      deliverableAttachmentSummary: response.deliverableAttachmentSummary,
      extensions: viewMeta.deserializeEntityExtensions(
        response.wbsItem.extensions || []
      ),
      typeIndex,
    }
    base.wbsItem = wbsItem
      ? createRowByResponse(wbsItem, response.cumulation)
      : {}
    return base
  }

  duplicateRow(original: ProjectPlanRow): ProjectPlanRow {
    return {
      ...original,
      wbsItem: duplicateWbsItem(original.wbsItem),
      cumulation: undefined,
    }
  }

  duplicateRowWithSelectedCols(
    original: ProjectPlanRow,
    selectedColIds: string[]
  ): ProjectPlanRow {
    const wbsItemFields = selectedColIds
      .map(colId => colId.split('wbsItem.')[1])
      .filter(field => !!field)
    const copiedWbsItem = copyWbsItemOnlySelectedFields(
      original.wbsItem,
      wbsItemFields
    )
    return {
      ...original,
      wbsItem: duplicateWbsItem(copiedWbsItem),
      cumulation: undefined,
    }
  }

  replaceRow(original: ProjectPlanRow, row: ProjectPlanRow): boolean {
    if (original.wbsItem.type !== row.wbsItem.type) {
      // Remove the alert
      alert(
        `タイプの付け替えはできません。コード=${row.wbsItem.code}, 前=${original.wbsItem.type}, 後=${row.wbsItem.type}`
      )
      return false
    }
    row.wbsItem.uuid = original.wbsItem.uuid
    row.wbsItem.lockVersion = original.wbsItem.lockVersion
    row.wbsItem.revision = original.wbsItem.revision
    row.wbsItem.updatedAt = original.wbsItem.updatedAt
    row.wbsItem.updatedBy = original.wbsItem.updatedBy
    original.wbsItem.estimatedWorkload = row.wbsItem.estimatedWorkload
    return true
  }

  addChild(parent: ProjectPlanRow, child: ProjectPlanDetail): boolean {
    return [
      WbsItemType.PROCESS,
      WbsItemType.DELIVERABLE_LIST,
      WbsItemType.DELIVERABLE,
      WbsItemType.TASK,
    ].includes(child.wbsItem.type)
  }
}

export interface ProjectPlanProps extends BulkSheetSpecificProps {
  updateReportToolbar: () => void
}

export interface ProjectPlanBulkSheetContext
  extends BulkSheetContext<
    ProjectPlanProps,
    ProjectPlanDetail,
    ProjectPlanRow,
    ProjectPlanState
  > {}

export interface ProjectPlanBulkSheetProps
  extends BulkSheetProps<
    ProjectPlanProps,
    ProjectPlanDetail,
    ProjectPlanRow,
    ProjectPlanState
  > {}

export enum QuickFilterKeys {
  ACCOUNTABLE = 'ACCOUNTABLE',
  RESPONSIBLE_OR_ASSIGNEE = 'RESPONSIBLE_OR_ASSIGNEE',
  MY_RESPONSIBILITY = 'MY_RESPONSIBILITY',
  NOT_DONE = 'NOT_DONE',
  DELAY = 'DELAY',
  COMMENT = 'COMMENT',
  START_BY_TODAY = 'START_BY_TODAY',
  END_BY_TODAY = 'END_BY_TODAY',
}

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  PLANNING = 'PLANNING',
  WORK_RESULT = 'WORK_RESULT',
  PROGRESS = 'PROGRESS',
  PRODUCTIVITY = 'PRODUCTIVITY',
  RESTORE = 'RESTORE',
}

export class ProjectPlanOptions extends BulkSheetOptions<
  ProjectPlanProps,
  ProjectPlanDetail,
  ProjectPlanRow,
  ProjectPlanState
> {
  addable = true
  groupColumnWidth = 404

  getRowsToRemove = (
    nodes: RowNode[],
    ctx: ProjectPlanBulkSheetContext
  ): {
    rows: RowNode[]
    unremovableReasonMessageId?: string
  } => {
    const resultOfCanRemoveRow = (reasonId: string | undefined = undefined) => {
      return {
        removable: !reasonId,
        unremovableReasonMessageId: reasonId,
      }
    }
    const canRemoveRow = (node: RowNode) => {
      if (node.data.ticketCumulation?.countTicket) {
        return resultOfCanRemoveRow(
          'bulksheet.contextMenu.remove.disabled.reason.exists.ticket'
        )
      }
      if (!node.hasChildren()) {
        const removable = !node.data.cumulation?.actualHour
        return resultOfCanRemoveRow(
          removable
            ? undefined
            : 'bulksheet.contextMenu.remove.disabled.reason.actual.registerd'
        )
      }
      for (let rowNode of node.allLeafChildren) {
        if (rowNode.data.ticketCumulation?.countTicket) {
          return resultOfCanRemoveRow(
            'bulksheet.contextMenu.remove.disabled.reason.exists.ticket'
          )
        }
        if (
          rowNode.data.wbsItem.wbsItemType.isTask() &&
          0 < (rowNode.data.cumulation?.actualHour || 0)
        ) {
          return resultOfCanRemoveRow(
            'bulksheet.contextMenu.remove.disabled.reason.actual.registerd'
          )
        }
      }
      return resultOfCanRemoveRow()
    }
    let result: RowNode[] = []
    for (const node of nodes) {
      const { removable, unremovableReasonMessageId } = canRemoveRow(node)
      if (!removable) {
        return {
          rows: [],
          unremovableReasonMessageId,
        }
      }
      result.push(...node.allLeafChildren)
    }
    result = result.filter((node, index, self) => self.indexOf(node) === index)
    return { rows: result }
  }
  displayNameField = 'wbsItem.displayName'
  draggable = true
  enableExcelExport = true
  enableExcelImport = true
  enableExpandAllRow = true
  canAddChild = (
    row: ProjectPlanRow,
    parentRow: ProjectPlanRow | undefined,
    ctx: ProjectPlanBulkSheetContext
  ) => {
    if (
      !row.wbsItem ||
      (!parentRow &&
        !row.wbsItem.wbsItemType?.isWorkgroup() &&
        !row.wbsItem.wbsItemType?.isProcess())
    ) {
      return false
    }
    return !!row.wbsItem.wbsItemType?.canBeChildOf(
      parentRow?.wbsItem.wbsItemType!
    )
  }
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.ProjectPlanColumnAndFilterState}-${ctx.state.uuid}`
  fieldRefreshedAfterMove = [
    'wbsItem.status',
    'wbsItem.ticketType',
    'wbsItem.estimatedWorkload.deliverable',
    'wbsItem.estimatedWorkload.task',
    'wbsItem.actualHour',
    'wbsItem.estimatedAmount.deliverable',
    'wbsItem.estimatedAmount.task',
    'wbsItem.actualAmount',
    'completionAmountRate',
    'ganttChart',
  ]
  rowDataSpec = new ProjectPlanRowDataSpec()
  uniqueColumn = 'projectPlan.wbsItem.code'
  pinnedColumns = [
    'projectPlan.wbsItem.code',
    'projectPlan.wbsItem.type',
    'projectPlan.wbsItem.ticketType',
    'projectPlan.wbsItem.status',
    'projectPlan.wbsItem.substatus',
    'projectPlan.action',
    'projectPlan.attachment',
    'projectPlan.wbsItem.displayName',
    'projectPlan.wbsItem.priority',
    'projectPlan.wbsItem.difficulty',
    'projectPlan.wbsItem.team',
    'projectPlan.wbsItem.accountable',
    'projectPlan.wbsItem.responsible',
    'projectPlan.wbsItem.assignee',
    'projectPlan.wbsItem.watchers',
    'projectPlan.wbsItem.currentSprint',
    'projectPlan.wbsItem.scheduledDate.startDate',
    'projectPlan.wbsItem.scheduledDate.endDate',
    'projectPlan.wbsItem.actualDate.startDate',
    'projectPlan.wbsItem.actualDate.endDate',
    'projectPlan.startDelayDays',
    'projectPlan.endDelayDays',
  ]
  lockedColumns = [
    'projectPlan.wbsItem.code',
    'projectPlan.wbsItem.type',
    'projectPlan.wbsItem.ticketType',
    'projectPlan.wbsItem.status',
    'projectPlan.wbsItem.substatus',
    'projectPlan.action',
    'projectPlan.attachment',
  ]
  customRenderers = {
    'projectPlan.wbsItem.displayName': 'wbsItemTreeCellRenderer',
  }
  customColumnTypes = {
    'projectPlan.wbsItem.type': [ColumnType.wbsItemType],
    'projectPlan.wbsItem.status': [ColumnType.wbsItemStatus],
    'projectPlan.wbsItem.estimatedStoryPoint': ['estimatedWorkload'],
    'projectPlan.wbsItem.estimatedWorkload.deliverable': [
      'deliverableWorkload',
    ],
    'projectPlan.wbsItem.estimatedWorkload.task': ['taskWorkload'],
    'projectPlan.wbsItem.actualHour': ['actualWork'],
    'projectPlan.wbsItem.currentSprint': ['currentSprint'],
    'projectPlan.wbsItem.scheduledDate.startDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'projectPlan.wbsItem.scheduledDate.endDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'projectPlan.wbsItem.actualDate.startDate': [ColumnType.wbsItemActualDate],
    'projectPlan.wbsItem.actualDate.endDate': [ColumnType.wbsItemActualDate],
    'projectPlan.commentSummary.latestComment': [ColumnType.comment],
    'projectPlan.wbsItem.priority': ['priorityColumn'],
    sequenceNo: [ColumnType.wbsItemSequenceNo],
    'projectPlan.wbsItem.estimatedAmount.deliverable': [
      'estimatedAmountDeliverable',
    ],
    'projectPlan.wbsItem.estimatedAmount.task': ['estimatedAmountTask'],
    'projectPlan.wbsItem.actualAmount': ['actualAmount'],
  }
  copyRow = copyRow
  getPasteColumnsCandidate = getPasteColumnsCandidate
  getRowDataUuidForFilter = rowNode => rowNode.data.wbsItem.uuid
  getRowStyle = (params): CSSProperties | undefined => {
    if (params.data.wbsItem && !params.data.wbsItem.wbsItemType.isTask()) {
      return {
        fontWeight: 'bold',
      }
    }
  }
  getDefaultContext = (): any => ({
    wbsItemType: WbsItemType.DELIVERABLE,
    aggregateTargetType: AggregateField.WBS_ITEM_COUNT,
    workloadUnit: WorkloadUnit.DAY,
  })

  private canAddRow = (
    type: WbsItemTypeVO,
    parentRow: ProjectPlanRow | undefined,
    targetRowData: ProjectPlanRow,
    canAddChild: boolean,
    multipleRowsContainingTopSelected: boolean | null,
    above?: boolean
  ): boolean => {
    return above
      ? !!parentRow &&
          type.canBeChildOf(parentRow.wbsItem.wbsItemType!) &&
          !multipleRowsContainingTopSelected
      : canAddChild &&
          !!targetRowData.wbsItem.wbsItemType &&
          type.canBeChildOf(targetRowData.wbsItem.wbsItemType)
  }

  private addAbove = (
    ctx: ProjectPlanBulkSheetContext,
    selectedNodes: RowNode[],
    type: WbsItemTypeVO,
    ticketListUuid?: string
  ): void => {
    const firstRow: ProjectPlanRow = selectedNodes[0].data
    const parent = ctx.rowDataManager.getParent(firstRow.uuid)
    const rows = selectedNodes.map(v =>
      this.rowDataSpec.createNewRow(ctx, {
        parent,
        type,
        ticketListUuid,
      })
    )
    ctx.rowDataManager.addRowsTo(rows, {
      parentUuid: parent?.uuid,
      prevSiblingUuid: firstRow.prevSiblingUuid,
    })
  }

  private addRow = (
    ctx: ProjectPlanBulkSheetContext,
    selectedNodes: RowNode[],
    targetRow: RowNode | null,
    type: WbsItemTypeVO,
    options?: {
      above?: boolean
      ticketType?: string
      ticketListUuid?: string
    }
  ): void => {
    runUseCaseWithPerfMonitoring('Add ProjectPlan Row', () => {
      const { above, ticketListUuid } = options
        ? options
        : {
            above: false,
            ticketListUuid: undefined,
          }
      if (above) {
        this.addAbove(ctx, selectedNodes, type, ticketListUuid)
        return
      }

      if (!targetRow) return
      const newRowData = this.rowDataSpec.createNewRow(ctx, {
        parent: targetRow.data,
        type,
        ticketListUuid,
      })
      if (type.isWorkgroup()) {
        ctx.addRow(undefined, undefined, newRowData)
      } else {
        ctx.addRow(targetRow.id, targetRow.id, newRowData)
      }
      targetRow.setExpanded(true)
    })
  }

  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: ProjectPlanBulkSheetContext
  ): ContextMenu | undefined => {
    if (!params.node || !params.node.data || params.node.data.isTotal) {
      return
    }
    const wbsItemTypes = store.getState().project.wbsItemTypes
    const row = params.node.data
    const treeRootNode =
      ctx.treeRoot && ctx.treeRoot.uuid
        ? ctx.gridApi!.getRowNode(ctx.treeRoot.uuid)
        : undefined

    const addMenuSuffix = (above?: boolean) =>
      above
        ? intl.formatMessage({ id: 'bulksheet.contextMenu.above' })
        : intl.formatMessage({ id: 'bulksheet.contextMenu.toChild' })
    let selectedNodes = params.api.getSelectedNodes()
    if (
      selectedNodes.length === 0 ||
      !selectedNodes.map(v => v.id).includes(params.node.id) ||
      !selectedNodes[0].data
    ) {
      selectedNodes = [params.node]
    }
    const parent = ctx.rowDataManager.getParent(selectedNodes[0].data.uuid)
    const canAddChild = row.wbsItem?.type && !!row.wbsItem?.displayName.trim()
    const cellRanges = params.api.getCellRanges()
    // selectedNodes do not contain top row
    const multipleRowsContainingTopSelected =
      cellRanges &&
      cellRanges.some(
        range => range.startRow?.rowIndex === 0 || range.endRow?.rowIndex === 0
      ) &&
      cellRanges.some(
        range =>
          (range.endRow?.rowIndex || 0) - (range.startRow?.rowIndex || 0) !== 0
      )

    const canAddRowInContextMenu = (
      type: WbsItemTypeVO,
      above?: boolean
    ): boolean => {
      return this.canAddRow(
        type,
        parent,
        row,
        canAddChild,
        multipleRowsContainingTopSelected,
        above
      )
    }

    const addRowWithContextMenu = (
      type: WbsItemTypeVO,
      options?: {
        above?: boolean
        ticketListUuid?: string
      }
    ): void => {
      this.addRow(ctx, selectedNodes, params.node, type, options)
    }

    const generateAddMultipleMenu = (
      type: WbsItemTypeVO
    ): MenuItemDef | undefined => {
      return {
        name: type.name,
        icon: `<img src="${type.iconUrl}" />`,
        action: () => {
          ctx.setState({
            addRowCountInputDialogState: {
              open: true,
              title: intl.formatMessage(
                { id: 'projectPlan.add' },
                { name: type.name }
              ),
              submitHandler: addRowCount => {
                Array.from({ length: addRowCount ?? 0 }).forEach(() =>
                  addRowWithContextMenu(type)
                )
                ctx.setState({ addRowCountInputDialogState: { open: false } })
              },
              closeHandler: () =>
                ctx.setState({ addRowCountInputDialogState: { open: false } }),
            },
          })
        },
        disabled: !canAddRowInContextMenu(type, undefined),
        tooltip: disabledWbsItemTypeMessage(type, false),
      }
    }

    const { ticketTypes, ticketListTypes } = store.getState().project
    const ticketListSubMenu = ticketListTypes.map(ticketType => ({
      name: ticketType.name,
      icon: `<img src="${ticketType.iconUrl}" />`,
      disabled: !canAddRowInContextMenu(ticketType),
      action: () => {
        addRowWithContextMenu(ticketType)
      },
    }))

    const getEarliestDate = async () => {
      const fromApi = (
        await getEarliestScheduledDate(ctx.props.projectUuid!, row.uuid)
      ).json
      const fromGrid =
        ctx.rowDataManager
          .getFlatDescendants(row.uuid)
          .filter(v => v.uuid !== row.uuid)
          .filter(v => v.isEdited)
          .map(v => v.wbsItem.scheduledDate?.startDate)
          .filter(v => !!v)
          .filter(v => canParseDate(v)) ?? []
      if (!canParseDate(fromApi) && fromGrid.length === 0) {
        store.dispatch(
          addScreenMessage(ctx.props.projectUuid!, {
            type: MessageLevel.WARN,
            title: intl.formatMessage({
              id: 'projectPlan.getPlanFromChildren.has.no.earliest.date',
            }),
          })
        )
        return undefined
      }
      const apiDate = canParseDate(fromApi)
        ? moment(fromApi)
        : moment('9999/12/31')
      const uiDate =
        fromGrid.length > 0
          ? moment.min(...fromGrid.map(v => moment(v)))
          : moment('9999/12/31')
      return moment.min(apiDate, uiDate).toISOString(true)
    }
    const getLatestDate = async () => {
      const fromApi = (
        await getLatestScheduledDate(ctx.props.projectUuid!, row.uuid)
      ).json
      const fromGrid =
        ctx.rowDataManager
          .getFlatDescendants(row.uuid)
          .filter(v => v.uuid !== row.uuid)
          .filter(v => v.isEdited)
          .map(v => v.wbsItem.scheduledDate?.endDate)
          .filter(v => !!v)
          .filter(v => canParseDate(v)) ?? []
      if (!canParseDate(fromApi) && fromGrid.length === 0) {
        store.dispatch(
          addScreenMessage(ctx.props.projectUuid!, {
            type: MessageLevel.WARN,
            title: intl.formatMessage({
              id: 'projectPlan.getPlanFromChildren.has.no.latest.date',
            }),
          })
        )
        return undefined
      }
      const apiDate = canParseDate(fromApi)
        ? moment(fromApi)
        : moment('1970/01/01')
      const uiDate =
        fromGrid.length > 0
          ? moment.max(...fromGrid.map(v => moment(v)))
          : moment('1970/01/01')
      return moment.max(apiDate, uiDate).toISOString(true)
    }
    const updateScheduledDate = (start, end) => {
      if (!start && !end) return
      const oldValue = row.wbsItem.scheduledDate
      if (!!start && start !== oldValue.startDate) {
        row.isEdited = true
        row.editedData = {
          ...row.editedData,
          'wbsItem.scheduledDate.startDate': oldValue.startDate,
        }
        row.wbsItem.scheduledDate.startDate = start
      }
      if (!!end && end !== oldValue.endDate) {
        row.isEdited = true
        row.editedData = {
          ...row.editedData,
          'wbsItem.scheduledDate.endDate': oldValue.endDate,
        }
        row.wbsItem.scheduledDate.endDate = end
      }
      ctx.rowDataManager.updateRow(row)
      store.dispatch(requireSave())
    }

    const addRowSubMenu = (above?: boolean) => {
      return wbsItemTypes
        .getAll()
        .filter(t => !t.isWorkgroup())
        .map(wbsItemType => getAddRowMenuItem(wbsItemType, above))
    }

    const getAddRowMenuItem = (wbsItem: WbsItemTypeVO, above?: boolean) => {
      return {
        name: wbsItem.name,
        icon: `<img src="${wbsItem.iconUrl}" />`,
        disabled: !canAddRowInContextMenu(wbsItem, above),
        action: () => {
          addRowWithContextMenu(wbsItem, { above })
        },
        tooltip: disabledWbsItemTypeMessage(wbsItem, above),
        shortcut: getShortcutKeyLabel(wbsItem.rootType, above),
      }
    }

    const getShortcutKeyLabel = (wbsItemType: WbsItemType, above?: boolean) => {
      let key: string | undefined = undefined
      switch (wbsItemType) {
        case WbsItemType.PROCESS:
          key = 'H'
          break
        case WbsItemType.DELIVERABLE_LIST:
          key = 'J'
          break
        case WbsItemType.DELIVERABLE:
          key = 'K'
          break
        case WbsItemType.TASK:
          key = 'L'
          break
      }
      return above
        ? intl.formatMessage(
            { id: 'bulksheet.contextMenu.shortcut.alt.shift' },
            { shortcutKey: key }
          )
        : intl.formatMessage(
            { id: 'bulksheet.contextMenu.shortcut.ctrl.shift' },
            { shortcutKey: key }
          )
    }

    const disabledWbsItemTypeMessage = (
      wbsItemType: WbsItemTypeVO,
      above?: boolean
    ): string | undefined => {
      let message: string | undefined = undefined
      if (!canAddRowInContextMenu(wbsItemType, above)) {
        switch (wbsItemType.rootType) {
          case WbsItemType.PROCESS:
            message = 'wbsItemType.process'
            break
          case WbsItemType.DELIVERABLE_LIST:
            message = 'wbsItemType.deliverableList'
            break
          case WbsItemType.DELIVERABLE:
            message = 'wbsItemType.deliverable'
            break
          case WbsItemType.TASK:
            message = 'wbsItemType.task'
            break
        }
      }
      return message
        ? above
          ? intl.formatMessage(
              { id: 'bulksheet.contextMenu.disabled.insert.above.rows' },
              { wbsItemType: intl.formatMessage({ id: message }) }
            )
          : intl.formatMessage(
              { id: 'bulksheet.contextMenu.disabled.insert.toChild.rows' },
              { wbsItemType: intl.formatMessage({ id: message }) }
            )
        : undefined
    }

    const insertAboveRowDisabled =
      addRowSubMenu(true).every(
        addRowSubMenuItem => addRowSubMenuItem?.disabled
      ) ||
      selectedNodes.length !== 1 ||
      multipleRowsContainingTopSelected

    const insertToChildRowDisabled =
      addRowSubMenu(false).every(
        addRowSubMenuItem => addRowSubMenuItem?.disabled
      ) ||
      selectedNodes.length !== 1 ||
      multipleRowsContainingTopSelected

    const addMultipleMenu = wbsItemTypes
      .getAll()
      .filter(t => !t.isWorkgroup())
      .map(wbsItemType => generateAddMultipleMenu(wbsItemType))

    const addMultipleRowsDisabled =
      addMultipleMenu.every(addRowSubMenuItem => addRowSubMenuItem?.disabled) ||
      selectedNodes.length !== 1 ||
      multipleRowsContainingTopSelected

    const addTicketListDisabled =
      ticketListSubMenu.every(
        ticketListMenuItem => ticketListMenuItem.disabled
      ) ||
      selectedNodes.length !== 1 ||
      multipleRowsContainingTopSelected

    const addTicketList = {
      name: intl.formatMessage({ id: 'bulksheet.contextMenu.ticketList' }),
      subMenu: ticketListSubMenu,
      disabled: addTicketListDisabled,
      tooltip: addTicketListDisabled
        ? intl.formatMessage({
            id: 'bulksheet.contextMenu.disabled.insert.ticketList',
          })
        : undefined,
    }

    const addTicketDisabled =
      ticketTypes.every(
        ticketType => !canAddRowInContextMenu(ticketType) || row.isAdded
      ) ||
      selectedNodes.length !== 1 ||
      multipleRowsContainingTopSelected

    const addTicket = {
      name: intl.formatMessage({ id: 'bulksheet.contextMenu.ticket' }),
      disabled: addTicketDisabled,
      tooltip: addTicketDisabled
        ? intl.formatMessage({
            id: 'bulksheet.contextMenu.disabled.insert.ticket',
          })
        : undefined,
      action: () => {
        ctx.setState({
          children: (
            <AddMultipleTicketDialog
              open={true}
              projectUuid={ctx.props.projectUuid!}
              title={intl.formatMessage({ id: 'bulksheet.contextMenu.ticket' })}
              submitHandler={(
                addRowCount: number | undefined,
                ticketTypeCode: string | undefined,
                ticketList: TicketListDetail | undefined
              ) => {
                const ticketType = ticketTypes.find(
                  v => v.code === ticketTypeCode
                )
                if (!addRowCount || !ticketType || !ticketList) {
                  throw new Error('Illegal arguments.')
                }
                Array.from({ length: addRowCount }).forEach(() =>
                  addRowWithContextMenu(ticketType, {
                    ticketListUuid: ticketList.uuid,
                  })
                )
                ctx.setState({ children: undefined })
              }}
              closeHandler={() => ctx.setState({ children: undefined })}
              initialValue={1}
            />
          ),
        })
      },
    }

    const openTicketListDisabled = !(
      params.node.data.wbsItem.wbsItemType.isDeliverable() &&
      params.node.data.wbsItem.wbsItemType.isTicket()
    )

    return new ContextMenu(
      [
        {
          id: 'ADD_ROW_GROUP',
          items: [
            {
              name: intl.formatMessage({
                id: 'bulksheet.contextMenu.insert.above.row',
              }),
              icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
              subMenu: addRowSubMenu(true),
              disabled: insertAboveRowDisabled,
              tooltip: insertAboveRowDisabled
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.insert.row',
                  })
                : undefined,
            },
            {
              name: intl.formatMessage({
                id: 'bulksheet.contextMenu.insert.toChild.row',
              }),
              icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
              subMenu: addRowSubMenu(false),
              disabled: insertToChildRowDisabled,
              tooltip: insertAboveRowDisabled
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.insert.row',
                  })
                : undefined,
            },
            {
              name: intl.formatMessage({
                id: 'addMultipleRows.wbsItem',
              }),
              icon: getMenuIconHtml(ContextMenuItemId.ADD_MULTIPLE_ROW),
              subMenu: addMultipleMenu,
              disabled: addMultipleRowsDisabled,
              tooltip: addMultipleRowsDisabled
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.insert.row',
                  })
                : undefined,
            },
            // Add workgroup
            selectedNodes.length === 1 &&
            !multipleRowsContainingTopSelected &&
            !treeRootNode
              ? {
                  id: 'ADD_WORKGROUP',
                  name: intl.formatMessage({
                    id: 'bulksheet.contextMenu.workgroup',
                  }),
                  icon: `<img src="${wbsItemTypes.workgroup.iconUrl}" />`,
                  action: () => {
                    addRowWithContextMenu(wbsItemTypes.workgroup)
                  },
                }
              : undefined,
          ],
        },
        // Add ticket
        {
          id: 'ADD_TICKET',
          items: [addTicketList, addTicket].filter(v => !!v),
        },
        ctx.generateEditContextMenu(params, [
          ContextMenuItemId.REMOVE_ROW,
          ContextMenuItemId.COPY_ROW,
          ContextMenuItemId.PASTE_ROW_AS_CHILD,
          ContextMenuItemId.CUT_ROW,
          ContextMenuItemId.INSERT_CUT_ROW,
          ContextMenuItemId.BULK_COPY_ROW,
        ]),
        ctx.generateUtilityContextMenu(params),
        // Other
        {
          id: 'OTHER',
          items: [
            // Open progress report
            {
              name: intl.formatMessage({ id: 'openProgressReport' }),
              disabled: !params.node.hasChildren(),
              action: this.getOpenProgressReport(
                ctx.props.projectUuid!,
                params.node.data
              ),
              icon: getMenuIconHtml(ContextMenuItemId.OPEN_PROGRESS_REPORT),
              tooltip: !params.node.hasChildren()
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.not.exists.child.row',
                  })
                : undefined,
            },
            // Open sprint report
            {
              name: intl.formatMessage({ id: 'openSprintReport' }),
              disabled:
                !(
                  params.node.data.wbsItem.wbsItemType.isWorkgroup() ||
                  params.node.data.wbsItem.wbsItemType.isProcess()
                ) || params.node.data.isAdded,
              action: async () => {
                const wbsItem = params.node!.data.wbsItem
                const projectResponse = await ProjectApi.getDetail({
                  uuid: ctx.props.projectUuid!,
                })
                open(
                  `/sprintReport/${projectResponse.json.code}?rootWbsItem=${wbsItem.uuid}`,
                  undefined,
                  undefined,
                  true
                )
              },
              icon: getMenuIconHtml(ContextMenuItemId.OPEN_SPRINT_REPORT),
              tooltip:
                !(
                  params.node.data.wbsItem.wbsItemType.isWorkgroup() ||
                  params.node.data.wbsItem.wbsItemType.isProcess()
                ) || params.node.data.isAdded
                  ? intl.formatMessage({
                      id: 'bulksheet.contextMenu.disabled.openSprintReport',
                    })
                  : undefined,
            },
            // Search delayed wbs
            {
              name: intl.formatMessage({ id: 'searchDelayedWbs' }),
              disabled: !params.node.hasChildren(),
              action: () => {
                openWbsItemSearch(
                  ctx.props.projectUuid!,
                  SearchConditionEndDelayed.with({
                    rootUuid: row.wbsItem.uuid,
                  })
                )
              },
              icon: getMenuIconHtml(ContextMenuItemId.SEARCH_DELAYED_WBS),
              tooltip: !params.node.hasChildren()
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.not.exists.child.row',
                  })
                : undefined,
            },
            // Open ticket list
            {
              name: intl.formatMessage({ id: 'openTicketList' }),
              disabled: openTicketListDisabled,
              action: () => {
                openTicketList(params.node!.data.wbsItem.uuid)
              },
              icon: getMenuIconHtml(ContextMenuItemId.OPEN_TICKET_LIST),
              tooltip: openTicketListDisabled
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.openTicketList',
                  })
                : undefined,
            },
            // Update plan from children
            {
              name: intl.formatMessage({ id: 'getPlanFromChildren' }),
              subMenu: [
                {
                  name: intl.formatMessage({
                    id: 'getPlanFromChildren.bothDate',
                  }),
                  action: async () =>
                    updateScheduledDate(
                      await getEarliestDate(),
                      await getLatestDate()
                    ),
                  icon: getMenuIconHtml(
                    ContextMenuItemId.GET_PLAN_FROM_CHILDREN_BOTH_DATE
                  ),
                },
                {
                  name: intl.formatMessage({
                    id: 'getPlanFromChildren.earliestDate',
                  }),
                  action: async () =>
                    updateScheduledDate(await getEarliestDate(), undefined),
                  icon: getMenuIconHtml(
                    ContextMenuItemId.GET_PLAN_FROM_CHILDREN_EARLIEST_DATE
                  ),
                },
                {
                  name: intl.formatMessage({
                    id: 'getPlanFromChildren.latestDate',
                  }),
                  action: async () =>
                    updateScheduledDate(undefined, await getLatestDate()),
                  icon: getMenuIconHtml(
                    ContextMenuItemId.GET_PLAN_FROM_CHILDREN_LATEST_DATE
                  ),
                },
              ],
              disabled: !params.node.hasChildren(),
              icon: getMenuIconHtml(ContextMenuItemId.GET_PLAN_FROM_CHILDREN),
              tooltip: !params.node.hasChildren()
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.not.exists.child.row',
                  })
                : undefined,
            },
          ],
        },
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }

  checkRowBulkCopiable = (data: ProjectPlanRow): boolean => {
    return !data.wbsItem.wbsItemType?.isWorkgroup()
  }

  getOpenProgressReport = (projectUuid: string, data?: ProjectPlanRow) => {
    return () => {
      if (!data) {
        openProgressReport(projectUuid, {}, {})
        return
      }
      const wbsItem = data.wbsItem
      const searchCondition = {
        rootWbsItemUuid: wbsItem.uuid,
      }
      const viewConfig = dateTermToViewConfig(wbsItem.scheduledDate)
      openProgressReport(projectUuid, searchCondition, viewConfig)
    }
  }
  checkRowPastable = (
    parent: ProjectPlanRow[],
    selected: ProjectPlanRow[],
    clipboard: ProjectPlanRow[]
  ): boolean => {
    return pastable(selected, clipboard)
  }
  getOpenDetailSpec = (row: ProjectPlanRow): Promise<OpenDetailSpec> => {
    return getOpenWbsItemDetailSpec(true, row.wbsItem)
  }
  getUpdatedRowAncestors = async (
    uuid: string,
    treeRootUuid?: string
  ): Promise<ProjectPlanDetail> => {
    return getUpdatedRowAncestors(uuid, treeRootUuid)
  }

  refreshAfterUpdateSingleRow = async (
    uuid: string,
    ctx: ProjectPlanBulkSheetContext
  ) => {
    try {
      const ancestors = await this.getUpdatedRowAncestors(
        uuid,
        ctx.props.treeRootUuid
      )
      if (objects.isEmpty(ancestors)) {
        const node = ctx.gridApi!.getRowNode(uuid)
        if (!node) {
          return
        }
        ctx.rowDataManager.removeRows([node.data])
        return
      }

      let children = [ancestors]
      const nodes: RowNode[] = []
      while (children && children.length > 0) {
        const child = children[0]
        if (child.uuid === uuid) {
          // Wbs changed on status change popper
          ctx.rowDataManager.initRow({
            ...ctx.rowDataManager.createRowByResponse(
              children[0],
              ctx.viewMeta
            ),
            children: ctx.rowDataManager.getChildren(child.uuid),
            isAdded: false,
            isEdited: false,
            editedData: undefined,
          })
        } else {
          // Ancestor rows
          const row = ctx.rowDataManager
            .getAllRows()
            .find(v => v.uuid === child.uuid)
          if (!row) return
          ctx.rowDataManager.updateRow({
            ...row,
            wbsItem: {
              ...row.wbsItem,
              status: child.wbsItem.status as WbsItemStatus,
              actualDate: {
                startDate: child.wbsItem.actualDate?.startDate,
                endDate: child.wbsItem.actualDate?.endDate,
              },
            },
          })
        }
        const node = ctx.gridApi!.getRowNode(child.uuid)
        nodes.push(node!)
        children = child.children
      }

      ctx.gridApi!.refreshCells({ rowNodes: nodes, force: true })
    } catch (e: any) {
      if (e.code !== 'NOT_FOUND') {
        throw e
      }
      const node = ctx.gridApi!.getRowNode(uuid)
      if (!node) {
        throw e
      }
      ctx.rowDataManager.removeRows([node.data])
    }
  }

  async getAll(state: ProjectPlanState, props?: ProjectPlanBulkSheetProps) {
    if (props?.treeRootUuid) {
      return getTree(
        state.uuid,
        Object.values(WbsItemType),
        props?.treeRootUuid
      )
    }
    return getAll(state.uuid)
  }

  onSubmit = async (
    ctx: ProjectPlanBulkSheetContext,
    data: {
      added: ProjectPlanRow[]
      edited: {
        before: ProjectPlanRow
        after: ProjectPlanRow
      }[]
      deleted: ProjectPlanRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const response = await runAsyncWithPerfMonitoring(
      'Submit ProjectPlan',
      async () => {
        // Update project plans and wbs items
        const request = runWithPerfMonitoring('Create request', () => {
          const projectPlanInput: ProjectPlanUpdateBatchDeltaRequest = {
            projectUuid: ctx.props.projectUuid!,
            rootProjectPlanUuid: ctx.treeRoot.uuid!,
            rootProjectPlanLockVersion: ctx.treeRoot.lockVersion!,
            added: data.added.map(v => this.createRequestByRow(v, viewMeta)),
            edited: data.edited.map(v =>
              this.createDeltaRequestByRow(v, viewMeta)
            ),
            deleted: data.deleted.map(v =>
              this.createDeleteRequestByRow(v, viewMeta)
            ),
          }
          const request: RequestOfUpdateBatchDelta = {
            projectPlans: projectPlanInput,
            productBacklogItems: this.createProductBacklogItemInput(
              data.added,
              data.edited
            ),
            sprintProductItems: this.createSprintProductItemInput(
              data.added,
              data.edited
            ),
            ticketLists: this.createTicketListInput(data.added),
            tickets: this.createTicketInput(data.added),
            watchers: createWatchers(data.added, data.edited),
          }
          return request
        })
        const response = await updateBatchDelta(request)

        // Update sprint backlogs
        const addedUuidMap = Object.fromEntries(
          response.json?.added.map(v => [v.uuid, v.wbsItemUuid])
        )
        const sprintBacklogRequest = createSprintBacklogUpdateBatchRequest(
          [
            ...data.added.map(v => ({
              ...v.wbsItem,
              uuid: addedUuidMap[v.uuid],
            })),
            ...data.edited.map(v => v.after.wbsItem),
          ],
          Object.fromEntries(
            [...response.json?.added, ...response.json?.edited].map(v => [
              v.wbsItemUuid,
              v.wbsItemLockVersion,
            ])
          )
        )
        await SprintBacklogApi.updateBatchSprintBacklog(sprintBacklogRequest)

        const sprintProductItemsRequest: UpdateSprintProductItemProps = {
          added: [],
          deleted: [
            ...data.edited
              .filter(
                v =>
                  v.after.wbsItem.wbsItemType?.isDeliverable() &&
                  !v.after.wbsItem.currentSprint &&
                  v.before.wbsItem.currentSprint
              )
              .map(
                v =>
                  ({
                    deliverableUuid: v.after.wbsItem.uuid,
                    deliverableLockVersion: v.after.wbsItem.lockVersion,
                    sprintUuid: v.before.wbsItem.currentSprint?.uuid,
                  } as SprintProductItemProp)
              ),
          ],
        }
        await updateSprintProductItems(sprintProductItemsRequest)

        return response
      }
    )
    return response
  }

  private createRequestByRow = (
    row: ProjectPlanRow,
    viewMeta: ViewMeta
  ): ProjectPlanBatchInput => {
    const wbsItem: WbsItemRow = row.wbsItem
    const wbsItemInput = createRequestByRow(
      wbsItem,
      viewMeta,
      'projectPlan.wbsItem',
      row.extensions
    )
    return {
      uuid: row.uuid,
      lockVersion: row.lockVersion,
      parentUuid: row.parentUuid,
      prevSiblingUuid: row.prevSiblingUuid,
      type: row.wbsItem.type!,
      wbsItem: wbsItemInput,
      productBacklogItem: row.productBacklogItem,
      currentSprintUuid:
        row.wbsItem.currentSprint && row.wbsItem.currentSprint.uuid,
      ticketType: row.wbsItem.ticketType,
    }
  }

  createWbsItemDeltaRequestByRow = (
    editedRow: {
      before: ProjectPlanRow
      after: ProjectPlanRow
    },
    viewMeta: ViewMeta
  ): WbsItemDeltaInput => {
    const { before: editedRowBefore, after: editedRowAfter } = editedRow
    return createDeltaRequestByRow(
      {
        before: editedRowBefore.wbsItem,
        after: editedRowAfter.wbsItem,
      },
      viewMeta,
      'projectPlan.wbsItem',
      {
        before: editedRowBefore.extensions,
        after: editedRowAfter.extensions,
      }
    )
  }

  private createDeltaRequestByRow = (
    editedRow: {
      before: ProjectPlanRow
      after: ProjectPlanRow
    },
    viewMeta: ViewMeta
  ): ProjectPlanBatchDeltaInput => {
    const { before: editedRowBefore, after: editedRowAfter } = editedRow
    const wbsItemDeltaInput = createDeltaRequestByRow(
      {
        before: editedRowBefore.wbsItem,
        after: editedRowAfter.wbsItem,
      },
      viewMeta,
      'projectPlan.wbsItem',
      {
        before: editedRowBefore.extensions,
        after: editedRowAfter.extensions,
      }
    )
    return {
      uuid: editedRowAfter.uuid,
      type: editedRowAfter.wbsItem.type!,
      parentUuid: {
        oldValue: editedRowBefore.parentUuid,
        newValue: editedRowAfter.parentUuid,
      },
      prevSiblingUuid: {
        oldValue: editedRowBefore.prevSiblingUuid,
        newValue: editedRowAfter.prevSiblingUuid,
      },
      wbsItem: wbsItemDeltaInput,
    }
  }

  private createDeleteRequestByRow = (
    row: ProjectPlanRow,
    viewMeta: ViewMeta
  ) => {
    return {
      uuid: row.uuid,
      wbsItemUuid: row.wbsItem.uuid!,
    }
  }

  private createProductBacklogItemInput = (
    added: ProjectPlanRow[],
    edited: {
      before: ProjectPlanRow
      after: ProjectPlanRow
    }[]
  ): ProductBacklogItemInput[] => {
    let input: ProductBacklogItemInput[] = []
    added.forEach(row =>
      input.push(this.createProductBacklogItemInputByRow(row))
    )
    edited.forEach(row =>
      input.push(this.createProductBacklogItemInputByRow(row.after))
    )
    return input
  }

  private createProductBacklogItemInputByRow = (
    row: ProjectPlanRow
  ): ProductBacklogItemInput => ({
    uuid: row.uuid,
    wbsItemUuid: row.wbsItem.uuid,
    teamUuid: row.wbsItem.team?.uuid,
    productBacklogItem: row.productBacklogItem,
  })

  private createSprintProductItemInput = (
    added: ProjectPlanRow[],
    edited: {
      before: ProjectPlanRow
      after: ProjectPlanRow
    }[]
  ): SprintItemInput[] => {
    let input: SprintItemInput[] = []
    added
      .filter(
        row =>
          row.wbsItem.wbsItemType?.isDeliverable() && row.wbsItem.currentSprint
      )
      .forEach(row => input.push(this.createSprintItemInput(row)))
    edited
      .filter(
        row =>
          row.after.wbsItem.wbsItemType?.isDeliverable() &&
          row.after.wbsItem.currentSprint
      )
      .forEach(row => input.push(this.createSprintItemInput(row.after)))
    return input
  }

  private createSprintItemInput = (row: ProjectPlanRow): SprintItemInput => ({
    uuid: row.uuid,
    wbsItemUuid: row.wbsItem.uuid,
    wbsItemLockVersion: row.wbsItem.lockVersion,
    sprintUuid: row.wbsItem.currentSprint?.uuid,
  })

  private createTicketListInput = (
    rows: ProjectPlanRow[]
  ): TicketListInput[] => {
    return rows
      .filter(
        row =>
          row.wbsItem.wbsItemType?.isDeliverable() &&
          row.wbsItem.wbsItemType?.isTicket()
      )
      .map(row => ({
        uuid: row.uuid,
        ticketType: row.wbsItem.ticketType!,
      }))
  }

  private createTicketInput = (rows: ProjectPlanRow[]): TicketInput[] => {
    return rows
      .filter(
        row =>
          row.wbsItem.wbsItemType?.isTask() &&
          row.wbsItem.wbsItemType?.isTicket()
      )
      .map(row => ({
        uuid: row.uuid,
        ticketType: row.wbsItem.ticketType!,
        ticketListUuid: row.ticketListUuid!,
      }))
  }

  getValueGetter = (field: string): AgGridValueGetter | undefined => {
    if (
      [
        'costPerformanceIndex',
        'progressRate',
        'schedulePerformanceIndex',
        'total',
        'earnedValue',
        'remaining',
        'preceding',
        'costVariance',
        'scheduleVariance',
        'estimateToComplete',
        'estimateAtCompletion',
        'varianceAtCompletion',
        'estimatedProgressRate',
        'delayed',
        'plannedValue',
      ].includes(field)
    ) {
      return (params: ValueGetterParams) => {
        if (!params.node) {
          return undefined
        }
        return projectPlanFacadeAggregator(
          params.node,
          field,
          params.context.wbsItemType,
          params.context.aggregateTargetType,
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'wbsItem.type') {
      return (params: ValueGetterParams) => {
        if (!params.data?.wbsItem?.baseWbsItemType) return {}
        return {
          wbsItemType: params.data.wbsItem.baseWbsItemType,
          typeIndex: params.data.typeIndex,
        } as WbsItemTypeCellValue
      }
    }
    if (field === 'wbsItem.ticketType') {
      return (params: ValueGetterParams) => {
        return params.data?.wbsItem?.baseWbsItemType
      }
    }
    if (['wbsItem.estimatedWorkload.deliverable'].includes(field)) {
      return (params: ValueGetterParams) => {
        const data: ProjectPlanRow = params.data
        if (data.wbsItem.wbsItemType?.isTask()) {
          return undefined
        }
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        if (data.wbsItem.wbsItemType?.isDeliverable()) {
          return getWorkloadUnitValue(
            data.wbsItem.estimatedWorkload,
            params.context.workloadUnit
          )
        }
        if (
          params.node &&
          (data.wbsItem.wbsItemType?.isWorkgroup() ||
            data.wbsItem.wbsItemType?.isProcess() ||
            data.wbsItem.wbsItemType?.isDeliverableList())
        ) {
          return deliverableAggregator(
            params.node,
            AggregateTargetValue.ESTIMATED_HOUR,
            'wbsItem',
            (node: RowNode) =>
              node.data.wbsItem.status !== WbsItemStatus.DISCARD,
            denominator
          )
        }
        return undefined
      }
    }
    if (['wbsItem.estimatedWorkload.task'].includes(field)) {
      return (params: ValueGetterParams) => {
        const data: ProjectPlanRow = params.data
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        if (data.wbsItem.wbsItemType?.isTask()) {
          return getWorkloadUnitValue(
            data.wbsItem.estimatedWorkload,
            params.context.workloadUnit
          )
        }
        if (
          params.node &&
          (data.wbsItem.wbsItemType?.isWorkgroup() ||
            data.wbsItem.wbsItemType?.isProcess() ||
            data.wbsItem.wbsItemType?.isDeliverableList() ||
            data.wbsItem.wbsItemType?.isDeliverable())
        ) {
          return taskAggregator(
            params.node,
            AggregateTargetValue.ESTIMATED_HOUR,
            'wbsItem',
            (node: RowNode) =>
              node.data.wbsItem.status !== WbsItemStatus.DISCARD,
            denominator
          )
        }
        return undefined
      }
    }
    if (['wbsItem.actualHour'].includes(field)) {
      return (params: ValueGetterParams) => {
        const data: ProjectPlanRow = params.data
        const denominator =
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        if (data.wbsItem.wbsItemType?.isTask()) {
          return (data.cumulation?.actualHour || 0) / denominator
        }
        if (
          params.node &&
          (data.wbsItem.wbsItemType?.isWorkgroup() ||
            data.wbsItem.wbsItemType?.isProcess() ||
            data.wbsItem.wbsItemType?.isDeliverableList() ||
            data.wbsItem.wbsItemType?.isDeliverable())
        ) {
          return taskAggregator(
            params.node,
            AggregateTargetValue.ACTUAL_HOUR,
            'wbsItem',
            node => true,
            denominator
          )
        }
        return undefined
      }
    }
    if (field === 'wbsItem.estimatedAmount.deliverable') {
      return (params: ValueGetterParams) => {
        return getDeliverableEstimatedAmount(params)
      }
    }
    if (field === 'wbsItem.estimatedAmount.task') {
      return (params: ValueGetterParams) => {
        return getTaskEstimatedAmount(params)
      }
    }
    if (field === 'wbsItem.actualAmount') {
      return (params: ValueGetterParams) => {
        return getActualAmount(params)
      }
    }
    if (field === 'completionAmountRate') {
      return (params: ValueGetterParams) => {
        const data: ProjectPlanRow = params.data
        if (params.context.wbsItemType === WbsItemType.DELIVERABLE) {
          return getRate(
            getActualAmount(params),
            getDeliverableEstimatedAmount(params)
          )
        }
        if (params.context.wbsItemType === WbsItemType.TASK) {
          return getRate(
            getActualAmount(params),
            getTaskEstimatedAmount(params)
          )
        }
      }
    }
    if (field === 'ganttChart') {
      return (params: ValueGetterParams) => {
        if (!params.data) return undefined
        const wbsItem: WbsItemDetail = params.data.wbsItem
        return JSON.stringify([
          wbsItem.scheduledDate,
          wbsItem.status,
          wbsItem.actualDate,
        ])
      }
    }
    if (field === 'startDelayDays') {
      return startDelayDaysValueGetter
    }
    if (field === 'endDelayDays') {
      return endDelayDaysValueGetter
    }
    return undefined
  }

  getValueSetter = (field: string): AgGridValueSetter | undefined => {
    if (
      [
        'wbsItem.estimatedWorkload.deliverable',
        'wbsItem.estimatedWorkload.task',
      ].includes(field)
    ) {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) {
          return false
        }
        params.data.isEdited = true
        const unit = getSelectedWorkloadUnit()
        const { dailyWorkHours, monthlyWorkDays } =
          store.getState().tenant.organization!
        let workload: Workload
        switch (unit) {
          case WorkloadUnit.MONTH:
            workload = Workload.from({
              month: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          case WorkloadUnit.DAY:
            workload = Workload.from({
              day: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          case WorkloadUnit.HOUR:
            workload = Workload.from({
              hour: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          default:
            workload = new Workload(0, 0, 0, {
              dailyWorkHours,
              monthlyWorkDays,
            })
        }
        params.data.wbsItem.estimatedWorkload = workload
        // Refresh parents
        refreshAncestors(params.api, [field], params.node || undefined)
        return true
      }
    }
    if (
      [
        'wbsItem.scheduledDate.startDate',
        'wbsItem.scheduledDate.endDate',
      ].includes(field)
    ) {
      return wbsScheduledDateValueSetter(field)
    }
    if (
      ['wbsItem.actualDate.startDate', 'wbsItem.actualDate.endDate'].includes(
        field
      )
    ) {
      return wbsActualDateValueSetter(field)
    }
    if (
      [
        'wbsItem.estimatedAmount.deliverable',
        'wbsItem.estimatedAmount.task',
      ].includes(field)
    ) {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) {
          return false
        }
        params.data.isEdited = true
        params.data.wbsItem.estimatedAmount = Number(params.newValue)
        refreshAncestors(params.api, [field], params.node || undefined)
        return true
      }
    }
    if ('wbsItem.actualAmount' === field) {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) {
          return false
        }
        params.data.isEdited = true
        params.data.wbsItem.actualAmount = Number(params.newValue)
        refreshAncestors(params.api, [field], params.node || undefined)
        return true
      }
    }
    return undefined
  }

  getCellEditorParams = (field: string): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.code') {
      return {}
    }
    if (field === 'wbsItem.status') {
      return {
        refreshFieldNames: [
          'wbsItem.status',
          'wbsItem.estimatedWorkload.deliverable',
          'wbsItem.estimatedWorkload.task',
        ],
      }
    }
    if (field === 'productBacklogItem') {
      return {
        hide: (rowNode: RowNode) =>
          !rowNode.data.wbsItem.wbsItemType?.isDeliverable,
      }
    }
    if (field === 'wbsItem.currentSprint') {
      return {
        validatePaste: value =>
          [SprintStatus.INPROGRESS, SprintStatus.STANDBY].includes(
            value.status!
          ),
      }
    }
    return undefined
  }

  getCellRendererParams = (
    field: string,
    ctx?: ProjectPlanBulkSheetContext
  ): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.actualHour') {
      return {
        link: (row: ProjectPlanRow) =>
          row && taskActualResultExists(row.wbsItem),
      }
    }
    if (field === 'wbsItem.watchers') {
      return {
        showIcon: true,
      }
    }
    if (field === 'ganttChart') {
      if (!ctx) return {}
      return generateGanttChartCellRendererParams(
        ctx,
        ctx.props.ganttParameter!
      )
    }
    return undefined
  }

  getAttachmentCellRendererParams = (
    bulkSheet: ProjectPlanBulkSheetContext
  ) => {
    return {
      getAttachmentList: async (
        row: ProjectPlanRow,
        bulkSheet: ProjectPlanBulkSheetContext
      ) => {
        if (row.wbsItem.uuid) {
          const response = await WbsItemApi.getDetail({
            uuid: row.wbsItem.uuid,
          })
          const wbsItemDetail = response.json
          return wbsItemDetail.deliverableAttachments || []
        }
        return []
      },
      getAttachmentSummary: (row: ProjectPlanRow) => {
        return row.deliverableAttachmentSummary
      },
    }
  }

  getDetailColumnCellRendererParams = (ctx: ProjectPlanBulkSheetContext) => {
    return {
      path: 'wbsItem.description',
      openComment: (row: ProjectPlanRow, ctx: ProjectPlanBulkSheetContext) => {
        const applicationFunctionUuid = getWbsItemFunctionUuid()
        const wbsItemUuid = row.wbsItem.uuid
        if (!applicationFunctionUuid || !wbsItemUuid) {
          return
        }
        store.dispatch(
          openComment({
            applicationFunctionUuid,
            dataUuid: wbsItemUuid,
            projectUuid: row.wbsItem.projectUuid!,
            headerComponents: [
              <CommentHeaderWbsItem
                key={1}
                wbsItem={mapRowDataForCommentHeader(row.wbsItem)}
                onAfterUpdate={() => {
                  ctx.refreshAfterUpdateSingleRow(row.uuid)
                }}
              />,
            ],
          })
        )
      },
      getCommentSummary: (row: ProjectPlanRow) => row.commentSummary,
      getSpeedDialActions: (row: ProjectPlanRow): SpeedDialActionProps[] => {
        const wbsItemTypes = store.getState().project.wbsItemTypes
        const wbsItem = row.wbsItem
        const parentUuid = row.uuid
        const addChild = (params: {
          parentUuid: string
          type: WbsItemTypeVO
          parent?: ProjectPlanRow
        }): void => {
          const { parentUuid, type, parent } = params
          const newRowData = this.rowDataSpec.createNewRow(ctx, {
            parent,
            type,
          })
          if (type.isWorkgroup()) {
            ctx.addRow(undefined, undefined, newRowData)
          } else {
            ctx.addRow(parentUuid, parentUuid, newRowData)
          }
        }

        const speedDialActionProps: SpeedDialActionProps[] = []
        if (wbsItemTypes.isEmpty()) {
          return speedDialActionProps
        }
        if (wbsItemTypes.task.canBeChildOf(wbsItem.wbsItemType!)) {
          {
            speedDialActionProps.push({
              icon: <img src={wbsItemTypes.task.iconUrl} />,
              tooltipTitle: intl.formatMessage({
                id: 'bulksheet.speedDial.add.task',
              }),
              onClick: () => {
                addChild({
                  parentUuid: parentUuid,
                  type: wbsItemTypes.task,
                  parent: row,
                })
              },
            })
          }
        }
        if (wbsItemTypes.deliverable.canBeChildOf(wbsItem.wbsItemType!)) {
          {
            speedDialActionProps.push({
              icon: <img src={wbsItemTypes.deliverable.iconUrl} />,
              tooltipTitle: intl.formatMessage({
                id: 'bulksheet.speedDial.add.deliverable',
              }),
              onClick: () => {
                addChild({
                  parentUuid: parentUuid,
                  type: wbsItemTypes.deliverable,
                  parent: row,
                })
              },
            })
          }
        }
        if (wbsItemTypes.deliverableList.canBeChildOf(wbsItem.wbsItemType!)) {
          {
            speedDialActionProps.push({
              icon: <img src={wbsItemTypes.deliverableList.iconUrl} />,
              tooltipTitle: intl.formatMessage({
                id: 'bulksheet.speedDial.add.deliverableList',
              }),
              onClick: () => {
                addChild({
                  parentUuid: parentUuid,
                  type: wbsItemTypes.deliverableList,
                  parent: row,
                })
              },
            })
          }
        }
        if (wbsItemTypes.process.canBeChildOf(wbsItem.wbsItemType!)) {
          speedDialActionProps.push({
            icon: <img src={wbsItemTypes.process.iconUrl} />,
            tooltipTitle: intl.formatMessage({
              id: 'bulksheet.speedDial.add.process',
            }),
            onClick: () => {
              addChild({
                parentUuid: parentUuid,
                type: wbsItemTypes.process,
                parent: row,
              })
            },
          })
        }
        return speedDialActionProps
      },
    }
  }

  getTaskActualResultKey = (params: CellClickedEvent): string | undefined => {
    const row: ProjectPlanRow = params.data
    if (row && taskActualResultExists(row.wbsItem)) {
      return row.wbsItem.uuid
    }
    return undefined
  }

  customColumnWidth = (field: string): number | undefined => {
    if (['productBacklogItem'].includes(field)) {
      return 45
    }
    if (
      [
        'wbsItem.accountable',
        'wbsItem.responsible',
        'wbsItem.assignee',
        'wbsItem.difficulty',
        'wbsItem.priority',
      ].includes(field)
    ) {
      return 65
    }
    if (['wbsItem.revision'].includes(field)) {
      return 75
    }
    if (
      [
        'wbsItem.estimatedStoryPoint',
        'storyPoint.earnedValue',
        'wbsItem.estimatedWorkload.deliverable',
        'wbsItem.estimatedWorkload.task',
        'wbsItem.actualHour',
        'wbsItem.difficulty',
        'total',
        'earnedValue',
        'progressRate',
        'costPerformanceIndex',
        'schedulePerformanceIndex',
        'remaining',
        'preceding',
        'costVariance',
        'scheduleVariance',
        'estimateToComplete',
        'estimateAtCompletion',
        'varianceAtCompletion',
        'delayed',
        'plannedValue',
      ].includes(field)
    ) {
      return 80
    }
    if (['wbsItem.currentSprint'].includes(field)) {
      return 85
    }
    if (
      [
        'wbsItem.scheduledDate.startDate',
        'wbsItem.scheduledDate.endDate',
        'wbsItem.actualDate.startDate',
        'wbsItem.actualDate.endDate',
        'estimatedProgressRate',
      ].includes(field)
    ) {
      return 90
    }
    if (
      [
        'wbsItem.code',
        'wbsItem.type',
        'action',
        'startDelayDays',
        'endDelayDays',
      ].includes(field)
    ) {
      return 100
    }
    if (field === 'action') {
      return 135
    }
    if (['wbsItem.substatus'].includes(field)) {
      return 110
    }
    if (['wbsItem.createdAt', 'wbsItem.updatedAt'].includes(field)) {
      return 135
    }
    if (['wbsItem.status'].includes(field)) {
      return 145
    }
    if (field === 'ganttChart') {
      return 560
    }
    if (['attachment'].includes(field)) {
      return 55
    }
    if (
      [
        'wbsItem.estimatedAmount.deliverable',
        'wbsItem.estimatedAmount.task',
        'wbsItem.actualAmount',
        'completionAmountRate',
      ].includes(field)
    ) {
      return 105
    }
    return undefined
  }

  getCellStyle = (field: string): CellStyle | CellStyleFunc | undefined => {
    if (field === 'wbsItem.priority') {
      return {
        justifyContent: 'center',
      }
    }
    return undefined
  }

  getOnCellValueChanged = (field: string, ctx: ProjectPlanBulkSheetContext) => {
    if (
      [
        'wbsItem.status',
        'wbsItem.estimatedWorkload.deliverable',
        'wbsItem.estimatedWorkload.task',
        'wbsItem.scheduledDate.startDate',
        'wbsItem.scheduledDate.endDate',
        'wbsItem.actualHour',
      ].includes(field)
    ) {
      return (params: NewValueParams) => {
        if (ctx.props.specificProps?.updateReportToolbar) {
          ctx.props.specificProps?.updateReportToolbar()
        }
      }
    }
    return undefined
  }

  onRowDataChanged = (ctx: ProjectPlanBulkSheetContext) => {
    if (ctx.props.specificProps?.updateReportToolbar) {
      ctx.props.specificProps?.updateReportToolbar()
    }
  }

  mergeRowCommentSummary = (
    targetRow: ProjectPlanRow,
    comments: List<Comment> | undefined
  ): ProjectPlanRow => {
    return {
      ...targetRow,
      commentSummary: commentListToSummary(comments),
    }
  }
  isSetKeyBind = true
  getKeyBindListeners = (
    ctx: ProjectPlanBulkSheetContext
  ): KeyBindListener[] => {
    const canAddRowOnKeyPress = (
      ctx: ProjectPlanBulkSheetContext,
      selectedNodes: RowNode[] | undefined,
      type: WbsItemTypeVO,
      above?: boolean
    ): boolean => {
      if (!ctx.gridApi || !selectedNodes || selectedNodes.length <= 0) {
        return false
      }
      const row = selectedNodes[0].data
      const parent = ctx.rowDataManager.getParent(selectedNodes[0].data.uuid)
      const canAddChild = row.wbsItem?.type && !!row.wbsItem?.displayName.trim()
      const cellRanges = ctx.gridApi.getCellRanges()
      const multipleRowsContainingTopSelected =
        cellRanges &&
        cellRanges.some(
          range =>
            range.startRow?.rowIndex === 0 || range.endRow?.rowIndex === 0
        ) &&
        cellRanges.some(
          range =>
            (range.endRow?.rowIndex || 0) - (range.startRow?.rowIndex || 0) !==
            0
        )
      if (
        !above &&
        (selectedNodes.length !== 1 || multipleRowsContainingTopSelected)
      ) {
        return false
      }
      return this.canAddRow(
        type,
        parent,
        row,
        canAddChild,
        multipleRowsContainingTopSelected,
        above
      )
    }
    const addRowOnKeyPress = (
      ctx: ProjectPlanBulkSheetContext,
      type: WbsItemTypeVO,
      ticketType?: string,
      above?: boolean
    ) => {
      const selectedNodes = ctx.gridApi?.getSelectedNodes()
      if (!canAddRowOnKeyPress(ctx, selectedNodes, type, above)) {
        return
      }
      this.addRow(ctx, selectedNodes!, selectedNodes![0], type, {
        ticketType,
        above,
      })
    }

    return [
      {
        key: 'alt+shift+k',
        fn: () => {
          addRowOnKeyPress(
            ctx,
            store.getState().project.wbsItemTypes.deliverable,
            undefined,
            true
          )
        },
      },
      {
        key: 'alt+shift+j',
        fn: () => {
          addRowOnKeyPress(
            ctx,
            store.getState().project.wbsItemTypes.deliverableList,
            undefined,
            true
          )
        },
      },
      {
        key: 'alt+shift+h',
        fn: () => {
          addRowOnKeyPress(
            ctx,
            store.getState().project.wbsItemTypes.process,
            undefined,
            true
          )
        },
      },
      {
        key: 'alt+shift+l',
        fn: () => {
          addRowOnKeyPress(
            ctx,
            store.getState().project.wbsItemTypes.task,
            undefined,
            true
          )
        },
      },
      {
        key: 'mod+shift+k',
        fn: () => {
          addRowOnKeyPress(
            ctx,
            store.getState().project.wbsItemTypes.deliverable
          )
        },
      },
      {
        key: 'mod+shift+j',
        fn: () => {
          addRowOnKeyPress(
            ctx,
            store.getState().project.wbsItemTypes.deliverableList
          )
        },
        stopDefaultBehavior: true,
      },
      {
        key: 'mod+shift+h',
        fn: () => {
          addRowOnKeyPress(ctx, store.getState().project.wbsItemTypes.process)
        },
      },
      {
        key: 'mod+shift+l',
        fn: () => {
          addRowOnKeyPress(ctx, store.getState().project.wbsItemTypes.task)
        },
      },
      {
        key: 'alt+shift+d',
        fn: ctx.removeRowKeyEventListener,
      },
      {
        key: 'alt+shift+c',
        fn: ctx.copyRowKeyEventListener,
      },
      {
        key: 'alt+shift+x',
        fn: ctx.cutRowKeyEventListener,
        stopDefaultBehavior: true,
      },
      {
        key: 'mod+i',
        fn: () => {
          ctx.pasteRowKeyEventListener(true, false)
        },
      },
      {
        key: 'mod+shift+i',
        fn: () => {
          ctx.pasteRowKeyEventListener(true, true)
        },
        stopDefaultBehavior: true,
      },
      {
        key: 'mod+shift+v',
        fn: ctx.insertCutRowKeyEventListener,
      },
    ]
  }
}

export const wbsScheduledDateValueSetter = field => {
  return (params: ValueSetterParams) => {
    if (params.oldValue === params.newValue) return false
    const value = !params.newValue
      ? params.newValue
      : dateValueParser(params.newValue)
    params.data.isEdited = true
    objects.setValue(params.data, field, value)
    if (!!value) {
      const scheduled = params.data.wbsItem.scheduledDate
      if (!!scheduled.startDate && !!scheduled.endDate) {
        // Keep start and end date order
        if (
          new DateVO(scheduled.endDate).isBefore(
            new DateVO(scheduled.startDate)
          )
        ) {
          params.data.wbsItem.scheduledDate = {
            startDate: field.includes('start')
              ? scheduled.startDate
              : scheduled.endDate,
            endDate: field.includes('end')
              ? scheduled.endDate
              : scheduled.startDate,
          }
        }
      }
    }
    return true
  }
}

export const wbsActualDateValueSetter = field => {
  return (params: ValueSetterParams) => {
    if (params.oldValue === params.newValue) return false
    const value = !params.newValue
      ? params.newValue
      : dateValueParser(params.newValue)
    params.data.isEdited = true
    objects.setValue(params.data, field, value)
    if (!!value) {
      const actualDate = params.data.wbsItem.actualDate
      if (!!actualDate.startDate && !!actualDate.endDate) {
        // Keep start and end date order
        if (
          new DateVO(actualDate.endDate).isBefore(
            new DateVO(actualDate.startDate)
          )
        ) {
          params.data.wbsItem.actualDate = {
            startDate: field.includes('start')
              ? actualDate.startDate
              : actualDate.endDate,
            endDate: field.includes('end')
              ? actualDate.endDate
              : actualDate.startDate,
          }
        }
      }
    }
    return true
  }
}

export const pastable = (
  selected: ProjectPlanRow[],
  clipboard: ProjectPlanRow[]
): boolean => {
  const uuids = clipboard.map(v => v.uuid)
  const data = clipboard.filter(
    v => !v.parentUuid || !uuids.includes(v.parentUuid)
  )
  return selected.every(p => {
    if (!p.wbsItem) return false
    return data.every(c =>
      c.wbsItem.wbsItemType?.canBeChildOf(p.wbsItem.wbsItemType!)
    )
  })
}

export const getGanttChartWidth = (parameter?: GanttParameterVO): number => {
  if (!parameter) return 560 // 20px a day for 2 weeks before and after
  const serialize = (date: Date) => date.getFullYear() * 12 + date.getMonth()
  const months =
    serialize(parameter.range.end.toDate()) -
    serialize(parameter.range.start.toDate()) +
    1
  const days =
    (parameter.range.end.toDate().getTime() -
      parameter.range.start.toDate().getTime()) /
    86400000 // Seconds of the day
  return parameter.unit === GanttDisplayUnit.MONTH
    ? 100 * months
    : parameter.unit === GanttDisplayUnit.WEEK
    ? 350 * months
    : 20 * days
}

export const startDelayDaysValueGetter = (params: ValueGetterParams) => {
  if (!params.data || !params.data.wbsItem) return undefined
  const wbsItem: WbsItemDetail = params.data.wbsItem
  if (!wbsItem.scheduledDate?.startDate) return undefined
  const diff = new DateVO(wbsItem.actualDate?.startDate).diff(
    new DateVO(wbsItem.scheduledDate.startDate)
  )
  return diff <= 0 ? undefined : diff
}

export const endDelayDaysValueGetter = (params: ValueGetterParams) => {
  if (!params.data || !params.data.wbsItem) return undefined
  const wbsItem: WbsItemDetail = params.data.wbsItem
  if (!wbsItem.scheduledDate?.endDate) return undefined
  const diff = new DateVO(wbsItem.actualDate?.endDate).diff(
    new DateVO(wbsItem.scheduledDate.endDate)
  )
  return diff <= 0 ? undefined : diff
}

export const generateGanttChartCellRendererParams = (
  bulkSheet: ProjectPlanBulkSheetContext,
  parameter: GanttParameterVO
): { [key: string]: any } => {
  return {
    parameter: parameter,
    updateRows: (rows: ProjectPlanRow[]) => {
      const prevData = bulkSheet.rowDataManager.getAllRows()
      const startField = 'wbsItem.scheduledDate.startDate'
      const endField = 'wbsItem.scheduledDate.endDate'
      rows.forEach(row => {
        if (!row.editedData) {
          row.editedData = {}
        }
        const editedStart = row.editedData[startField]
        const editedEnd = row.editedData[endField]
        const prev = prevData.find(v => v.uuid === row.uuid)
        if (prev && prev.wbsItem.scheduledDate) {
          const prevDate = prev.wbsItem.scheduledDate
          const date = row.wbsItem.scheduledDate!
          if (editedStart) {
            editedStart === date.startDate && delete row.editedData[startField]
          } else if (prevDate.startDate !== date.startDate) {
            row.editedData['wbsItem.scheduledDate.startDate'] =
              prevDate.startDate
          }
          if (editedEnd) {
            editedEnd === date.endDate && delete row.editedData[endField]
          } else if (prevDate.endDate !== date.endDate) {
            row.editedData['wbsItem.scheduledDate.endDate'] = prevDate.endDate
          }
        }
        bulkSheet.rowDataManager.updateRow(row)
        store.dispatch(requireSave())
      })
    },
  }
}

export const getPasteColumnsCandidate = (ctx: ProjectPlanBulkSheetContext) => {
  const systemRequiredId = ['type', 'ticketListUuid', 'wbsItem.wbsItemType']
  const required = ['wbsItem.type', 'wbsItem.ticketType', 'wbsItem.displayName']
  const optional = [
    'wbsItem.status',
    'wbsItem.substatus',
    'wbsItem.priority',
    'wbsItem.difficulty',
    'wbsItem.description',
    'wbsItem.team',
    'wbsItem.accountable',
    'wbsItem.responsible',
    'wbsItem.assignee',
    'wbsItem.watchers',
    'wbsItem.currentSprint',
    'wbsItem.scheduledDate.startDate',
    'wbsItem.scheduledDate.endDate',
    'wbsItem.actualDate.startDate',
    'wbsItem.actualDate.endDate',
  ]

  const uimeta = ctx.viewMeta.functionMeta.properties.byId
  const prefix = ctx.viewMeta.externalIdPrefix
  const getLabel = (id: string): string => {
    const value = uimeta.get(prefix + '.' + id)
    return value ? value.name : ''
  }

  const systemRequiredCol = systemRequiredId.map(v => {
    return {
      path: v,
      label: '',
      defaultChecked: true,
      disabled: false,
      hidden: true,
    }
  })
  const requiredCol = required.map(v => {
    return {
      path: v,
      label: getLabel(v),
      defaultChecked: true,
      disabled: true,
      hidden: false,
    }
  })
  const optionalCol = optional.map(v => {
    return {
      path: v,
      label: getLabel(v),
      defaultChecked: false,
      disabled: false,
      hidden: false,
    }
  })
  const estimatedWorkloadCol = {
    path: 'wbsItem.estimatedWorkload',
    label: intl.formatMessage({
      id: 'dialog.contextMenu.paste.selectedCol.estimatedWorkload.label',
    }),
    defaultChecked: false,
    disabled: false,
    hidden: false,
  }
  return [
    ...systemRequiredCol,
    ...requiredCol,
    ...optionalCol,
    estimatedWorkloadCol,
  ]
}

export const copyRow = (nodes: RowNode[], ctx: ProjectPlanBulkSheetContext) => {
  const uuidMap: { [oldUuid: string]: string } = {}
  const copiedRows: ProjectPlanRow[] = []
  for (const node of nodes) {
    const copiedData: ProjectPlanRow = _.cloneDeep(node.data)
    const newRow = ctx.rowDataSpec.createNewRow(ctx, {
      type: copiedData.wbsItem.wbsItemType,
      ticketListUuid: copiedData.ticketListUuid,
    })
    uuidMap[copiedData.uuid] = newRow.uuid
    getPasteColumnsCandidate(ctx).forEach(col => {
      const copyValue = objects.getValue(copiedData, col.path)
      objects.setValue(newRow, col.path, copyValue)
    })
    if (!!uuidMap[copiedData.parentUuid || '']) {
      newRow.parentUuid = uuidMap[copiedData.parentUuid!]
    }
    copiedRows.push(newRow)
  }
  return copiedRows
}

const getDeliverableEstimatedAmount = (
  params: ValueGetterParams
): number | undefined => {
  const data: ProjectPlanRow = params.data
  if (data.wbsItem.wbsItemType?.isTask()) {
    return undefined
  }
  if (!params.node) return 0
  if (data.wbsItem.wbsItemType?.isDeliverable()) {
    return objects.getValue(params.node.data, 'wbsItem.estimatedAmount')
  }
  if (
    data.wbsItem.wbsItemType?.isWorkgroup() ||
    data.wbsItem.wbsItemType?.isProcess() ||
    data.wbsItem.wbsItemType?.isDeliverableList()
  ) {
    let aggregatedValue = 0
    params.node.allLeafChildren &&
      params.node.allLeafChildren.forEach(childNode => {
        const wbsItemType: WbsItemTypeVO = objects.getValue(
          childNode.data,
          `wbsItem.wbsItemType`
        )
        if (
          params.node!.id === childNode.id ||
          !wbsItemType?.isDeliverable() ||
          childNode.data.wbsItem.status === WbsItemStatus.DISCARD
        ) {
          return
        }
        const value = objects.getValue(
          childNode.data,
          'wbsItem.estimatedAmount'
        )
        aggregatedValue += value || 0
      })
    return aggregatedValue
  }
  return 0
}
const getTaskEstimatedAmount = (params: ValueGetterParams): number => {
  const data: ProjectPlanRow = params.data
  if (!params.node) return 0
  if (data.wbsItem.wbsItemType?.isTask()) {
    return objects.getValue(params.node.data, 'wbsItem.estimatedAmount')
  }
  if (
    data.wbsItem.wbsItemType?.isWorkgroup() ||
    data.wbsItem.wbsItemType?.isProcess() ||
    data.wbsItem.wbsItemType?.isDeliverableList() ||
    data.wbsItem.wbsItemType?.isDeliverable()
  ) {
    let aggregatedValue = 0
    params.node.allLeafChildren &&
      params.node.allLeafChildren.forEach(childNode => {
        const wbsItemType: WbsItemTypeVO = objects.getValue(
          childNode.data,
          `wbsItem.wbsItemType`
        )
        if (
          params.node!.id === childNode.id ||
          !wbsItemType?.isTask() ||
          childNode.data.wbsItem.status === WbsItemStatus.DISCARD
        ) {
          return
        }
        const value = objects.getValue(
          childNode.data,
          'wbsItem.estimatedAmount'
        )
        aggregatedValue += value || 0
      })
    return aggregatedValue
  }
  return 0
}
const getActualAmount = (params: ValueGetterParams): number => {
  const data: ProjectPlanRow = params.data
  if (!params.node) return 0
  if (data.wbsItem.wbsItemType?.isTask()) {
    return objects.getValue(params.node.data, 'wbsItem.actualAmount')
  }
  if (
    data.wbsItem.wbsItemType?.isWorkgroup() ||
    data.wbsItem.wbsItemType?.isProcess() ||
    data.wbsItem.wbsItemType?.isDeliverableList() ||
    data.wbsItem.wbsItemType?.isDeliverable()
  ) {
    let aggregatedValue = 0
    params.node.allLeafChildren &&
      params.node.allLeafChildren.forEach(childNode => {
        const wbsItemType: WbsItemTypeVO = objects.getValue(
          childNode.data,
          `wbsItem.wbsItemType`
        )
        if (
          params.node!.id === childNode.id ||
          !wbsItemType?.isTask() ||
          childNode.data.wbsItem.status === WbsItemStatus.DISCARD
        ) {
          return
        }
        const value = objects.getValue(childNode.data, 'wbsItem.actualAmount')
        aggregatedValue += value || 0
      })
    return aggregatedValue
  }
  return 0
}

const getRate = (numerator: number, denominator?: number) => {
  if (!denominator) return '-'
  return numerator / denominator
}

import { styled } from '@mui/material'
import {
  ComponentProps,
  CSSProperties,
  forwardRef,
  PropsWithChildren,
  RefObject,
  Ref,
} from 'react'
import { colorPalette } from '../../style/colorPallete'
import { useResizableArea } from './hooks/resizableArea'
import { ResizableArea } from '../../components/draggers/ResizableArea'

export const SingleSheetRoot = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  border: `1px solid ${colorPalette.monotone[2]}`,
  backgroundColor: colorPalette.monotone[0],
})

type MainAreaProps = Pick<CSSProperties, 'width'>
export const MainArea = styled('div')(({ width }: MainAreaProps) => ({
  width,
  height: '100%',
}))
type ResizableMainAreaProps = MainAreaProps & {
  singleSheetRootRef: RefObject<HTMLDivElement>
}
export const ResizableMainArea = ({
  singleSheetRootRef,
  children,
}: PropsWithChildren<ResizableMainAreaProps>) => {
  const { defaultWidth, maxWidth, height } =
    useResizableArea(singleSheetRootRef)
  return (
    <ResizableArea width={defaultWidth} maxWidth={maxWidth} height={height}>
      <MainArea>{children}</MainArea>
    </ResizableArea>
  )
}
export const SideBarArea = styled('div')({
  height: '100%',
  flex: '1 1 0%',
})

export const PanelRoot = styled('div')({
  width: '100%',
  height: '100%',
  overflowY: 'scroll',
})

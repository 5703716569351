import { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { formatDate, formatDateTimeWithDay } from '../../../../utils/date'
import { FontSize } from '../../../../styles/commonStyles'
import { TimerMeasuringTaskOfUser } from '../../../../domain/entity/TimerEntity'
import { intl } from '../../../../i18n'
import { convertUnixToHMS } from '../../../../utils/time'
import { useTimerRepository } from '../../../../services/adaptors/timerRepositoryAdapter'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../store'

const INTERVAL_FOR_API_REQUEST = 30000
const INTERVAL_FOR_RENDERING = 1000

interface TimerMeasuringTasksForDisplay {
  userUuid: string
  userName: string
  taskUuid: string
  taskName: string
  startTime: string
  elapsedTime: string
}

const RootDiv = styled('div')({
  width: '100%',
  height: 'calc(100% - 7px)',
  overflow: 'auto',
})
const TitleDiv = styled('div')({
  fontWeight: 'bold',
  marginBottom: '10px',
  fontSize: `${FontSize.LARGE}px`,
})
const HeaderCell = styled(TableCell)({
  padding: '8px',
  borderBottom: 'none',
  fontWeight: 'bold',
})

const nameFormat = (Name: string) => {
  return Name.length > 10 ? Name.slice(0, 10) + '...' : Name
}

const TimerMeasuringTaskList = () => {
  const [timerMeasuringTaskListData, setTimerMeasuringTaskListData] = useState<
    TimerMeasuringTaskOfUser[]
  >([])
  const [timerMeasuringTasksForDisplay, setTimerMeasuringTasksForDisplay] =
    useState<TimerMeasuringTasksForDisplay[]>([])
  const projectUuid = useSelector<AllState, string | undefined>(
    state => state.project.selected
  )
  const { getTimerMeasuringTaskList } = useTimerRepository()
  const today = formatDate(new Date())

  useEffect(() => {
    if (projectUuid) {
      const fetchTimerMeasuringTasks = async () => {
        const newTimerMeasuringTaskList = await getTimerMeasuringTaskList(
          projectUuid
        )
        setTimerMeasuringTaskListData(newTimerMeasuringTaskList)
      }
      fetchTimerMeasuringTasks()
      const intervalId = setInterval(
        fetchTimerMeasuringTasks,
        INTERVAL_FOR_API_REQUEST
      )
      return () => clearInterval(intervalId)
    }
  }, [projectUuid, getTimerMeasuringTaskList])

  useEffect(() => {
    const updateTimerMeasuringTaskList = () => {
      const formattedList = timerMeasuringTaskListData.map(item => {
        const startTime = new Date(item.startTime)
        const elapsedTime = new Date().getTime() - startTime.getTime()
        const formattedDate = formatDateTimeWithDay(item.startTime)

        return {
          userUuid: item.userUuid,
          userName: nameFormat(item.userName),
          taskUuid: item.taskUuid,
          taskName: `${nameFormat(item.parentDeliverableName)} / ${nameFormat(
            item.taskName
          )}`,
          startTime: formattedDate!.toString(),
          elapsedTime: convertUnixToHMS(elapsedTime),
        }
      })
      setTimerMeasuringTasksForDisplay(formattedList)
    }

    updateTimerMeasuringTaskList()
    const intervalId = setInterval(
      updateTimerMeasuringTaskList,
      INTERVAL_FOR_RENDERING
    )
    return () => clearInterval(intervalId)
  }, [timerMeasuringTaskListData])

  return timerMeasuringTaskListData.length === 0 ? (
    <RootDiv>
      <TitleDiv>{today}</TitleDiv>
      <div>
        {intl.formatMessage({
          id: 'timerMeasuringList.header.inoccupation',
        })}
      </div>
    </RootDiv>
  ) : (
    <RootDiv>
      <TitleDiv>{today}</TitleDiv>
      <Table aria-label="simple table" sx={{ boxShadow: 'none' }}>
        <TableHead sx={{ backgroundColor: '#F5F5F5' }}>
          <TableRow>
            <HeaderCell>
              {intl.formatMessage({
                id: 'timerMeasuringList.header.user',
              })}
            </HeaderCell>
            <HeaderCell>
              {intl.formatMessage({
                id: 'timerMeasuringList.header.task',
              })}
            </HeaderCell>
            <HeaderCell>
              {intl.formatMessage({
                id: 'timerMeasuringList.header.start',
              })}
            </HeaderCell>
            <HeaderCell>
              {intl.formatMessage({
                id: 'timerMeasuringList.header.elapsed',
              })}
            </HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timerMeasuringTasksForDisplay.map(timerMeasuringTaskList => (
            <TableRow
              key={`${timerMeasuringTaskList.userUuid}-${timerMeasuringTaskList.taskUuid}`}
            >
              <TableCell sx={{ width: '170px' }}>
                {timerMeasuringTaskList.userName}
              </TableCell>
              <TableCell>{timerMeasuringTaskList.taskName}</TableCell>
              <TableCell sx={{ width: '200px' }}>
                {timerMeasuringTaskList.startTime}
              </TableCell>
              <TableCell sx={{ width: '100px' }}>
                {timerMeasuringTaskList.elapsedTime}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </RootDiv>
  )
}

export default TimerMeasuringTaskList

import { DateV2, DateV2ForApiRequest, dateV2Service } from '../DateV2'

export type DateTermV2 = {
  startDate: DateV2 | undefined
  endDate: DateV2 | undefined
}
export type DateTermV2ForApiRequest = {
  startDate: DateV2ForApiRequest | undefined
  endDate: DateV2ForApiRequest | undefined
}

const isEqual = (
  a: DateTermV2 | undefined,
  b: DateTermV2 | undefined
): boolean => {
  if (!a && !b) return true
  if (!a || !b) return false
  return (
    dateV2Service.isEqual(a.startDate, b.startDate) &&
    dateV2Service.isEqual(a.endDate, b.endDate)
  )
}
const fromVoToApiRequest = (src: DateTermV2): DateTermV2ForApiRequest => {
  return {
    startDate: src.startDate
      ? dateV2Service.fromVoToApiRequest(src.startDate)
      : undefined,
    endDate: src.endDate
      ? dateV2Service.fromVoToApiRequest(src.endDate)
      : undefined,
  }
}

export const dateTermV2Service = {
  isEqual,
  fromVoToApiRequest,
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  SingleSheetPropertyId,
  SingleSheetProperty,
  SingleSheetPropertyValueType,
  SingleSheetPropertyType,
} from '../model/singleSheetProperty'
import { intl } from '../../../../i18n'
import {
  WbsItemAdditionalPropertyEntity,
  WbsItemAdditionalPropertyType,
} from '../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { i18nLabelVoService } from '../../../../domain/value-object/I18nLabelVO'
import { WbsItemEntityExtensionProperty } from '../../../pages/WbsItemV2/model/entityExtension'

// TODO: Now only required validation is implemented.
export const useSingleSheetFormValidation = (
  singleSheetProperties: SingleSheetProperty[]
): {
  validate: ValidateSingleSheetPropertyValue
  validateWbsItemAdditionalPropertyValue: ValidateSingleSheetWbsItemAdditionalPropertyValue
  validateEntityExtensionPropertyValue: ValidateSingleSheetEntityExtensionPropertyValue
  validationErrors: SingleSheetPropertiesValidationErrors
  hasValidationErrors: boolean
} => {
  const singleSheetPropertyMap = useMemo(() => {
    const map: SingleSheetPropertyMap = {}
    singleSheetProperties.forEach(v => {
      map[v.id] = v
    })
    return map
  }, [singleSheetProperties])
  const [validationErrors, setValidationErrors] =
    useState<SingleSheetPropertiesValidationErrors>(
      {} as SingleSheetPropertiesValidationErrors
    )
  const [hasValidationErrors, setHasValidationErrors] = useState<boolean>(false)

  const validate = useCallback(
    (
      singleSheetPropertyId: SingleSheetPropertyId,
      value: SingleSheetPropertyValueType
    ) => {
      const singleSheetProperty = singleSheetPropertyMap[singleSheetPropertyId]
      const validationErrors: SingleSheetPropertyValidationError[] = []
      if (!singleSheetProperty) return

      const requiredValidationError = validateRequiredProperty(
        singleSheetProperty,
        value
      )
      if (requiredValidationError) {
        validationErrors.push(requiredValidationError)
      }

      setValidationErrors(prev => {
        return {
          ...prev,
          [singleSheetPropertyId]: validationErrors,
        }
      })
    },
    [singleSheetPropertyMap]
  )
  // TODO Merge following method into the validate method.
  const validateWbsItemAdditionalPropertyValue = useCallback(
    (
      wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
      value: SingleSheetPropertyValueType
    ) => {
      const validationErrors: SingleSheetPropertyValidationError[] = []

      const requiredValidationError = validateRequiredWbsItemAdditionalProperty(
        wbsItemAdditionalProperty,
        value
      )
      if (requiredValidationError) {
        validationErrors.push(requiredValidationError)
      }

      setValidationErrors(prev => {
        return {
          ...prev,
          [wbsItemAdditionalProperty.uuid]: validationErrors,
        }
      })
    },
    []
  )
  // TODO Merge following method into the validate method.
  const validateEntityExtensionPropertyValue = useCallback(
    (
      entityExtensionProperty: WbsItemEntityExtensionProperty,
      value: SingleSheetPropertyValueType
    ) => {
      const validationErrors: SingleSheetPropertyValidationError[] = []

      const requiredValidationError = validateRequiredEntityExtensionProperty(
        entityExtensionProperty,
        value
      )
      if (requiredValidationError) {
        validationErrors.push(requiredValidationError)
      }

      setValidationErrors(prev => {
        return {
          ...prev,
          [entityExtensionProperty.uuid]: validationErrors,
        }
      })
    },
    []
  )

  useEffect(() => {
    const hasErrors = Object.values(validationErrors).some(
      v => (v as SingleSheetPropertyValidationErrors).length > 0
    )
    setHasValidationErrors(hasErrors)
  }, [singleSheetProperties, validationErrors])

  return {
    validate,
    validateWbsItemAdditionalPropertyValue,
    validateEntityExtensionPropertyValue,
    validationErrors,
    hasValidationErrors,
  }
}

// Validators.
const validateRequiredProperty = (
  singleSheetProperty: SingleSheetProperty,
  value: SingleSheetPropertyValueType
): SingleSheetPropertyValidationError | undefined => {
  if (!singleSheetProperty.required) return undefined

  switch (singleSheetProperty.type) {
    case SingleSheetPropertyType.TEXT:
    case SingleSheetPropertyType.MULTI_LINE_TEXT:
      if (value === undefined || value === '') {
        return intl.formatMessage(
          { id: 'validator.required' },
          {
            field: singleSheetProperty.label,
          }
        )
      }
      return undefined
    case SingleSheetPropertyType.MULTI_SELECT:
      if (value === undefined || (Array.isArray(value) && value.length === 0)) {
        return intl.formatMessage(
          { id: 'validator.required' },
          {
            field: singleSheetProperty.label,
          }
        )
      }
      return undefined
    case SingleSheetPropertyType.NUMBER:
    case SingleSheetPropertyType.DATE:
    case SingleSheetPropertyType.DATE_TIME:
    case SingleSheetPropertyType.CHECKBOX:
    case SingleSheetPropertyType.SELECT:
    case SingleSheetPropertyType.ENTITY_SEARCH:
      if (value === undefined) {
        return intl.formatMessage(
          { id: 'validator.required' },
          {
            field: singleSheetProperty.label,
          }
        )
      }
      return undefined
    case SingleSheetPropertyType.CHECKBOX: // Skip required validation because this value type is boolean.
    default:
      return
  }
}
const validateRequiredWbsItemAdditionalProperty = (
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  value: SingleSheetPropertyValueType
): SingleSheetPropertyValidationError | undefined => {
  if (!wbsItemAdditionalProperty.required) return undefined

  switch (wbsItemAdditionalProperty.propertyType) {
    case WbsItemAdditionalPropertyType.TEXT:
    case WbsItemAdditionalPropertyType.MULTI_LINE_TEXT:
      if (value === undefined || value === '') {
        return intl.formatMessage(
          { id: 'validator.required' },
          {
            field: i18nLabelVoService.getLabel(
              wbsItemAdditionalProperty.propertyNameI18n
            ),
          }
        )
      }
      return undefined
    case WbsItemAdditionalPropertyType.MULTI_SELECT:
      if (value === undefined || (Array.isArray(value) && value.length === 0)) {
        return intl.formatMessage(
          { id: 'validator.required' },
          {
            field: i18nLabelVoService.getLabel(
              wbsItemAdditionalProperty.propertyNameI18n
            ),
          }
        )
      }
      return undefined
    case WbsItemAdditionalPropertyType.NUMBER:
    case WbsItemAdditionalPropertyType.DATE:
    case WbsItemAdditionalPropertyType.DATE_TIME:
    case WbsItemAdditionalPropertyType.CHECKBOX:
    case WbsItemAdditionalPropertyType.SELECT:
    case WbsItemAdditionalPropertyType.ENTITY_SEARCH:
      if (value === undefined) {
        return intl.formatMessage(
          { id: 'validator.required' },
          {
            field: i18nLabelVoService.getLabel(
              wbsItemAdditionalProperty.propertyNameI18n
            ),
          }
        )
      }
      return undefined
    case WbsItemAdditionalPropertyType.CHECKBOX: // Skip required validation because this value type is boolean.
    default:
      return
  }
}
const validateRequiredEntityExtensionProperty = (
  entityExtensionProperty: WbsItemEntityExtensionProperty,
  value: SingleSheetPropertyValueType
): SingleSheetPropertyValidationError | undefined => {
  if (!entityExtensionProperty.required) return undefined

  switch (entityExtensionProperty.propertyType) {
    case WbsItemAdditionalPropertyType.TEXT:
    case WbsItemAdditionalPropertyType.MULTI_LINE_TEXT:
      if (value === undefined || value === '') {
        return intl.formatMessage(
          { id: 'validator.required' },
          {
            field: entityExtensionProperty.name,
          }
        )
      }
      return undefined
    case WbsItemAdditionalPropertyType.MULTI_SELECT:
      if (value === undefined || (Array.isArray(value) && value.length === 0)) {
        return intl.formatMessage(
          { id: 'validator.required' },
          {
            field: entityExtensionProperty.name,
          }
        )
      }
      return undefined
    case WbsItemAdditionalPropertyType.NUMBER:
    case WbsItemAdditionalPropertyType.DATE:
    case WbsItemAdditionalPropertyType.DATE_TIME:
    case WbsItemAdditionalPropertyType.CHECKBOX:
    case WbsItemAdditionalPropertyType.SELECT:
    case WbsItemAdditionalPropertyType.ENTITY_SEARCH:
      if (value === undefined) {
        return intl.formatMessage(
          { id: 'validator.required' },
          {
            field: entityExtensionProperty.name,
          }
        )
      }
      return undefined
    case WbsItemAdditionalPropertyType.CHECKBOX: // Skip required validation because this value type is boolean.
    default:
      return
  }
}

// Public types.
export type SingleSheetPropertyValidationError = string
export type SingleSheetPropertyValidationErrors =
  SingleSheetPropertyValidationError[]
export type SingleSheetPropertiesValidationErrors = {
  [key in SingleSheetPropertyId]: SingleSheetPropertyValidationError[]
}
export type ValidateSingleSheetPropertyValue = (
  singleSheetPropertyId: SingleSheetPropertyId,
  value: SingleSheetPropertyValueType
) => void
export type ValidateSingleSheetWbsItemAdditionalPropertyValue = (
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  value: SingleSheetPropertyValueType
) => void
export type ValidateSingleSheetEntityExtensionPropertyValue = (
  entityExtensionProperty: WbsItemEntityExtensionProperty,
  value: SingleSheetPropertyValueType
) => void

// Private types.
type SingleSheetPropertyMap = {
  [key: SingleSheetPropertyId]: SingleSheetProperty
}

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { TableHeader } from '../../../../containers/SingleSheetV2/table/Header'
import { TableRow } from '../../../../containers/SingleSheetV2/table/cells'
import { DateCell } from '../../../../containers/SingleSheetV2/table/cells/DateCell'
import { NumberCell } from '../../../../containers/SingleSheetV2/table/cells/NumberCell'
import { useKeyBind } from '../../../../hooks/useKeyBind'
import { useCallback, useEffect, useState } from 'react'
import { KEY_SAVE } from '../../../../model/keyBind'
import {
  DateV2,
  dateV2Service,
} from '../../../../../domain/value-object/DateV2'
import SubmitButton from '../button/SubmitButton'
import CloseButton from '../button/CloseButton'
import { useGetTaskActualWork } from '../../../../../applications/usecases/taskActualWork/getTaskActualWork'
import { UUIDV2 } from '../../../../../domain/value-object/UUIDV2'
import { useSaveTaskActualWork } from '../../../../../applications/usecases/taskActualWork/saveTaskActualWork'
import { useDispatch } from 'react-redux'
import { addScreenMessage, MessageLevel } from '../../../../../store/messages'
import { intl } from '../../../../../i18n'

type Props = {
  taskUuid: string
  // externalId: string
} & Omit<DialogState, 'openDialog'>
type DialogState = {
  open: boolean
  openDialog: () => void
  closeDialog: () => void
}

export const useTaskActualWorkRegistrationDialog = (): DialogState => {
  const [open, setOpen] = useState(false)
  const openDialog = useCallback(() => {
    setOpen(true)
  }, [])
  const closeDialog = useCallback(() => {
    setOpen(false)
  }, [])
  return { open, openDialog, closeDialog }
}
const TaskActualWorkRegistrationDialog = ({
  taskUuid,
  open,
  closeDialog,
}: Props) => {
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(false)
  const [workDate, setWorkDate] = useState(dateV2Service.today())
  const [workHour, setWorkHour] = useState(0)
  const { getMyActualWorkByTaskUuidAndDate } = useGetTaskActualWork()
  const { saveMyTaskActualWork } = useSaveTaskActualWork()

  const refreshWorkHour = useCallback(
    async (taskUuid: UUIDV2, workDate: DateV2) => {
      setProcessing(true)
      const taskActualWork = await getMyActualWorkByTaskUuidAndDate(
        taskUuid,
        workDate
      )
      setWorkHour(taskActualWork.hour)
      setProcessing(false)
    },
    [getMyActualWorkByTaskUuidAndDate]
  )
  useEffect(() => {
    refreshWorkHour(taskUuid, workDate)
  }, [taskUuid, workDate, refreshWorkHour])

  const onChangeWorkDate = useCallback(newValue => {
    setWorkDate(newValue)
  }, [])
  const onChangeWorkHour = useCallback(newValue => {
    setWorkHour(newValue)
  }, [])

  const submit = useCallback(async () => {
    if (processing) return
    setProcessing(true)

    await saveMyTaskActualWork({
      taskUuid,
      hour: workHour,
      actualDate: workDate,
    })
    dispatch(
      addScreenMessage('wbsItem', {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({ id: 'registration.complete' }),
      })
    )
    setProcessing(false)
  }, [taskUuid, workHour, workDate, saveMyTaskActualWork, dispatch, processing])

  useKeyBind(
    [{ key: KEY_SAVE, fn: submit, stopDefaultBehavior: true }],
    [submit]
  )

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogContent>
        <TableHeader title="作業実績" />
        <TableRow>
          <DateCell
            label="作業日"
            cellWidth="full"
            value={workDate}
            onChange={onChangeWorkDate}
          />
        </TableRow>
        <TableRow>
          <NumberCell
            label="作業時間"
            cellWidth="full"
            value={workHour}
            onChange={onChangeWorkHour}
            placeholder="0.00"
          />
        </TableRow>
      </DialogContent>
      <DialogActions>
        <SubmitButton onClick={submit} disabled={processing} />
        <CloseButton onClick={closeDialog} />
      </DialogActions>
    </Dialog>
  )
}

export default TaskActualWorkRegistrationDialog

import { useEffect, useState } from 'react'
import { useProjectRepository } from '../../../../services/adaptors/projectRepositoryAdaptor'
import { ProjectEntity } from '../../../../domain/entity/ProjectEntity'

export const useAssignedProject = () => {
  const { getAssigned } = useProjectRepository()
  const [assigned, setAssigned] = useState<ProjectEntity[]>([])
  useEffect(() => {
    const fn = async () => {
      const response = await getAssigned()
      setAssigned(response)
    }
    fn()
  }, [getAssigned])
  return {
    assigned,
  }
}

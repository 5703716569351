import { ColumnState } from 'ag-grid-community'
import { useCallback, useEffect, useMemo, useState } from 'react'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import { TicketType } from '../../../../lib/functions/ticket'

type StoredColumnAndFilterState = {
  column: ColumnState[]
  filter: { [key: string]: any }
}

export const useColumnAndFilterState = (
  searchConditionStateInited: boolean,
  functionUuid: string,
  projectUuid: string,
  ticketType: TicketType | undefined,
  ticketListUuid: string | undefined
) => {
  const [initialized, setInitialized] = useState(false)
  const [columnState, setColumnState] = useState<ColumnState[]>([])
  const [filterState, setFilterState] = useState<{ [key: string]: any }>({})
  const [restored, setRestored] = useState(false)

  const uiStateKey = useMemo(() => {
    const base = `${UiStateKey.TicketColumnAndFilterState}-${projectUuid}`
    return ticketType && ticketListUuid
      ? `${base}-${ticketType}-${ticketListUuid}`
      : base
  }, [projectUuid, ticketType, ticketListUuid])

  const restorePageState = useCallback(async () => {
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: uiStateKey,
      scope: UiStateScope.User,
    })
    const stored = response.json
    try {
      if (!stored.value) {
        return
      }
      const payload: StoredColumnAndFilterState = JSON.parse(stored.value)
      payload.column && setColumnState(payload.column)
      payload.filter && setFilterState(payload.filter)
    } finally {
      setInitialized(true)
    }
  }, [functionUuid, uiStateKey])

  const rememberPageState = useCallback(
    (value: StoredColumnAndFilterState) => {
      uiStates.update(
        {
          key: uiStateKey,
          scope: UiStateScope.User,
          value: JSON.stringify(value),
        },
        functionUuid
      )
    },
    [functionUuid, uiStateKey]
  )

  const saveImmediately = useCallback(() => {
    rememberPageState({
      column: columnState,
      filter: filterState,
    })
  }, [columnState, filterState, rememberPageState])

  const saveColumnState = useCallback(
    (s: ColumnState[]) => {
      if (!restored) return
      setColumnState(s)
    },
    [restored]
  )

  const saveFilterState = useCallback(
    s => {
      if (!restored) return
      setFilterState(s)
    },
    [restored]
  )

  const startRestoring = useCallback(() => setRestored(false), [])
  const finishRestoring = useCallback(() => setRestored(true), [])

  useEffect(() => {
    if (!searchConditionStateInited) return
    restorePageState()
  }, [searchConditionStateInited, restorePageState])

  useEffect(() => {
    if (!initialized) return
    if (!restored) return
    saveImmediately()
  }, [initialized, restored, saveImmediately])

  const result = useMemo(
    () => ({
      initialized,
      startRestoring,
      finishRestoring,
      columnState,
      saveColumnState,
      filterState,
      saveFilterState,
      saveImmediately,
    }),
    [
      initialized,
      startRestoring,
      finishRestoring,
      columnState,
      saveColumnState,
      filterState,
      saveFilterState,
      saveImmediately,
    ]
  )

  return result
}

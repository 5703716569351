import React from 'react'
import { TenantLogin } from './view/pages/authorizations-newdesign/TenantLogin'
import TenantPrivate from './view/pages/authorizations/TenantPrivate'
import Signedout from './view/pages/authorizations/Signedout'
import ChangePassword from './view/pages/authorizations/ChangePassword'
import { WelcomeNewUser } from './view/pages/authorizations-newdesign/WelcomeNewUser'
import {
  Prompt,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { styled } from '@mui/system'
import store, { AllState } from './store'
import { fetchFunctions } from './store/appFunction'
import components from './view/pages'
import { Function } from './lib/commons/appFunction'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import AlertDialog, { DialogProps } from './view/components/dialogs/AlertDialog'
import Auth from './lib/commons/auth'
import { ForgotPassword } from './view/pages/authorizations-newdesign/ForgotPassword'
import { ForgotPasswordSubmit } from './view/pages/authorizations-newdesign/ForgotPasswordSubmit'
import { ChangePasswordForNewUser } from './view/pages/authorizations-newdesign/ChangePasswordForNewUser'
import Loggedout from './view/pages/authorizations/Loggedout'
import { fetchNews, subscribeNews } from './store/news'
import GlobalAlert from './view/components/dialogs/GlobalAlert'
import { showAlert } from './store/globalAlert'
import SideMenu from './view/containers/SideMenu'
import Information from './view/containers/Information'
import EnvironmentInformation from './view/components/environment-informations/EnvironmentInformation'
import objects from './utils/objects'
import SnackBar from './view/components/snackbars/SnackBar'
import LayerDialog from './view/containers/LayerDialog'
import { Maintenance } from './view/pages/authorizations-newdesign/Maintenance'
import { UserInformationHeader } from './view/containers/UserInformationHeader'
import { RedirectProjectPlanLL } from './view/pages/router'
import { initializeWbsItemCommentUserSummaries } from './store/wbsItemCommentUserSummary'
import { ChangedPassword } from './view/pages/authorizations-newdesign/ChangedPassword'
import './styles.scss'
import { VerifyEmail } from './view/pages/authorizations-newdesign/VerifyEmail'

const RootContainer = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
})
const Content = styled('main')({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
})

interface ComponentOwnProps {}

interface StateProps {
  functions: Function[]
  currentExternalId?: string
  maintenanceMessage?: string
}

type Props = ComponentOwnProps &
  StateProps &
  WrappedComponentProps &
  RouteComponentProps

interface State {
  dialogState: DialogProps
  nextLocation?: string
  confirmNavigation?: boolean
}

class App extends React.Component<Props, State> {
  state: State = {
    dialogState: { isOpen: false },
  }

  openTransferConfirmationDialog = (nextLocation: string) => {
    this.setState({
      dialogState: {
        isOpen: true,
        title: this.props.intl.formatMessage({
          id: 'locationChange.confirm.title',
        }),
        message: this.props.intl.formatMessage({
          id: 'locationChange.confirm.message',
        }),
        submitButtonTitle: this.props.intl.formatMessage({
          id: 'yes',
        }),
        submitHandler: () => {
          this.setState({
            confirmNavigation: true,
            nextLocation,
            dialogState: {
              isOpen: false,
            },
          })
        },
        closeButtonTitle: this.props.intl.formatMessage({
          id: 'no',
        }),
      },
    })
  }

  closeDialog = () => {
    this.setState({
      confirmNavigation: false,
      dialogState: {
        isOpen: false,
      },
    })
  }
  async componentDidMount() {
    const tenant = Auth.getCurrentTenant()
    if (tenant && (await tenant.isLoggedin())) {
      if (!tenant.user) return
      const userUuid = tenant.user.uuid
      store.dispatch(fetchFunctions())

      // For news.
      store.dispatch(subscribeNews(userUuid))
      store.dispatch(fetchNews(userUuid, 0))
      // For wbsItemCommentUserSummary.
      store.dispatch(initializeWbsItemCommentUserSummaries(userUuid))
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.maintenanceMessage) {
      this.props.history.push('/maintenance')
      return
    }
    if (prevProps.currentExternalId !== this.props.currentExternalId) {
      const appFunction = this.props.functions.find(
        f => f.externalId === this.props.currentExternalId
      )
      if (!appFunction) {
        store.dispatch(
          showAlert({
            message: this.props.intl.formatMessage({
              id: 'global.warning.unauthorized.function',
            }),
          })
        )
      }
    }
    if (
      this.state.nextLocation === undefined ||
      prevState.nextLocation === this.state.nextLocation
    ) {
      if (this.state.confirmNavigation) {
        this.setState({
          nextLocation: undefined,
          confirmNavigation: false,
        })
      }
      return
    }
    this.props.history.push(this.state.nextLocation)
  }

  render() {
    const { functions, location } = this.props

    return (
      <RootContainer>
        <Prompt
          message={location => {
            if (
              location.pathname === this.props.history.location.pathname &&
              location.search === this.props.history.location.search
            ) {
              return false
            }
            if (
              this.state.confirmNavigation ||
              !store.getState().hasRequiredSaveData.hasRequiredSaveData ||
              !store.getState().user.authorized
            ) {
              return true
            }
            this.openTransferConfirmationDialog(location.pathname)
            return false
          }}
        />
        <GlobalAlert />
        <AlertDialog
          {...this.state.dialogState}
          closeHandler={this.closeDialog}
        />
        <Switch>
          <Route exact={true} path="/login" component={TenantLogin} />
          <Route exact={true} path="/loggedout" component={Loggedout} />
          <Route exact={true} path="/signedout" component={Signedout} />
          <Route
            exact={true}
            path="/changePasswordForNewUser/:email"
            component={ChangePasswordForNewUser}
          />
          <Route
            exact={true}
            path="/forgotPassword"
            component={ForgotPassword}
          />
          <Route
            exact={true}
            path="/forgotPasswordSubmit/:email"
            component={ForgotPasswordSubmit}
          />
          <Route
            exact={true}
            path="/changedPassword"
            component={ChangedPassword}
          />
          <Route
            exact={true}
            path="/welcomeNewUser"
            component={WelcomeNewUser}
          />
          <Route exact={true} path="/verifyEmail" component={VerifyEmail} />
          <Route exact={true} path="/maintenance" component={Maintenance} />
          <TenantPrivate>
            <>
              <SideMenu />
              <SnackBar />
              <EnvironmentInformation />
              <Content id="main">
                <UserInformationHeader />
                <Switch>
                  <Route exact={true} path="/" component={() => <></>} />
                  <Route
                    exact={true}
                    path="/changePassword"
                    component={ChangePassword}
                  />
                  <Route
                    exact={true}
                    path="/projectPlanLL"
                    component={RedirectProjectPlanLL}
                  />
                  <Route
                    exact={true}
                    path="/projectPlanLL/:code"
                    component={RedirectProjectPlanLL}
                  />
                  {functions.flatMap(func => {
                    const component = components[func.externalId]
                    if (component) {
                      const render = props => {
                        const componentKeys: string[] = [func.uuid] // Set unique key as react component key for remount on change url params.
                        const urlParams = props.match.params
                        const urlParamKeys = props.match.path
                          .split('/')
                          .filter(v => Boolean(v) && v.startsWith(':'))
                          .map(v => v.replace(':', ''))
                        const urlParamProps = {}
                        urlParamKeys.forEach(urlParamKey => {
                          const urlParam = urlParamKey
                            ? urlParams[urlParamKey]
                            : undefined
                          if (urlParam) {
                            componentKeys.push(urlParam)
                            objects.setValue(
                              urlParamProps,
                              urlParamKey,
                              urlParam
                            )
                          }
                        })
                        return React.createElement(component.class, {
                          ...props,
                          ...urlParamProps,
                          key: componentKeys.join('-'),
                          externalId: func.externalId,
                          uuid: func.uuid,
                          repository: component.repository,
                          options: component.options,
                          breadcrumbs: component.breadcrumbs,
                          useUiMetaOf: component.useUiMetaOf,
                          useFunctionUuidOf: component.useFunctionUuidOf,
                          cockpit: component.cockpit,
                          functionLayerDepth: 0,
                        })
                      }
                      const routeKey =
                        // @ts-ignore
                        func.uuid + (location.state?.forceRefreshKey || '')
                      const defaultRoute = (
                        <Route
                          key={routeKey}
                          exact={true}
                          path={component.defaultPath}
                          render={render}
                        />
                      )
                      const optionalRoutes = component.optionalPaths
                        ? component.optionalPaths.map(optPath => (
                            <Route
                              key={routeKey}
                              exact={true}
                              path={optPath}
                              render={render}
                            />
                          ))
                        : []
                      return [defaultRoute, ...optionalRoutes]
                    } else {
                      return []
                    }
                  })}
                </Switch>
              </Content>
              <Information />
              <LayerDialog depth={1} />
            </>
          </TenantPrivate>
        </Switch>
      </RootContainer>
    )
  }
}

const mapStateToProps = (state: AllState) => ({
  functions: state.appFunction.functions,
  currentExternalId: state.appFunction.currentExternalId,
  maintenanceMessage: state.tenant.maintenanceMessage,
})

const AppWithRouter = withRouter(injectIntl(App))

export default connect<StateProps, void, ComponentOwnProps, AllState>(
  mapStateToProps
)(AppWithRouter)

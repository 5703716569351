import React from 'react'
import { styled } from '@mui/system'
import { ResizableBox } from 'react-resizable'
import { Box } from '@mui/material'
import { colorPalette } from '../../style/colorPallete'

interface Props {
  width: number
  maxWidth: number
  minWidth?: number
  height: number
  children?: React.ReactNode | React.ReactNode[]
  onResize?: (newSize: { width: number; height: number }) => void
}

export const ResizableArea = ({
  width,
  height,
  maxWidth,
  minWidth,
  children,
  onResize,
}: Props) => {
  return (
    <ResizableBox
      width={width}
      height={height}
      maxConstraints={[maxWidth - 20, Infinity]}
      minConstraints={minWidth ? [minWidth, Infinity] : undefined}
      resizeHandles={['w', 'e']}
      handle={<DragLine />}
      onResize={onResize ? (_, data) => onResize(data.size) : undefined}
    >
      {children}
    </ResizableBox>
  )
}

const DragLine = styled(Box)({
  position: 'absolute',
  zIndex: 9999,
  width: '3px',
  height: '100%',
  top: 0,
  right: 0,
  cursor: 'col-resize',
  backgroundColor: 'transparent',
  transition: 'background 0.5s',
  '&:hover': {
    backgroundColor: colorPalette.skyBlue[2],
  },
})

import { useDispatch, useSelector } from 'react-redux'
import { AllState } from '../../store'
import {
  GroupHeaderLayout,
  WbsItemAdditionalPropertyLayoutEntity,
} from '../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { useCallback, useMemo, useRef } from 'react'
import { fetchWbsItemAdditionalProperties } from '../../store/wbsItemAdditionalProperty'
import { EntitySearchReferenceEntity } from '../../domain/entity/WbsItemAdditionalPropertyEntity'
import { ReferencedEntityWithIcon } from '../../domain/value-object/ReferencedEntity'
import { useWbsItemRepository } from '../../services/adaptors/wbsItemRepositoryAdaptor'
import { useProjectMemberRepository } from '../../services/adaptors/projectMemberRepositoryAdaptor'
import { useTeamRepository } from '../../services/adaptors/teamRepositoryAdaptor'
import { WbsItemType } from '../../domain/entity/WbsItemEntity'
import { WbsItemTypeVO } from '../../domain/value-object/WbsItemTypeVO'
import { useTicketRepository } from '../../services/adaptors/ticketRepositoryAdaptor'
import { BaseWbsItemType } from '../../store/project'
import { useProjectPrivateContext } from '../context/projectContext'

// Filtered by wbs item types.
export const useWbsItemAdditionalPropertiesOfBaseWbsItemTypes = () => {
  const { projectUuid } = useProjectPrivateContext()
  const baseWbsItemTypes = useSelector<AllState, BaseWbsItemType>(
    state => state.project.wbsItemTypes
  )
  const baseWbsItemTypeUuids = useMemo(
    () => baseWbsItemTypes.getAll().map(v => v.uuid),
    [baseWbsItemTypes]
  )
  const state = useWbsItemAdditionalProperties(
    projectUuid,
    baseWbsItemTypeUuids
  )

  return state
}
export const useWbsItemAdditionalProperties = (
  projectUuid: string,
  wbsItemTypeUuids?: string[]
): {
  fetched: boolean
  wbsItemAdditionalProperties: WbsItemAdditionalPropertyLayoutEntity | undefined
} => {
  const stateOfProject = useSelector<
    AllState,
    | {
        fetchedAt: number
        wbsItemAdditionalProperties:
          | WbsItemAdditionalPropertyLayoutEntity
          | undefined
      }
    | undefined
  >(state => state.wbsItemAdditionalProperty[projectUuid])
  const fetching = useRef(false)
  const dispatch = useDispatch()

  const filteredByWbsItemTypes = useMemo<
    WbsItemAdditionalPropertyLayoutEntity | undefined
  >(() => {
    if (!stateOfProject || !stateOfProject.wbsItemAdditionalProperties) {
      return
    }
    fetching.current = false
    if (!wbsItemTypeUuids || wbsItemTypeUuids.length === 0) {
      return stateOfProject.wbsItemAdditionalProperties
    }
    const original = stateOfProject.wbsItemAdditionalProperties
    const groupHeaderLayouts: GroupHeaderLayout[] = []
    // Filter properties by wbs item types.
    original.groupHeaderLayouts.forEach(groupHeaderLayout => {
      if (!groupHeaderLayout.propertyLayouts) return
      const propertyLayouts = groupHeaderLayout.propertyLayouts.filter(v => {
        const associatedWbsItemTypes = v.wbsItemAdditionalProperty.wbsItemTypes
        return (
          !associatedWbsItemTypes ||
          associatedWbsItemTypes.length === 0 ||
          associatedWbsItemTypes.some(associatedWbsItemType =>
            wbsItemTypeUuids.includes(associatedWbsItemType.uuid)
          )
        )
      })
      // Ignore group headers does not have child properties.
      if (propertyLayouts.length > 0) {
        groupHeaderLayouts.push({
          groupHeader: groupHeaderLayout.groupHeader,
          displayOrder: groupHeaderLayout.displayOrder,
          propertyLayouts,
        })
      }
    })
    return {
      projectUuid: original.projectUuid,
      groupHeaderLayouts,
    }
  }, [stateOfProject, wbsItemTypeUuids])

  if (!stateOfProject?.fetchedAt && !fetching.current) {
    fetching.current = true
    dispatch(fetchWbsItemAdditionalProperties(projectUuid))
    return {
      fetched: false,
      wbsItemAdditionalProperties: undefined,
    }
  }

  if (fetching.current) {
    return {
      fetched: false,
      wbsItemAdditionalProperties: undefined,
    }
  }

  return {
    fetched: true,
    wbsItemAdditionalProperties: filteredByWbsItemTypes,
  }
}

export type EntitySearchReferenceEntityRepository = {
  search: (
    projectUuid: string,
    text?: string
  ) => Promise<ReferencedEntityWithIcon[]>
}
export type GetEntitySearchReferenceEntityRepository = (
  entitySearchReferenceEntity: EntitySearchReferenceEntity
) => EntitySearchReferenceEntityRepository
export const useEntitySearchReferenceEntityRepositories = (
  projectUuid: string
) => {
  const wbsItemRepository = useWbsItemRepository()
  const ticketRepository = useTicketRepository()
  const projectMemberRepository = useProjectMemberRepository()
  const teamRepository = useTeamRepository()
  const getEntitySearchReferenceEntityRepository: GetEntitySearchReferenceEntityRepository =
    useCallback(
      (entitySearchReferenceEntity: EntitySearchReferenceEntity) => {
        switch (entitySearchReferenceEntity) {
          case EntitySearchReferenceEntity.PROCESS:
            return {
              search: (text?: string) =>
                wbsItemRepository.searchAsReferencedEntity(
                  projectUuid,
                  [WbsItemType.PROCESS],
                  text || ''
                ),
            }
          case EntitySearchReferenceEntity.DELIVERABLE_LIST:
            return {
              search: (text?: string) =>
                wbsItemRepository.searchAsReferencedEntity(
                  projectUuid,
                  [WbsItemType.DELIVERABLE_LIST],
                  text || ''
                ),
            }
          case EntitySearchReferenceEntity.DELIVERABLE:
            return {
              search: (text?: string) =>
                wbsItemRepository.searchAsReferencedEntity(
                  projectUuid,
                  [WbsItemType.DELIVERABLE],
                  text || ''
                ),
            }
          case EntitySearchReferenceEntity.TASK:
            return {
              search: (text?: string) =>
                wbsItemRepository.searchAsReferencedEntity(
                  projectUuid,
                  [WbsItemType.TASK],
                  text || ''
                ),
            }
          case EntitySearchReferenceEntity.REFINEMENT:
            return {
              search: (text?: string) =>
                ticketRepository.searchAsReferencedEntity(
                  projectUuid,
                  ['REFINEMENT'],
                  text || ''
                ),
            }
          case EntitySearchReferenceEntity.ISSUE:
            return {
              search: (text?: string) =>
                ticketRepository.searchAsReferencedEntity(
                  projectUuid,
                  ['ISSUE'],
                  text || ''
                ),
            }
          case EntitySearchReferenceEntity.TEAM:
            return {
              search: (text?: string) =>
                teamRepository.fetchListAsReferencedEntity(projectUuid),
            }
          case EntitySearchReferenceEntity.PROJECT_MEMBER:
            return {
              search: (text?: string) =>
                projectMemberRepository.searchAsReferencedEntity(
                  projectUuid,
                  text || ''
                ),
            }
          default:
            throw new Error(
              `This entity search reference entity(=${entitySearchReferenceEntity}) is unsupported. projectUuid=${projectUuid}.`
            )
        }
      },
      [projectUuid, wbsItemRepository, projectMemberRepository, teamRepository]
    )

  return { getEntitySearchReferenceEntityRepository }
}
export const useWbsItemAdditionalPropertiesBySingleType = (
  projectUuid: string,
  wbsItemType: WbsItemTypeVO
) => {
  const wbsItemTypeUuids = useMemo(() => {
    if (wbsItemType.baseTicketUuid) {
      return [wbsItemType.baseTicketUuid]
    } else if (wbsItemType.baseUuid) {
      return [wbsItemType.baseUuid]
    } else {
      return [wbsItemType.uuid]
    }
  }, [wbsItemType])
  return useWbsItemAdditionalProperties(projectUuid, wbsItemTypeUuids)
}
